export const RatingRatios = {
    'A': {
        letter: 'A',
        min: 0,
        max: 2,
        averageValue: 1.75,
        color: 'rgb(50, 178, 78)'
    },
    'B': {
        letter: 'B',
        min: 2,
        max: 2.5,
        averageValue: 2.25,
        color: 'rgb(200, 225, 180)'
    },
    'C': {
        letter: 'C',
        min: 2.5,
        max: 3,
        averageValue: 2.75,
        color: 'rgb(188, 177, 148)'
    },
    'D': {
        letter: 'D',
        min: 3,
        max: 3.5,
        averageValue: 3.25,
        color: 'rgb(250, 194, 14)'
    },
    'E': {
        letter: 'E',
        min: 3.5,
        max: 4,
        averageValue: 3.75,
        color: 'rgb(192, 90, 26)'
    },
    'F': {
        letter: 'F',
        min: 4,
        max: null,
        averageValue: 4.25,
        color: 'rgb(127, 61, 17)'
    }
}

export const getRatioNote = (value) => {
    let ratio = null;

    Object.keys(RatingRatios).forEach((key) => {
        let ratingRatio = RatingRatios[key];
        if (ratio === null && value >= ratingRatio.min && (ratingRatio.max === null || value < ratingRatio.max)) {
            ratio = ratingRatio;
        }
    });

    return ratio;
}

export const getRatioRange = (letter) => {
    if (RatingRatios[letter].max !== null) {
        return RatingRatios[letter].min + ' - ' + RatingRatios[letter].max;
    } else {
        return '>= ' + RatingRatios[letter].min;
    }
}