import React, {useContext, useEffect, useState} from "react";
import {Card, Col, Container, Row, Tab, Tabs} from "react-bootstrap";
import {getTranslation} from "../tools/Translation";
import {UserContext} from "../../contexts/UserContext";
import EquipmentList from "../caustic-soda/EquipmentList";
import Equipments from "../caustic-soda/Equipments";
import {CausticSodaQuote} from "../../model/CausticSoda/CausticSodaQuote";
import EquipmentsTables from "../caustic-soda/EquipmentsTables";
import Cips from "../caustic-soda/Cips";
// import {dataSet} from "../../model/CausticSoda/Dataset";
import Conclusion from "../caustic-soda/Conclusion";
import {QuoteContext} from "../../contexts/QuoteContext";
import {Apps} from "../../model/Apps";
import ClientData from "../caustic-soda/ClientData";

export default function CausticSoda(props) {
    const context = useContext(UserContext);
    const quoteContext = useContext(QuoteContext);

    const [quote, setQuote] = useState(new CausticSodaQuote());
    // Use a dataset in dev mode
    // const [quote, setQuote] = useState(new CausticSodaQuote(dataSet));
    const [initialRender, setInitialRender] = useState(true);

    const setProperty = (key, value) => {
        quote[key] = value;
        _setQuote(new CausticSodaQuote(quote));
    }

    const setEquipmentProperty = (id, key, value) => {
        quote.setEquipmentProperty(context, id, key, value);
        _setQuote(new CausticSodaQuote(quote));
    }

    // Before updating the state, trigger some code...
    const _setQuote = (quoteData) => {
        quoteContext.currentQuote.appRelatedData = quoteData;
        if (!quoteContext.currentQuote.isModified) {
            quoteContext.currentQuote.setIsModified(true);
            quoteContext.currentQuote.updateContextState();
        }
        setQuote(quoteData);
    }

    // When a quote is opened, load the data in the appRelatedData property
    useEffect(() => {
        if (initialRender) {
            setInitialRender(false);
        } else {
            if (quoteContext.currentQuote.application === Apps.CausticSoda) {
                // A quote has been opened
                setQuote(new CausticSodaQuote(quoteContext.currentQuote.appRelatedData));
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [quoteContext.currentQuote.id]);

    return (
        <>
            <Container fluid className="caustic-soda">
                <Tabs justify defaultActiveKey="general-info" variant="pills">
                    <Tab eventKey="general-info" title={getTranslation('cs.general-info', context.user.locale)}>
                        <Container fluid>
                            <ClientData quote={quote} setProperty={setProperty} />
                            <Row className="mt-3">
                                <Col>
                                    <Card>
                                        <Card.Header className="text-center"><strong>{getTranslation('cs.actuel-cip', context.user.locale)}</strong></Card.Header>
                                        <Card.Body>
                                            <Row className="mb-3">
                                                <Col>
                                                    <div className="custom-control custom-switch">
                                                        <input type="checkbox" className="custom-control-input" id="test-mode" checked={quoteContext.currentQuote.testMode} onChange={() => {quoteContext.currentQuote.setTestMode(!quoteContext.currentQuote.testMode);}} />
                                                        <label className="custom-control-label" htmlFor="test-mode">{getTranslation('cs.test-mode', context.user.locale)}</label>
                                                    </div>
                                                </Col>
                                            </Row>
                                            {false &&
                                                <button onClick={() => {console.log(JSON.stringify(quote))}}>Export state JSON</button>
                                            }
                                            <Cips quote={quote} setQuote={_setQuote} showAlternative={false} />
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                            <Row className="mt-3">
                                <Col>
                                    <Card>
                                        <Card.Header className="text-center"><strong>{getTranslation('cs.equipments', context.user.locale)}</strong></Card.Header>
                                        <Card.Body>
                                            <EquipmentList quote={quote} setQuote={_setQuote} setProperty={setProperty} setEquipmentProperty={setEquipmentProperty} />
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                            {quote.equipments.length > 0 &&
                            <Equipments quote={quote} setQuote={_setQuote} setProperty={setProperty} setEquipmentProperty={setEquipmentProperty} />
                            }
                        </Container>
                    </Tab>
                    <Tab eventKey="alternarive-methods" title={getTranslation('cs.alternative-methods', context.user.locale)}>
                        <Cips quote={quote} setQuote={_setQuote} showAlternative={true} />
                    </Tab>
                    <Tab eventKey="table" title={getTranslation('cs.equipments-table', context.user.locale)}>
                        <EquipmentsTables quote={quote} />
                    </Tab>
                    <Tab eventKey="conclusion" title={getTranslation('cs.conclusion-etude', context.user.locale)}>
                        <Conclusion quote={quote} />
                    </Tab>
                </Tabs>
            </Container>
        </>
    )
}