import React, {useContext} from "react";
import {getTranslation} from "../tools/Translation";
import {config} from "../config/Config";
import {UserContext} from "../../contexts/UserContext";

export default function TableCommonBody(props) {
    const context = useContext(UserContext);

    return (
        <>
            <td>
                {props.equipment.name}
            </td>
            <td>
                {props.equipment.ligne}
            </td>
            <td>
                {props.equipment.getTypeObject(context)?.getTranslation({fieldName: 'name', locale: context.user.locale})}
            </td>
            <td>
                {props.equipment.getEntrantMatiere(context)?.getTranslation({fieldName: 'name', locale: context.user.locale})}
            </td>
            <td>
                {props.equipment.getCircuitThermique(context)?.getTranslation({fieldName: 'name', locale: context.user.locale})}
            </td>
            <td>
                {getTranslation(config.yesNoOptions[props.equipment.circuitRecetteSensible ? props.equipment.circuitRecetteSensible : '0']?.name, context.user.locale)}
            </td>
            {props.quote.advancedMethod &&
                <td>
                    {props.equipment.getTypeCircuit(context)?.getTranslation({fieldName: 'name', locale: context.user.locale})}
                </td>
            }
            <td>
                {props.equipment.getCycleLavage(context)?.getTranslation({fieldName: 'name', locale: context.user.locale})}
            </td>
        </>
    );
}