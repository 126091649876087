import * as EasycalcApi from 'easycalc-api-client';
import EasycalcApiClient from './EasycalcApiClient';
import {setEntity, getLocalVersions} from "./Database"
import {Apps} from "../../model/Apps";

const endpointsByApp = {
    [Apps.Farm]: ['category', 'country', 'ph', 'product', 'species', 'unit', 'productgroup', 'language', 'translation', 'currency', 'consumptiontype', 'formulavariable', 'applicationtype', 'surface', 'protocol'], // globalvariable not used for now
    [Apps.WaterTreatment]: ['product', 'category', 'country', 'unit', 'productgroup', 'language', 'translation', 'currency'],
    [Apps.CausticSoda]: ['product', 'country', 'unit', 'productgroup', 'language', 'translation', 'currency', 'factoryobjecttype', 'inputproduct', 'recipe', 'washingtype', 'thermalcircuit', 'washingstep', 'recipematrix', 'producttype']
};

export function getEndpointsByApps(apps) {
    const endpoints = [];

    if (apps.length > 0) {
        apps.forEach((app) => {
            if (app in endpointsByApp) {
                endpointsByApp[app].forEach((endpoint) => {
                    if (!endpoints.includes(endpoint)) {
                        endpoints.push(endpoint);
                    }
                });
            }
        });
    }

    return endpoints;
}

export async function getVersions(apps) {
    let toDownload = [];

    let [distantVersions, localVersions] = await Promise.all([
        (new EasycalcApi.VersionApi(new EasycalcApiClient())).getVersions(),
        getLocalVersions()
    ]);

    const endpoints = getEndpointsByApps(apps);

    distantVersions.forEach((d) => {
        const dv = d.version;
        const de = d.entity;
        if (endpoints.indexOf(de) !== -1) {
            const l = localVersions[de];
            if (!l || l.version !== dv) {
                toDownload.push(d);
            }
        }
    });

    return toDownload;
}

export async function updateEntity(entity, addLoading, removeLoading, onSuccess, onError, resetPrices) {
    let apiClient = new EasycalcApiClient();

    addLoading();

    let successCallback = (data) => {
        let o;
        if (entity.entity !== 'translation') {
            let keyValueArray = {};
            data.forEach((obj) => {
                keyValueArray[obj.id] = obj;
            });
            o = {version: entity.version, data: keyValueArray};
            if (entity.entity === 'productgroup') {
                resetPrices(keyValueArray);
            }
        } else {
            let out = {};
            data.forEach((obj) => {
                obj.translations.forEach((t) => {
                    if (!out[obj.translationKey]) {
                        out[obj.translationKey] = {};
                    }
                    out[obj.translationKey][t.locale] = t.name;
                });
            })
            o = {version: entity.version, data: out};
        }

        setEntity(entity.entity, o).then(() => {
            if (typeof onSuccess === "function") {
                onSuccess(data);
            }
            removeLoading();
        }).catch(() => {
            removeLoading();
        });
    };

    let errorCallback = (error) => {
        if (typeof onError === "function") {
            onError(error);
        }
        removeLoading();
    };

    var api;

    // Don't forget to add items in the supported entities list
    switch (entity.entity) {
        case 'category':
            api = new EasycalcApi.CategoryApi(apiClient);
            api.getCategories().then(successCallback).catch(errorCallback);
            break;
        case 'country':
            api = new EasycalcApi.CountryApi(apiClient);
            api.getCountries().then(successCallback).catch(errorCallback);
            break;
        case 'ph':
            api = new EasycalcApi.PHApi(apiClient);
            api.getPhs().then(successCallback).catch(errorCallback);
            break;
        case 'product':
            api = new EasycalcApi.ProductApi(apiClient);
            api.getProducts().then(successCallback).catch(errorCallback);
            break;
        case 'species':
            api = new EasycalcApi.SpeciesApi(apiClient);
            api.getSpecies().then(successCallback).catch(errorCallback);
            break;
        case 'unit':
            api = new EasycalcApi.UnitApi(apiClient);
            api.getUnits().then(successCallback).catch(errorCallback);
            break;
        case 'productgroup':
            api = new EasycalcApi.ProductGroupApi(apiClient);
            api.getProductGroups().then(successCallback).catch(errorCallback);
            break;
        case 'language':
            api = new EasycalcApi.LanguageApi(apiClient);
            api.getLanguages().then(successCallback).catch(errorCallback);
            break;
        case 'translation':
            api = new EasycalcApi.TranslationApi(apiClient);
            api.getTranslations().then(successCallback).catch(errorCallback);
            break;
        case 'currency':
            api = new EasycalcApi.CurrencyApi(apiClient);
            api.getCurrencies().then(successCallback).catch(errorCallback);
            break;
        case 'consumptiontype':
            api = new EasycalcApi.ConsumptionTypeApi(apiClient);
            api.getConsumptionTypes().then(successCallback).catch(errorCallback);
            break;
        case 'formulavariable':
            api = new EasycalcApi.FormulasApi(apiClient);
            api.getFormulaVariables().then(successCallback).catch(errorCallback);
            break;
        case 'globalvariable':
            api = new EasycalcApi.FormulasApi(apiClient);
            api.getGlobalVariables().then(successCallback).catch(errorCallback);
            break;
        case 'applicationtype':
            api = new EasycalcApi.ProductApi(apiClient);
            api.getApplicationTypes().then(successCallback).catch(errorCallback);
            break;
        case 'surface':
            api = new EasycalcApi.SurfaceApi(apiClient);
            api.getSurfaces().then(successCallback).catch(errorCallback);
            break;
        case 'protocol':
            api = new EasycalcApi.ProtocolApi(apiClient);
            api.getProtocols().then(successCallback).catch(errorCallback);
            break;
        case 'factoryobjecttype':
            api = new EasycalcApi.CausticSodaApi(apiClient);
            api.getFactoryObjectTypes().then(successCallback).catch(errorCallback);
            break;
        case 'inputproduct':
            api = new EasycalcApi.CausticSodaApi(apiClient);
            api.getInputProducts().then(successCallback).catch(errorCallback);
            break;
        case 'recipe':
            api = new EasycalcApi.CausticSodaApi(apiClient);
            api.getRecipes().then(successCallback).catch(errorCallback);
            break;
        case 'washingtype':
            api = new EasycalcApi.CausticSodaApi(apiClient);
            api.getWashingTypes().then(successCallback).catch(errorCallback);
            break;
        case 'thermalcircuit':
            api = new EasycalcApi.CausticSodaApi(apiClient);
            api.getThermalCircuits().then(successCallback).catch(errorCallback);
            break;
        case 'washingstep':
            api = new EasycalcApi.CausticSodaApi(apiClient);
            api.getWashingSteps().then(successCallback).catch(errorCallback);
            break;
        case 'recipematrix':
            api = new EasycalcApi.CausticSodaApi(apiClient);
            api.getRecipeMatrix().then(successCallback).catch(errorCallback);
            break;
        case 'producttype':
            api = new EasycalcApi.CausticSodaApi(apiClient);
            api.getProductType().then(successCallback).catch(errorCallback);
            break;
        default:
            console.error('API CALL FOR UNKNOWN ENTITY: ' + entity.entity, entity);
            break;
    }
}
