export function shortenText(text, maxLength) {
    const threedots = '[…]';
    const dot = '●';

    // Usual trunc (and remove potential space)
    if (maxLength && text.length > maxLength) {
        text = text.substr(0, maxLength);
        if (text.slice(-1) === ' ') {
            text = text.slice(0, -1);
        }
        return text + '…';
    }

    // Dot trunk
    maxLength = 260;

    while (text.length > maxLength) {
        if (text.lastIndexOf(dot) > -1) {
            text = text.substr(0, text.lastIndexOf(dot)) + threedots;
        } else {
            text = text.substr(0, maxLength - threedots.length) + threedots;
        }
    }

    return text;
}

export function slugify(text) {
    return text.toLowerCase().replace(' ', '-');
}