import React from 'react';
import {Accordion, AccordionCollapse, AccordionToggle, Button, Card, ModalBody, ModalFooter, ModalTitle, Table} from "react-bootstrap";
import VFLigne from "./VFLigne";
import {getTranslation} from "../../tools/Translation";
import {sprintf} from "sprintf-js";
import {Modal} from "react-bootstrap";
import ModalHeader from "react-bootstrap/ModalHeader";
import {QuoteContext} from "../../../contexts/QuoteContext";

export default class ValorisationFourrages extends React.Component {

    state = {values: {}}


    sortSurfacesByCoupeAndName = (a, b) => {

        const UCLocale = this.context.currentQuote.getUserContext().user.locale;

        let c1 = a.nombreDeCoupe;
        let c2 = b.nombreDeCoupe;

        let n1 = a.getTranslation({fieldName: 'name', locale: UCLocale}).toLowerCase();
        let n2 = b.getTranslation({fieldName: 'name', locale: UCLocale}).toLowerCase();

        if (c1 < c2) return 1;
        if (c1 > c2) return -1;
        if (n1 < n2) return -1;
        if (n1 > n2) return 1;
        return 0;
    }

    handleCloseButtonClick = () => {
        this.context.currentQuote.hideCalculatorValorisation();
    }

    handleOKButtonClick = () => {
        this.context.currentQuote.setProductDosing(this.context.currentQuote.selectedProductData.categoryId, this.context.currentQuote.selectedProductData.product.id, this.getTotal());
        this.handleCloseButtonClick();
    }

    reset = () => this.setState({values: {}});

    updateValue = (id, value) => {
        let values = this.state.values;
        if (value) {
            values[id] = value;
        } else {
            delete (values[id]);
        }
        this.setState({values: values});
        this.getTotal();
    };

    getTotal = () => {
        let total = 0;
        Object.keys(this.state.values).forEach((e) => {
            total += this.state.values[e];
        });
        return total;
    }

    render() {

        if (!this.props.show) {
            return false;
        }

        const produit = this.context.currentQuote.selectedProductData.product || null;//this.props.source;
        let typesDeSurfaces = this.context.currentQuote.getUserContext().surface.data;
        if (!produit || !produit.surfaces || !typesDeSurfaces.length) {
            return false;
        }
        const UCLocale = this.context.currentQuote.getUserContext().user.locale;
        let sortedTypesDeSurfaces = Object.values(typesDeSurfaces);
        sortedTypesDeSurfaces.sort(this.sortSurfacesByCoupeAndName);
        //get maximum number of column groups
        let maximumGroupCount = 0;
        let descriptions = [];
        for (let i = 0; i < sortedTypesDeSurfaces.length; i++) {
            const s = sortedTypesDeSurfaces[i];
            if (produit.surfaces.includes(s.id)) {
                //check if s.id in product.surfaces array
                maximumGroupCount = s.nombreDeCoupe > maximumGroupCount ? s.nombreDeCoupe : maximumGroupCount;
                let specMS = parseFloat(s.tonnesBrutesParHectare * s.pourcentageMS).toFixed(2);
                let description = sprintf(getTranslation("%1$s: %2$s T de MS (%3$s T brutes/ha à %4$i%% de MS)", UCLocale),
                    s.getTranslation({fieldName: 'name', locale: UCLocale}),
                    specMS,
                    s.tonnesBrutesParHectare,
                    parseInt(s.pourcentageMS * 100)
                );
                descriptions.push(<tr key={i}>
                    <td className="descriptions">{description}</td>
                </tr>);
            }
        }

        const out = [];

        for (let i = 1; i <= maximumGroupCount; i++) {

            let coupeHeader = null;
            if (maximumGroupCount > 1) {
                coupeHeader = <div className="text-center tableCell title coupe" colSpan={6}>{(i === 1 ? '1re ' : i === 2 ? '2de ' : i === 3 ? '3ème ' : i) + getTranslation("coupe", UCLocale)}</div>;
            }
            out.push(
                <Card key={i} style={{border: 0}}>
                    <AccordionToggle style={{padding: 0, border: 0, marginBottom: 2}} eventKey={i}>{coupeHeader}</AccordionToggle>
                    <AccordionCollapse eventKey={i}>
                        <Table className="tableVF">
                            <thead>
                            <tr>
                                <th className="tableCell title taux">{produit.vfDosing && produit.vfDosing > 0 ? (produit.vfDosing + getTranslation("t brutes / sachet", UCLocale)) : null}</th>
                                <th className="tableCell title">{getTranslation("Taux de MS moyen (%)", UCLocale)}</th>
                                <th className="tableCell title">{getTranslation("Surface (ha)", UCLocale)}</th>
                                <th className="tableCell title">{getTranslation("Tonnes de MS/ha", UCLocale)}</th>
                                <th className="tableCell title yield">{getTranslation("Rendement brut par ha", UCLocale)}</th>
                                <th className="tableCell title dose">{getTranslation("Nombre de doses", UCLocale)}</th>
                            </tr>
                            </thead>
                            <tbody>
                            {Object.keys(sortedTypesDeSurfaces).map((key) => {
                                let surface = sortedTypesDeSurfaces[key];
                                if (produit.surfaces.includes(surface.id)) {
                                    let uKey = key + '-' + i;
                                    let filledGroup = <tr style={{flexWrap: "nowrap"}} key={uKey}>
                                        <VFLigne updateValue={this.updateValue} index={i} id={key + '-' + i} nom={surface.getTranslation({fieldName: 'name', locale: UCLocale})} dosing={produit.vfDosing ? produit.vfDosing : surface.defaultDosing}/>
                                    </tr>;

                                    return i <= surface.nombreDeCoupe ? filledGroup : null;
                                } else {
                                    return null;
                                }
                            })}
                            </tbody>
                        </Table>
                    </AccordionCollapse>
                </Card>
            );
        }

        out.push(
            <Table key={0} borderless>
                <tbody>
                <tr>
                    <td></td>
                    <td className="VFtotal">{this.getTotal()}</td>
                </tr>
                {descriptions}
                </tbody>
            </Table>
        )

        return <Modal show={this.props.show} size="xl" onHide={this.handleCloseButtonClick} onEntered={this.reset}>
            <ModalHeader>
                <ModalTitle style={{color: "rgb(148, 103, 6)", textAlign: "center", fontWeight: "bold"}}> {produit.name} </ModalTitle>
            </ModalHeader>
            <ModalBody>
                <Accordion defaultActiveKey={1}>
                    {out}
                </Accordion>
            </ModalBody>
            <ModalFooter>
                <Button variant="secondary" onClick={this.handleCloseButtonClick}>{getTranslation("Annuler", UCLocale)}</Button>
                <Button variant="primary" onClick={this.handleOKButtonClick}>{getTranslation("Ok", UCLocale)}</Button>
            </ModalFooter>
        </Modal>
    }
}

ValorisationFourrages.contextType = QuoteContext;
