import React, { Component } from 'react';
import classNames from "classnames";

export default class LoadingOverlay extends Component {
    render() {
        return (
            <>
                <div className={classNames({
                      overlay: true,
                      show: this.props.isLoading
                    })} />
                <div className={classNames({
                  spanner: true,
                  show: this.props.isLoading
                })}>
                    <div className="loader" />
                    <p>Loading data...</p>
                </div>
            </>
        )
    }
}