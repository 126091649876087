import React from "react";
import {config} from "../config/Config";
import {QuoteContext} from "../../contexts/QuoteContext";

export default class ProductImage extends React.Component {

    showProductDetails = (e) => {
        e.preventDefault();
        this.context.currentQuote.showProductDetailsPopup(this.props.product);
    }

    render() {
        let product = this.props.product;
        return (
            <a href="." onClick={this.showProductDetails}><img src={product.image ? product.image : config.productPlaceholderImage} alt={product.name}/></a>
        )
    }
}
ProductImage.contextType = QuoteContext;
