import React, {useContext} from "react";
import {Card, Col, Container, Row} from "react-bootstrap";
import {getTranslation} from "../tools/Translation";
import {UserContext} from "../../contexts/UserContext";
import CustomInput from "../tools/CustomInput";
import CustomSelect from "../tools/CustomSelect";
import {config} from "../config/Config";
import {slugify} from "../tools/String";

export default function EquipmentForm(props) {
    const context = useContext(UserContext);

    return (
        <Container fluid>
            <Row>
                <Col>
                    <Card>
                        <Card.Header className="text-center bg-primary text-white">{getTranslation('cs.basic-data', context.user.locale)}</Card.Header>
                        <Card.Body>
                            <CustomInput name='ligne' type={'text'} equipment={props.equipment} handleChange={props.setEquipmentProperty} errorFields={[]} />
                            <CustomSelect name='typeObjet' allowEmpty={true} values={context.factoryobjecttype?.data ? context.factoryobjecttype.data : {}} fieldName={'name'} equipment={props.equipment} handleChange={props.setEquipmentProperty} errorFields={[]} />
                            <CustomSelect name='entrantMatiere' allowEmpty={true} values={context.inputproduct?.data ? context.inputproduct.data : {}} fieldName={'name'} equipment={props.equipment} handleChange={props.setEquipmentProperty} errorFields={[]} />
                            <CustomSelect name='circuitThermique' allowEmpty={true} values={context.thermalcircuit?.data ? context.thermalcircuit.data : {}} fieldName={'name'} equipment={props.equipment} handleChange={props.setEquipmentProperty} errorFields={[]} />
                            <CustomSelect name='circuitRecetteSensible' allowEmpty={true} values={config.yesNoOptions} notTranslatable={true} fieldName={'name'} equipment={props.equipment} handleChange={props.setEquipmentProperty} errorFields={[]} />
                        </Card.Body>
                    </Card>
                </Col>
                <Col>
                    <Card>
                        <Card.Header className="text-center bg-primary text-white">{getTranslation('cs.initial-recipe', context.user.locale)}</Card.Header>
                        <Card.Body>
                            <CustomSelect name='cycleLavage' allowEmpty={true} values={context.recipe?.data ? context.recipe.data : {}} fieldName={'name'} equipment={props.equipment} handleChange={props.setEquipmentProperty} errorFields={[]} />
                            {Object.keys(context.washingstep.data).map((washingStepId) => {
                                if (props.quote.advancedMethod || (!props.quote.advancedMethod && !context.washingstep.data[washingStepId].advancedMethod)) {
                                    return (
                                        <CustomInput key={washingStepId} label={getTranslation('cs.duration-washing-step-' + slugify(context.washingstep.data[washingStepId].getTranslation({fieldName: 'name', locale: 'fr'})), context.user.locale)} name={'dureeWs' + washingStepId} advancedMethod={context.washingstep.data[washingStepId].advancedMethod} type={'number'} equipment={props.equipment} handleChange={props.setEquipmentProperty} errorFields={[]} />
                                    )
                                }

                                return null;
                            })}
                            <CustomInput name='frequenceRecettesMensuelles' type={'number'} equipment={props.equipment} handleChange={props.setEquipmentProperty} errorFields={[]} />
                            <CustomInput name='debitCircuit' type={'number'} equipment={props.equipment} handleChange={props.setEquipmentProperty} errorFields={[]} />
                            <CustomSelect name='maintientDesinfection' advancedMethod={false} allowEmpty={true} values={config.yesNoOptions} notTranslatable={true} fieldName={'name'} equipment={props.equipment} handleChange={props.setEquipmentProperty} errorFields={[]} />
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
}