import React from 'react';
import {Col, Row} from "react-bootstrap";
import PriceListProduct from "./PriceListProduct";
import PriceListPackaging from "./PriceListPackaging";
import {UserContext} from "../../contexts/UserContext";
import {QuoteContext} from "../../contexts/QuoteContext";

export default class PriceListLigne extends React.Component {
    render() {
        // réordonner les formats selon le poids total
        let productFormats = [];
        this.props.userContext.user.productFormats[this.props.data.id].forEach((formatId) => {
            this.props.data.formats.forEach((format) => {
                if (formatId === format.id) {
                    productFormats.push(format);
                }
            });
        });
        let orderedFormats = productFormats.sort((a, b) => (parseInt(a.value) > parseInt(b.value)) ? 1 : -1);
        return (
            <UserContext.Consumer>
                {({user, unit}) => {
                    if (!this.context.currentQuote.showProductWithNoFormat && orderedFormats.length === 0) {
                        return null;
                    }
                    return (
                        <Row className="product-line">
                            <Col className="product-detail p-0"><PriceListProduct data={this.props.data}/></Col>
                            <Col className="pr-0 packaging-wrapper">
                                <div className="m-0">
                                    <div className="p-0">
                                        <div className="packaging-box m-0">
                                            {orderedFormats.map((format) => {
                                                const unitObject = unit.data && unit.data[format.unit] ? unit.data[format.unit] : null;
                                                const unitLabel = unitObject ? unitObject.getTranslation({fieldName: 'name', locale: user.locale}) : '';
                                                const sizeLabelLocalized = format.getTranslation({fieldName: 'label', locale: user.locale});
                                                const sizeLabel = sizeLabelLocalized ? sizeLabelLocalized : (unitLabel ? format.value + ' ' + unitLabel : format.value);
                                                return <PriceListPackaging key={format.id} id={format.id} sizeLabel={sizeLabel} updateEmptyPackagingCount={this.props.updateEmptyPackagingCount}/>
                                            })}
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    );
                }}
            </UserContext.Consumer>
        );
    }
}
PriceListLigne.contextType = QuoteContext;
