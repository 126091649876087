import React from "react";
import {getTranslation} from "../tools/Translation";
import {QuoteContext} from "../../contexts/QuoteContext";

export default class NumberOfMonthsToggler extends React.Component {
    onClickHandler = () => {
        this.context.monthsToggler();
    }

    render() {
        return (
            <div className="number-of-months-toggler" onClick={this.onClickHandler}>
                {this.context.currentQuote.numberOfMonths} {getTranslation("M", this.props.locale)}
            </div>
        );
    }
}

NumberOfMonthsToggler.contextType = QuoteContext;