import React from 'react';

export default class VFLigne extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            averageTMS: undefined,
            surface: undefined,
            TMSha: undefined,
            yield: undefined,
            dose: undefined
        }
    }

    updateDoses = () => {
        let tmsP = this.state.averageTMS;
        let surf = this.state.surface;
        let tms = this.state.TMSha;
        let raw = this.props.dosing;
        let rendement = tmsP * tms ? (Math.round(tms * 100 / tmsP)).toFixed(1) : undefined;
        let dose = raw * tmsP ? Math.round(((surf * tms) / (tmsP / 100)) / raw) : undefined;
        this.setState({dose: dose ? dose : undefined});
        this.setState({yield: rendement ? rendement : undefined})
        this.props.updateValue(this.props.id, dose);
    }

    setAverageTMS = (e) => this.setState({averageTMS: e.target.value}, this.updateDoses);
    setSurface = (e) => this.setState({surface: e.target.value}, this.updateDoses);
    setTMSha = (e) => this.setState({TMSha: e.target.value}, this.updateDoses);

    render() {
        return (
            <>
                <td className="tableCell title">{this.props.nom}</td>
                <td className="tableCell content"><input onChange={this.setAverageTMS} defaultValue={this.state.averageTMS}/></td>
                <td className="tableCell content"><input onChange={this.setSurface} defaultValue={this.state.surface}/></td>
                <td className="tableCell content"><input onChange={this.setTMSha} defaultValue={this.state.TMSha}/></td>
                <td className="tableCell yield">{this.state.yield}</td>
                <td className="tableCell dose">{this.state.dose}</td>
            </>
        );
    }
}
