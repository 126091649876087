import {convertObjectsToEntity} from "../../model/Entity";
import {getEntityWithVersion} from "./Database";
import {Quote} from "../../model/Quote";
import {Product} from "../../model/Product";
import {Category} from "../../model/Category";
import Debug from "../tools/Debug";
import {getEndpointsByApps} from "./UpdateService";

export async function getDataFromStorage() {
    return Promise.all([
        convertObjectsToEntity(await getEntityWithVersion('category'), Category),
        convertObjectsToEntity(await getEntityWithVersion('country')),
        convertObjectsToEntity(await getEntityWithVersion('language')),
        convertObjectsToEntity(await getEntityWithVersion('ph')),
        convertObjectsToEntity(await getEntityWithVersion('product'), Product),
        convertObjectsToEntity(await getEntityWithVersion('species')),
        convertObjectsToEntity(await getEntityWithVersion('unit')),
        convertObjectsToEntity(await getEntityWithVersion('productgroup')),
        convertObjectsToEntity(await getEntityWithVersion('currency')),
        convertObjectsToEntity(await getEntityWithVersion('consumptiontype')),
        convertObjectsToEntity(await getEntityWithVersion('formulavariable')),
        convertObjectsToEntity(await getEntityWithVersion('globalvariable')),
        convertObjectsToEntity(await getEntityWithVersion('applicationtype')),
        convertObjectsToEntity(JSON.parse(localStorage.getItem('translation')) || {version: null, data: {}}),
        convertObjectsToEntity(await getEntityWithVersion('surface')),
        convertObjectsToEntity(await getEntityWithVersion('protocol')),
        convertObjectsToEntity(await getEntityWithVersion('factoryobjecttype')),
        convertObjectsToEntity(await getEntityWithVersion('inputproduct')),
        convertObjectsToEntity(await getEntityWithVersion('recipe')),
        convertObjectsToEntity(await getEntityWithVersion('washingtype')),
        convertObjectsToEntity(await getEntityWithVersion('thermalcircuit')),
        convertObjectsToEntity(await getEntityWithVersion('washingstep')),
        convertObjectsToEntity(await getEntityWithVersion('recipematrix')),
        convertObjectsToEntity(await getEntityWithVersion('producttype'))
    ]).then(([category, country, language, ph, product, species, unit, productgroup, currency, consumptiontype, formulavariable, globalvariable, applicationtype, translation, surface, protocol, factoryobjecttype, inputproduct, recipe, washingtype, thermalcircuit, washingstep, recipematrix, producttype]) => {
        return {category: category, country: country, ph: ph, product: product, species: species, unit: unit, language: language, productgroup: productgroup, translation: translation, currency: currency, consumptiontype: consumptiontype, formulavariable: formulavariable, globalvariable: globalvariable, applicationtype: applicationtype, surface: surface, protocol: protocol, factoryobjecttype: factoryobjecttype, inputproduct: inputproduct, recipe: recipe, washingtype: washingtype, thermalcircuit: thermalcircuit, washingstep: washingstep, recipematrix: recipematrix, producttype: producttype};
    });
}

export function getQuotesFromLocalStorage() {
    let o = JSON.parse(localStorage.getItem('quotes')) || [];
    let quotes = [];
    o.forEach((value) => {
        quotes.push(new Quote(value));
    });
    return quotes;
}

export function isMissingData(userContext) {
    let missing = false;
    let endpoints = getEndpointsByApps(userContext.user.apps);

    endpoints.forEach((entity) => {
        if (!userContext[entity] || !userContext[entity].data || Object.keys(userContext[entity].data).length === 0) {
            Debug.log('Missing data: ' + entity);
            missing = true;
            return false;
        }
    });
    return missing;
}
