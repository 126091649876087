import Dexie from 'dexie';

const database = new Dexie('easycalc');

database.version(1).stores({
    versions: 'entity, entity, version',
    category: 'id, data.id, data.isRobotMilking, data.costPerAnimalPerMonth, data.costPer1000Litre, data.isUdderHygiene, data.isCatalog',
    country: 'id, data.id, data.shortname, data.defaultCurrency',
    ph: 'id, data.id',
    product: 'id, data.id',
    species: 'id, data.id',
    unit: 'id, data.id',
    productgroup: 'id, data.id, data.name, data.pricesUpdateAt, data.priceUpdateBy',
    language: 'id, data.id, data.localeValue, data.fullLocale',
    translation: 'id, data.id, data.translationKey',
    currency: 'id, data.id, data.sign, data.iso',
    consumptiontype: 'id, data.id',
    formulavariable: 'id, data.id, data.constant, data.source, data.property, data.label, data.isEditable, data.defaultValue',
    applicationtype: 'id, data.id',
    surface: 'id, data.id, data.tonnesBrutesParHectare, data.pourcentageMS, data.nombreDeCoupe, data.defaultDosing',
    protocol: 'id, data.id',
    factoryobjecttype: 'id, data.id, data.circuitValue',
    inputproduct: 'id, data.id, data.circuitValue',
    recipe: 'id, data.id',
    washingtype: 'id, data.id, data.maxNotation',
    thermalcircuit: 'id, data.id, data.circuitValue',
    washingstep: 'id, data.id, data.advancedMethod, data.rince, data.productType',
    recipematrix: 'id, data.id, data.disinfection, data.value, data.washing_step, data.recipe, data.washing_type',
    producttype: 'id, data.id',
    globalvariable: 'id, data.id'
  });
  database.open();

const gv = async (entity) => 
{
    const response = await database.versions.get({entity: entity});   
    return response !== undefined ? response.version : -1;
}

const updateEntityVersion = async (entity, version) => {
    await database.versions.where("entity").equals(entity).modify({version: version});
}

const createEntityVersion = async (entity, version) => {
    await database.versions.add({entity: entity, version: version});
} 

export async function resetDB(){
    database.delete();
    window.location.reload();
}

export async function getEntityWithVersion(entity) {
    const v = await getVersion(entity);
    const d = await getEntity(entity);
    return { version: v, data: d };
}

async function getVersion(entity) {
    const version = await gv(entity);
    return version;
}

async function getEntity(entity) {
    const rows = await database[entity].toArray();
    return parseData(rows);
}

async function setEntityVersion(entity, newVersion){
    await gv(entity).then((version) => {
        if (version >= 0) {
            updateEntityVersion(entity, newVersion);
        } else {
            createEntityVersion(entity, newVersion);
        }
    })
}

export async function getLocalVersions() {
    const allVersions = await database.versions.toArray();
    let retval = [];
    allVersions.map((row) => {
        if(row.entity === "translation" && !localStorage.getItem('translation')){
            row.version = null;
        }
        return retval[row.entity] = { entity: row.entity, version: row.version };
    })

    return retval;
}

export async function deleteVersions() {
    return await database.versions.clear();
}

export async function setEntity(entity, d){
    if (entity === "translation") {
        localStorage.setItem(entity, JSON.stringify(d));
        await setEntityVersion(entity, d.version);
    } else {
        database[entity].clear();
        
        for(const id in d.data){
            const data = d.data[id];
            await database[entity].add({id: data.id, data});
        }
        await setEntityVersion(entity, d.version);
    }
}

function parseData(data) {
    let retval = [];
    for (let i = 0; i < data.length; i++) {
        let d = data[i];
        retval[d.id] = d.data;
    }
    return retval;
}
