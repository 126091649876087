import React from "react";
import {QuoteContext} from "../../contexts/QuoteContext";

export default class ProductFormats extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedFormat: null
        };
    }

    getProductPackages(productId) {
        const user = this.context.currentQuote.getUserContext().user;
        const packages = [];
        if (productId && user.productFormats && user.productFormats[productId] && user.productFormats[productId].length > 0) {
            const products = this.context.currentQuote.getUserContext().product.data;
            user.productFormats[productId].forEach((formatId) => {
                if (products[productId].formats && products[productId].formats.length > 0) {
                    products[productId].formats.forEach((format) => {
                        if (formatId === format.id) {
                            packages.push(format);
                        }
                    })
                }
            });
        }

        return packages;
    }

    setPriceAndName(format) {
        this.props.onChange(this.props.priceProperty, this.context.currentQuote.prices[format.id] ? this.context.currentQuote.prices[format.id] : 0);
        this.props.onChange(this.props.packageNameProperty, format.name);
        this.setState({
            selectedFormat: format
        })
    }

    render() {
        return (
            <div>
                {this.getProductPackages(this.props.productId).map((format) => {
                    let className = 'btn btn-sm btn-primary btn-package mr-2';
                    if (format === this.state.selectedFormat) {
                        className += ' selected-format';
                    }

                    return (
                        <button key={format.id} className={className} onClick={() => {this.setPriceAndName(format)}}>{format.name}</button>
                    )
                })}
            </div>
        );
    }
}

ProductFormats.contextType = QuoteContext;