import React from "react";
import {faPrint} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {QuoteContext} from "../../contexts/QuoteContext";
import PizZip from "pizzip";
import Docxtemplater from "docxtemplater";
import {saveAs} from "file-saver";
import PizZipUtils from "pizzip/utils";
import {CausticSodaQuote} from "../../model/CausticSoda/CausticSodaQuote";
import classNames from "classnames";
import {getTranslation} from "../tools/Translation";

export default class PrintPreviewIconCausticSoda extends React.Component {

    constructor(props) {
        super(props);
        this.supportedLocales = ['de', 'en', 'es', 'fr',  'pl'];
    }

    loadFile = (url, callback) => {
        PizZipUtils.getBinaryContent(url, callback);
    }

    generateDocFile = (error, content) => {
        if (error) {
            throw error;
        }

        let zip = new PizZip(content);
        let doc = new Docxtemplater(zip, {paragraphLoop: true, linebreaks: true});

        const causticSodaQuote = new CausticSodaQuote(this.context.currentQuote.appRelatedData);
        doc.render(causticSodaQuote.getPptxExportData(this.context.currentQuote.getUserContext(), this.context.currentQuote));
        let out = doc.getZip().generate({
            type: "blob",
            mimeType: "application/vnd.openxmlformats-officedocument.presentationml.presentation",
            compression: "DEFLATE"
        });

        const filename = this.context.currentQuote.name + '_' + this.context.currentQuote.locale + '.pptx';
        saveAs(out, filename);
    }

    canPrintPreview = () => {
        return this.context.currentQuote.appRelatedData
            && this.context.currentQuote.appRelatedData.equipments
            && this.context.currentQuote.appRelatedData.equipments.length > 0
            && this.supportedLocales.includes(this.context.currentQuote.locale);
    }

    showPrintPreview = (e) => {
        e.preventDefault();

        if (this.canPrintPreview()) {
            // Generate the file
            const filePath = process.env.PUBLIC_URL + '/assets/template/CausticSodaTemplate.' + this.context.currentQuote.locale + '.pptx';
            this.loadFile(
                filePath,
                this.generateDocFile
            )
        }
    }

    render() {
        return (
            <a href="#"
               onClick={(e) => this.showPrintPreview(e)}
               className={classNames({
                   'export-pptx-link-wrapper': true,
                   disabled: (!this.canPrintPreview())
               })}>
                <FontAwesomeIcon
                    className={"print-icon" + (!this.canPrintPreview() ? " light" : "")}
                    size={"lg"}
                    icon={faPrint}
                />
                <span className="export-pptx-link">
                    {getTranslation('cs.export-in-pptx', this.context.currentQuote.locale)}
                </span>
            </a>
        )
    }
}

PrintPreviewIconCausticSoda.contextType = QuoteContext;