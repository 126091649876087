import Config from '../../Config';
import moment from "moment/moment";

export default class Debug {
    static log = (...data) => {
        if (Config.Debug && Config.echoLog) {
            let time = new moment();
            console.log("[" + time.format("hh:mm:ss") + "]", data);
        }
    }
}