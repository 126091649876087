import React from 'react';
import {Modal, Row, Col} from 'react-bootstrap';
import {getTranslation} from "../tools/Translation";
import {UserContext} from "../../contexts/UserContext";
import kersiaLogo from "../../assets/images/logo-kersia.svg";
import classNames from "classnames";

export default class DistributorForm extends React.Component {
    onClickHandler = (e, productGroupId) => {
        e.preventDefault();
        if (productGroupId !== this.context.user.productGroup) {
            this.props.currentQuote.clearQuotes();
            this.context.user.setProductGroup(productGroupId);
        }
        this.props.toggleDistributorForm();
    }

    render() {
        if (!this.context || !this.context.productgroup || !this.context.productgroup.data || !this.context.user.productGroups) {
            return null;
        }

        let productsGroups = this.context.productgroup.data;

        return (
            <Modal
                show={this.props.display}
                keyboard={true}
                size="md"
                onHide={this.props.toggleDistributorForm}
                centered>
                <Modal.Header>
                    <Modal.Title>
                        {getTranslation('distributor.select', this.context.user.locale)}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row className="row-cols-{ this.context.user.productGroups.length < 3 ? 2 : 3 }">
                        { this.context.user.productGroups.map((productGroupId) => {
                            if (productsGroups[productGroupId]) {
                                return (
                                    <Col className={classNames({'distributor-form-logo': true, 'selected': this.context.user.productGroup === productGroupId})} key={productGroupId}>
                                        <a href="." onClick={(e) => this.onClickHandler(e, productGroupId)}>
                                            <span className="distributor-logo-wrapper">
                                                <img
                                                    src={productsGroups[productGroupId].image ? productsGroups[productGroupId].image : kersiaLogo}
                                                    alt={productsGroups[productGroupId].name}/>
                                            </span><br/>
                                            {productsGroups[productGroupId].name}
                                        </a>
                                    </Col>
                                )
                            } else {
                                return null;
                            }
                        })}
                    </Row>
                </Modal.Body>
            </Modal>
        );
    }
}

DistributorForm.contextType = UserContext;