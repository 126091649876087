import React, {useContext, useState} from "react";
import {Card} from "react-bootstrap";
import {ProductTypes} from "./ProductTypes";
import {getTranslation} from "../tools/Translation";
import Accordion from "react-bootstrap/Accordion";
import ProductSelect from "./ProductSelect";
import ProductInput from "./ProductInput";
import classNames from "classnames";
import {config} from "../config/Config";
import {QuoteContext} from "../../contexts/QuoteContext";
import {UserContext} from "../../contexts/UserContext";
import {CipTypes} from "../../model/CausticSoda/CipTypes";
import {getRatioRange, RatingRatios} from "../../model/CausticSoda/RatingRatios";

export default function Cip(props) {
    const userContext = useContext(UserContext);
    const quoteContext = useContext(QuoteContext);
    const productTypes = userContext.producttype?.data;

    const [displayInfos, setDisplayInfos] = useState(false);

    return (
        <Accordion className="mt-3" defaultActiveKey={props.activeKey}>
            <Card key={props.key}>
                <Accordion.Toggle as={Card.Header} eventKey={props.accordionKey} className="bg-primary text-white">
                    {props.title}
                </Accordion.Toggle>
                <Accordion.Collapse eventKey={props.accordionKey}>
                    <Card.Body>
                        <table className="table table-bordered table-fixed-width small text-center">
                            <thead>
                                <tr>
                                    <th>

                                    </th>
                                    <th>
                                        {productTypes[ProductTypes.Additive].getTranslation({fieldName: 'name', locale: userContext.user.locale})}
                                    </th>
                                    <th className="bg-warning">
                                        {productTypes[ProductTypes.CausticSoda].getTranslation({fieldName: 'name', locale: userContext.user.locale})}
                                    </th>
                                    <th className="bg-danger">
                                        {productTypes[ProductTypes.Acid].getTranslation({fieldName: 'name', locale: userContext.user.locale})}
                                    </th>
                                    <th className="bg-success">
                                        {productTypes[ProductTypes.Desinfectant].getTranslation({fieldName: 'name', locale: userContext.user.locale})}
                                    </th>
                                    <th className="bg-info">
                                        {getTranslation('cs.water', userContext.user.locale)}
                                    </th>
                                    <th className="bg-secondary">
                                        {getTranslation('cs.occupation-time', userContext.user.locale)}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="valign-middle">
                                        <strong>{getTranslation('cs.product-name', userContext.user.locale)}</strong>
                                    </td>
                                    <td className={classNames({'bg-disabled': !props.displayAdditive})}>
                                        {props.displayAdditive &&
                                            <ProductSelect onChange={(e) => {props.setCipProperty(props.type, ProductTypes.Additive, 'product', e.target.value)}} productType={ProductTypes.Additive} value={props.quote.cips[props.type].products[ProductTypes.Additive].product} />
                                        }
                                    </td>
                                    <td>
                                        <ProductSelect onChange={(e) => {props.setCipProperty(props.type, ProductTypes.CausticSoda, 'product', e.target.value)}} productType={ProductTypes.CausticSoda} value={props.quote.cips[props.type].products[ProductTypes.CausticSoda].product} />
                                    </td>
                                    <td>
                                        <ProductSelect onChange={(e) => {props.setCipProperty(props.type, ProductTypes.Acid, 'product', e.target.value)}} productType={ProductTypes.Acid} value={props.quote.cips[props.type].products[ProductTypes.Acid].product} />
                                    </td>
                                    <td>
                                        <ProductSelect onChange={(e) => {props.setCipProperty(props.type, ProductTypes.Desinfectant, 'product', e.target.value)}} productType={ProductTypes.Desinfectant} value={props.quote.cips[props.type].products[ProductTypes.Desinfectant].product} />
                                    </td>
                                    <td className="bg-disabled">

                                    </td>
                                    <td className="bg-disabled">

                                    </td>
                                </tr>
                                <tr>
                                    <td className="valign-middle">
                                        <strong>{getTranslation('cs.concentration-naoh-hno3', userContext.user.locale)}</strong>
                                    </td>
                                    <td className="bg-disabled">

                                    </td>
                                    <td>
                                        <ProductInput disabled={!props.editSoda} onChange={(e) => {props.setCipProperty(props.type, ProductTypes.CausticSoda, 'concentrationNaohHno3', e.target.value)}} value={props.quote.cips[props.type].products[ProductTypes.CausticSoda].concentrationNaohHno3} valueType={userContext.unit?.data[config.unitPercent]?.getTranslation({fieldName: 'name', locale: userContext.user.locale})} />
                                    </td>
                                    <td className={classNames({'bg-disabled': [CipTypes.monoAlternate, CipTypes.monoSodaAdditive].includes(props.type)})}>
                                        {![CipTypes.monoAlternate, CipTypes.monoSodaAdditive].includes(props.type) &&
                                            <ProductInput disabled={!props.editAcid} onChange={(e) => {props.setCipProperty(props.type, ProductTypes.Acid, 'concentrationNaohHno3', e.target.value)}} value={props.quote.cips[props.type].products[ProductTypes.Acid].concentrationNaohHno3} valueType={userContext.unit?.data[config.unitPercent]?.getTranslation({fieldName: 'name', locale: userContext.user.locale})} />
                                        }
                                    </td>
                                    <td className="bg-disabled">

                                    </td>
                                    <td className="bg-disabled">

                                    </td>
                                    <td className="bg-disabled">

                                    </td>
                                </tr>
                                <tr>
                                    <td className="valign-middle">
                                        <strong>{getTranslation('cs.product-concentration', userContext.user.locale)}</strong>
                                    </td>
                                    <td className={classNames({'bg-disabled': !props.displayAdditive})}>
                                        {props.displayAdditive &&
                                            <ProductInput disabled={!props.editAdditive} onChange={(e) => {props.setCipProperty(props.type, ProductTypes.Additive, 'concentration', e.target.value)}} value={props.quote.cips[props.type].products[ProductTypes.Additive].concentration} valueType={userContext.unit?.data[config.unitPercent]?.getTranslation({fieldName: 'name', locale: userContext.user.locale})} />
                                        }
                                    </td>
                                    <td>
                                        <ProductInput disabled={!props.editSoda} onChange={(e) => {props.setCipProperty(props.type, ProductTypes.CausticSoda, 'concentration', e.target.value)}} value={props.quote.cips[props.type].products[ProductTypes.CausticSoda].concentration} valueType={userContext.unit?.data[config.unitPercent]?.getTranslation({fieldName: 'name', locale: userContext.user.locale})} />
                                    </td>
                                    <td>
                                        <ProductInput disabled={!props.editAcid} onChange={(e) => {props.setCipProperty(props.type, ProductTypes.Acid, 'concentration', e.target.value)}} value={props.quote.cips[props.type].products[ProductTypes.Acid].concentration} valueType={userContext.unit?.data[config.unitPercent]?.getTranslation({fieldName: 'name', locale: userContext.user.locale})} />
                                    </td>
                                    <td>
                                        <ProductInput disabled={!props.editDesinfectant} onChange={(e) => {props.setCipProperty(props.type, ProductTypes.Desinfectant, 'concentration', e.target.value)}} value={props.quote.cips[props.type].products[ProductTypes.Desinfectant].concentration} valueType={userContext.unit?.data[config.unitPercent]?.getTranslation({fieldName: 'name', locale: userContext.user.locale})} />
                                    </td>
                                    <td className="bg-disabled">

                                    </td>
                                    <td className="bg-disabled">

                                    </td>
                                </tr>
                                <tr>
                                    <td className="valign-middle">
                                        <strong>{getTranslation('cs.annual-volume', userContext.user.locale)}</strong>
                                    </td>
                                    <td className={classNames({'bg-disabled': !props.displayAdditive})}>
                                        {props.displayAdditive &&
                                            <ProductInput disabled={true} onChange={(e) => {props.setCipProperty(props.type, ProductTypes.Additive, 'volume', e.target.value)}} value={props.quote.cips[props.type].products[ProductTypes.Additive].volume} valueType={userContext.unit?.data[config.unitKg]?.getTranslation({fieldName: 'name', locale: userContext.user.locale})} />
                                        }
                                    </td>
                                    <td>
                                        <ProductInput disabled={!props.editSoda || props.type === CipTypes.monoAlternate || props.type === CipTypes.sodaAdditive} onChange={(e) => {props.setCipProperty(props.type, ProductTypes.CausticSoda, 'volume', e.target.value)}} value={props.quote.cips[props.type].products[ProductTypes.CausticSoda].volume} valueType={userContext.unit?.data[config.unitKg]?.getTranslation({fieldName: 'name', locale: userContext.user.locale})} />
                                    </td>
                                    <td>
                                        <ProductInput disabled={!props.editAcid || props.type === CipTypes.monoAlternate} onChange={(e) => {props.setCipProperty(props.type, ProductTypes.Acid, 'volume', e.target.value)}} value={props.quote.cips[props.type].products[ProductTypes.Acid].volume} valueType={userContext.unit?.data[config.unitKg]?.getTranslation({fieldName: 'name', locale: userContext.user.locale})} />
                                    </td>
                                    <td>
                                        <ProductInput disabled={!props.editDesinfectant || props.type === CipTypes.monoAlternate} onChange={(e) => {props.setCipProperty(props.type, ProductTypes.Desinfectant, 'volume', e.target.value)}} value={props.quote.cips[props.type].products[ProductTypes.Desinfectant].volume} valueType={userContext.unit?.data[config.unitKg]?.getTranslation({fieldName: 'name', locale: userContext.user.locale})} />
                                    </td>
                                    <td className="valign-middle">
                                        <ProductInput disabled={true} onChange={null} value={props.quote.cips[props.type].products[ProductTypes.Water].volume} valueType={userContext.unit?.data[config.unitCubicMeters]?.getTranslation({fieldName: 'name', locale: userContext.user.locale})} />
                                    </td>
                                    <td className="valign-middle">
                                        <ProductInput disabled={true} onChange={null} value={props.quote.cips[props.type].products[ProductTypes.OccupationTime].volume} valueType={userContext.unit?.data[config.unitHours]?.getTranslation({fieldName: 'name', locale: userContext.user.locale})} />
                                    </td>
                                </tr>
                                <tr>
                                    <td className="valign-middle">
                                        <strong>{getTranslation('cs.pricing', userContext.user.locale)}</strong>
                                    </td>
                                    <td className={classNames({'bg-disabled': !props.displayAdditive})}>
                                        {props.displayAdditive &&
                                            <ProductInput disabled={!props.editAdditive} onChange={(e) => {props.setCipProperty(props.type, ProductTypes.Additive, 'pricing', e.target.value)}} value={props.quote.cips[props.type].products[ProductTypes.Additive].pricing} valueType={quoteContext.currentQuote.getCurrencySign() + '/' + userContext.unit?.data[config.unitKg]?.getTranslation({fieldName: 'name', locale: userContext.user.locale})} digits={3} />
                                        }
                                    </td>
                                    <td>
                                        <ProductInput disabled={!props.editSoda} onChange={(e) => {props.setCipProperty(props.type, ProductTypes.CausticSoda, 'pricing', e.target.value)}} value={props.quote.cips[props.type].products[ProductTypes.CausticSoda].pricing} valueType={quoteContext.currentQuote.getCurrencySign() + '/' + userContext.unit?.data[config.unitKg]?.getTranslation({fieldName: 'name', locale: userContext.user.locale})} digits={3} />
                                    </td>
                                    <td>
                                        <ProductInput disabled={!props.editAcid} onChange={(e) => {props.setCipProperty(props.type, ProductTypes.Acid, 'pricing', e.target.value)}} value={props.quote.cips[props.type].products[ProductTypes.Acid].pricing} valueType={quoteContext.currentQuote.getCurrencySign() + '/' + userContext.unit?.data[config.unitKg]?.getTranslation({fieldName: 'name', locale: userContext.user.locale})} digits={3} />
                                    </td>
                                    <td>
                                        <ProductInput disabled={!props.editDesinfectant} onChange={(e) => {props.setCipProperty(props.type, ProductTypes.Desinfectant, 'pricing', e.target.value)}} value={props.quote.cips[props.type].products[ProductTypes.Desinfectant].pricing} valueType={quoteContext.currentQuote.getCurrencySign() + '/' + userContext.unit?.data[config.unitKg]?.getTranslation({fieldName: 'name', locale: userContext.user.locale})} digits={3} />
                                    </td>
                                    <td>
                                        <ProductInput disabled={!props.editWater} onChange={(e) => {props.setCipProperty(props.type, ProductTypes.Water, 'pricing', e.target.value)}} value={props.quote.cips[props.type].products[ProductTypes.Water].pricing} valueType={quoteContext.currentQuote.getCurrencySign() + '/' + userContext.unit?.data[config.unitCubicMeters]?.getTranslation({fieldName: 'name', locale: userContext.user.locale})} digits={3} />
                                    </td>
                                    <td className="bg-disabled">

                                    </td>
                                </tr>
                                <tr>
                                    <td className="valign-middle">
                                        <strong>{getTranslation('cs.annual-ca', userContext.user.locale)}</strong>
                                    </td>
                                    <td className={classNames({'bg-disabled': !props.displayAdditive})}>
                                        {props.displayAdditive &&
                                            <ProductInput classToAdd={'text-danger'} disabled={true} onChange={null} value={parseFloat(props.quote.cips[props.type].products[ProductTypes.Additive].annualCa).toFixed(0)} valueType={quoteContext.currentQuote.getCurrencySign()} />
                                        }
                                    </td>
                                    <td>
                                        <ProductInput classToAdd={'text-danger'} disabled={true} onChange={null} value={parseFloat(props.quote.cips[props.type].products[ProductTypes.CausticSoda].annualCa).toFixed(0)} valueType={quoteContext.currentQuote.getCurrencySign()} />
                                    </td>
                                    <td>
                                        <ProductInput classToAdd={'text-danger'} disabled={true} onChange={null} value={parseFloat(props.quote.cips[props.type].products[ProductTypes.Acid].annualCa).toFixed(0)} valueType={quoteContext.currentQuote.getCurrencySign()} />
                                    </td>
                                    <td>
                                        <ProductInput classToAdd={'text-danger'} disabled={true} onChange={null} value={parseFloat(props.quote.cips[props.type].products[ProductTypes.Desinfectant].annualCa).toFixed(0)} valueType={quoteContext.currentQuote.getCurrencySign()} />
                                    </td>
                                    <td>
                                        <ProductInput classToAdd={'text-danger'} disabled={true} onChange={null} value={parseFloat(props.quote.cips[props.type].products[ProductTypes.Water].annualCa).toFixed(0)} valueType={quoteContext.currentQuote.getCurrencySign()} />
                                    </td>
                                    <td className="bg-disabled">

                                    </td>
                                </tr>
                                <tr>
                                    <td className="no-border">&nbsp;</td>
                                    <td className="no-border">&nbsp;</td>
                                    <td className="no-border">&nbsp;</td>
                                    <td className="no-border">&nbsp;</td>
                                    <td className="no-border">&nbsp;</td>
                                    <td className="no-border">&nbsp;</td>
                                </tr>
                                <tr>
                                    <td>&nbsp;</td>
                                    <td className="text-danger">
                                        <strong>{getTranslation('cs.global-cost', userContext.user.locale)}</strong>
                                    </td>
                                    <td>
                                        {props.displayDelta &&
                                            <strong>{getTranslation('cs.delta', userContext.user.locale)}</strong>
                                        }
                                    </td>
                                    <td>&nbsp;</td>
                                    <td className={classNames({'bg-secondary': props.displayCo2EmissionsDelta})}>
                                        {props.displayCo2EmissionsDelta &&
                                            <strong>{getTranslation('cs.co2-emissions', userContext.user.locale)}*</strong>
                                        }
                                    </td>
                                    <td className={classNames({'bg-info': props.displayWaterOptimisation})}>
                                        {props.displayWaterOptimisation &&
                                            <>
                                                <strong>{getTranslation('cs.optimized-water', userContext.user.locale)}</strong>
                                            </>
                                        }
                                    </td>
                                    <td className={classNames({'bg-secondary': props.displayTimeOptimization})}>
                                        {props.displayTimeOptimization &&
                                            <>
                                                <strong>{getTranslation('cs.optimized-time', userContext.user.locale)}</strong>
                                            </>
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <td>&nbsp;</td>
                                    <td>
                                        <ProductInput classToAdd={'text-danger'} disabled={true} onChange={null} value={props.quote.cips[props.type].globalCost} valueType={quoteContext.currentQuote.getCurrencySign()} />
                                    </td>
                                    <td>
                                        {props.displayDelta &&
                                            <>
                                                <ProductInput classToAdd={'text-success'} disabled={true} onChange={null} value={props.quote.cips[props.type].delta} valueType={quoteContext.currentQuote.getCurrencySign()} />
                                            </>
                                        }
                                    </td>
                                    <td>&nbsp;</td>
                                    {props.displayCo2EmissionsDelta && isNaN(props.quote.cips[props.type].co2EmissionsDelta) &&
                                        <td className={"valign-middle"}>
                                            <span>Élément non calculable</span>
                                        </td>
                                    }
                                    {props.displayCo2EmissionsDelta && !isNaN(props.quote.cips[props.type].co2EmissionsDelta) &&
                                        <td>
                                            <ProductInput disabled={true} onChange={null}
                                                          value={props.quote.cips[props.type].co2EmissionsDelta}
                                                          valueType={userContext.unit?.data[config.unitPercent]?.getTranslation({fieldName: 'name', locale: userContext.user.locale})}
                                                          classToAdd={props.quote.cips[props.type].co2EmissionsDelta > 0 ? 'text-danger' : 'text-success'}
                                            />
                                        </td>
                                    }
                                    <td>
                                        {props.displayWaterOptimisation &&
                                            <>
                                                <ProductInput disabled={true} onChange={null} value={props.quote.cips[props.type].optimizedWater} valueType={userContext.unit?.data[config.unitCubicMeters]?.getTranslation({fieldName: 'name', locale: userContext.user.locale})} />
                                            </>
                                        }
                                    </td>
                                    <td>
                                        {props.displayTimeOptimization &&
                                            <>
                                                <ProductInput disabled={true} onChange={null} value={props.quote.cips[props.type].optimizedTime} valueType={userContext.unit?.data[config.unitHours]?.getTranslation({fieldName: 'name', locale: userContext.user.locale})} />
                                            </>
                                        }
                                    </td>
                                </tr>
                                {(props.displayWaterOptimisation || props.displayCo2EmissionsDelta) &&
                                    <tr>
                                        <td>&nbsp;</td>
                                        <td>&nbsp;</td>
                                        <td>&nbsp;</td>
                                        <td>&nbsp;</td>
                                        {props.displayCo2EmissionsDelta && isNaN(props.quote.cips[props.type].co2EmissionsDeltaKg) &&
                                            <td>&nbsp;</td>
                                        }
                                        {props.displayCo2EmissionsDelta && !isNaN(props.quote.cips[props.type].co2EmissionsDeltaKg) &&
                                            <td>
                                                <ProductInput disabled={true} onChange={null}
                                                              value={props.quote.cips[props.type].co2EmissionsDeltaKg}
                                                              valueType={userContext.unit?.data[config.unitKg]?.getTranslation({fieldName: 'name', locale: userContext.user.locale})}
                                                              classToAdd={props.quote.cips[props.type].co2EmissionsDeltaKg > 0 ? 'text-danger' : 'text-success'}
                                                />
                                            </td>
                                        }
                                        <td className="text-danger">
                                            {props.displayWaterOptimisation &&
                                                <>
                                                    <ProductInput classToAdd={'text-danger'} disabled={true} onChange={null} value={props.quote.cips[props.type].optimizedWaterCost} valueType={quoteContext.currentQuote.getCurrencySign()} />
                                                </>
                                            }
                                        </td>
                                        <td>&nbsp;</td>
                                    </tr>
                                }
                                <tr>
                                    <td className="no-border">&nbsp;</td>
                                    <td className="no-border">&nbsp;</td>
                                    <td className="no-border">&nbsp;</td>
                                    <td className="no-border">&nbsp;</td>
                                    <td className="no-border">&nbsp;</td>
                                    <td className="no-border">&nbsp;</td>
                                    <td className="no-border">&nbsp;</td>
                                </tr>
                                <tr>
                                    <td className="no-border"><button className="btn btn-sm btn-primary" onClick={() => {setDisplayInfos(!displayInfos)}}>{getTranslation(displayInfos ? 'cs.hide-ratios' : 'cs.show-ratios', userContext.user.locale)}</button></td>
                                    <td className="no-border">&nbsp;</td>
                                    <td className="no-border">&nbsp;</td>
                                    <td className="no-border">&nbsp;</td>
                                    <td className="no-border">&nbsp;</td>
                                    <td className="no-border">&nbsp;</td>
                                    <td className="no-border">&nbsp;</td>
                                </tr>
                                {displayInfos &&
                                    <>
                                        {props.showCurrentRatio &&
                                            <>
                                                <tr>
                                                    <td className="no-border">&nbsp;</td>
                                                    <td className="no-border">&nbsp;</td>
                                                    <td className="no-border">&nbsp;</td>
                                                    <td className="no-border">&nbsp;</td>
                                                    <td className="no-border">&nbsp;</td>
                                                    <td className="no-border">&nbsp;</td>
                                                    <td className="no-border">&nbsp;</td>
                                                </tr>
                                                <tr>
                                                    <td>&nbsp;</td>
                                                    <td>&nbsp;</td>
                                                    <td className="bg-warning">
                                                        <strong>{productTypes[ProductTypes.CausticSoda].getTranslation({fieldName: 'name', locale: userContext.user.locale})}</strong>
                                                    </td>
                                                    <td className="bg-danger">
                                                        <strong>{productTypes[ProductTypes.Acid].getTranslation({fieldName: 'name', locale: userContext.user.locale})}</strong>
                                                    </td>
                                                    <td>&nbsp;</td>
                                                    <td>&nbsp;</td>
                                                    <td>&nbsp;</td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <strong>{getTranslation('cs.ratio-kg-lavage', userContext.user.locale)}</strong>
                                                    </td>
                                                    <td>&nbsp;</td>
                                                    <td><strong>{props.quote.cips[props.type].products[ProductTypes.CausticSoda].ratio ? props.quote.cips[props.type].products[ProductTypes.CausticSoda].ratio : ''}</strong></td>
                                                    <td><strong>{props.quote.cips[props.type].products[ProductTypes.Acid].ratio ? props.quote.cips[props.type].products[ProductTypes.Acid].ratio : ''}</strong></td>
                                                    <td>&nbsp;</td>
                                                    <td>&nbsp;</td>
                                                    <td>&nbsp;</td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <strong>{getTranslation('cs.note', userContext.user.locale)}</strong>
                                                    </td>
                                                    <td>&nbsp;</td>
                                                    <td style={{color: (props.quote.cips[props.type].products[ProductTypes.CausticSoda].ratioNote ? props.quote.cips[props.type].products[ProductTypes.CausticSoda].ratioNote.color : '#FFF')}}><strong>{props.quote.cips[props.type].products[ProductTypes.CausticSoda].ratioNote ? props.quote.cips[props.type].products[ProductTypes.CausticSoda].ratioNote.letter : ''}</strong></td>
                                                    <td style={{color: (props.quote.cips[props.type].products[ProductTypes.Acid].ratioNote ? props.quote.cips[props.type].products[ProductTypes.Acid].ratioNote.color : '#FFF')}}><strong>{props.quote.cips[props.type].products[ProductTypes.Acid].ratioNote ? props.quote.cips[props.type].products[ProductTypes.Acid].ratioNote.letter : ''}</strong></td>
                                                    <td>&nbsp;</td>
                                                    <td>&nbsp;</td>
                                                    <td>&nbsp;</td>
                                                </tr>
                                            </>
                                        }
                                        <tr>
                                            <td className="no-border">&nbsp;</td>
                                            <td className="no-border">&nbsp;</td>
                                            <td className="no-border" colSpan={2}><strong>{getTranslation('cs.ratios-text', userContext.user.locale)}</strong></td>
                                            <td className="no-border">&nbsp;</td>
                                            <td className="no-border">&nbsp;</td>
                                            <td className="no-border">&nbsp;</td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <strong>{getTranslation('cs.evaluation-ratios', userContext.user.locale)}</strong>
                                            </td>
                                            <td>&nbsp;</td>
                                            <td className={classNames({'bg-warning': props.showPotentialGains})}>
                                                {props.showPotentialGains &&
                                                    <strong>{productTypes[ProductTypes.CausticSoda].getTranslation({fieldName: 'name', locale: userContext.user.locale})}</strong>
                                                }
                                            </td>
                                            <td className={classNames({'bg-danger': props.showPotentialGains})}>
                                                {props.showPotentialGains &&
                                                    <strong>{productTypes[ProductTypes.Acid].getTranslation({fieldName: 'name', locale: userContext.user.locale})}</strong>
                                                }
                                            </td>
                                            <td>&nbsp;</td>
                                            <td>&nbsp;</td>
                                            <td>&nbsp;</td>
                                        </tr>
                                        {Object.keys(RatingRatios).map((letter) => {
                                            let ratingRatio = RatingRatios[letter];
                                            return (
                                                <tr key={letter}>
                                                    <td style={{backgroundColor: ratingRatio.color}}>
                                                        <strong>{ratingRatio.letter} ({getRatioRange(letter)}{userContext.unit?.data[config.unitKgWashing]?.getTranslation({fieldName: 'name', locale: userContext.user.locale})})</strong>
                                                    </td>
                                                    <td>&nbsp;</td>
                                                    <td className={classNames({'text-danger': props.showPotentialGains && parseFloat(props.quote.cips[props.type]?.products[ProductTypes.CausticSoda]?.ratios[letter]) > 0, 'text-success': props.showPotentialGains && parseFloat(props.quote.cips[props.type]?.products[ProductTypes.CausticSoda]?.ratios[letter]) <= 0})}>
                                                        {props.showPotentialGains &&
                                                            <strong>{props.quote.cips[props.type]?.products[ProductTypes.CausticSoda]?.ratios[letter]?.toFixed(0)}</strong>
                                                        }
                                                    </td>
                                                    <td className={classNames({'text-danger': props.showPotentialGains && parseFloat(props.quote.cips[props.type]?.products[ProductTypes.CausticSoda]?.ratios[letter]) > 0, 'text-success': props.showPotentialGains && parseFloat(props.quote.cips[props.type]?.products[ProductTypes.CausticSoda]?.ratios[letter]) <= 0})}>
                                                        {props.showPotentialGains &&
                                                        <strong>{props.quote.cips[props.type]?.products[ProductTypes.Acid]?.ratios[letter]?.toFixed(0)}</strong>
                                                        }
                                                    </td>
                                                    <td>&nbsp;</td>
                                                    <td>&nbsp;</td>
                                                    <td>&nbsp;</td>
                                                </tr>
                                            );
                                        })}
                                    </>
                                }
                            </tbody>
                        </table>
                        {props.displayCo2EmissionsDelta &&
                            <div>
                                <small>{getTranslation('cs.co2-emissions-desc', userContext.user.locale)}</small>
                            </div>
                        }
                    </Card.Body>
                </Accordion.Collapse>
            </Card>
        </Accordion>
    );
}