import React from "react";
import {UserContext} from "../../contexts/UserContext";
import classNames from "classnames";

export default class CurrencySelector extends React.Component {
    onClickHandler = (currencyId) => {
        this.context.user.setCurrency(currencyId, this.props.currentQuote);
        this.props.closeMenu();
    }

    render() {
        if (!this.context.currency.data) {
            return null;
        }

        return (
            <div className="currency-selector">
                {Object.keys(this.context.currency.data).map((currencyId) =>
                    <div className={classNames({
                        currency: true,
                        selected: this.context.user.currency === this.context.currency.data[currencyId].id
                    })} key={currencyId} onClick={() => this.onClickHandler(this.context.currency.data[currencyId].id)}>
                        <span className="sign">{ this.context.currency.data[currencyId].sign }</span>
                        { this.context.currency.data[currencyId].getTranslation({fieldName: 'name', locale: this.context.user.locale}) }
                    </div>
                )}
            </div>
        )
    }
}

CurrencySelector.contextType = UserContext;