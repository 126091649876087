import React from "react";
import {UserContext} from "../../contexts/UserContext";
import {QuoteContext} from "../../contexts/QuoteContext";
import {ListGroupItem, Row, Col, Container, Button} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFolderOpen, faTrashAlt} from "@fortawesome/free-solid-svg-icons";
import {QuoteProduct} from "../../model/QuoteProduct";
import QuoteInitializer from "../../model/QuoteInitializer";
import {getTranslation} from "../tools/Translation";
import {Apps} from "../../model/Apps";
import classNames from "classnames";

export default class QuoteOpenLine extends React.Component {

    constructor(props) {
        super(props);
        this.handleOpenQuote = this.handleOpenQuote.bind(this);
        this.handleDeleteQuote = this.handleDeleteQuote.bind(this);
    }

    handleOpenQuote(user, quoteData, products, formulavariables) {
        let originalCategories = quoteData.categories;
        let newCategories = {};
        Object.keys(originalCategories).forEach((categoryId) => {
            let originalCategory = originalCategories[categoryId];
            let newProducts = {};
            Object.keys(originalCategory).forEach((productId) => newProducts[productId] = new QuoteProduct(originalCategory[productId]));
            newCategories[categoryId] = newProducts;
        });
        quoteData.categories = newCategories;
        let quote = quoteData;
        quote.app = this.context.currentQuote.app;

        // Set object instance based on the JSON loaded from the localStorage
        let quoteInitializer = new QuoteInitializer();
        quoteInitializer.init(quote, {product: products, formulavariable: formulavariables});

        if (quote.application === Apps.Farm) {
            this.context.availableQuotes.push(quote);
        }

        user.setDataFromQuote(quoteData);
        quote.setCurrent();
        this.props.closeModal();
    }

    handleDeleteQuote(quoteId) {
        this.props.showConfirmDelete(quoteId);
    }

    render() {
        return (
            <UserContext.Consumer>
                {({user, species, country, currency, product, formulavariable}) => {
                    if (user) {
                        let quote = this.props.quote;
                        let options = <Col style={{paddingRight: 14}}>
                            <span className={"font-italic"} style={{marginLeft: 12, color: '#5f5f5f'}}>{getTranslation("openQuote.alreadyOpened", user.locale)}</span>
                        </Col>;
                        if (!this.props.alreadyOpened) {
                            options = <>
                                <Col><Button onClick={() => this.handleOpenQuote(user, quote, product, formulavariable)}><FontAwesomeIcon size={"lg"} icon={faFolderOpen}/></Button></Col>
                                <Col><Button onClick={() => this.handleDeleteQuote(quote.id)} variant="danger"><FontAwesomeIcon size={"lg"} icon={faTrashAlt}/></Button></Col>
                            </>;
                        }
                        return <ListGroupItem className={"line"} key={quote.id} style={this.props.alreadyOpened ? {backgroundColor: "#80ba9e"} : null}>
                            <Container>
                                <Row>
                                    {this.context.currentQuote.application === Apps.Farm && species.data.length &&
                                        <Col className={"first"}>
                                            <img className={"species"} src={species.data[quote.species].thumbnail} alt={species.data[quote.species].getTranslation({fieldName: 'name', locale: user.locale})}/>
                                            <img className={"country"} src={country.data[quote.country].image} alt={country.data[quote.country].getTranslation({fieldName: 'name', locale: user.locale})}/>
                                        </Col>
                                    }
                                    <Col className={classNames({'currencyCol': true, 'ml-3': this.context.currentQuote.application !== Apps.Farm, 'quote-open-padding': this.context.currentQuote.application !== Apps.Farm})}>
                                        <span className={"currency"}>[{currency.data[quote.currency].sign}]</span>
                                    </Col>
                                    <Col className="quote-open-padding">
                                        <Row><Col><strong>{(quote.name || "").substr(0, 25)}</strong></Col></Row>
                                        <Row><Col style={{fontSize: 12}}>{(quote.generalData['nom'] || "").substr(0, 33)}</Col></Row>
                                    </Col>
                                    <Col className={"actions"}>
                                        <Row>{options}</Row>
                                    </Col>
                                </Row>
                            </Container>
                        </ListGroupItem>
                    } else {
                        return null;
                    }
                }
                }
            </UserContext.Consumer>
        );
    }
}
QuoteOpenLine.contextType = QuoteContext;
