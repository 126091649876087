import React, {Component} from "react";
import {UserContext} from "../../contexts/UserContext";

export default class ProductPanelToggle extends Component {
    constructor(props) {
        super(props);

        this.state = {
            display: 'block',
            isVisible: true
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.isVisible !== this.props.isVisible) {
            if (!this.props.isVisible) {
                this.setState({
                    isVisible: false,
                    display: 'none'
                });
            } else {
                setTimeout(() => {
                    this.setState({
                        display: 'block',
                        isVisible: true
                    });
                }, 460);
            }
        }
    }

    render() {
        if (!this.props.category || !this.context.category || !this.context.category.data || !this.context.user.categories || this.context.user.categories.length === 0) {
            return null;
        }

        let categories = this.context.category.data;

        return (
            <div id="open-slider" onMouseEnter={() => this.props.togglePanel(false, true)} onClick={() => this.props.togglePanel(false, true)} style={{backgroundColor: categories[this.props.category] ? categories[this.props.category].color : null, opacity: this.state.isVisible ? 1 : 0}} />
        )
    }
}

ProductPanelToggle.contextType = UserContext;