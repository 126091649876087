import {config} from "../components/config/Config";

export function convertObjectsToEntity(obj, className) {
    if (obj.data) {
        for (const [objectId, object] of Object.entries(obj.data)) {
            if (object && object.translations && object.translations !== null) {
                obj.data[objectId] = convertObjectToEntity(object, className);
            }
        }
    }

    return obj;
}

// Recursive function
// Check for sub-objects and convert them to Entity instance as well if it owns a "translations" property
export function convertObjectToEntity(data, className) {
    let entity;
    if (className) {
        entity = new className(data);
    } else {
        entity = new Entity(data);
    }

    for (const [property, value] of Object.entries(entity)) {
        if (value !== null && Array.isArray(value) && typeof value === "object") {
            for (const [subProperty, subValue] of Object.entries(value)) {
                if (subValue !== null && !Array.isArray(subValue) && typeof subValue === "object") {
                    if (subValue.translations !== undefined) {
                        entity[property][subProperty] = convertObjectToEntity(subValue);
                    }
                }
            }
        }

        if (value !== null && !Array.isArray(value) && typeof value === "object") {
            if (value.translations !== undefined) {
                entity[property] = convertObjectToEntity(value);
            }
        }
    }

    return entity;
}

export class Entity {
    constructor(data) {
        Object.assign(this, data);
    }

    /**
     *
     * @param parameters object
     * @returns {string}
     */
    getTranslation(parameters) {
        let fieldName = parameters.fieldName && parameters.fieldName.length ? parameters.fieldName : null;
        let locale = parameters.locale ? parameters.locale : config.defaultLocale;
        let retval = '';

        if (this.translations !== undefined && null !== this.translations && null !== fieldName) {
            this.translations.forEach((translation) => {
                if (translation.locale === locale) {
                    let t = translation[fieldName];
                    retval = t && t.length > 0 ? t : (parameters.locale !== config.defaultLocale ? this.getTranslation(Object.assign(parameters, {locale: config.defaultLocale})) : '');
                }
            })
        }

        return retval;
    }

    getObjectById(property, id) {
        let returnVal = null;
        if (this[property] && this[property].length > 0) {
            this[property].forEach((obj) => {
                if (obj.id === id) {
                    returnVal = obj;
                    return false;
                }
            });
        }

        return returnVal;
    }
}
