import React from "react";
import PrintPreviewIcon from "./PrintPreviewIcon";
import {faSync} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import QuoteTotal from "../quote/QuoteTotal";
import Months from "../header/Months";
import $ from "jquery";
import PaperSize from "./PaperSize";

export default class PrintPreviewToolbar extends React.Component {
    constructor(props) {
        super(props);
        this.printPreviewElement = React.createRef();
        this.timeout = null;
        this.state = {
            showLoading: false
        }
    }

    setLoading = (value) => {
        this.setState({
            showLoading: value
        });
    }

    // Transition and fixed positioning = transition bug, so we position absolute while animating the overlay
    // then we set the position fixed when the transition is done.
    componentDidMount() {
        setTimeout(() => {
            if (this.printPreviewElement.current) {
                $(this.printPreviewElement.current).addClass('loaded');
            }
        }, 500);
    }

    componentWillUnmount() {
        clearTimeout(this.timeout);
    }

    render() {
        return (
            <div className="flex-item toolbar-preview" ref={this.printPreviewElement}>
                <div className="flex-container">
                    <div className="toolbar">
                        <div className="exit" onClick={this.props.closeModal}/>
                        <PrintPreviewIcon setLoading={this.setLoading} />
                        {this.state.showLoading &&
                            <FontAwesomeIcon icon={faSync} className="fa-spin loading-icon" size={"lg"} />
                        }
                        <PaperSize currentQuote={this.props.currentQuote} onPaperSizeChange={this.props.onPaperSizeChange} />
                        <QuoteTotal/>
                        <Months toggleToolbar={() => {}} />
                    </div>
                </div>
            </div>
        )
    }
}