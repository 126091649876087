import React from 'react';
import {Modal, Form, Button, Spinner} from 'react-bootstrap';
import ErrorMessage from "../tools/ErrorMessage";
import appLogo from "../../assets/images/easycalc-s.svg";
import {config} from "../config/Config";
import {flags, getStaticTranslation} from "../tools/Translation";
import $ from "jquery";

export default class LoginForm extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            username: null,
            password: null,
            error: null,
            activeButton: '',
            locale: localStorage.getItem(config.userLoginLocale) || config.defaultLocale
        };
    }

    handleChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    changeLocale = (e, locale) => {
        e.preventDefault();
        this.setState({
            locale: locale
        });
        localStorage.setItem(config.userLoginLocale, locale);
    }

    handleAzureLogin = () => {
        this.setState({
            activeButton: 'loginAD',
            error: null
        });

        this.props.loginADMethod();
    }

    handleSubmit = (event) => {
        event.preventDefault();

        this.setState({
            activeButton: 'login',
            error: null
        });

        if (this.state.username && this.state.password) {
            this.props.loginMethod(this.state.username, this.state.password).then(() => {
                // Check if the component has been unmounted
                if (!this.props.isAuthenticated && this.props.isInitialized) {
                    if (!this.props.loginError) {
                        this.setState({
                            username: null,
                            password: null
                        });
                    }
                }
            });
        } else {
            this.setState({
                error: getStaticTranslation('missingField', this.state.locale)
            });
        }
    }

    onShow = () => {
        $('body').addClass('notlogged');
    }

    onHide = () => {
        $('body').removeClass('notlogged');
    }

    render() {
        let error;
        if (this.state.error) {
            error = this.state.error;
        } else if (this.props.loginError) {
            error = this.props.loginError;
        }

        return (
            <Modal
                show={!this.props.isAuthenticated && this.props.isInitialized}
                onShow={this.onShow}
                onExit={this.onHide}
                backdrop="static"
                keyboard={false}
                size="md"
                centered>
                <Modal.Header>
                    <Modal.Title>
                        <img id="app-logo" src={appLogo} alt={config.appName} />
                        <div className="independant-locale-selector">
                            <a href="." className={this.state.locale === 'fr' ? 'active' : ''} onClick={(e) => this.changeLocale(e, 'fr')}><img src={flags.fr} alt="Français"/></a>
                            <a href="." className={this.state.locale === 'en' ? 'active' : ''} onClick={(e) => this.changeLocale(e, 'en')}><img src={flags.en} alt="English"/></a>
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ErrorMessage error={error}/>
                    <p>
                        {getStaticTranslation('loginAD', this.state.locale)}
                    </p>
                    <div className="row">
                        <div className="col text-center">
                            <Button onClick={this.handleAzureLogin} variant="primary" className="mb-3">
                                {this.props.isAuthenticating && this.state.activeButton === 'loginAD' &&
                                <>
                                    <Spinner as="span" size="sm" role="status" animation="border"/>
                                    &nbsp;&nbsp;
                                </>
                                }
                                {getStaticTranslation('o365login', this.state.locale)}
                            </Button>
                        </div>
                    </div>
                    <p className="text-center">
                        <strong>{getStaticTranslation('or', this.state.locale)}</strong>
                    </p>
                    <Form onSubmit={this.handleSubmit}>
                        <Form.Group controlId="username">
                            <Form.Control name="username" type="text" placeholder={getStaticTranslation('username', this.state.locale)}
                                          onChange={this.handleChange}/>
                        </Form.Group>

                        <Form.Group controlId="password">
                            <Form.Control name="password" type="password" placeholder={getStaticTranslation('password', this.state.locale)}
                                          onChange={this.handleChange}/>
                        </Form.Group>

                        <Button variant="primary" type="submit" className="float-right">
                            {this.props.isAuthenticating && this.state.activeButton === 'login' &&
                            <>
                                <Spinner as="span" size="sm" role="status" animation="border"/>
                                &nbsp;&nbsp;
                            </>
                            }
                            {getStaticTranslation('submit', this.state.locale)}
                        </Button>
                    </Form>
                </Modal.Body>
            </Modal>
        );
    }
}