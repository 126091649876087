import React from 'react';
import {Col, Row} from "react-bootstrap";
import {QuoteContext} from "../../contexts/QuoteContext";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faShoppingCart} from "@fortawesome/free-solid-svg-icons";
import {getTranslation} from "../tools/Translation";

export default class AddToCategorySelector extends React.Component {

    addProductToCategory = (categoryId, productId) => {
        this.context.currentQuote.addProduct(categoryId, productId);
        this.props.hideHandler();
    };

    getAddToCart = (product) => {
        let catIds = Object.assign([], product.categories);
        if (!catIds.includes(1)) {
            catIds.push(1);
        }
        catIds.sort();
        //check if already added to linked categories AND catalogue
        const currentQuote = this.context.currentQuote;
        const categories = currentQuote.getUserContext().category.data;
        return catIds.map((categoryId) => {
            //check if product is in the category
            if (!currentQuote.categories[categoryId] || !currentQuote.categories[categoryId][product.id]) {
                //if not, add the button to add to category
                return <Row key={categoryId}>
                    <Col></Col>
                    <Col style={{textAlign: 'center'}}>
                        <button type="button" className="btn btn-primary btn-block" onClick={() => this.addProductToCategory(categoryId, product.id)} style={{marginBottom: 3}}>
                            <FontAwesomeIcon size={"lg"} icon={faShoppingCart} style={{marginRight: 10}}/>
                            {getTranslation("Ajouter à la catégorie: ", currentQuote.getUserContext().user.locale) + categories[categoryId].getTranslation({fieldName: "name", locale: currentQuote.getUserContext().user.locale})}
                        </button>
                    </Col>
                    <Col></Col>
                </Row>
            } else {
                return null;
            }
        });
    }

    render = () => this.getAddToCart(this.props.product);
}

AddToCategorySelector.contextType = QuoteContext;
