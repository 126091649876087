import {Entity} from "./Entity";
import Calculation from "../components/calculation/Calculation";
import ProductCalculationPart from "./ProductCalculationPart";
import {evaluate} from "mathjs";
import {getTranslation} from "../components/tools/Translation";

export class ProductCalculation extends Entity {
    categoryId;
    product;
    parts;

    components = {
        1: Calculation
    }

    constructor(data) {
        super(data);
        if (!data.parts) {
            this.parts = null;
        }
    }

    getComponent(categoryId) {
        this.categoryId = categoryId;

        switch (categoryId) {
            // case 1:
            //     return this.components[1];
            default:
                return this.components[1];
        }
    }

    getParts(currentQuote, userContext) {
        if (this.formula && this.parts === null) {
            const strippedFormula = this.formula.replace(/\(\)/, '');
            const matches = strippedFormula.split(' ');
            this.parts = [];
            matches.forEach((match) => {
                const part = new ProductCalculationPart({
                    product: this.product,
                    category: this.categoryId,
                    currentQuote: currentQuote,
                    formulaVariables: userContext.formulavariable.data,
                    parameter: match
                });
                this.parts.push(part);
            });

            // Add padding to labels between two inputs
            // this.parts.forEach((part, i) => {
            //     if (i > 0) {
            //         if (((part.type === part.components.label) && (this.parts[i-1].type === part.components.input && !this.parts[i-1].formulaVariable.additionalText)) || ((i < (this.parts.length - 1)) && (this.parts[i+1].type === part.components.input))) {
            //             part.addPadding = true;
            //         }
            //     }
            // });

            // Find the unit
            let unitId = this.product.getFormatUnit();
            let unitName = '';
            if (unitId) {
                unitName = ' ' + userContext.unit.data[unitId].getTranslation({fieldName: 'name', locale: userContext.user.locale}) || '';
            }

            // Add total
            this.parts.push(new ProductCalculationPart({
                product: this.product,
                category: this.categoryId,
                currentQuote: currentQuote,
                formulaVariables: userContext.formulavariable.data,
                parameter: 'total',
                total: ' = ' + this.getTotal() + unitName
            }));

            return this.parts;
        } else if (this.parts !== null) {
            return this.parts;
        }

        return [];
    }

    persistToContext(currentQuote, userContext) {
        // Get the different parameters
        const parts = this.getParts(currentQuote, userContext);

        if (parts.length > 0) {
            // Persist to the quote context
            const productData = currentQuote.getProductData(this.categoryId, this.product.id);
            parts.forEach((part) => {
                productData.setCalculationPart(part, true);
            });
        }
    }

    getTotal(returnAsText, userContext) {
        if (this.formula && this.parts && this.parts.length > 0) {
            let formula = this.formula.replaceAll(' x ', ' * ').replaceAll(' ÷ ', ' / ');
            this.parts.forEach((part) => {
                if (!part.staticText) {
                    if (part.additionalOperation && !returnAsText) {
                        formula = formula.replace(part.parameter, '(' + part.value + part.additionalOperation.replaceAll(' x ', ' * ').replaceAll(' ÷ ', ' / ') + ')');
                    } else {
                        let value = part.value;
                        if (returnAsText && part.isNumberOfMonths()) {
                            value += getTranslation('months', userContext.user.locale);
                        }
                        formula = formula.replace(part.parameter, value);
                    }
                }
            });

            let returnValue;
            try {
                returnValue = parseInt(evaluate(formula));
            } catch (e) {
                // @TODO Remplacer null par nom du paramètre
                // console.log(this.formula);
                // console.log(formula);
                returnValue = 0;
            }

            if (returnAsText) {
                return formula.replace(/\s/g, '').replaceAll('/', '÷').replaceAll('*', 'x');
            }

            return returnValue;
        }

        return 0;
    }

    getFormula(userContext) {
        // Find the unit
       let unitId = this.product.getFormatUnit();
       let unitName = '';
       if (unitId) {
           unitName = ' ' + userContext.unit.data[unitId].getTranslation({fieldName: 'name', locale: userContext.user.locale}) || '';
       }
        return this.getTotal(true, userContext) + '=' + this.getTotal() + unitName;
    }

    updateGeneralDataPart(currentQuote, property, categoryId) {
        if (this.formula && this.parts && this.parts.length > 0) {
            this.categoryId = categoryId;
            this.parts.forEach((part) => {
                if (part.isUsingGeneralData() && part.formulaVariable.property === property) {
                    part.parse();
                    const productData = currentQuote.getProductData(this.categoryId, this.product.id);
                    productData.setCalculationPart(part);
                }
            });
        }
    }

    updateSoftWater(currentQuote, categoryId) {
        if (this.formula && this.parts && this.parts.length > 0) {
            this.categoryId = categoryId;
            this.parts.forEach((part) => {
                if (part.isSoftWater()) {
                    part.parse();
                    const productData = currentQuote.getProductData(this.categoryId, this.product.id);
                    productData.setCalculationPart(part);
                }
            });
        }
    }

    updateEstimatedConsumption(currentQuote, categoryId, value) {
        if (this.formula && this.parts && this.parts.length > 0) {
            this.categoryId = categoryId;
            this.parts.forEach((part) => {
                if (part.isEstimatedConsumption()) {
                    if (value) {
                        part.value = value;
                    } else {
                        part.parse();
                    }
                    const productData = currentQuote.getProductData(this.categoryId, this.product.id);
                    productData.setCalculationPart(part);
                }
            });
        }
    }

    updateNumberOfMonths(currentQuote, categoryId) {
        if (this.formula && this.parts && this.parts.length > 0) {
            this.categoryId = categoryId;
            this.parts.forEach((part) => {
                if (part.isNumberOfMonths()) {
                    part.parse();
                    const productData = currentQuote.getProductData(this.categoryId, this.product.id);
                    productData.setCalculationPart(part);
                }
            });
        }
    }

    updateDosing(currentQuote, categoryId, value) {
        if (this.formula && this.parts && this.parts.length > 0) {
            this.categoryId = categoryId;
            this.parts.forEach((part) => {
                if (part.isEstimatedConsumption()) {
                    part.value = value;
                    const productData = currentQuote.getProductData(this.categoryId, this.product.id);
                    productData.setCalculationPart(part);
                }
            });
        }
    }

    updateTotal(productData) {
        if (this.parts && this.parts.length > 0) {
            this.parts.forEach((part) => {
                if (part.total) {
                    part.value = part.total = part.total.replace(/[+-]?([0-9]*[.])?[0-9]+/, this.getTotal());
                    productData.setCalculationPart(part, false, true);
                }
            });
        }
    }

    updateProtocolMultiplier(currentQuote, categoryId, multiplier) {
        if (this.parts && this.parts.length > 0) {
            this.categoryId = categoryId;
            this.parts.forEach((part) => {
                if (part.isProtolMultiplier()) {
                    part.value = multiplier;
                    const productData = currentQuote.getProductData(this.categoryId, this.product.id);
                    productData.setCalculationPart(part);
                }
            });
        }
    }
}
