import React from "react";
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import {getTranslation} from "../tools/Translation";

export default class Biocide extends React.Component {
    render() {
        if (!this.props.product.isBiocide) {
            return null;
        }

        return (
            <OverlayTrigger
                overlay={
                    <Tooltip className="biocide-tooltip">
                        {getTranslation("biocide.fulltext", this.props.user.locale)}
                    </Tooltip>
                }>
                <div className="biocide" style={this.props.category.color ? {color: this.props.category.color} : null}>
                    {getTranslation("biocide.shorttext", this.props.user.locale)}
                </div>
            </OverlayTrigger>
        )
    }
}