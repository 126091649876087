import React from "react";
import {UserContext} from "../../contexts/UserContext";
import {getTranslation} from "../tools/Translation";
import logoKersia from "../../assets/images/logo-kersia.png";
let vsprintf = require('sprintf-js').vsprintf;

export default class Footer extends React.Component {
    render() {
        let productGroups = this.context.productgroup.data;
        const pageStr = vsprintf(getTranslation('print.pager', this.context.user.locale), [parseInt(this.props.page), parseInt(this.props.pages)]);
        return (
            <div className="print-footer">
                <div className="content">
                    {this.props.salesTermsAndConditions && (parseInt(this.props.page) === parseInt(this.props.pages)) &&
                        <div className="print-sales-terms-and-conditions">
                            {this.props.salesTermsAndConditions}
                        </div>
                    }
                    <div className="print-page">
                        {pageStr}
                    </div>
                    <div className="print-logo">
                        {(!productGroups[this.context.user.productGroup] || !productGroups[this.context.user.productGroup].image) &&
                            <img src={logoKersia} alt="Kersia"/>
                        }
                        {productGroups[this.context.user.productGroup] && productGroups[this.context.user.productGroup].image &&
                            <img src={productGroups[this.context.user.productGroup].image} alt={productGroups[this.context.user.productGroup].name}/>
                        }
                    </div>
                    {this.props.currentQuote.hasBiocide() &&
                        <div className="print-biocide">
                            (*) {getTranslation('biocide.fulltext', this.context.user.locale)}
                        </div>
                    }
                </div>
            </div>
        );
    }
}

Footer.contextType = UserContext;