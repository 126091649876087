import React from "react";
import essycalcLogo from "../../assets/images/easycalc-logo.png";
import {UserContext} from "../../contexts/UserContext";
import {getTranslation} from "../tools/Translation";

export default class ProductListHeader extends React.Component {
    render() {
        return (
            <div className={"sheet-padding sheet-padding-top"}>
                <div className="print-product-list-header">
                    {getTranslation('print.productoverview', this.context.user.locale)}
                </div>
                <img src={essycalcLogo} className="easycalc-logo" alt="Easycalc" />
            </div>
        );
    }
}

ProductListHeader.contextType = UserContext;