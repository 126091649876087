import React from "react";
import Nouislider from "nouislider-react";
import "nouislider/distribute/nouislider.css";
import {QuoteContext} from "../../contexts/QuoteContext";

export default class MonthSlider extends React.Component {
    constructor(data) {
        super(data);
        this.element = React.createRef();
        this.timeout = null;
    }

    onEnd = (render, handle, value, un, percent) => {
        this.context.currentQuote.setNumberOfMonths(Math.round(value));
    }

    onMouseEnter = () => {
        clearTimeout(this.timeout);
    }

    onMouseLeave = () => {
        this.timeout = setTimeout(() => {
            this.props.toggleToolbar(true);
        }, 500);
    }

    render() {
        return (
            <div id="month-slider" ref={this.element} onMouseEnter={this.onMouseEnter} onMouseLeave={this.onMouseLeave}>
                <Nouislider
                  accessibility
                  start={this.context.currentQuote.numberOfMonths}
                  step={1}
                  tooltips={true}
                  connect={'lower'}
                  pips={{
                      mode: 'values',
                      values: [],
                      density: 4
                  }}
                  range={{
                    min: 1,
                    max: 24
                  }}
                  onEnd={this.onEnd}
                />
            </div>
        )
    }
}

MonthSlider.contextType = QuoteContext;