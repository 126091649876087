import React from "react";
import {getTranslation} from "../tools/Translation";
import {UserContext} from "../../contexts/UserContext";

export default class ProductInSpray extends React.Component {
    onChangeHandler = () => {
        this.props.productData.toggleInSpray();
        if (this.props.productData.inSpray) {
            this.props.productData.setEstimatedConsumption(1);
        }
    }

    render() {
        return (
            <div className="in-spray" style={this.props.category.color ? {backgroundColor: this.props.category.color} : null}>
                <label>
                    <input type="checkbox" onChange={this.onChangeHandler} checked={this.props.productData.inSpray === true} />
                    <span>{getTranslation("inspray", this.context.user.locale)}</span>
                </label>
            </div>
        )
    }
}

ProductInSpray.contextType = UserContext;