import React from "react";
import $ from "jquery";
import {UserContext} from "../../contexts/UserContext";
import CalculationAdditionalText from "./CalculationAdditionalText";

export default class InputType extends React.Component {
    constructor(props) {
        super(props);

        this.textWidthElement = React.createRef();
        this.inputElement = React.createRef();
    }

    componentDidMount() {
        this.resizeInput();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.resizeInput();
        if ((prevProps.productData.calculationParts[this.props.calculationPart.parameter] !== this.props.productData.calculationParts[this.props.calculationPart.parameter]) && this.props.productData.calculationParts[this.props.calculationPart.parameter] === 0) {
            $(this.inputElement.current).select();
        }
    }

    onFocus = () => {
        $(this.inputElement.current).select();
    }

    resizeInput = () => {
        this.textWidthElement.current.innerHTML = this.inputElement.current.value;
        $(this.inputElement.current).width($(this.textWidthElement.current).outerWidth() + 6);
    }

    handleChange = (e) => {
        this.props.calculationPart.value = !isNaN(e.target.value.trim()) && e.target.value.trim() !== '' ? e.target.value.trim() : 0;
        this.props.productData.setCalculationPart(this.props.calculationPart);
    }

    render() {
        return (
            <>
                <input ref={this.inputElement}  type="text" className="product-input" onFocus={this.onFocus} value={!isNaN(this.props.productData.calculationParts[this.props.calculationPart.parameter]) ? this.props.productData.calculationParts[this.props.calculationPart.parameter] : ''} onChange={this.handleChange}/>
                {this.props.calculationPart.formulaVariable && this.props.calculationPart.formulaVariable.additionalText &&
                    <div className="formula-text">
                        <CalculationAdditionalText additionalText={this.props.calculationPart.formulaVariable.additionalText} product={this.props.product} productData={this.props.productData} />
                    </div>
                }
                <span ref={this.textWidthElement} className="hidden-element calculation"/>
            </>
        )
    }
}

InputType.contextType = UserContext;