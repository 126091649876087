import React, {useContext} from "react";
import {Modal} from "react-bootstrap";
import {getTranslation} from "./Translation";
import {UserContext} from "../../contexts/UserContext";

export default function DeleteConfirmModal(props) {

    const context = useContext(UserContext);

    return (
        <Modal
            show={props.show}
            backdrop="static"
            keyboard={false}
            size="md"
            centered>
            <Modal.Header>
                <h4 className="mb-0">{props.title}</h4>
            </Modal.Header>
            <Modal.Body>
                <p>
                    {props.text}
                </p>
                <div className="d-flex justify-content-end">
                    <button className="btn btn-primary float-right text-white mr-2" onClick={() => {props.okCallback()}}>{getTranslation('yes', context.user.locale)}</button>
                    <button className="btn btn-danger float-right" onClick={() => {props.cancelCallback()}}>{getTranslation('cancel', context.user.locale)}</button>
                </div>
            </Modal.Body>
        </Modal>
    );
}