import React from 'react';
import {Container, Modal, ModalBody, ModalTitle} from "react-bootstrap";
import ModalHeader from "react-bootstrap/ModalHeader";
import {UserContext} from "../../contexts/UserContext";
import {QuoteContext} from "../../contexts/QuoteContext";
import PriceListLigne from "./PriceListLigne";
import {getTranslation} from "../tools/Translation";
import PriceListToolbox from "./PriceListToolbox";
import * as EasycalcApi from 'easycalc-api-client';
import EasycalcApiClient from "../data/EasycalcApiClient";
import Debug from "../tools/Debug";
import moment from "moment/moment";

export default class PriceList extends React.Component {

    autoUpdateRunning = false;

    constructor(props, context) {
        super(props, context);
        this.runPricelistQueue();
    }

    getFieldCount = () => {
        let count = 0;
        let formatIds = [];
        if (this.context && this.context.user) {
            let cp = this.context.user.categoryProducts;
            Object.keys(cp).forEach((catId) => {
                cp[catId].forEach((productId) => {
                    this.context.user.productFormats[productId].forEach((formatId) => {
                        if (!formatIds.includes(formatId)) {
                            formatIds.push(formatId);
                            count += 1;
                        }
                    });
                });
            });
        }
        return count;
    }

    getNonEmptyFieldCount = (currentQuote) => {
        let count = 0;
        let formatIds = [];
        if (this.context && this.context.user) {
            let cp = this.context.user.categoryProducts;
            Object.keys(cp).forEach((catId) => {
                cp[catId].forEach((productId) => {
                    if (this.context.user.productFormats[productId] && this.context.user.productFormats[productId].length > 0) {
                        this.context.user.productFormats[productId].forEach((formatId) => {
                            if (!formatIds.includes(formatId) && currentQuote.prices[formatId] && parseFloat(currentQuote.prices[formatId]) > 0) {
                                formatIds.push(formatId);
                                count++;
                            }
                        });
                    }
                });
            });
        }

        return count;
    }

    getOrderedProductList = (order) => {
        const products = this.context.user.categoryProducts[1];
        if (this.context.product.data.length) {
            if (order === 'desc') {
                return products.sort((a, b) => (this.context.product.data[a].name < this.context.product.data[b].name) ? 1 : -1);
            } else {
                return products.sort((a, b) => (this.context.product.data[a].name > this.context.product.data[b].name) ? 1 : -1);
            }
        } else {
            return [];
        }
    }

    updateEmptyPackagingCount = (currentQuote) => {
        currentQuote.emptyPricesCount = this.getFieldCount() - this.getNonEmptyFieldCount(currentQuote);
    }

    getBasePriceList = () => {
        Debug.log('PriceList::getBasePriceList');
        return JSON.parse(this.context.productgroup.data[this.context.user.productGroup].prices);
    }

    overwritePriceList = (prices) => {
        Debug.log('PriceList::overwritePriceList');
        let oldPriceSession = sessionStorage.getItem('pl');
        let currentProductGroup = this.context.user.productGroup;
        let newPriceSession = oldPriceSession ? JSON.parse(oldPriceSession) : {};
        if (!newPriceSession[currentProductGroup] || newPriceSession[currentProductGroup] !== prices) {
            newPriceSession[currentProductGroup] = prices;
        }
        sessionStorage.setItem('pl', JSON.stringify(newPriceSession));
        this.runPricelistQueue();
    }

    setPriceList = (productGroupId, prices) => {
        Debug.log('PriceList::setPriceList');
        let apiClient = new EasycalcApiClient();
        let api = new EasycalcApi.ProductGroupApi(apiClient);
        api.productGroupSetPrices({productGroupId: productGroupId, prices: JSON.stringify(prices)})
            .then(() => {
                this.removeSessionPriceList(productGroupId);
            })
            .catch(
                (e) => {
                    console.error(e);
                }
            );
    }

    removeSessionPriceList(id) {
        Debug.log('PriceList::removeSessionPriceList');
        let sessionRaw = sessionStorage.getItem('pl');
        if (sessionRaw) {
            let sessionObject = JSON.parse(sessionRaw);
            if (sessionObject && sessionObject[id]) {
                delete sessionObject[id];
                sessionStorage.setItem('pl', JSON.stringify(sessionObject));
            }
        }
    }

    componentDidMount() {
        try {
            this.runPricelistQueue();
        } catch (e) {
            console.error(e);
        }
    }

    runPricelistQueue() {
        Debug.log('PriceList::runPricelistQueue');
        let priceLists = sessionStorage.getItem('pl');
        if (priceLists) {
            const lists = JSON.parse(priceLists);
            Object.keys(lists).forEach((key) => {
                this.setPriceList(key, lists[key]);
            })
        }
        if (!this.autoUpdateRunning) {
            this.autoUpdateRunning = true;
            setInterval(() => {
                this.runPricelistQueue()
            }, 5 * 60 * 1000)
        }
    }

    render() {
        if (this.context.user.categoryProducts[1] && this.context.user.categoryProducts[1].length > 0 && this.context.product.data && this.props.show) {

            let productData = this.context.productgroup.data[this.context.user.productGroup];
            let lastModificationDate = productData.pricesUpdateAt ? moment(productData.pricesUpdateAt).format('YYYY-MM-DD hh:mm:ss') : '---';
            let lastModificationBy = productData.pricesUpdatedBy ? productData.pricesUpdatedBy : '---';

            return (
                <QuoteContext.Consumer>
                    {({currentQuote}) => {
                        return (
                            <Modal className="priceListModal" {...this.props} show={this.props.show} onHide={this.props.onHide} backdrop='static' keyboard={false}>
                                <PriceListToolbox updateEmptyPackagingCount={this.updateEmptyPackagingCount} getBasePriceList={this.getBasePriceList} overwritePriceList={this.overwritePriceList}/>
                                <ModalHeader>
                                    <ModalTitle>{getTranslation('priceList.title', this.context.user.locale)}</ModalTitle>
                                    <div style={{marginTop: 'auto', color: '#FFF', fontSize: '.75em', textAlign: 'right'}}>
                                        <span>{getTranslation('priceList.title.modificationDate', this.context.user.locale)}: {lastModificationDate}</span><br/>
                                        <span>{getTranslation('priceList.title.modificationAgent', this.context.user.locale)}: {lastModificationBy}</span>
                                    </div>
                                </ModalHeader>
                                <ModalBody>
                                    <Container>{this.getOrderedProductList(currentQuote.priceListSortOrder).map((productId) => <PriceListLigne key={productId} userContext={this.context} data={this.context.product.data[productId]} updateEmptyPackagingCount={this.updateEmptyPackagingCount}/>)}</Container>
                                </ModalBody>
                            </Modal>
                        );
                    }}
                </QuoteContext.Consumer>
            );
        } else {
            return null;
        }
    }
}
PriceList.contextType = UserContext;
