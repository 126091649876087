import React from "react";
import {QuoteContext} from "../../contexts/QuoteContext";
import {Col, Row} from "react-bootstrap";
import {getTranslation} from "../tools/Translation";

export default class CategoryTotal extends React.Component {
    render() {
        if (!this.context.currentQuote.hasCategory(this.props.category)) {
            return null;
        }

        return (
            <Row className="total">
                <Col>
                    <div className="category-total">
                        <span className="title">{getTranslation("category.total", this.props.user.locale)}:</span>
                        <span className="total">{parseFloat(this.context.currentQuote.getCategoryTotal(this.props.category)).toFixed(2)} {this.context.currentQuote.getCurrencySign()}</span>
                    </div>
                </Col>
            </Row>
        )
    }
}

CategoryTotal.contextType = QuoteContext;