import React from "react";
import LocaleSelector from "../user/LocaleSelector";
import QuoteTotal from "../quote/QuoteTotal";
import QuoteOpen from "../quote/QuoteOpen";
import QuoteSave from "../quote/QuoteSave";
import QuoteDownload from "../quote/QuoteDownload";
import {QuoteContext} from "../../contexts/QuoteContext";
import SearchBar from "../search/SearchBar";
import Months from "./Months";
import MonthSlider from "./MonthSlider";
import PrintPreviewIcon from "../export/PrintPreviewIcon";
import $ from "jquery";
import HideEmptyCategories from "./HideEmptyCategories";
import {Apps} from "../../model/Apps";
import PrintPreviewIconCausticSoda from "../export/PrintPreviewIconCausticSoda";

export default class Toolbar extends React.Component {
    constructor(data) {
        super(data);
        this.isOpen = false;
        this.element = React.createRef();
        this.spacer = React.createRef();
        this.isAnimating = false;
    }

    componentDidMount() {
        this.context.monthsToggler = () => {
            this.toggleToolbar();
        };

        this.context.currentQuote.app.setState({
            quoteContext: this.context
        });
    }

    // Shit pour mettre le toolbar en absolute pour pouvoir le redimensionner
    toggleToolbar = (onMouseLeave) => {
        if (!this.isAnimating) {
            if (!this.isOpen && !onMouseLeave) {
                this.isAnimating = true;
                this.isOpen = true;
                $(this.spacer.current).css('width', $(this.element.current).outerWidth()).css('height', $(this.element.current).parent().outerHeight()).addClass('d-lg-block');
                $(this.element.current).css('width', $(this.element.current).outerWidth()).addClass('position-absolute').addClass('open');
                setTimeout(() => {
                    this.isAnimating = false;
                }, 200);
            } else {
                this.isAnimating = true;
                this.isOpen = false;
                $(this.element.current).removeClass('open');
                setTimeout(() => {
                    $(this.spacer.current).removeClass('d-lg-block');
                    $(this.element.current).removeClass('position-absolute').removeAttr('style');
                    this.isAnimating = false;
                }, 200);
            }
        }
    }

    render() {
        let openCount = this.context.availableQuotes.length;
        return (
            <div className="flex-item">
                <div className="flex-container">
                    {[Apps.Farm, Apps.CausticSoda].includes(this.props.currentModule) &&
                        <>
                            <div className="toolbar" ref={this.element}>
                                <QuoteOpen nb={openCount}/>
                                <QuoteSave/>
                                {[Apps.Farm].includes(this.props.currentModule) &&
                                    <>
                                        <QuoteDownload/>
                                        <PrintPreviewIcon/>
                                        <SearchBar/>
                                        <QuoteTotal/>
                                        <Months toggleToolbar={this.toggleToolbar}/>
                                        <HideEmptyCategories/>
                                        <MonthSlider toggleToolbar={this.toggleToolbar}/>
                                    </>
                                }
                                {[Apps.CausticSoda].includes(this.props.currentModule) &&
                                    <PrintPreviewIconCausticSoda />
                                }
                            </div>
                            <div className="toolbar-spacer" ref={this.spacer}/>
                        </>
                    }
                    <LocaleSelector className="mr-3"/>
                </div>
            </div>
        )
    }
}

Toolbar.contextType = QuoteContext;
