import React from "react";
import {getTranslation} from "../tools/Translation";
import {UserContext} from "../../contexts/UserContext";
import classNames from "classnames";
let vsprintf = require('sprintf-js').vsprintf;

export default class CategoryTableHeader extends React.Component {
    render() {
        const currentQuote = this.props.currentQuote;
        return (
            <div className={classNames({
                                    "preview-box-wrapper": true,
                                    "clearfix": true,
                                    "no-discount": !currentQuote.quoteHasDiscount(),
                                    "no-costperanimal": !currentQuote.quoteHasCostPerAnimalPerYear(this.context.category.data) || !currentQuote.settings['options.costpercowpermonth']
                                })}>
                <div className="preview-box product-title">
                    <div className="box-title">
                        {getTranslation('print.product', this.context.user.locale)}
                    </div>
                </div>
                <div className="preview-box product-consumption">
                    <div className="box-title">
                        {currentQuote.categoryHasCalculation(this.props.category) ? getTranslation('print.calculation', this.context.user.locale) : <span>&nbsp;</span>}
                    </div>
                </div>
                {currentQuote.quoteHasCostPerAnimalPerYear(this.context.category.data) && currentQuote.settings['options.costpercowpermonth'] &&
                    <div className="preview-box product-consumption-per-animal">
                        <div className="box-title">
                            {currentQuote.categoryHasCalculation(this.props.category) && currentQuote.categoryHasCostPerAnimalPerYear(this.context.category.data, this.props.category) ? vsprintf(getTranslation('print.costperanimal', this.context.user.locale), [this.context.species.data[currentQuote.species].getTranslation({
                                fieldName: 'name',
                                locale: this.context.user.locale
                            }).substring(0, 1)]) : <span>&nbsp;</span>}
                        </div>
                    </div>
                }
                <div className="preview-box product-offer">
                    <div className="box-title">
                        {currentQuote.categoryHasCalculation(this.props.category) ? vsprintf(getTranslation('print.offerperiod', this.context.user.locale), [currentQuote.numberOfMonths]) : <span>&nbsp;</span>}
                    </div>
                </div>
                <div className="preview-box product-price">
                    <div className="box-title">
                        {getTranslation('cost.unitprice', this.context.user.locale)}
                    </div>
                </div>
                <div className="preview-box product-price">
                    <div className="box-title">
                        {getTranslation('category.total', this.context.user.locale)}
                    </div>
                </div>
                {currentQuote.quoteHasDiscount() &&
                    <div className="preview-box product-price">
                        <div className="box-title">
                            {currentQuote.categoryHasDiscount(this.props.category) ? getTranslation('cost.discount', this.context.user.locale) : <span>&nbsp;</span>}
                        </div>
                    </div>
                }
                <div className="preview-box product-price">
                    <div className="box-title">
                        {getTranslation('category.total', this.context.user.locale)}
                    </div>
                </div>
            </div>
        )
    }
}

CategoryTableHeader.contextType = UserContext;