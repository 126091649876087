import React from 'react';
import {config} from '../config/Config.js';
import {PublicClientApplication} from '@azure/msal-browser';
import * as EasycalcApi from 'easycalc-api-client';
import EasycalcApiClient from '../data/EasycalcApiClient';
import {parseData} from "../data/DataParser";
import User from "../../model/User";
import {getDataFromStorage, isMissingData} from "../data/LocalStorage";
import {Quote} from "../../model/Quote";
import QuoteSync from "../tools/QuoteSync";
import {Apps} from "../../model/Apps";
import {getVersions, updateEntity} from "../data/UpdateService";

export function withUserProvider(WrappedComponent) {
    return class extends React.Component {
        publicClientApplication;
        authWindow;
        userApi;
        isUserLoadedFromLocalStorage = false;
        isDataLoaded = false;
        hasLoadedNewData = false;
        ADUserTryingToLogin = null;
        dataParsed = false;
        dataLoadedFromLocalStorage = false;
        getVersionsDone = false;

        constructor(props) {
            super(props);

            let userContext = config.defaultUserContext;
            userContext.user.userProvider = this;

            // Bind this to the Quote's app property to be able to update the state
            let defaultQuoteContext = config.defaultQuoteContext;
            // if the localStorage is empty, the config return a new blank quote and we need to add it to the available quote list
            if (defaultQuoteContext.availableQuotes.length === 0) {
                defaultQuoteContext.availableQuotes.push(defaultQuoteContext.currentQuote);
            }
            defaultQuoteContext.currentQuote.app = this;

            this.state = {
                error: null,
                isAuthenticated: false,
                isAuthenticating: false,
                initialized: false,
                userContext: userContext,
                quoteContext: defaultQuoteContext,
                loadingCount: 0,
                missingData: false,
                newVersionAvailable: false,
                showNewVersionAvailable: false,
                authorizedApps: [Apps.Farm]
            };

            this.entityToLoad = 0;

            // Initialize the MSAL application object
            this.publicClientApplication = new PublicClientApplication({
                auth: {
                    clientId: config.appId,
                    authority: config.appAuthority,
                    redirectUri: process.env.REACT_APP_AZURE_REDIRECT_URI
                },
                cache: {
                    cacheLocation: "sessionStorage",
                    storeAuthStateInCookie: true
                }
            });

            let apiClient = new EasycalcApiClient();
            this.userApi = new EasycalcApi.UserApi(apiClient);
        }

        componentDidMount() {
            const channelBroadcast = new BroadcastChannel('easycalc');
            channelBroadcast.onmessage = (event) => {
                if (event.data.key && event.data.key === 'NEW_VERSION_AVAILABLE') {
                    this.setState({
                        newVersionAvailable: true
                    });

                    // Data has been loaded, show the reload popup
                    if (this.state.loadingCount === 0) {
                        this.checkNewVersionAvailable();
                    }
                }
            }

            if (this.hasToken()) {
                this.isTokenValid();
            } else {
                this.setState({
                    initialized: true
                });
            }
        }

        setUserDefaultCurrency(updateQuote) {
            if (this.state.userContext.country.data && this.state.userContext.country.data[this.state.userContext.user.country] && this.state.userContext.country.data[this.state.userContext.user.country].defaultCurrency) {
                let user = this.state.userContext.user;
                user.currency = this.state.userContext.country.data[this.state.userContext.user.country].defaultCurrency;
                this.setState(function(state) {
                    return {
                        userContext: Object.assign(state.userContext, {
                            user: user
                        })
                    }
                }, () => {
                    if (updateQuote) {
                        let quote = this.state.quoteContext.currentQuote;
                        quote.setDataFromUser(this.state.userContext.user);
                    }
                });
            }
        }

        render() {
            return <WrappedComponent
                error={this.state.error}
                isAuthenticated={this.state.isAuthenticated}
                loginInitialized={this.state.initialized}
                isAuthenticating={this.state.isAuthenticating}
                isLoading={this.state.loadingCount}
                addLoading={() => this.addLoading()}
                removeLoading={() => this.removeLoading()}
                login={(user, password) => this.login(user, password)}
                loginAD={() => this.loginAD()}
                logout={(e) => this.logout(e)}
                getToken={() => this.getToken()}
                setError={(message) => this.setErrorMessage(message)}
                parseData={(isDataLoaded, hasParsedNewData) => this.parseData(isDataLoaded, hasParsedNewData)}
                userContext={this.state.userContext}
                quoteContext={this.state.quoteContext}
                missingData={this.state.missingData}
                showNewVersionAvailable={this.state.showNewVersionAvailable}
                authorizedApps={this.state.authorizedApps}
                {...this.props} />;
        }

        checkNewVersionAvailable = () => {
            if (this.state.newVersionAvailable) {
                this.setState({
                    newVersionAvailable: false,
                    showNewVersionAvailable: true
                });
            }
        }

        async login(username, password) {
            this.addLoading();
            this.setState({
                isAuthenticating: true,
                error: null
            });

            this.isUserLoadedFromLocalStorage = false;

            await this.userApi.userLogin({
                username: username,
                password: password
            }).then(user => {
                this.authenticateUser(user);
            }).catch(response => {
                if (response.body) {
                    this.setErrorMessage(response.body.error);
                } else if (response.error) {
                    this.setErrorMessage(response.error.message);
                }
                this.clearUser();
            });

            this.setState({
                isAuthenticating: false
            });

            setTimeout(() => {
                this.removeLoading();
            }, 600);
        }

        async loginAD(promptAccountSelection) {
            try {
                this.setState({
                    isAuthenticating: true,
                    error: null
                });

                this.isUserLoadedFromLocalStorage = false;

                // Stock the account the user wants to login, in case we have many logged in account,
                // we need to know for which one we want to get a token
                let loginPopupCallback = (authResult) => {
                    if (authResult.account) {
                        this.ADUserTryingToLogin = authResult.account;
                    }
                };
                loginPopupCallback.bind(this);

                // Login via popup
                this.ADUserTryingToLogin = null;
                await this.publicClientApplication.loginPopup({
                    scopes: config.scopes,
                    //prompt: (this.publicClientApplication.getAllAccounts().length > 1 || promptAccountSelection || this.publicClientApplication.getAllAccounts().length === 0) ? "select_account" : "none"
                    prompt: "select_account"
                }).then(loginPopupCallback);

                // After login, get the user's profile
                await this.getADUserProfile();

                this.setState({
                    isAuthenticating: false
                });
            } catch (err) {
                if (this.isInteractionRequired(err) && !promptAccountSelection) {
                    await this.sleep(1000); // MS n'aime pas quand on popup 2 fois de suite rapidement
                    await this.loginAD(true);
                } else {
                    this.clearUser();
                    this.setState({
                        isAuthenticated: false,
                        isAuthenticating: false,
                        error: this.normalizeError(err)
                    });
                }
            }
        }

        sleep(ms) {
            return new Promise(resolve => setTimeout(resolve, ms));
        }

        async getADAccessToken(scopes) {
            try {
                const accounts = this.publicClientApplication
                    .getAllAccounts();

                if (accounts.length <= 0) throw new Error('login_required');
                // Get the access token silently
                // If the cache contains a non-expired token, this function
                // will just return the cached token. Otherwise, it will
                // make a request to the Azure OAuth endpoint to get a token
                let silentResult = await this.publicClientApplication
                    .acquireTokenSilent({
                        scopes: scopes,
                        account: this.ADUserTryingToLogin
                    });

                return silentResult.accessToken;
            } catch (err) {
                // If a silent request fails, it may be because the user needs
                // to login or grant consent to one or more of the requested scopes
                if (this.isInteractionRequired(err)) {
                    let interactiveResult = await this.publicClientApplication
                        .acquireTokenPopup({
                            scopes: scopes
                        });

                    return interactiveResult.accessToken;
                } else {
                    throw err;
                }
            }
        }

        async getADUserProfile(token) {
            try {
                let accessToken;
                if (!token) {
                    accessToken = await this.getADAccessToken(config.scopes);
                } else {
                    accessToken = token;
                }

                if (accessToken) {
                    // Get the user's profile from Graph
                    await this.userApi.userLoginAD({
                        token: accessToken
                    }).then(user => {
                        this.authenticateUser(user);
                    }).catch(response => {
                        if (response.body) {
                            this.setErrorMessage(response.body.error);
                        } else if (response.error) {
                            this.setErrorMessage(response.error.message);
                        }
                        this.clearUser();
                    });
                }
            } catch (err) {
                this.setErrorMessage(err);
                this.clearUser();
            }
        }

        logout(e) {
            e.preventDefault();
            // Push user's quotes before logging out
            let quoteSync = new QuoteSync();
            quoteSync.uploadQuotes(() => {
                console.log('clear quotes in Local Storage after logout');
                localStorage.removeItem('quotes');
            });

            this.clearUser();
        }

        clearUser() {
            localStorage.removeItem(config.tokenParamName);
            localStorage.removeItem(config.userParamName);

            const quote = new Quote();
            quote.app = this;

            let newUser = new User({userProvider: this});
            this.setState(function(state) {
                return {
                    isAuthenticated: false,
                    userContext: Object.assign(state.userContext, {user: newUser}),
                    quoteContext: Object.assign(state.quoteContext, {
                        availableQuotes: [quote],
                        currentQuote: quote
                    })
                }
            });
        }

        hasToken() {
            return null != localStorage.getItem(config.tokenParamName);
        }

        getToken() {
            return localStorage.getItem(config.tokenParamName);
        }

        setToken(token) {
            localStorage.setItem(config.tokenParamName, token);
        }

        getUser() {
            return JSON.parse(localStorage.getItem(config.userParamName)) || {};
        }

        setUser() {
            this.state.userContext.user.persistToLocalStorage();
        }

        async isTokenValid() {
            let token = this.getToken();
            if (token) {
                this.addLoading();

                this.isUserLoadedFromLocalStorage = true;

                await this.userApi.checkToken({
                    token: token
                }).then(user => {
                    this.authenticateUser(Object.assign(this.getUser(), user));
                }).catch(response => {
                    if (response.body) {
                        this.setErrorMessage(response.body.error);
                    } else if (response.error) {
                        if (response.error.message.indexOf('Origin') !== -1) {
                            let user = this.getUser();
                            if (user) {
                                this.authenticateUser(user);
                                return;
                            }
                        }

                        this.setErrorMessage(response.error.message);
                    }

                    this.clearUser();
                });

                this.setState({
                    initialized: true
                });

                this.removeLoading();
            }
        }

        authenticateUser(user) {
            if (user) {
                this.setToken(user.token);
                let newUser = new User(Object.assign(user, {userProvider: this}));

                // New user, set the current locale and country
                if (!this.isUserLoadedFromLocalStorage) {
                    this.state.quoteContext.currentQuote.reset();

                    // Use the locale defined in the Login popup if the user changed the locale
                    if (localStorage.getItem(config.userLoginLocale) !== null) {
                        newUser.setLocale(localStorage.getItem(config.userLoginLocale), this.state.quoteContext.currentQuote, true);
                    } else if (newUser.defaultLocale) {
                        newUser.setLocale(newUser.defaultLocale, this.state.quoteContext.currentQuote, true);
                    }

                    if (newUser.defaultProductGroup) {
                        newUser.setProductGroup(newUser.defaultProductGroup, true);
                    } else if (newUser.productGroups && newUser.productGroups.length > 0) {
                        newUser.setLocale(newUser.productGroups[0], this.state.quoteContext.currentQuote, true);
                    }

                    if (newUser.countries && newUser.countries.length > 0) {
                        newUser.setCountry(newUser.countries[0], this.state.quoteContext.currentQuote, true);
                    }
                }

                this.addLoading();

                this.setState(function(state) {
                    return {
                        isAuthenticated: true,
                        userContext: Object.assign(state.userContext, {
                            user: newUser
                        }),
                        authorizedApps: newUser.apps ? newUser.apps : [Apps.Farm]
                    }
                }, () => {
                    getDataFromStorage().then((data) => {
                        this.setState(function(state) {
                            return {
                                userContext: Object.assign(state.userContext, data)
                            }
                        }, () => {
                            // If the data has been loaded from the localStorage and no new data has been loaded from
                            // the getVersions call, check if there is missing data
                            this.dataLoadedFromLocalStorage = true;

                            getVersions(newUser.apps)
                                .then((toDownload) => {
                                    this.entityToLoad = toDownload.length;
                                    if (this.entityToLoad === 0) {
                                        // Parse the data
                                        this.parseData(true, false);
                                    } else {
                                        toDownload.forEach((entity) => {
                                            let onSuccess = (function (data) {
                                                this.entityToLoad--;
                                                if (this.entityToLoad === 0) {
                                                    this.parseData(true, true);
                                                }
                                            }).bind(this);

                                            let onError = (function (error) {
                                                this.entityToLoad--;
                                                if (this.entityToLoad === 0) {
                                                    this.parseData(true, true);
                                                }
                                            }).bind(this);

                                            let resetPrices = (productGroups) => {
                                                if (this.isAuthenticated && this.userContext.user.productGroup) {
                                                    let productGroupId = this.userContext.user.productGroup;
                                                    let productGroup = productGroups[productGroupId];
                                                    let CQ = this.quoteContext.currentQuote;
                                                    CQ.prices = JSON.parse(productGroup.prices);
                                                    CQ.updateContextState(true);
                                                }
                                            }

                                            updateEntity(entity, () => this.addLoading, () => this.removeLoading, onSuccess, onError, resetPrices);
                                        });
                                    }
                                }).catch(() => {
                                    this.parseData(true, false);

                                    if (isMissingData(this.state.userContext)) {
                                        this.setState({
                                            missingData: true
                                        });
                                    }
                                });

                            this.setUserDefaultCurrency(true);
                        });
                    });

                    // Download and push user's quotes
                    let quoteSync = new QuoteSync();
                    quoteSync.downloadAndPushQuotes();
                });
            }
        }

        parseData(isDataLoaded, hasLoadedNewData, forceReload) {
            if (isDataLoaded !== null) {
                this.isDataLoaded = true;
            }

            if (hasLoadedNewData !== null) {
                this.hasLoadedNewData = hasLoadedNewData;
            }

            if ((this.state.isAuthenticated && this.isDataLoaded) || forceReload) {
                if (this.hasLoadedNewData || !this.isUserLoadedFromLocalStorage || forceReload) {
                    this.dataParsed = true;

                    // Parse data for this user and update the state
                    parseData(this.state.userContext).then((userContext) => {
                        this.setState({
                            userContext: userContext
                        }, () => {
                            this.setUser();

                            this.setUserDefaultCurrency(true);

                            this.removeLoading();
                            this.checkNewVersionAvailable();

                            if (isMissingData(this.state.userContext)) {
                                this.setState({
                                    missingData: true
                                });
                            }
                        })
                    }).catch(() => {
                        this.removeLoading();
                        this.checkNewVersionAvailable();

                        if (isMissingData(this.state.userContext)) {
                            this.setState({
                                missingData: true
                            });
                        }
                    });
                } else {
                    // If the data has been loaded from the localStorage and no new data has been loaded,
                    // check if there is missing data
                    this.getVersionsDone = true;
                    if (this.dataLoadedFromLocalStorage) {
                        if (isMissingData(this.state.userContext)) {
                            this.setState({
                                missingData: true
                            });
                        }

                        this.setUserDefaultCurrency(true);
                    }

                    setTimeout(() => {
                        this.removeLoading();
                        this.checkNewVersionAvailable();
                    }, 600);
                }
            }
        }

        addLoading() {
            this.setState(function(state) {
                return {
                    loadingCount: state.loadingCount + 1
                }
            });
        }

        removeLoading() {
            this.setState(function(state) {
                return {
                    loadingCount: state.loadingCount === 0 ? 0 : state.loadingCount - 1
                }
            });
        }

        setErrorMessage(message) {
            this.setState({
                error: message
            });
        }

        normalizeError(error) {
            var normalizedError = {};
            if (typeof (error) === 'string') {
                let errParts = error.split('|');
                normalizedError = errParts.length > 1 ? errParts[1] : error;
            } else {
                normalizedError = error.message;
            }
            return normalizedError;
        }

        isInteractionRequired(error) {
            if (!error.message || error.message.length <= 0) {
                return false;
            }

            return (
                error.message.indexOf('consent_required') > -1 ||
                error.message.indexOf('interaction_required') > -1 ||
                error.message.indexOf('login_required') > -1 ||
                error.message.indexOf('no_account_in_silent_request') > -1
            );
        }
    };
}
