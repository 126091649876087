import React, {Component} from "react";
import {UserContext} from "../../contexts/UserContext";
import {QuoteContext} from "../../contexts/QuoteContext";
import classNames from "classnames";
import {Quote} from "../../model/Quote";

export default class SpeciesSelector extends Component {
    onChange = (value, user) => {
        let speciesId = parseInt(value);
        user.setSpecies(speciesId);
        // Create a new Quote if this one has products

        if (this.context.currentQuote.hasProducts()) {
            let q = new Quote();
            q.app = this.context.currentQuote.app;
            q.setDataFromUser(user);
            q.addToAvailableQuotes();
            this.context.availableQuotes.push(q);
        } else {
            this.context.currentQuote.setSpecies(speciesId);
        }


        this.props.closeMenu();
    }

    render() {
        return (
            <UserContext.Consumer>
                {({user, species}) => {
                    if (!user.species || !species || species.version === null || !species.data) {
                        return null;
                    }
                    return (
                        <>
                            {Object.keys(species.data).map((key) =>
                                <div className={classNames({
                                    'species-item': true,
                                    'selected': parseInt(user.species) === parseInt(key)
                                })} onClick={() => this.onChange(key, user)} key={key}>
                                    <img src={species.data[key].thumbnail} alt={species.data[key].getTranslation({fieldName: 'name', locale: user.locale})}/> {species.data[key].getTranslation({fieldName: 'name', locale: user.locale})}
                                </div>
                            )}
                        </>
                    )
                }}
            </UserContext.Consumer>
        )
    }
}

SpeciesSelector.contextType = QuoteContext;
