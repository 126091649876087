import React, { Component } from 'react';
import { withUserProvider } from './components/user/UserProvider';
import Header from "./components/header/Header";
import LoginForm from "./components/user/LoginForm";
import LoadingOverlay from "./components/tools/LoadingOverlay";
import { UserContext } from "./contexts/UserContext";
import { QuoteContext } from "./contexts/QuoteContext";
import DistributorForm from "./components/user/DistributorForm";
import MissingDataOverlay from "./components/tools/MissingDataOverlay";
import OptionsForm from "./components/config/OptionsForm";
import ClosePopup from "./components/tools/ClosePopup";
import Farm from './components/module/Farm';
import WaterTreatment from './components/module/WaterTreatment';
import CGU from "./components/tools/CGU";
import NewVersionAvailable from "./components/tools/NewVersionAvailable";
import {deleteVersions} from "./components/data/Database";
import {Apps} from "./model/Apps";
import CausticSoda from "./components/module/CausticSoda";

class App extends Component {

    entityToLoad;

    constructor(props) {
        super(props);

        this.state = {
            showDistributorForm: false,
            showOptionsForm: false,
            showClosePopup: false,
            hasAcceptedCgu: localStorage.getItem('cgu'),
            exitApp: false,
            moduleName: localStorage.getItem('lastApp') ? parseInt(localStorage.getItem('lastApp')) : Apps.Farm,
            backgroundId: 1
        }
    }

    toggleModule = (module) => {
        let nextModule;

        if (!module) {
            nextModule = this.state.moduleName === Apps.Farm ? Apps.WaterTreatment : Apps.Farm;
        } else {
            nextModule = module;
        }

        let nextBackground;

        if (nextModule === Apps.Farm) {
            nextBackground = this.props.userContext.user.species;
        } else {
            nextBackground = 'app-' + nextModule;
        }

        localStorage.setItem('lastApp', nextModule);
        this.setState({ backgroundId: nextBackground, moduleName: nextModule });
        this.props.quoteContext.currentQuote.setApplication(nextModule);
        this.props.userContext.user.setAppBackground(nextBackground);
    }

    componentDidMount() {
        // Temp fix, now handled with the service worker
        if (!localStorage.getItem('forceReloadData')) {
            deleteVersions().finally(() => {
                localStorage.setItem('forceReloadData', '1');
                window.location.reload();
            });
        }

        // Confirm close window
        window.onbeforeunload = (e) => {
            if (this.props.quoteContext.currentQuote.isModified && !this.state.exitApp) {
                return true;
            }
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.authorizedApps !== prevProps.authorizedApps) {
            if (!this.props.authorizedApps.includes(this.state.moduleName)) {
                this.toggleModule(this.props.authorizedApps[0]);
            } else {
                this.toggleModule(this.state.moduleName);
            }
        }
    }

    toggleClosePopup = (action) => {
        if (action === 'open') {
            this.setState({
                showClosePopup: true
            });
        } else if (action === 'close') {
            this.setState({
                showClosePopup: false
            });
        } else {
            this.setState({
                showClosePopup: false,
                exitApp: true
            }, () => {
                const remote = window.require('electron').remote;
                remote.getCurrentWindow().close();
            });
        }
    }

    toggleDistributorForm = () => {
        this.setState(function (state) {
            return {
                showDistributorForm: !state.showDistributorForm
            }
        });
    }

    closeCguPopup = () => {
        this.setState(function (state) {
            return {
                hasAcceptedCgu: true
            }
        });
    }

    toggleOptionsForm = (value) => {
        this.setState({
            showOptionsForm: value
        });
    }

    render() {
        return (
            <UserContext.Provider value={this.props.userContext}>
                <QuoteContext.Provider value={this.props.quoteContext}>
                    <DistributorForm
                        display={this.state.showDistributorForm && this.props.userContext.user.productGroups && this.props.userContext.user.productGroups.length > 1}
                        toggleDistributorForm={this.toggleDistributorForm}
                        currentQuote={this.props.quoteContext.currentQuote}
                    />
                    <LoginForm
                        isAuthenticated={this.props.isAuthenticated}
                        isInitialized={this.props.loginInitialized}
                        loginADMethod={this.props.loginAD}
                        loginMethod={this.props.login}
                        isAuthenticating={this.props.isAuthenticating}
                        loginError={this.props.error}
                    />
                    {this.state.showClosePopup &&
                        <ClosePopup toggle={this.toggleClosePopup} />
                    }
                    {this.props.missingData && !this.props.isLoading &&
                        <MissingDataOverlay show={true} />
                    }
                    {this.props.showNewVersionAvailable &&
                        <NewVersionAvailable show={true} />
                    }
                    {!this.state.hasAcceptedCgu && !this.props.isLoading &&
                        <CGU show={true} closeCguPopup={this.closeCguPopup} />
                    }
                    <LoadingOverlay isLoading={this.props.isLoading} />

                    <Header
                        isAuthenticated={this.props.isAuthenticated}
                        logoutMethod={this.props.logout}
                        toggleDistributorForm={this.toggleDistributorForm}
                        showOptionsForm={this.toggleOptionsForm}
                        currentModule={this.state.moduleName}
                        toggleModule={this.toggleModule}
                    />

                    {this.state.showOptionsForm &&
                        <OptionsForm show={true} showMethod={this.toggleOptionsForm} currentModule={this.state.moduleName} />
                    }

                    {!this.props.isLoading &&
                        <>
                            {this.state.moduleName === Apps.Farm &&
                                <Farm {...this.props} />
                            }
                            {this.state.moduleName === Apps.WaterTreatment && this.props.isAuthenticated &&
                                <WaterTreatment {...this.props} />
                            }
                            {this.state.moduleName === Apps.CausticSoda && this.props.isAuthenticated &&
                                <CausticSoda {...this.props} />
                            }
                        </>
                    }

                </QuoteContext.Provider>
            </UserContext.Provider>
        );
    }
}

export default withUserProvider(App);
