import React from "react";
import {getTranslation} from "../tools/Translation";
import {UserContext} from "../../contexts/UserContext";
import classNames from "classnames";

export default class ProductOffer extends React.Component {
    render() {
        let offerString = getTranslation("cost.selectpackage", this.context.user.locale);

        if (this.props.quoteProduct.quantity && this.props.quoteProduct.getFormat()) {
            offerString = this.props.quoteProduct.quantity + ' x ' + this.props.quoteProduct.getFormat().name;
        }

        return (
            <div className={classNames({
                "fake-input": true,
                "pointer": true,
                "offer": true,
                "border-danger": !this.props.quoteProduct.format
            })} onClick={() => this.props.quoteProduct.doHighlightFormats()}>
                {offerString}
            </div>
        )
    }
}

ProductOffer.contextType = UserContext;