import React, {useContext} from "react";
import {Col, Row} from "react-bootstrap";
import {getTranslation} from "../tools/Translation";
import {UserContext} from "../../contexts/UserContext";
import TableCommonHeader from "./TableCommonHeader";
import TableCommonBody from "./TableCommonBody";
import {slugify} from "../tools/String";
import {CipTypes} from "../../model/CausticSoda/CipTypes";

export default function TableMonoAdditive(props) {
    const context = useContext(UserContext);

    return (
        <Row className="mb-3">
            <Col>
                <h3>{getTranslation('cs.monophase-alternee-additif', context.user.locale)}</h3>
                <table className="table table-bordered table-responsive small data-table table-hover table-valign-middle text-center">
                    <thead className="table-mono-additive">
                        <tr>
                            <TableCommonHeader {...props} />
                            {Object.keys(context.washingstep.data).map((washingStepId) => {
                                if (context.washingstep.data[washingStepId].productType && !context.washingstep.data[washingStepId].rince) {
                                    return (
                                        <th key={washingStepId}>
                                            {getTranslation(slugify('cs.conso-annuelle-washing-step-' + context.washingstep.data[washingStepId].getTranslation({fieldName: 'name', locale: 'fr'})), context.user.locale)}
                                        </th>
                                    )
                                }

                                return null;
                            })}
                            {Object.keys(context.washingstep.data).map((washingStepId) => {
                                if (context.washingstep.data[washingStepId].rince) {
                                    return (
                                        <th key={washingStepId}>
                                            {getTranslation(slugify('cs.conso-annuelle-rince-washing-step-' + context.washingstep.data[washingStepId].getTranslation({fieldName: 'name', locale: 'fr'})), context.user.locale)}
                                        </th>
                                    )
                                }

                                return null;
                            })}
                            <th>
                                {getTranslation('cs.consoEauAnnuelleGlobale', context.user.locale)}
                            </th>
                        </tr>
                        <tr>
                            {Object.keys(context.washingstep.data).map((washingStepId) => {
                                if (context.washingstep.data[washingStepId].productType && !context.washingstep.data[washingStepId].rince) {
                                    return (
                                        <th key={washingStepId}>
                                            {props.quote.getSommeConsoEauAnnuelleProductType(context, CipTypes.monoSodaAdditive, context.washingstep.data[washingStepId].productType).toFixed(0)}
                                        </th>
                                    )
                                }

                                return null;
                            })}
                            {Object.keys(context.washingstep.data).map((washingStepId) => {
                                if (context.washingstep.data[washingStepId].rince) {
                                    return (
                                        <th key={washingStepId}>
                                            {props.quote.getSommeConsoEauAnnuelleWashingStep(context, washingStepId, false).toFixed(0)}
                                        </th>
                                    )
                                }

                                return null;
                            })}
                            <th>
                                {parseFloat(props.quote.getMonoAdditiveSommeConsoEauAnnuelle(context)).toFixed(0)}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {props.quote.equipments.map((equipment) => {
                            return (
                                <tr key={equipment.id}>
                                    <TableCommonBody quote={props.quote} equipment={equipment} />

                                    {Object.keys(context.washingstep.data).map((washingStepId) => {
                                        if (context.washingstep.data[washingStepId].productType && !context.washingstep.data[washingStepId].rince) {
                                            return (
                                                <td key={washingStepId}>

                                                </td>
                                            )
                                        }

                                        return null;
                                    })}
                                    {Object.keys(context.washingstep.data).map((washingStepId) => {
                                        if (context.washingstep.data[washingStepId].rince) {
                                            return (
                                                <td key={washingStepId}>

                                                </td>
                                            )
                                        }

                                        return null;
                                    })}
                                    <td>

                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </Col>
        </Row>
    );
}