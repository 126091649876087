import React from "react";
import {UserContext} from "../../contexts/UserContext";

export default class LabelOnly extends React.Component {
    render() {

        if (!this.props.estimatedConsumptions) {
            return null;
        }

        let estimatedConsumption = null;

        if (this.props.productData.estimatedConsumptionId && this.props.estimatedConsumptions.length > 0) {
            this.props.estimatedConsumptions.forEach((e) => {
                if (e.id === this.props.productData.estimatedConsumptionId) {
                    estimatedConsumption = e;
                }
            });
        } else {
            return null;
        }

        if (!this.context.consumptiontype.data || !this.context.consumptiontype.data[estimatedConsumption.consumptionType]) {
            return null;
        }

        let label = this.context.consumptiontype.data[estimatedConsumption.consumptionType].getTranslation({fieldName: 'label', locale: this.context.user.locale});
        return <>{label}</>
    }
}

LabelOnly.contextType = UserContext;
