import React, {useContext} from "react";
import classNames from "classnames";
import {UserContext} from "../../contexts/UserContext";
import {getTranslation} from "./Translation";
import {Col, Row} from "react-bootstrap";

export default function CustomSelect(props) {
    const context = useContext(UserContext);

    return (
        <Row className="mb-1">
            <Col className="pt-2">
                <label htmlFor={props.name} className="form-label">{ getTranslation('cs.' + props.name, context.user.locale) } :</label>
            </Col>
            <Col>
                <select className={classNames({'form-control': true, 'error': props.errorFields.includes(props.name), 'advanced-method': props.advancedMethod})} id={props.name} onChange={(e) => {props.handleChange(props.equipment.id, props.name, e.target.value)}} value={props.equipment[props.name]}>
                    {props.allowEmpty &&
                        <option key={0} value='' />
                    }
                    {Object.entries(props.values).map((parts) => {
                        const object = parts[1];
                        if (props.notTranslatable) {
                            return (
                                <option key={object.id} value={object.id}>{getTranslation(object[props.fieldName], context.user.locale)}</option>
                            )
                        }

                        return (
                            <option key={object.id} value={object.id}>{object.getTranslation({fieldName: props.fieldName, locale: context.user.locale})}</option>
                        )}
                    )}
                </select>
            </Col>
        </Row>
    )
}