import React from "react";
import {getTranslation} from "../tools/Translation";
import {UserContext} from "../../contexts/UserContext";

export default class Total extends React.Component {
    render() {
        return (
            <div className="total">
                {getTranslation('print.generaltotal', this.context.user.locale).toUpperCase()}&nbsp;:&nbsp;
                {this.props.currentQuote.getTotal().toFixed(2)} {this.props.currentQuote.getCurrencySign()}
            </div>
        )
    }
}

Total.contextType = UserContext;