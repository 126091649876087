import React, {Component} from "react";
import {config} from "../config/Config";
import classNames from "classnames";
import {QuoteContext} from "../../contexts/QuoteContext";
import {OverlayTrigger, Tooltip} from "react-bootstrap";

export default class CarouselItem extends Component {
    constructor(props) {
        super(props);
        this.wrapper = React.createRef();

        this.state = {
            forbid: false
        }

        this.productAdded = false;
    }

    onMouseOverHandler = () => {
        this.wrapper.current.classList.add('hover');
    }

    onMouseOutHandler = () => {
        this.wrapper.current.classList.remove('hover');
    }

    onClickHandler = (forbid) => {
        if (forbid) {
            this.setState({
                forbid: true
            });
        } else {
            this.context.currentQuote.addProduct(this.props.category.id, this.props.product.id)
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const productAdded = this.context.currentQuote.hasProduct(this.props.category.id, this.props.product.id);
        if (productAdded !== this.productAdded) {
            this.productAdded = productAdded;

            if (!this.productAdded && this.state.forbid) {
                this.setState({
                    forbid: false
                });
            }
        }
    }

    render() {
        let product = this.props.product;
        let category = this.props.category;
        let categories = this.props.categories;
        const forbid = !this.state.forbid && !this.context.currentQuote.hasProduct(category.id, product.id) && [config.phAcidicId, config.phAlkalineId].includes(product.ph) && this.context.currentQuote.categoryHasProductWithPh(category.id, product.ph);

        const item = (
            <div ref={this.wrapper} onMouseEnter={this.onMouseOverHandler} onMouseLeave={this.onMouseOutHandler} onClick={() => this.onClickHandler(forbid)}>
                <img src={product.image ? product.image : config.productPlaceholderImage} alt={product.name}/>
                <div className={classNames({
                    'top-caption': true,
                    'no-opacity': !product.activeIngredient
                })} style={category.color ? {color: category.color} : null}>{product.activeIngredient ? product.activeIngredient.getTranslation({fieldName: "name", locale: this.props.user.locale}) : '&nbsp;'}</div>
                <div className="plus" style={{display: this.context.currentQuote.hasProduct(category.id, product.id) ? 'none' : 'block'}}/>
                <div className="check" style={{opacity: this.context.currentQuote.hasProduct(category.id, product.id) ? 1 : 0}}/>
                <div className="forbid" style={{opacity: forbid ? 1 : 0}}/>
                <div className="caption" style={category.color ? {backgroundColor: category.color} : null}>
                    <span>{product.name}</span>
                </div>
            </div>
        );

        // Robotic Milking, display the product categories (other than this category) in Tooltip
        if (category.isRoboticMilking && product.categories.length > 1) {
            let categoryList = '';

            product.categories.forEach((categoryId) => {
                categoryList = categoryList + (categories && categories[categoryId] && categories[categoryId].id !== category.id ? (categories[categoryId].getTranslation({
                    fieldName: "name",
                    locale: this.props.user.locale
                }) + ', ') : '');
            });

            categoryList = categoryList.replace(new RegExp(/(.*),\s$/ig), "$1");

            return (
                <OverlayTrigger
                    overlay={
                        <Tooltip>
                            {categoryList}
                        </Tooltip>
                    }>
                    {item}
                </OverlayTrigger>
            )
        }

        // Otherwise, no Tooltip
        return item;
    }
}

CarouselItem.contextType = QuoteContext;
