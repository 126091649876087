import React from "react";
import {getTranslation} from "../tools/Translation";
import {UserContext} from "../../contexts/UserContext";

export default class TotalDiscount extends React.Component {
    render() {
        return (
            <div className="sheet-padding clearfix">
                <div className="total-discount">
                    {getTranslation('print.totaldiscount', this.context.user.locale)}:&nbsp;
                    {this.props.currentQuote.getTotalDiscount().toFixed(2)} {this.props.currentQuote.getCurrencySign()}
                </div>
            </div>
        )
    }
}

TotalDiscount.contextType = UserContext;