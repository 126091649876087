import React from "react";
import {QuoteContext} from "../../contexts/QuoteContext";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFolderOpen} from "@fortawesome/free-solid-svg-icons";
import {getTranslation} from "../tools/Translation";
import {Modal, Button, ListGroup} from "react-bootstrap";
import {getQuotesFromLocalStorage} from "../data/LocalStorage";
import QuoteOpenLine from "./QuoteOpenLine";
import ModalHeader from "react-bootstrap/ModalHeader";
import {Quote} from "../../model/Quote";
import Encryption from "../tools/Encryption";
import {QuoteProduct} from "../../model/QuoteProduct";
import QuoteInitializer from "../../model/QuoteInitializer";
import moment from "moment";
import QuoteSync from "../tools/QuoteSync";
import {Apps} from "../../model/Apps";

export default class QuoteOpen extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            showOpenQuoteModal: false,
            showConfirmDeleteModal: false
        }
        this.showOpenQuoteModal = this.showOpenQuoteModal.bind(this);
        this.hideOpenQuoteModal = this.hideOpenQuoteModal.bind(this);
        this.showConfirmDeleteModal = this.showConfirmDeleteModal.bind(this);
        this.hideConfirmDeleteModal = this.hideConfirmDeleteModal.bind(this);
        this.handleDoDelete = this.handleDoDelete.bind(this);
    }

    alreadyInList(id) {
        let retval = false;

        if (this.context.currentQuote.application === Apps.Farm) {
            this.context.availableQuotes.forEach((quote) => {
                if (quote.id === id) {
                    retval = true;
                }
            });
        }

        if (this.context.currentQuote.application === Apps.CausticSoda) {
            if (id === this.context.currentQuote.id) {
                retval = true;
            }
        }

        return retval;
    }

    showOpenQuoteModal = () => this.setState({showOpenQuoteModal: true});

    hideOpenQuoteModal() {
        this.setState({showOpenQuoteModal: false});
    }

    showConfirmDeleteModal(quoteId) {
        this.setState({showConfirmDeleteModal: true});
        this.setState({quoteIdToDelete: quoteId});
        this.hideOpenQuoteModal();
    }

    hideConfirmDeleteModal() {
        this.showOpenQuoteModal();
        this.setState({showConfirmDeleteModal: false});
    }

    handleDoDelete() {
        const quoteId = this.state.quoteIdToDelete;
        if (quoteId) {
            this.context.currentQuote.removeFromLocalStorage(quoteId);
            let quoteSync = new QuoteSync();
            quoteSync.uploadQuotes();
        }
        this.hideConfirmDeleteModal();
    }

    handleImportClick = () => {
        let el = document.createElement('input');
        el.setAttribute('type', 'file');
        el.setAttribute('accept', '.eas,.eaq')
        el.onchange = this.handleFileSelect;
        el.click();
    }

    getCategoryId(value) {
        let UC = this.context.currentQuote.getUserContext();
        let categories = UC && UC.category && UC.category.data ? UC.category.data : null;
        let categoryId = null;
        const dictionnary = {
            //accessories: null,
            betweenmilkings: 'betweenmilkings',
            //biosecurity: null,
            catalogue: 'catalog',
            clusters: 'clustersdisinfection',
            controllers: 'controllers',
            internalcircuits: 'milkingmachinecleaning',
            milktank: 'milktank',
            nutrition: 'animalnutrition',
            //otherproducts: null,
            paws: 'pawhygiene',
            robotic: 'roboticsystems',
            surfaces: 'cleaninganddisinfectionofsurfaces',
            udderafterm: 'udderhygieneaftermilking',
            udderbeforem: 'udderhygienebeforemilking',
            valorization: 'valorizationoffodder',
            vehicles: 'vehiclehygiene',
            watertt: 'watertreatment'
        };

        value = null !== dictionnary[value] ? dictionnary[value] : value;

        if (null !== categories) {
            let found = false;
            categories.forEach(function (c) {
                if (!found) {
                    let categoryName = c.getTranslation({fieldName: 'name', locale: 'en'}).trim().replace(/\s/g, '').toLowerCase();
                    if (categoryName === value) {
                        categoryId = c.id;
                        found = true;
                    }
                }
            })
        }
        return categoryId;
    }

    getProductFromCode(value) {
        let UC = this.context.currentQuote.getUserContext();
        let products = UC.product.data;
        let product = null;
        let found = false;
        products.forEach(function (p) {
            if (!found && p.name === value) {
                product = p;
                found = true;
            }
        })
        return product;
    }

    getProduct(value) {
        let UC = this.context.currentQuote.getUserContext();
        let productGroup = UC.productgroup.data[UC.user.getProductGroup()];
        let product = this.getProductFromCode(value);
        let isInGroup = false;
        if (null !== product) {
            let found = false;
            productGroup.products.forEach(function (p) {
                if (!found && p === product.id) {
                    isInGroup = true;
                    found = true;
                }
            })
        }
        return isInGroup ? product : null;
    }

    setSelectedQuote(quote) {
        if (!quote.app) {
            quote.app = this.context.currentQuote.app;
        }
        quote.setCurrent();
    }

    switchQuoteHandler(user, e) {
        user.setDataFromQuote(e);
        this.setSelectedQuote(e);
    }

    handleFileSelect = async (e) => {
        const reader = new FileReader();
        reader.onload = async (e) => {
            let filename = e.target.fileName;
            const extension = filename.split('.').pop().toLowerCase();
            if (extension === 'eaq') {
                let enc = new Encryption();
                let quoteData = new Quote(JSON.parse(enc.decrypt(e.target.result)));
                //reset quote id
                quoteData.id = moment().valueOf();
                let originalCategories = quoteData.categories;
                let UC = this.context.currentQuote.getUserContext();
                let newCategories = {};
                Object.keys(originalCategories).forEach((categoryId) => {
                    let originalCategory = originalCategories[categoryId];
                    let newProducts = {};
                    Object.keys(originalCategory).forEach((productId) => newProducts[productId] = new QuoteProduct(originalCategory[productId]));
                    newCategories[categoryId] = newProducts;
                });
                quoteData.categories = newCategories;
                let quote = quoteData;
                quote.app = this.context.currentQuote.app;

                // Set object instance based on the JSON loaded from the localStorage
                let quoteInitializer = new QuoteInitializer();
                quoteInitializer.init(quote, {product: UC.product, formulavariable: UC.formulavariable});

                this.context.availableQuotes.push(quote);
                UC.user.setDataFromQuote(quoteData);
                quote.setCurrent();

            } else {
                let lines = e.target.result.split('\n');
                let scanCategories = false;
                let currentCategory = null;
                if (lines.length > 0) {

                    let user = this.context.currentQuote.getUserContext().user;
                    let quote = new Quote();
                    quote.app = this.context.currentQuote.app;

                    for (let lineId = 0; lineId < lines.length; lineId++) {
                        let line = lines[lineId];
                        if (line.length > 1) {

                            let values = line.replace(/¤.*?¤/g, '').replace(/µ.*?µ/g, '').split(':');
                            let key = values[0];
                            let value = values[1];

                            if (!scanCategories) {
                                if (value) {
                                    value = value.replace(/\r?\n|\r/, '');
                                    if (value.length > 0 && value !== '*') {
                                        switch (key) {

                                            case 'animalType':
                                                quote.setSpeciesFromName(value);
                                                break;
                                            case 'country':
                                                let CnV = value.split('-');
                                                if (CnV[0]) {
                                                    quote.setCountryFromAlpha2(CnV[0]);
                                                }
                                                if (CnV[1]) {
                                                    quote.setLocale(CnV[1]);
                                                }
                                                break;
                                            case 'currency':
                                                quote.setCurrencyFromSign(value);
                                                break;

                                            case 'productRangeDB':
                                                quote.setProductGroupFromOldId(value);
                                                break;

                                            case 'period':
                                                quote.setNumberOfMonths(value);
                                                break;

                                            case 'animals':
                                                quote.generalData.numberofanimals = value;
                                                break;

                                            case 'clusters':
                                                quote.generalData.clusters = value;
                                                break;

                                            case 'robots':
                                                quote.generalData.robots = value;
                                                break;

                                            case 'tank volume':
                                                quote.generalData.mikltankcapacity = value;
                                                break;

                                            case 'milkProd':
                                                quote.generalData.milkproductionperyear = value;
                                                break;

                                            case 'heifers':
                                                quote.generalData.numberofheifersperyear = value;
                                                break;

                                            case 'calvingAge':
                                                quote.generalData.ageatcalvinginmonths = value;
                                                break;

                                            case 'grassHa':
                                                quote.generalData.numberofhectaresofgrasssiltedorwrapped = value;
                                                break;

                                            case 'cornHa':
                                                quote.generalData.numberofhectaresofcornsilag = value;
                                                break;

                                            case 'remarks':
                                                quote.generalData.remarques = value;
                                                break;

                                            case 'contactdate':
                                                quote.generalData.date = value;
                                                break;

                                            case 'made':
                                                quote.generalData.etablipar = value;
                                                break;

                                            case 'vendor':
                                                quote.generalData.nom = value;
                                                break;

                                            case 'site':
                                                quote.generalData.gaec = value;
                                                break;

                                            case 'breeder':
                                                quote.generalData.nomeleveur = value;
                                                break;

                                            case 'mail':
                                                quote.generalData.email = value;
                                                break;

                                            case 'phone':
                                                quote.generalData.telephone = value;
                                                break;

                                            case 'address':
                                                quote.generalData.adresse = value;
                                                break;

                                            case 'infocust':
                                                quote.generalData.infosutiles = value;
                                                break;

                                            case 'validated':
                                                scanCategories = true;
                                                break;

                                            case 'milkrefval': // TODO: À Valider
                                                break;

                                            case 'total':
                                                break;

                                            case 'AOC': //option Zone AOC
                                            case 'udderdiscount': //option Remise globale hygiène mamelle
                                            case 'globaldiscount': //option Remise globale (sur tous les produits)
                                            case 'tax': // option Prix TTC
                                            case 'priceperkg': //option Prix au kilo
                                            case 'costGMilk': //option Coût au 1000L de lait/an
                                            case 'costDCM': //option Coût par vache/mois
                                            case 'costGDCM': //option Coût global par vache/mois
                                            case 'fullview':
                                            case 'version':
                                                //rien faire
                                                break;

                                            default:
                                                break;
                                        }
                                    }
                                }
                            } else {
                                if (value.length > 0 && value !== '*') {

                                    let categoryId = this.getCategoryId(key);

                                    if (null == categoryId && null !== currentCategory) {

                                        let product = this.getProduct(key);

                                        if (null !== product) {

                                            //add product to quote
                                            quote.addProduct(currentCategory, product.id);
                                            //get product instance
                                            let productInstance = quote.categories[currentCategory][product.id];
                                            //split values from file
                                            let productInfos = value.split('-');
                                            //region set individual details

                                            //region estimated consumption
                                            let estimatedConsumption = productInfos[0] && productInfos[0] !== '*' ? productInfos[0] : null;
                                            if (null !== estimatedConsumption) {
                                                productInstance.setCalculationPart({parameter: 'ProductConsumption.MinimumValue', value: estimatedConsumption});
                                            }
                                            //endregion

                                            //region number of months
                                            let numberOfMonths = productInfos[1] && parseInt(productInfos[1]) > 0 ? parseInt(productInfos[1]) : null;
                                            if (null !== numberOfMonths) {
                                                productInstance.setCalculationPart({parameter: 'Quote.NumberOfMonths', value: numberOfMonths});
                                            }
                                            //endregion

                                            //region poids total
                                            //let poidsTotal = productInfos[2]; // estimatedConsumption * numberOfMonth / 12 * numberOfAnimals
                                            //endregion

                                            //region packaging
                                            let packagingLabel = productInfos[5] && productInfos[5] !== '*' ? productInfos[5] : null; // ex: 4x5 not 20
                                            if (null !== packagingLabel) {
                                                //get format id from code
                                                let found = false;
                                                productInstance.product.formats.forEach(function (p) {
                                                        if (!found && p.name.toLowerCase() === packagingLabel.toLowerCase()) {
                                                            productInstance.setFormat(p.id);
                                                            found = true;
                                                        }
                                                    }
                                                );
                                            }
                                            //endregion

                                            //region price
                                            //require packaging id
                                            let price = productInfos[3];
                                            productInstance.setPrice(price);
                                            //force price modified
                                            productInstance.setIsPriceModified();
                                            //endregion

                                            //region proposed quantity
                                            let proposedQuantity = productInfos[4] && productInfos[4] !== '*' && parseInt(productInfos[4]) > 0 ? parseInt(productInfos[4]) : null; // RoundUp( poidsTotal / packagingWeight )
                                            if (null !== proposedQuantity) {
                                                productInstance.setQuantity(proposedQuantity);
                                            }
                                            //endregion

                                            //region flag eau douce
                                            let eauDouce = productInfos[6] !== '*' ? (parseInt(productInfos[6]) === 1 || productInfos[6].toLowerCase() === 'true') : false;
                                            if (eauDouce) {
                                                quote.setCategoryUsingSoftWater(currentCategory, true);
                                            }
                                            //endregion

                                            //region discount
                                            let discount = productInfos[7] !== '*' ? productInfos[7] : null;
                                            if (null !== discount) {
                                                productInstance.setHasDiscount();
                                                productInstance.setDiscount(discount);
                                            }
                                            //endregion

                                            //region specific mode spray, mousse, etc...
                                            //let specificMode = productInfos[8] !== '*' ? productInfos[8] : null;
                                            //endregion

                                            //region mise à disposition
                                            let forFree = productInfos[9] && productInfos[9] !== '*' ? productInfos[9] === "true" : false; // mise à disposition
                                            productInstance.setLoaned(forFree);
                                            if (forFree) {
                                                productInstance.setPrice(0);
                                            }
                                            //endregion

                                            //region en spray checkbox
                                            let inSpray = productInfos[10] && productInfos[10] !== '*' ? productInfos[10].toLowerCase() === 'true' : false;
                                            if (inSpray) {
                                                productInstance.setInSpray(inSpray);
                                            }
                                            //endregion

                                            //endregion
                                        }

                                    } else {
                                        currentCategory = categoryId;
                                    }
                                }
                            }
                        }
                    }

                    this.context.availableQuotes.push(quote);
                    this.setSelectedQuote(quote);
                    this.switchQuoteHandler(user, quote);
                }
            }

            this.hideOpenQuoteModal();
        }
        reader.fileName = e.target.files[0].name;
        reader.readAsText(e.target.files[0]);
    }

    render() {
        let _quotes = getQuotesFromLocalStorage();

        // Dont show quotes to be deleted.
        let quotes = [];
        _quotes.forEach((quote) => {
            if (!quote.toBeDeleted && (quote.application === this.context.currentQuote.application)) {
                quotes.push(quote);
            }
        });

        let userLocale = this.context.currentQuote.getUserContext().user.locale;

        let quoteList;
        if (quotes.length > 0) {
            quoteList = quotes.map(quote => <QuoteOpenLine alreadyOpened={this.alreadyInList(quote.id)} key={quote.id} quote={quote} closeModal={this.hideOpenQuoteModal} showConfirmDelete={this.showConfirmDeleteModal} hideConfirmDelete={this.hideConfirmDeleteModal}/>);
        } else {
            quoteList = <span className="text-center font-italic">{getTranslation('openQuote.noAvailableQuote', userLocale)}</span>
        }

        return (
            <>
                <Modal style={{zIndex: 10005}} show={this.state.showOpenQuoteModal} backdrop="static" keyboard={false} size="md" centered>
                    <ModalHeader>
                        <Modal.Title>{getTranslation('openQuote.title', userLocale)}</Modal.Title>
                        {this.context.currentQuote.application === Apps.Farm &&
                            <Button onClick={this.handleImportClick}>{getTranslation('openQuote.importFile', userLocale)}</Button>
                        }
                    </ModalHeader>
                    <Modal.Body>
                        <ListGroup>
                            {quoteList}
                        </ListGroup>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.hideOpenQuoteModal}>{getTranslation('openQuote.actionCancel', userLocale)}</Button>
                    </Modal.Footer>
                </Modal>
                <Modal style={{zIndex: 10006}} show={this.state.showConfirmDeleteModal} backdrop="static" keyboard={false} size="md" centered>
                    <ModalHeader><Modal.Title>{getTranslation('deleteQuote.title', userLocale)}</Modal.Title></ModalHeader>
                    <Modal.Body>
                        {getTranslation('deleteQuote.bodyMessage', userLocale)}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.hideConfirmDeleteModal}>{getTranslation('deleteQuote.actionCancel', userLocale)}</Button>
                        <Button variant="danger" onClick={this.handleDoDelete}>{getTranslation('deleteQuote.actionDelete', userLocale)}</Button>
                    </Modal.Footer>
                </Modal>
                <FontAwesomeIcon className={"file-open" + ((this.props.nb < 5) ? "" : " light")} size={"lg"} icon={faFolderOpen} onClick={this.showOpenQuoteModal}/>
            </>
        )
    }
}

QuoteOpen.contextType = QuoteContext;
