import React, {useContext} from "react";
import {Col, Row} from "react-bootstrap";
import {getTranslation} from "../tools/Translation";
import {UserContext} from "../../contexts/UserContext";
import TableCommonHeader from "./TableCommonHeader";
import TableCommonBody from "./TableCommonBody";
import {slugify} from "../tools/String";
import {config} from "../config/Config";
import {CipTypes} from "../../model/CausticSoda/CipTypes";

export default function TableMonoAlternate(props) {
    const context = useContext(UserContext);

    return (
        <Row className="mb-3">
            <Col>
                <h3>{getTranslation('cs.monophase-alternee', context.user.locale)}</h3>
                <table className="table table-bordered table-responsive small data-table table-hover table-valign-middle text-center">
                    <thead className="table-mono-alternate">
                        <tr>
                            <TableCommonHeader {...props} />
                            {props.quote.advancedMethod &&
                                <>
                                    <th>
                                        {getTranslation('cs.dureeTotaleLavagesMensuels', context.user.locale)}
                                    </th>
                                    <th rowSpan={2}>
                                        {getTranslation('cs.maintientDesinfection', context.user.locale)}
                                    </th>
                                </>
                            }
                            {Object.keys(context.washingstep.data).map((washingStepId) => {
                                if (props.quote.advancedMethod || (!props.quote.advancedMethod && ![config.washingStepPrelavageId, config.washingStepDesinfectant].includes(parseInt(washingStepId)))) {
                                    return (
                                        <th key={washingStepId}>
                                            {getTranslation(slugify('cs.nb-washing-step-' + context.washingstep.data[washingStepId].getTranslation({fieldName: 'name', locale: 'fr'})), context.user.locale)}
                                        </th>
                                    )
                                }

                                return null;
                            })}
                            {props.quote.advancedMethod &&
                                <th rowSpan={2}>
                                    {getTranslation('cs.debitCircuitRincage', context.user.locale)}
                                </th>
                            }
                            {props.quote.advancedMethod &&
                                <>
                                    {Object.keys(context.washingstep.data).map((washingStepId) => {
                                        if (props.quote.advancedMethod && !context.washingstep.data[washingStepId].rince && context.washingstep.data[washingStepId].productType) {
                                            return (
                                                <th key={washingStepId}>
                                                    {getTranslation(slugify('cs.conso-annuelle-washing-step-' + context.washingstep.data[washingStepId].getTranslation({fieldName: 'name', locale: 'fr'})), context.user.locale)}
                                                </th>
                                            )
                                        }

                                        return null;
                                    })}
                                    {Object.keys(context.washingstep.data).map((washingStepId) => {
                                        if (context.washingstep.data[washingStepId].rince) {
                                            return (
                                                <th key={washingStepId}>
                                                    {getTranslation(slugify('cs.conso-annuelle-rince-washing-step-' + context.washingstep.data[washingStepId].getTranslation({fieldName: 'name', locale: 'fr'})), context.user.locale)}
                                                </th>
                                            )
                                        }

                                        return null;
                                    })}
                                    <th>
                                        {getTranslation('cs.consoEauAnnuelleGlobale', context.user.locale)}
                                    </th>
                                </>
                            }
                        </tr>
                        <tr>
                            {props.quote.advancedMethod &&
                                <th>
                                    {props.quote.getSommeMonoAlterneeDureeTotalLavages(context).toFixed(3)}
                                </th>
                            }
                            {Object.keys(context.washingstep.data).map((washingStepId) => {
                                if (props.quote.advancedMethod || (!props.quote.advancedMethod && ![config.washingStepPrelavageId, config.washingStepDesinfectant].includes(parseInt(washingStepId)))) {
                                    return (
                                        <th key={washingStepId}>
                                            {props.quote.getSommeNbWashingStep(context, washingStepId, true, null)}
                                        </th>
                                    )
                                }

                                return null;
                            })}
                            {props.quote.advancedMethod &&
                                <>
                                    {Object.keys(context.washingstep.data).map((washingStepId) => {
                                        if (props.quote.advancedMethod && !context.washingstep.data[washingStepId].rince && context.washingstep.data[washingStepId].productType) {
                                            return (
                                                <th key={washingStepId} className="text-center">
                                                    {props.quote.getSommeConsoEauAnnuelleProductType(context, CipTypes.monoAlternate, context.washingstep.data[washingStepId].productType).toFixed(0)}
                                                </th>
                                            )
                                        }

                                        return null;
                                    })}
                                    {Object.keys(context.washingstep.data).map((washingStepId) => {
                                        if (context.washingstep.data[washingStepId].rince) {
                                            return (
                                                <th key={washingStepId} className="text-center">
                                                    {props.quote.getSommeConsoEauAnnuelleWashingStep(context, washingStepId, false).toFixed(0)}
                                                </th>
                                            )
                                        }

                                        return null;
                                    })}
                                    <th className="text-center">
                                        {props.quote.getSommeConsoEauAnnuelleWashingSteps(context, CipTypes.monoAlternate).toFixed(0)}
                                    </th>
                                </>
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {props.quote.equipments.map((equipment) => {
                            return (
                                <tr key={equipment.id}>
                                    <TableCommonBody quote={props.quote} equipment={equipment} />
                                    {props.quote.advancedMethod &&
                                        <>
                                            <td>
                                                {equipment.getMonoAlterneeDureeTotalLavages(context, props.quote)}
                                            </td>
                                            <td>
                                                {getTranslation(config.yesNoOptions[equipment.maintientDesinfection ? equipment.maintientDesinfection : 0].name, context.user.locale)}
                                            </td>
                                        </>
                                    }
                                    {Object.keys(context.washingstep.data).map((washingStepId) => {
                                        if (props.quote.advancedMethod || (!props.quote.advancedMethod && ![config.washingStepPrelavageId, config.washingStepDesinfectant].includes(parseInt(washingStepId)))) {
                                            return (
                                                <td key={washingStepId}>
                                                    {equipment.getTypeCircuit(context) !== null &&
                                                        <>
                                                            {equipment.getNbWashingStep(context, washingStepId, equipment.getTypeCircuit(context).id, equipment.cycleLavage, equipment.maintientDesinfection)}
                                                        </>
                                                    }
                                                    {equipment.getTypeCircuit(context) === null &&
                                                        0
                                                    }
                                                </td>
                                            )
                                        }

                                        return null;
                                    })}
                                    {props.quote.advancedMethod &&
                                        <>
                                            <td>
                                                {parseFloat(equipment.debitCircuit ? equipment.debitCircuit : 0).toFixed(0)}
                                            </td>
                                            {Object.keys(context.washingstep.data).map((washingStepId) => {
                                                if (props.quote.advancedMethod && !context.washingstep.data[washingStepId].rince && context.washingstep.data[washingStepId].productType) {
                                                    return (
                                                        <td key={washingStepId}>

                                                        </td>
                                                    )
                                                }

                                                return null;
                                            })}
                                            {Object.keys(context.washingstep.data).map((washingStepId) => {
                                                if (context.washingstep.data[washingStepId].rince) {
                                                    return (
                                                        <td key={washingStepId}>
                                                            {equipment.getTypeCircuit(context) !== null &&
                                                                <>
                                                                    {equipment.getConsoEauAnnuelleWashingStep(context, washingStepId, equipment.getTypeCircuit(context).id, equipment.cycleLavage, equipment.maintientDesinfection)}
                                                                </>
                                                            }
                                                            {equipment.getTypeCircuit(context) === null &&
                                                                0
                                                            }
                                                        </td>
                                                    )
                                                }

                                                return null;
                                            })}
                                            <td>

                                            </td>
                                        </>
                                    }
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </Col>
        </Row>
    );
}