import React, {useContext} from "react";
import classNames from "classnames";
import {UserContext} from "../../contexts/UserContext";
import {getTranslation} from "./Translation";
import {Col, Row} from "react-bootstrap";

export default function CustomInput(props) {
    const context = useContext(UserContext);

    return (
        <Row className="mb-1">
            <Col className="pt-2">
                <label htmlFor={props.name} className="form-label">{ props.label ? props.label : getTranslation('cs.' + props.name, context.user.locale) } :</label>
            </Col>
            <Col>
                <input type={props.type ? props.type : 'text'} className={classNames({'form-control': true, 'error': props.errorFields.includes(props.name), 'advanced-method': props.advancedMethod})} id={props.name} onChange={(e) => {props.handleChange(props.equipment.id, props.name, e.target.value)}} value={props.equipment[props.name] === undefined ? '' : props.equipment[props.name]} />
            </Col>
        </Row>
    )
}