import React from 'react';
import kersiaLogo from "../../assets/images/logo-kersia.svg";
import * as Icon from 'react-bootstrap-icons';
import { config } from "../config/Config";
import { UserContext } from "../../contexts/UserContext";
import SpeciesSelector from "../user/SpeciesSelector";
import { Col, Container, Navbar, Row } from "react-bootstrap";
import { getTranslation } from "../tools/Translation";
import classNames from "classnames";
import $ from "jquery";
import appLogo from "../../assets/images/easycalc-s.svg";
import CurrencySelector from "../user/CurrencySelector";
import { QuoteContext } from "../../contexts/QuoteContext";
import Toolbar from "./Toolbar";
import { deleteVersions } from "../data/Database";
import Config from "../../Config";
import QuoteSync from "../tools/QuoteSync";
import {Apps} from "../../model/Apps";

class AuthNavItem extends React.Component {
    onClickHandler = (e) => {
        e.stopPropagation(); // Stop propagation otherwise the click on the <a> tag will trigger the click on the parent <div>
        this.props.closeMenu();
        this.props.logoutMethod(e);
    }

    render() {
        if (!this.props.isAuthenticated) {
            return null;
        }

        return (
            <UserContext.Consumer>
                {({ user }) => (
                    <div onClick={this.onClickHandler}>
                        <div className="loggedin-as">{getTranslation('logged.in.as', user.locale)}</div>
                        <span className="align-middle">{user.fullname}</span>
                        <a href="." onClick={this.onClickHandler} className="btn-link ml-2"
                            role="button" title={getTranslation('logout', user.locale)}><Icon.BoxArrowRight size={18} /></a>
                    </div>
                )}
            </UserContext.Consumer>
        )
    }
}

class DistributorLogo extends React.Component {
    onClickHandler = (e) => {
        e.preventDefault();
        if (this.context.user.productGroups && this.context.user.productGroups.length > 1) {
            this.props.toggleDistributorForm();
        }
    }

    render() {
        if (!this.context || !this.context.productgroup || !this.context.productgroup.data || !this.context.user.productGroup) {
            return null;
        }

        let productGroups = this.context.productgroup.data;

        if (!productGroups[this.context.user.productGroup] || !productGroups[this.context.user.productGroup].image) {
            return (
                <img id="kersia-logo" src={kersiaLogo} alt={config.kersia} onClick={this.onClickHandler} />
            )
        } else {
            return (
                <img className="distributor-logo" src={productGroups[this.context.user.productGroup].image} alt={productGroups[this.context.user.productGroup].name} onClick={this.onClickHandler} />
            )
        }
    }
}

DistributorLogo.contextType = UserContext;

class BurgerMenu extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isOpen: false,
            displayBlock: false,
            menuSpeciesOpen: false,
            menuCurrencyOpen: false,
            menuGeneralOpen: false,
            menuDebugOpen: false
        }

        this.out = true;
    }

    componentDidMount() {
        $('body').click(() => {
            if (this.out && this.state.isOpen) {
                this.onClickHandler();
            }
        });
    }

    onMouseEnter = () => {
        this.out = false;
    }

    onMouseLeave = () => {
        this.out = true;
    }

    onClickHandler = () => {
        if (this.state.isOpen) {
            setTimeout(() => {
                this.setState({
                    displayBlock: false,
                    menuSpeciesOpen: false,
                    menuCurrencyOpen: false,
                    menuDebugOpen: false
                });
            }, 200);

            this.setState(function (state) {
                return {
                    isOpen: !state.isOpen
                }
            });
        } else {
            this.setState({
                displayBlock: true
            }, () => {
                setTimeout(() => {
                    this.setState(function (state) {
                        return {
                            isOpen: !state.isOpen
                        }
                    });
                }, 50);
            });
        }
    }

    onMenuItemClick = (e, item) => {
        e.stopPropagation();
        e.preventDefault();

        this.setState(function (state) {
            let newState = {};
            Object.keys(state).forEach((key) => {
                if (key.indexOf('menu') > -1) {
                    newState[key] = false;
                }
            });

            newState['menu' + item + 'Open'] = !state['menu' + item + 'Open'];

            return newState;
        });
    }

    preventClick = (e) => {
        e.stopPropagation();
        e.preventDefault();
    }

    render() {

        let debugMenu = [];
        if (Config.Debug) {
            debugMenu.push(<li key="0" className={classNames({ open: this.state.menuDebugOpen })} onClick={this.preventClick}>
                <a href="." onClick={(e) => this.onMenuItemClick(e, 'Debug')} className="burger-link" style={{ backgroundColor: '#ff4d00' }}>Debug <Icon.ChevronDown size={18} /></a>
                <div className="burger-content" style={{ padding: 0 }}>
                    <ul className="open" style={{ listStyle: 'none', padding: 0 }}>
                        <li className="open">
                            <div className="burger-link">
                                <a href="/" className="menu-item" id="output-current-quote" onClick={() => {
                                    this.onClickHandler();
                                    console.log(this.context.currentQuote);
                                }}>Console.log(currentQuote)
                                </a>
                            </div>
                        </li>
                        <li className="open">
                            <div className="burger-link">
                                <a href="/" className="menu-item" id="force-data-update" onClick={() => {
                                    this.onClickHandler();
                                    deleteVersions().finally(() => {
                                        localStorage.removeItem(config.tokenParamName);
                                        window.location.reload();
                                    });
                                }}>Force data update
                                </a>
                            </div>
                        </li>
                        <li className="open">
                            <div className="burger-link">
                                <a href="/" className="menu-item" onClick={() => {
                                    this.onClickHandler();
                                    let quoteSync = new QuoteSync();
                                    quoteSync.downloadAndPushQuotes();
                                }}>Trigger quote sync
                                </a>
                            </div>
                        </li>
                    </ul>
                </div>
            </li>);
        }

        let menuSpeciesSelector = [];
        let menuProductMosaic = [];
        let menuPriceList = [];

        if (this.props.currentModule === Apps.Farm) {

            menuSpeciesSelector.push(<li key="menuSpecies" className={classNames({ open: this.state.menuSpeciesOpen })} onClick={this.preventClick}>
                <a href="." onClick={(e) => this.onMenuItemClick(e, 'Species')} className="burger-link">{getTranslation('menu.species', this.context.currentQuote.locale ? this.context.currentQuote.locale : config.defaultLocale)} <Icon.ChevronDown size={18} /></a>
                <div className="burger-content">
                    <SpeciesSelector closeMenu={this.onClickHandler} />
                </div>
            </li>);

            menuProductMosaic.push(<li key="menuMosaic" className="open" onClick={this.preventClick}>
                <div className="burger-link" onClick={() => { this.onClickHandler(); this.context.currentQuote.showMosaic(); }}>
                    <a href=".">{getTranslation('menu.mosaic', this.context.currentQuote.locale ? this.context.currentQuote.locale : config.defaultLocale)}</a>
                </div>
            </li>);
        }

        menuPriceList.push(<li key="menuPrices" className="open" onClick={this.preventClick}>
            <div className="burger-link" onClick={() => { this.onClickHandler(); this.context.currentQuote.showPriceList(); }}>
                <a href=".">{getTranslation('menu.priceList', this.context.currentQuote.locale ? this.context.currentQuote.locale : config.defaultLocale)}</a>
            </div>
        </li>)

        return (
            <button onClick={this.onClickHandler} className="burger" type="button" onMouseEnter={this.onMouseEnter} onMouseLeave={this.onMouseLeave}>

                <div className={classNames({ 'animated-icon': true, 'open': this.state.isOpen })}><span /><span /><span /></div>

                <div className={classNames({ 'rightmenuup': true, 'open': this.state.isOpen, 'd-block': this.state.displayBlock })} />

                <ul className={classNames({ 'burger-menu': true, 'text-left': true, 'open': this.state.isOpen, 'd-block': this.state.displayBlock })}>

                    {menuSpeciesSelector}

                    <li className={classNames({ open: this.state.menuCurrencyOpen })} onClick={this.preventClick}>
                        <a href="." onClick={(e) => this.onMenuItemClick(e, 'Currency')} className="burger-link">{getTranslation('menu.currency', this.context.currentQuote.locale ? this.context.currentQuote.locale : config.defaultLocale)} <Icon.ChevronDown size={18} /></a>
                        <div className="burger-content">
                            <CurrencySelector closeMenu={this.onClickHandler} currentQuote={this.context.currentQuote} />
                        </div>
                    </li>

                    {menuProductMosaic}

                    {menuPriceList}

                    {Object.entries(Apps).map((app) => {
                        if (app[1] !== this.props.currentModule && this.context.currentQuote.getUserContext().user.apps.includes(app[1])) {
                            return (
                                <li className='open' onClick={this.preventClick} key={app[1]}>
                                    <div className='burger-link' onClick={() => {
                                        this.props.toggleModule(app[1]);
                                        this.onClickHandler();
                                    }}>
                                        <a href=".">{getTranslation('app.' + app[1], this.context.currentQuote.locale ? this.context.currentQuote.locale : config.defaultLocale)}</a>
                                    </div>
                                </li>
                            )
                        }
                        return null;
                    })}

                    <li className="open" onClick={this.preventClick}>
                        <div className="burger-link" onClick={() => {
                            this.props.showOptionsForm(true);
                            this.onClickHandler();
                        }}>
                            <a href=".">{getTranslation('Settings', this.context.currentQuote.locale ? this.context.currentQuote.locale : config.defaultLocale)}</a>
                        </div>
                    </li>

                    <li className="open" onClick={this.preventClick}>
                        <div className="burger-link">
                            <AuthNavItem isAuthenticated={this.props.isAuthenticated} logoutMethod={this.props.logoutMethod} closeMenu={this.onClickHandler} />
                        </div>
                    </li>

                    {debugMenu}

                </ul>

            </button>
        )
    }
}

BurgerMenu.contextType = QuoteContext;

export default class Header extends React.Component {
    render() {
        return (
            <Container className="header" fluid={true}>
                <Row className="no-gutters">
                    <Col>
                        <Container>
                            <Row>
                                <Col className="p-0">
                                    <Navbar className="flex-container">
                                        <div className="flex-item">
                                            <DistributorLogo toggleDistributorForm={this.props.toggleDistributorForm} />
                                        </div>
                                        <Toolbar currentModule={this.props.currentModule} />
                                        <div className="flex-item">
                                            <div className="flex-container">
                                                <img id="app-logo" src={appLogo} alt={config.appName} className="header-logo mr-1" />
                                                <BurgerMenu
                                                    isAuthenticated={this.props.isAuthenticated}
                                                    logoutMethod={this.props.logoutMethod}
                                                    showOptionsForm={this.props.showOptionsForm}
                                                    toggleModule={this.props.toggleModule}
                                                    currentModule={this.props.currentModule}
                                                />
                                            </div>
                                        </div>
                                    </Navbar>
                                </Col>
                            </Row>
                        </Container>
                    </Col>
                </Row>
            </Container>
        );
    }
}
