import * as EasycalcApi from 'easycalc-api-client';
import EasycalcApiClient from "../data/EasycalcApiClient";

export default class QuoteSync {
    constructor() {
        this.api = new EasycalcApi.QuoteApi(new EasycalcApiClient());
    }

    downloadQuotes(callback) {
        this.api.getQuotes().then((result) => {
            this.parseQuotes(result);
            if (callback) {
                callback();
            }
        });
    }

    downloadAndPushQuotes(callback) {
        this.downloadQuotes(() => { this.uploadQuotes(callback) });
    }

    uploadQuotes(callback) {
        let quotes = JSON.parse(localStorage.getItem('quotes')) || [];
        if (quotes.length > 0) {
            this.api.setQuotes({quotes: JSON.stringify(quotes)}).then(() => {

                // Delete quotes marked to be deleted
                let finalQuotes = [];
                quotes.forEach((q) => {
                    if (!q.toBeDeleted) {
                        finalQuotes.push(q);
                    }
                });

                localStorage.setItem('quotes', JSON.stringify(finalQuotes));

                if (callback) {
                    callback();
                }
            });
        }
    }

    parseQuotes(serverData) {
        if (serverData && serverData.length) {
            let currentLSQuotes = JSON.parse(localStorage.getItem('quotes'));
            let isModified = false;

            serverData.forEach((value) => {
                if (value.quoteId && value.quoteDate) {
                    let json = value && value.jsonValue ? JSON.parse(value.jsonValue) : null;
                    let ServerQuoteId = parseInt(value.quoteId);
                    if (json) {
                        let numberOfQuotes = currentLSQuotes && currentLSQuotes.length ? currentLSQuotes.length : 0;
                        if (numberOfQuotes > 0) {
                            let i = numberOfQuotes;
                            let isFound = false;
                            while (i--) {
                                let LSQuoteId = parseInt(currentLSQuotes[i].id);
                                if (LSQuoteId === ServerQuoteId) {
                                    isFound = true;
                                    if (currentLSQuotes[i].lastModifDate < json.quoteDate) {
                                        currentLSQuotes[i] = json;
                                        isModified = true;
                                    }
                                }
                            }
                            if (!isFound) {
                                console.log('quote id: ', ServerQuoteId, ' have not been found in LocalStorage');
                                console.log('push quote in LocalStorage');
                                currentLSQuotes.push(json);
                                isModified = true;
                            }
                        } else {
                            currentLSQuotes = !currentLSQuotes ? [] : currentLSQuotes;
                            currentLSQuotes.push(json);
                            isModified = true;
                        }
                    }
                }
            });

            if (isModified) {
                localStorage.setItem('quotes', JSON.stringify(currentLSQuotes));
            }
        }
    }
}