import {Entity} from "./Entity";
import {ProductCalculation} from "./ProductCalculation";
import {ProductConsumption} from "./ProductConsumption";

export class Product extends Entity {
    constructor(data) {
        super(data);

        if (data.calculations && data.calculations.length > 0) {
            // Create instances of ProductCalculation
            data.calculations.forEach((calculation, index) => {
                calculation.product = this;
                this.calculations[index] = new ProductCalculation(calculation);
            });
        }

        if (data.productConsumptions && data.productConsumptions.length > 0) {
            // Create instances of ProductConsumption
            data.productConsumptions.forEach((productConsumption, index) => {
                this.productConsumptions[index] = new ProductConsumption(productConsumption);
            });
        }
    }

    // Returns the matching calculation, or null
    getCalculation(categoryId, currentQuote) {
        if (this.calculations && this.calculations.length > 0) {

            // Give a score to each product consumption
            this.calculations.forEach((calculation) => {
                calculation.categoryId = categoryId;
                calculation.score = 0;

                if (!calculation.country) {
                    calculation.score += 1;
                } else {
                    if (currentQuote.country === calculation.country) {
                        calculation.score += 2;
                    } else {
                        calculation.score = 0;
                        return false;
                    }
                }

                if (!calculation.species) {
                    calculation.score += 1;
                } else {
                    if (currentQuote.species === calculation.species) {
                        calculation.score += 2;
                    } else {
                        calculation.score = 0;
                        return false;
                    }
                }

                if (!calculation.category) {
                    calculation.score += 1;
                } else {
                    if (calculation.category === categoryId) {
                        calculation.score += 2;
                    } else {
                        calculation.score = 0;
                        return false;
                    }
                }
            });

            // Use the highest score
            this.calculations.sort(this.compareOrdering);

            if (this.calculations[0].score > 0) {
                return this.calculations[0];
            }
        }

        return null;
    }

    // Returns an array of matching productConsumption, or null
    getProductConsumption(currentQuote, categoryId) {
        if (this.productConsumptions && this.productConsumptions.length > 0) {

            // Give a score to each product consumption
            this.productConsumptions.forEach((productConsumption) => {
                productConsumption.score = 0;

                if (!productConsumption.country) {
                    productConsumption.score += 1;
                } else {
                    if (currentQuote.country === productConsumption.country) {
                        productConsumption.score += 2;
                    } else {
                        productConsumption.score = 0;
                        return false;
                    }
                }

                if (!productConsumption.species) {
                    productConsumption.score += 1;
                } else {
                    if (currentQuote.species === productConsumption.species) {
                        productConsumption.score += 2;
                    } else {
                        productConsumption.score = 0;
                        return false;
                    }
                }

                if (!productConsumption.category) {
                    productConsumption.score += 1;
                } else {
                    if (categoryId === productConsumption.category) {
                        productConsumption.score += 2;
                    } else {
                        productConsumption.score = 0;
                        return false;
                    }
                }
            });

            // Use the highest score
            this.productConsumptions.sort(this.compareOrdering);

            if (this.productConsumptions[0].score > 0) {
                let productConsumption = this.productConsumptions[0];

                // In case we have an applicationType, we need all the estimated consumptions with different application types
                if (productConsumption.applicationType) {
                    let productConsumptions = [];
                    this.productConsumptions.forEach((p) => {
                        if (productConsumption.score === p.score && p.applicationType && productConsumption.country === p.country && productConsumption.species === p.species) {
                            productConsumptions.push(p);
                        }
                    });

                    return productConsumptions;
                }

                return [productConsumption];
            }
        }

        return null;
    }

    // Get the unit of the product packages, used in the estimated consumption and calculation
    getFormatUnit() {
        let unitId = null;
        if (this.formats) {
            this.formats.forEach((format) => {
                unitId = format.unit;
            });
        }

        return unitId;
    }

    getSmallestFormat() {
        let format = null;

        if (this.formats && this.formats.length > 0) {
            this.formats.sort(this.compareFormats);
            format = this.formats[0];
        }

        return format;
    }

    compareOrdering(a, b) {
        if (a.score < b.score) {
            return 1;
        }
        if (a.score > b.score) {
            return -1;
        }
        return 0;
    }

    isWaterTreatment() {
        return this.maxLSI !== null || this.maxAverageHourRate !== null;
    }

    compareFormats(a, b) {
        if (parseFloat(a.value) > parseFloat(b.value)) {
            return 1;
        }
        if (parseFloat(a.value) < parseFloat(b.value)) {
            return -1;
        }
        return 0;
    }
}
