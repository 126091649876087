import React from "react";
import {Button, ButtonGroup} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFileExport} from "@fortawesome/free-solid-svg-icons";
import {utils, writeFileXLSX} from 'xlsx';
import {UserContext} from "../../contexts/UserContext";

export default class PriceListExport extends React.Component {
    constructor(props) {
        super(props);

        this.state = {};
    }

    handleExportClick = () => {
        const products = [];
        const sortedProducts = this.context.user.categoryProducts[1].sort((a, b) => (this.context.product.data[a].name > this.context.product.data[b].name) ? 1 : -1);

        sortedProducts.forEach((productId) => {
            const product = this.context.product.data[productId] ?? null;
            if (product) {
                let productFormats = [];
                this.context.user.productFormats[productId].forEach((formatId) => {
                    product.formats.forEach((format) => {
                        if (formatId === format.id) {
                            productFormats.push(format);
                        }
                    });
                });
                const orderedFormats = productFormats.sort((a, b) => (parseInt(a.value) > parseInt(b.value)) ? 1 : -1);
                orderedFormats.forEach((format) => {

                    const unitObject = this.context.unit.data && this.context.unit.data[format.unit] ? this.context.unit.data[format.unit] : null;
                    const unitLabel = unitObject ? unitObject.getTranslation({fieldName: 'name', locale: this.context.user.locale}) : '';
                    const sizeLabelLocalized = format.getTranslation({fieldName: 'label', locale: this.context.user.locale});
                    const sizeLabel = sizeLabelLocalized ? sizeLabelLocalized : (unitLabel ? format.value + ' ' + unitLabel : format.value);

                    products.push({
                        id: format.id,
                        name: product.name,
                        package: sizeLabel,
                        price: this.props.currentQuote.prices[format.id] ? parseFloat(this.props.currentQuote.prices[format.id]) : ''
                    });
                });
            }
        });

        const ws = utils.json_to_sheet(products);
        const wb = utils.book_new();
        utils.book_append_sheet(wb, ws, "Price List");
        const max_width = products.reduce((w, r) => Math.max(w, r.name.length), 10);
        ws["!cols"] = [ {hidden: true}, { wch: max_width }, {wch: 25}, {wch: 10}];
        writeFileXLSX(wb, "price-list.xlsx");
    }

    render() {
        return (
            <ButtonGroup className="mr-2 btn-group">
                <Button onClick={this.handleExportClick}>
                    <FontAwesomeIcon size={"lg"} icon={faFileExport}/>
                </Button>
            </ButtonGroup>
        );
    }
}

PriceListExport.contextType = UserContext;