import React from "react";
import {QuoteContext} from "../../contexts/QuoteContext";

export default class ProductCalculation extends React.Component {
    render() {
        if (!this.props.product) {
            return null;
        }

        const calculation = this.props.product.getCalculation(this.props.category.id, this.context.currentQuote);
        if (!calculation) {
            return null;
        }

        const productData = this.context.currentQuote.getProductData(this.props.category.id, this.props.product.id);
        let estimatedConsumption = null;
        if (productData.estimatedConsumptionId) {
            estimatedConsumption = this.props.product.getObjectById('productConsumptions', productData.estimatedConsumptionId);
        }

        if (!estimatedConsumption) {
            return null;
        }

        const TagName = calculation.getComponent(this.props.category.id);
        if (!TagName) {
            return null;
        }

        return (
            <TagName product={this.props.product} category={this.props.category} productData={productData} calculation={calculation} estimatedConsumption={estimatedConsumption} currentQuote={this.context.currentQuote} />
        )
    }
}

ProductCalculation.contextType = QuoteContext;