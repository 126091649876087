"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "ApiClient", {
  enumerable: true,
  get: function get() {
    return _ApiClient.default;
  }
});
Object.defineProperty(exports, "ApplicationType", {
  enumerable: true,
  get: function get() {
    return _ApplicationType.default;
  }
});
Object.defineProperty(exports, "ApplicationTypeTranslation", {
  enumerable: true,
  get: function get() {
    return _ApplicationTypeTranslation.default;
  }
});
Object.defineProperty(exports, "Category", {
  enumerable: true,
  get: function get() {
    return _Category.default;
  }
});
Object.defineProperty(exports, "CategoryApi", {
  enumerable: true,
  get: function get() {
    return _CategoryApi.default;
  }
});
Object.defineProperty(exports, "CategorySpeciesFrequency", {
  enumerable: true,
  get: function get() {
    return _CategorySpeciesFrequency.default;
  }
});
Object.defineProperty(exports, "CategoryTranslation", {
  enumerable: true,
  get: function get() {
    return _CategoryTranslation.default;
  }
});
Object.defineProperty(exports, "CausticSodaApi", {
  enumerable: true,
  get: function get() {
    return _CausticSodaApi.default;
  }
});
Object.defineProperty(exports, "ConsumptionType", {
  enumerable: true,
  get: function get() {
    return _ConsumptionType.default;
  }
});
Object.defineProperty(exports, "ConsumptionTypeApi", {
  enumerable: true,
  get: function get() {
    return _ConsumptionTypeApi.default;
  }
});
Object.defineProperty(exports, "ConsumptionTypeTranslation", {
  enumerable: true,
  get: function get() {
    return _ConsumptionTypeTranslation.default;
  }
});
Object.defineProperty(exports, "Country", {
  enumerable: true,
  get: function get() {
    return _Country.default;
  }
});
Object.defineProperty(exports, "CountryApi", {
  enumerable: true,
  get: function get() {
    return _CountryApi.default;
  }
});
Object.defineProperty(exports, "CountryTranslation", {
  enumerable: true,
  get: function get() {
    return _CountryTranslation.default;
  }
});
Object.defineProperty(exports, "Currency", {
  enumerable: true,
  get: function get() {
    return _Currency.default;
  }
});
Object.defineProperty(exports, "CurrencyApi", {
  enumerable: true,
  get: function get() {
    return _CurrencyApi.default;
  }
});
Object.defineProperty(exports, "CurrencyTranslation", {
  enumerable: true,
  get: function get() {
    return _CurrencyTranslation.default;
  }
});
Object.defineProperty(exports, "FactoryObjectType", {
  enumerable: true,
  get: function get() {
    return _FactoryObjectType.default;
  }
});
Object.defineProperty(exports, "FactoryObjectTypeTranslation", {
  enumerable: true,
  get: function get() {
    return _FactoryObjectTypeTranslation.default;
  }
});
Object.defineProperty(exports, "FormatTranslation", {
  enumerable: true,
  get: function get() {
    return _FormatTranslation.default;
  }
});
Object.defineProperty(exports, "FormatTranslation2", {
  enumerable: true,
  get: function get() {
    return _FormatTranslation2.default;
  }
});
Object.defineProperty(exports, "FormatTranslation3", {
  enumerable: true,
  get: function get() {
    return _FormatTranslation3.default;
  }
});
Object.defineProperty(exports, "FormulaVariable", {
  enumerable: true,
  get: function get() {
    return _FormulaVariable.default;
  }
});
Object.defineProperty(exports, "FormulaVariableTranslation", {
  enumerable: true,
  get: function get() {
    return _FormulaVariableTranslation.default;
  }
});
Object.defineProperty(exports, "FormulasApi", {
  enumerable: true,
  get: function get() {
    return _FormulasApi.default;
  }
});
Object.defineProperty(exports, "GlobalVariable", {
  enumerable: true,
  get: function get() {
    return _GlobalVariable.default;
  }
});
Object.defineProperty(exports, "InputProduct", {
  enumerable: true,
  get: function get() {
    return _InputProduct.default;
  }
});
Object.defineProperty(exports, "InputProductTranslation", {
  enumerable: true,
  get: function get() {
    return _InputProductTranslation.default;
  }
});
Object.defineProperty(exports, "Language", {
  enumerable: true,
  get: function get() {
    return _Language.default;
  }
});
Object.defineProperty(exports, "LanguageApi", {
  enumerable: true,
  get: function get() {
    return _LanguageApi.default;
  }
});
Object.defineProperty(exports, "PHApi", {
  enumerable: true,
  get: function get() {
    return _PHApi.default;
  }
});
Object.defineProperty(exports, "Ph", {
  enumerable: true,
  get: function get() {
    return _Ph.default;
  }
});
Object.defineProperty(exports, "PhTranslation", {
  enumerable: true,
  get: function get() {
    return _PhTranslation.default;
  }
});
Object.defineProperty(exports, "Product", {
  enumerable: true,
  get: function get() {
    return _Product.default;
  }
});
Object.defineProperty(exports, "Product2", {
  enumerable: true,
  get: function get() {
    return _Product2.default;
  }
});
Object.defineProperty(exports, "Product2Formats", {
  enumerable: true,
  get: function get() {
    return _Product2Formats.default;
  }
});
Object.defineProperty(exports, "Product3", {
  enumerable: true,
  get: function get() {
    return _Product3.default;
  }
});
Object.defineProperty(exports, "Product3Formats", {
  enumerable: true,
  get: function get() {
    return _Product3Formats.default;
  }
});
Object.defineProperty(exports, "Product4", {
  enumerable: true,
  get: function get() {
    return _Product4.default;
  }
});
Object.defineProperty(exports, "ProductActiveIngredient", {
  enumerable: true,
  get: function get() {
    return _ProductActiveIngredient.default;
  }
});
Object.defineProperty(exports, "ProductActiveIngredient2", {
  enumerable: true,
  get: function get() {
    return _ProductActiveIngredient2.default;
  }
});
Object.defineProperty(exports, "ProductActiveIngredient3", {
  enumerable: true,
  get: function get() {
    return _ProductActiveIngredient3.default;
  }
});
Object.defineProperty(exports, "ProductActiveIngredientTranslation", {
  enumerable: true,
  get: function get() {
    return _ProductActiveIngredientTranslation.default;
  }
});
Object.defineProperty(exports, "ProductActiveIngredientTranslation2", {
  enumerable: true,
  get: function get() {
    return _ProductActiveIngredientTranslation2.default;
  }
});
Object.defineProperty(exports, "ProductActiveIngredientTranslation3", {
  enumerable: true,
  get: function get() {
    return _ProductActiveIngredientTranslation3.default;
  }
});
Object.defineProperty(exports, "ProductApi", {
  enumerable: true,
  get: function get() {
    return _ProductApi.default;
  }
});
Object.defineProperty(exports, "ProductCalculations", {
  enumerable: true,
  get: function get() {
    return _ProductCalculations.default;
  }
});
Object.defineProperty(exports, "ProductConsumption", {
  enumerable: true,
  get: function get() {
    return _ProductConsumption.default;
  }
});
Object.defineProperty(exports, "ProductConsumption2", {
  enumerable: true,
  get: function get() {
    return _ProductConsumption2.default;
  }
});
Object.defineProperty(exports, "ProductConsumption3", {
  enumerable: true,
  get: function get() {
    return _ProductConsumption3.default;
  }
});
Object.defineProperty(exports, "ProductFormats", {
  enumerable: true,
  get: function get() {
    return _ProductFormats.default;
  }
});
Object.defineProperty(exports, "ProductGroup", {
  enumerable: true,
  get: function get() {
    return _ProductGroup.default;
  }
});
Object.defineProperty(exports, "ProductGroupApi", {
  enumerable: true,
  get: function get() {
    return _ProductGroupApi.default;
  }
});
Object.defineProperty(exports, "ProductGroupTranslation", {
  enumerable: true,
  get: function get() {
    return _ProductGroupTranslation.default;
  }
});
Object.defineProperty(exports, "ProductTranslation", {
  enumerable: true,
  get: function get() {
    return _ProductTranslation.default;
  }
});
Object.defineProperty(exports, "ProductTranslation2", {
  enumerable: true,
  get: function get() {
    return _ProductTranslation2.default;
  }
});
Object.defineProperty(exports, "ProductTranslation3", {
  enumerable: true,
  get: function get() {
    return _ProductTranslation3.default;
  }
});
Object.defineProperty(exports, "ProductType", {
  enumerable: true,
  get: function get() {
    return _ProductType.default;
  }
});
Object.defineProperty(exports, "ProductTypeTranslation", {
  enumerable: true,
  get: function get() {
    return _ProductTypeTranslation.default;
  }
});
Object.defineProperty(exports, "Protocol", {
  enumerable: true,
  get: function get() {
    return _Protocol.default;
  }
});
Object.defineProperty(exports, "ProtocolApi", {
  enumerable: true,
  get: function get() {
    return _ProtocolApi.default;
  }
});
Object.defineProperty(exports, "ProtocolLevel", {
  enumerable: true,
  get: function get() {
    return _ProtocolLevel.default;
  }
});
Object.defineProperty(exports, "ProtocolLevelTranslation", {
  enumerable: true,
  get: function get() {
    return _ProtocolLevelTranslation.default;
  }
});
Object.defineProperty(exports, "ProtocolLevelValue", {
  enumerable: true,
  get: function get() {
    return _ProtocolLevelValue.default;
  }
});
Object.defineProperty(exports, "ProtocolTranslation", {
  enumerable: true,
  get: function get() {
    return _ProtocolTranslation.default;
  }
});
Object.defineProperty(exports, "Quote", {
  enumerable: true,
  get: function get() {
    return _Quote.default;
  }
});
Object.defineProperty(exports, "QuoteApi", {
  enumerable: true,
  get: function get() {
    return _QuoteApi.default;
  }
});
Object.defineProperty(exports, "Recipe", {
  enumerable: true,
  get: function get() {
    return _Recipe.default;
  }
});
Object.defineProperty(exports, "RecipeMatrix", {
  enumerable: true,
  get: function get() {
    return _RecipeMatrix.default;
  }
});
Object.defineProperty(exports, "RecipeTranslation", {
  enumerable: true,
  get: function get() {
    return _RecipeTranslation.default;
  }
});
Object.defineProperty(exports, "SingleProductApi", {
  enumerable: true,
  get: function get() {
    return _SingleProductApi.default;
  }
});
Object.defineProperty(exports, "Species", {
  enumerable: true,
  get: function get() {
    return _Species.default;
  }
});
Object.defineProperty(exports, "SpeciesApi", {
  enumerable: true,
  get: function get() {
    return _SpeciesApi.default;
  }
});
Object.defineProperty(exports, "SpeciesOption", {
  enumerable: true,
  get: function get() {
    return _SpeciesOption.default;
  }
});
Object.defineProperty(exports, "SpeciesOptionTranslation", {
  enumerable: true,
  get: function get() {
    return _SpeciesOptionTranslation.default;
  }
});
Object.defineProperty(exports, "SpeciesTranslation", {
  enumerable: true,
  get: function get() {
    return _SpeciesTranslation.default;
  }
});
Object.defineProperty(exports, "Surface", {
  enumerable: true,
  get: function get() {
    return _Surface.default;
  }
});
Object.defineProperty(exports, "SurfaceApi", {
  enumerable: true,
  get: function get() {
    return _SurfaceApi.default;
  }
});
Object.defineProperty(exports, "SurfaceTranslation", {
  enumerable: true,
  get: function get() {
    return _SurfaceTranslation.default;
  }
});
Object.defineProperty(exports, "ThermalCircuit", {
  enumerable: true,
  get: function get() {
    return _ThermalCircuit.default;
  }
});
Object.defineProperty(exports, "ThermalCircuitTranslation", {
  enumerable: true,
  get: function get() {
    return _ThermalCircuitTranslation.default;
  }
});
Object.defineProperty(exports, "Translation", {
  enumerable: true,
  get: function get() {
    return _Translation.default;
  }
});
Object.defineProperty(exports, "TranslationApi", {
  enumerable: true,
  get: function get() {
    return _TranslationApi.default;
  }
});
Object.defineProperty(exports, "TranslationTranslation", {
  enumerable: true,
  get: function get() {
    return _TranslationTranslation.default;
  }
});
Object.defineProperty(exports, "Unit", {
  enumerable: true,
  get: function get() {
    return _Unit.default;
  }
});
Object.defineProperty(exports, "UnitApi", {
  enumerable: true,
  get: function get() {
    return _UnitApi.default;
  }
});
Object.defineProperty(exports, "UnitTranslation", {
  enumerable: true,
  get: function get() {
    return _UnitTranslation.default;
  }
});
Object.defineProperty(exports, "User", {
  enumerable: true,
  get: function get() {
    return _User.default;
  }
});
Object.defineProperty(exports, "UserApi", {
  enumerable: true,
  get: function get() {
    return _UserApi.default;
  }
});
Object.defineProperty(exports, "VersionApi", {
  enumerable: true,
  get: function get() {
    return _VersionApi.default;
  }
});
Object.defineProperty(exports, "Versions", {
  enumerable: true,
  get: function get() {
    return _Versions.default;
  }
});
Object.defineProperty(exports, "WashingStep", {
  enumerable: true,
  get: function get() {
    return _WashingStep.default;
  }
});
Object.defineProperty(exports, "WashingStepTranslation", {
  enumerable: true,
  get: function get() {
    return _WashingStepTranslation.default;
  }
});
Object.defineProperty(exports, "WashingType", {
  enumerable: true,
  get: function get() {
    return _WashingType.default;
  }
});
Object.defineProperty(exports, "WashingTypeTranslation", {
  enumerable: true,
  get: function get() {
    return _WashingTypeTranslation.default;
  }
});

var _ApiClient = _interopRequireDefault(require("./ApiClient"));

var _ApplicationType = _interopRequireDefault(require("./model/ApplicationType"));

var _ApplicationTypeTranslation = _interopRequireDefault(require("./model/ApplicationTypeTranslation"));

var _Category = _interopRequireDefault(require("./model/Category"));

var _CategorySpeciesFrequency = _interopRequireDefault(require("./model/CategorySpeciesFrequency"));

var _CategoryTranslation = _interopRequireDefault(require("./model/CategoryTranslation"));

var _ConsumptionType = _interopRequireDefault(require("./model/ConsumptionType"));

var _ConsumptionTypeTranslation = _interopRequireDefault(require("./model/ConsumptionTypeTranslation"));

var _Country = _interopRequireDefault(require("./model/Country"));

var _CountryTranslation = _interopRequireDefault(require("./model/CountryTranslation"));

var _Currency = _interopRequireDefault(require("./model/Currency"));

var _CurrencyTranslation = _interopRequireDefault(require("./model/CurrencyTranslation"));

var _FactoryObjectType = _interopRequireDefault(require("./model/FactoryObjectType"));

var _FactoryObjectTypeTranslation = _interopRequireDefault(require("./model/FactoryObjectTypeTranslation"));

var _FormatTranslation = _interopRequireDefault(require("./model/FormatTranslation"));

var _FormatTranslation2 = _interopRequireDefault(require("./model/FormatTranslation2"));

var _FormatTranslation3 = _interopRequireDefault(require("./model/FormatTranslation3"));

var _FormulaVariable = _interopRequireDefault(require("./model/FormulaVariable"));

var _FormulaVariableTranslation = _interopRequireDefault(require("./model/FormulaVariableTranslation"));

var _GlobalVariable = _interopRequireDefault(require("./model/GlobalVariable"));

var _InputProduct = _interopRequireDefault(require("./model/InputProduct"));

var _InputProductTranslation = _interopRequireDefault(require("./model/InputProductTranslation"));

var _Language = _interopRequireDefault(require("./model/Language"));

var _Ph = _interopRequireDefault(require("./model/Ph"));

var _PhTranslation = _interopRequireDefault(require("./model/PhTranslation"));

var _Product = _interopRequireDefault(require("./model/Product"));

var _Product2 = _interopRequireDefault(require("./model/Product2"));

var _Product2Formats = _interopRequireDefault(require("./model/Product2Formats"));

var _Product3 = _interopRequireDefault(require("./model/Product3"));

var _Product3Formats = _interopRequireDefault(require("./model/Product3Formats"));

var _Product4 = _interopRequireDefault(require("./model/Product4"));

var _ProductActiveIngredient = _interopRequireDefault(require("./model/ProductActiveIngredient"));

var _ProductActiveIngredient2 = _interopRequireDefault(require("./model/ProductActiveIngredient2"));

var _ProductActiveIngredient3 = _interopRequireDefault(require("./model/ProductActiveIngredient3"));

var _ProductActiveIngredientTranslation = _interopRequireDefault(require("./model/ProductActiveIngredientTranslation"));

var _ProductActiveIngredientTranslation2 = _interopRequireDefault(require("./model/ProductActiveIngredientTranslation2"));

var _ProductActiveIngredientTranslation3 = _interopRequireDefault(require("./model/ProductActiveIngredientTranslation3"));

var _ProductCalculations = _interopRequireDefault(require("./model/ProductCalculations"));

var _ProductConsumption = _interopRequireDefault(require("./model/ProductConsumption"));

var _ProductConsumption2 = _interopRequireDefault(require("./model/ProductConsumption2"));

var _ProductConsumption3 = _interopRequireDefault(require("./model/ProductConsumption3"));

var _ProductFormats = _interopRequireDefault(require("./model/ProductFormats"));

var _ProductGroup = _interopRequireDefault(require("./model/ProductGroup"));

var _ProductGroupTranslation = _interopRequireDefault(require("./model/ProductGroupTranslation"));

var _ProductTranslation = _interopRequireDefault(require("./model/ProductTranslation"));

var _ProductTranslation2 = _interopRequireDefault(require("./model/ProductTranslation2"));

var _ProductTranslation3 = _interopRequireDefault(require("./model/ProductTranslation3"));

var _ProductType = _interopRequireDefault(require("./model/ProductType"));

var _ProductTypeTranslation = _interopRequireDefault(require("./model/ProductTypeTranslation"));

var _Protocol = _interopRequireDefault(require("./model/Protocol"));

var _ProtocolLevel = _interopRequireDefault(require("./model/ProtocolLevel"));

var _ProtocolLevelTranslation = _interopRequireDefault(require("./model/ProtocolLevelTranslation"));

var _ProtocolLevelValue = _interopRequireDefault(require("./model/ProtocolLevelValue"));

var _ProtocolTranslation = _interopRequireDefault(require("./model/ProtocolTranslation"));

var _Quote = _interopRequireDefault(require("./model/Quote"));

var _Recipe = _interopRequireDefault(require("./model/Recipe"));

var _RecipeMatrix = _interopRequireDefault(require("./model/RecipeMatrix"));

var _RecipeTranslation = _interopRequireDefault(require("./model/RecipeTranslation"));

var _Species = _interopRequireDefault(require("./model/Species"));

var _SpeciesOption = _interopRequireDefault(require("./model/SpeciesOption"));

var _SpeciesOptionTranslation = _interopRequireDefault(require("./model/SpeciesOptionTranslation"));

var _SpeciesTranslation = _interopRequireDefault(require("./model/SpeciesTranslation"));

var _Surface = _interopRequireDefault(require("./model/Surface"));

var _SurfaceTranslation = _interopRequireDefault(require("./model/SurfaceTranslation"));

var _ThermalCircuit = _interopRequireDefault(require("./model/ThermalCircuit"));

var _ThermalCircuitTranslation = _interopRequireDefault(require("./model/ThermalCircuitTranslation"));

var _Translation = _interopRequireDefault(require("./model/Translation"));

var _TranslationTranslation = _interopRequireDefault(require("./model/TranslationTranslation"));

var _Unit = _interopRequireDefault(require("./model/Unit"));

var _UnitTranslation = _interopRequireDefault(require("./model/UnitTranslation"));

var _User = _interopRequireDefault(require("./model/User"));

var _Versions = _interopRequireDefault(require("./model/Versions"));

var _WashingStep = _interopRequireDefault(require("./model/WashingStep"));

var _WashingStepTranslation = _interopRequireDefault(require("./model/WashingStepTranslation"));

var _WashingType = _interopRequireDefault(require("./model/WashingType"));

var _WashingTypeTranslation = _interopRequireDefault(require("./model/WashingTypeTranslation"));

var _CategoryApi = _interopRequireDefault(require("./api/CategoryApi"));

var _CausticSodaApi = _interopRequireDefault(require("./api/CausticSodaApi"));

var _ConsumptionTypeApi = _interopRequireDefault(require("./api/ConsumptionTypeApi"));

var _CountryApi = _interopRequireDefault(require("./api/CountryApi"));

var _CurrencyApi = _interopRequireDefault(require("./api/CurrencyApi"));

var _FormulasApi = _interopRequireDefault(require("./api/FormulasApi"));

var _LanguageApi = _interopRequireDefault(require("./api/LanguageApi"));

var _PHApi = _interopRequireDefault(require("./api/PHApi"));

var _ProductApi = _interopRequireDefault(require("./api/ProductApi"));

var _ProductGroupApi = _interopRequireDefault(require("./api/ProductGroupApi"));

var _ProtocolApi = _interopRequireDefault(require("./api/ProtocolApi"));

var _QuoteApi = _interopRequireDefault(require("./api/QuoteApi"));

var _SingleProductApi = _interopRequireDefault(require("./api/SingleProductApi"));

var _SpeciesApi = _interopRequireDefault(require("./api/SpeciesApi"));

var _SurfaceApi = _interopRequireDefault(require("./api/SurfaceApi"));

var _TranslationApi = _interopRequireDefault(require("./api/TranslationApi"));

var _UnitApi = _interopRequireDefault(require("./api/UnitApi"));

var _UserApi = _interopRequireDefault(require("./api/UserApi"));

var _VersionApi = _interopRequireDefault(require("./api/VersionApi"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }