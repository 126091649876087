import React from "react";
import {faPrint} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {QuoteContext} from "../../contexts/QuoteContext";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import $ from "jquery";
import {getTranslation} from "../tools/Translation";
import {config} from "../config/Config";
import moment from "moment";

export default class PrintPreviewIcon extends React.Component {
    showPrintPreview = () => {
        if (this.context.currentQuote.showPrintPreview) {
            // Export to PDF
            if (this.context.currentQuote.isPrintPreviewVisible) {
                if (this.context.currentQuote.testMode) {
                    alert(getTranslation('cs.test-mode-print-error', this.context.currentQuote.locale));
                } else {
                    this.props.setLoading(true);
                    window.print();
                    let pdf = new jsPDF('p', 'mm', this.context.currentQuote.settings['options.paperSizeA4'] === true || this.context.currentQuote.settings['options.paperSizeA4'] === 'true' ? 'a4' : 'letter');
                    this.renderPage($('.sheet'), 0, pdf);
                }
            // Show print preview
            } else {
                this.context.currentQuote.setPrintPreviewVisible(true);
            }
        }
    }

    renderPage = (sheetsToPDF, page, pdf) => {
        let sheet = sheetsToPDF.eq(page);
        html2canvas(sheet[0], {
            scale:       2,
            width:       sheet.outerWidth(),
            height:      sheet.outerHeight()
        }).then((canvas) => {
            page++;
            pdf.addImage( canvas.toDataURL( 'image/png', 1), 'PNG', 0, 0, pdf.internal.pageSize.getWidth(), pdf.internal.pageSize.getHeight(), null, 'FAST' );

            // Last page
            if (page === sheetsToPDF.length) {
                pdf.setProperties( {
                    title:    getTranslation('estimate', this.context.currentQuote.locale),
                    author:   config.appName,
                    subject:  getTranslation('estimate', this.context.currentQuote.locale),
                    keywords: '',
                    creator:  config.appName
                });
                pdf.setDisplayMode( '100%', null );
                let pdfName = getTranslation('estimate', this.context.currentQuote.locale).toLowerCase();
                if (this.context.currentQuote.generalData['nomeleveur']) {
                    pdfName = this.context.currentQuote.generalData['nomeleveur'];
                }
                pdf.save(pdfName + "_" + moment().format("DD-MM-YYYY"));
                this.props.setLoading(false);
            } else {
                pdf.addPage();
                this.renderPage(sheetsToPDF, page, pdf);
            }
        });
    }

    render() {
        return (
            <FontAwesomeIcon className={"print-icon" + (!this.context.currentQuote.showPrintPreview ? " light" : "")} size={"lg"} icon={faPrint} onClick={this.showPrintPreview}/>
        )
    }
}

PrintPreviewIcon.contextType = QuoteContext;