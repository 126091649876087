import React from "react";
import infoImg from "../../assets/images/info.png";
import {getTranslation} from "../tools/Translation";
import {UserContext} from "../../contexts/UserContext";
let vsprintf = require('sprintf-js').vsprintf;

export default class GlobalCostPerAnimal extends React.Component {
    render() {
        let strCost = vsprintf(getTranslation('print.globalcost', this.context.user.locale), [this.context.species.data[this.props.currentQuote.species].getTranslation({
            fieldName: 'name',
            locale: this.context.user.locale
        }).toLowerCase()]);

        return (
            <div className="see-product-details">
                <img src={infoImg} alt="Info" className="info" />
                {strCost}: { this.props.currentQuote.getCostPerAnimalPerYear(this.context.category.data).toFixed(2) } {this.props.currentQuote.getCurrencySign()}
            </div>
        )
    }
}

GlobalCostPerAnimal.contextType = UserContext;