import React from "react";
import {UserContext} from "../../contexts/UserContext";
import Product from "./Product";
import CategoryTableHeader from "./CategoryTableHeader";
import CategoryTotal from "./CategoryTotal";

export default class Products extends React.Component {
    render() {
        return (
            <>
                <div className={"sheet-padding" + (this.props.page > 0 ? ' sheet-padding-top' : '')}>
                    { this.props.categories.map((categoryId) => {
                        if (this.context.category && this.context.category.data && this.context.category.data[categoryId]) {
                            const category = this.context.category.data[categoryId];
                            return (
                                <div key={'cat' + categoryId} className="category-box clearfix" data-id={categoryId}>
                                    <div className="category-title">
                                        { category.getTranslation({fieldName: 'name', locale: this.context.user.locale}) }
                                    </div>
                                    <CategoryTableHeader category={categoryId} currentQuote={this.props.currentQuote} />
                                    { this.props.currentQuote.getCategoryProducts(categoryId).map((productId) => {
                                        if (this.context.product && this.context.product.data && this.context.product.data[productId]) {
                                            const product = this.context.product.data[productId];
                                            return (
                                                <Product category={categoryId} currentQuote={this.props.currentQuote} productData={this.props.currentQuote.getProductData(categoryId, productId)} product={product} key={'product' + productId} userContext={this.context} />
                                            )
                                        }
                                        return null;
                                    })}
                                    <CategoryTotal category={categoryId} currentQuote={this.props.currentQuote} />
                                </div>
                            )
                        }
                        return null;
                    })}
                </div>
            </>
        )
    }
}

Products.contextType = UserContext;