import PizZipUtils from "pizzip/utils/index.js";
import React from "react";
import PizZip from "pizzip";
import Docxtemplater from "docxtemplater";
import {saveAs} from "file-saver";
import {QuoteContext} from "../../contexts/QuoteContext";
import moment from 'moment';
import {getTranslation} from "../tools/Translation";
import {config} from "../config/Config";
import {Apps} from "../../model/Apps";
import * as EasycalcApi from 'easycalc-api-client';
import EasycalcApiClient from "../data/EasycalcApiClient";

function loadFile(url, callback) {
    PizZipUtils.getBinaryContent(url, callback);
}

export default class TabOfferReport extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            selectedDocxLocale: config.supportedWaterTreatmentDocxLocales[0]
        }
    }

    onDocxLocaleChange = (locale) => {
        this.setState({
            selectedDocxLocale: locale
        });
    }

    getProductById = (id) => {
        return this.context.currentQuote.getUserContext().product.data[id] ? this.context.currentQuote.getUserContext().product.data[id] : null;
    }

    getRenderParameters = () => {
        let context = this.context.currentQuote.getUserContext();

        let currentDate = moment().format('ll');
        let mainProduct = this.props.customProducts.product ? this.getProductById(this.props.customProducts.product) : this.props.products.max;
        let selectedBiocide = this.props.customProducts.biocide ? this.getProductById(this.props.customProducts.biocide) : this.props.selectedBiocide.product;

        //Formules Excel selon le fichier "LSI calculation easycalc.xlsx"
        //Variables selon les onglets
        //I = inputs
        let IF3 = parseFloat(this.props.offerValues.circuit_volume);
        let IF6 = parseFloat(this.props.offerValues.working_hours_per_year);
        let IF7 = parseFloat(this.props.offerValues.current_chemical_costs_per_year);
        //OP = offer parameter
        let OPB2 = parseFloat(this.props.offerValues.offer_parameter_main_price);
        let OPB3 = parseFloat(this.props.offerValues.offer_parameter_main_dosing_rate);
        let OPB4 = parseFloat(this.props.products.cyclingValue);
        let OPB6 = parseFloat(this.props.offerValues.offer_parameter_main_make_up_water_cost);
        let OPB7 = parseFloat(this.props.offerValues.offer_parameter_main_effluent_costs);
        let OPB9 = parseFloat(this.props.offerValues.offer_parameter_biocide_price);
        let OPB10 = parseFloat(this.props.offerValues.offer_parameter_biocide_dosing_rate);
        let OPB11 = parseFloat(this.props.offerValues.offer_parameter_biocide_dosing_week);
        let OPB13 = parseFloat(this.props.offerValues.offer_parameter_biocide_service_frequency_year);
        let OPB14 = parseFloat(this.props.offerValues.offer_parameter_biocide_service_needed);
        let OPB24 = parseFloat(this.props.selectedBiocide.cyclingValue);
        let OPC24 = parseFloat(this.props.selectedBiocide.yearlyMakeUpWater);
        let OPD24 = parseFloat(this.props.selectedBiocide.evaporation);
        let OPF24 = parseFloat(this.props.selectedBiocide.hydricPerformance);
        //VO = validation offre
        let VOC17 = parseFloat(this.props.offerValues.offer_parameter_visit_fee);
        //offer
        let B4 = OPC24;
        let B5 = OPD24;
        let B6 = B4 - B5;
        let B8 = OPB24;
        let B12 = OPB6 * B4 + OPB7 * B6;
        let B13 = OPB7 * B6;
        let B17 = OPB10;
        let B18 = OPB11;
        let B19 = B18 * 52 * B17 * IF3 / 1000;
        let B22 = IF7;
        let B23 = B22 + B12 + B13;
        let B26 = 2256 * B5 / 1000;
        let B27 = B26 / IF6 * 1000 / 3600;
        let B28 = B5 / IF6;
        //let B29 = B22 / B26; not used?
        let B30 = (B22 + B13 + B12) / B26;
        let B31 = OPF24;
        let C5 = B5;
        let C8 = OPB4;
        let C4 = C5 / (1 - 1 / C8);
        let C6 = C4 - C5;
        let C7 = B4 - C4;
        let C9 = OPB3 / C8;
        let C10 = C9 * C4 / 1000;
        let C11 = C10 * OPB2;
        let C12 = OPB6 * C4 + OPB7 * C6;
        let C13 = OPB7 * C6;
        let C14 = B12 - C12 + B13 - C13;
        let C20 = B19 * OPB9;
        let C22 = C20 + C11;
        let C23 = C22 + C13 + C12;
        let C29 = C22 / B26;
        let C30 = (C22 + C13 + C12) / B26;
        let C31 = 100 * C5 / C4;

        let renderParameters = {
            'repName': context.user.fullname,
            'reportDate': currentDate,
            'customerName': this.props.offerValues.offer_parameter_customer_name,
            'mainProductLabel': mainProduct.name,
            'mainProductYearlyConsumption': parseFloat(C10).toFixed(0),
            'mainProductCost': parseFloat(this.props.offerValues.offer_parameter_main_price).toFixed(2),
            'mainProductPackaging': this.props.offerValues.offer_parameter_main_package,
            'mainProductYearlyCost': parseFloat(C11).toFixed(0),
            'selectedBiocideLabel': selectedBiocide.name,
            'selectedBiocideYearlyConsumption': parseFloat(B19).toFixed(0),
            'selectedBiocideCost': parseFloat(this.props.offerValues.offer_parameter_biocide_price).toFixed(2),
            'selectedBiocidePackaging': this.props.offerValues.offer_parameter_biocide_package,
            'selectedBiocideYearlyCost': parseFloat(C20).toFixed(0),
            'temperatureMinimum': this.props.offerValues.temp_min,
            'temperatureMaximum': this.props.offerValues.temp_max,
            'conductivity': this.props.offerValues.conductivity,
            'totalHardness': this.props.offerValues.total_hardness,
            'calciumHardness': this.props.offerValues.calcium_hardness,
            'alkalinity': this.props.offerValues.m_alkalinity,
            'silica': this.props.offerValues.silica,
            'chloride': this.props.offerValues.chloride,
            'chlorideLimitation': this.props.offerValues.chloride_limitation,
            'mainProductDescription': mainProduct.getTranslation({
                fieldName: 'description',
                locale: context.user.locale
            }),
            'selectedBiocideDescription': selectedBiocide.getTranslation({
                fieldName: 'description',
                locale: context.user.locale
            }),

            'cYMUW': parseFloat(B4).toFixed(0),
            'pYMUW': parseFloat(C4).toFixed(0),
            'cYEW': parseFloat(B5).toFixed(0),
            'pYEW': parseFloat(C5).toFixed(0),
            'cYBW': parseFloat(B6).toFixed(0),
            'pYBW': parseFloat(C6).toFixed(0),
            'pYSWQ': parseFloat(C7).toFixed(0),
            'pYSWM': parseFloat(C14).toFixed(0),
            'cHP': parseFloat(B31).toFixed(0),
            'pHP': parseFloat(C31).toFixed(0),
            'cC': parseFloat(this.props.offerValues.calculated_cycling_in_circuit).toFixed(1),
            'pC': parseFloat(C8).toFixed(1),
            'cWHPY': parseFloat(IF6).toFixed(0),
            'pAER': parseFloat(B28).toFixed(2),
            'pACP': parseFloat(B27).toFixed(2),
            'cTC': parseFloat(B22).toFixed(0),
            'pTC': parseFloat(C22).toFixed(0),
            'pSC': parseFloat(VOC17 * OPB13).toFixed(0),
            'cTCG': parseFloat(B23).toFixed(0),
            'pTCG': parseFloat(C23 + VOC17 * OPB13).toFixed(0),
            'cCCPGC': parseFloat(IF7 / B26).toFixed(3),
            'pCCPGC': parseFloat(C29).toFixed(3),
            'cTCPGC': parseFloat(B30).toFixed(3),
            'pTCPGC': parseFloat(C30).toFixed(3),

            'technicalCommentOnWaterProfile': this.props.offerValues.offer_parameter_technical_comments_water_profile,
            'muwC': this.props.offerValues.conductivity,
            'muwTH': this.props.offerValues.total_hardness,
            'muwCH': this.props.offerValues.calcium_hardness,
            'muwMA': this.props.offerValues.m_alkalinity,
            'muwS': this.props.offerValues.silica,
            'muwMH': this.props.offerValues.calculated_magnesium_hardness,
            'muwCL': this.props.offerValues.chloride,
            'muwSF': this.props.offerValues.offer_parameter_biocide_service_frequency_year + ' / per year',
            'ccSF': this.props.offerValues.offer_parameter_biocide_service_frequency_year + ' / per year',
            'ccC': parseFloat(C8 * this.props.offerValues.conductivity).toFixed(0),
            'ccTH': parseFloat(C8 * this.props.offerValues.total_hardness).toFixed(0),
            'ccCH': parseFloat(C8 * this.props.offerValues.calcium_hardness).toFixed(0),
            'ccMA': parseFloat(C8 * this.props.offerValues.m_alkalinity).toFixed(0),
            'ccS': parseFloat(C8 * this.props.offerValues.silica).toFixed(0),
            'ccMH': parseFloat(C8 * this.props.offerValues.calculated_magnesium_hardness).toFixed(0),
            'ccCL': parseFloat(C8 * this.props.offerValues.chloride).toFixed(0),
            'ccCY': parseFloat(C8).toFixed(0), //E12
            'ccAA': parseFloat(OPB3).toFixed(0),
            'ccTC': this.props.offerValues.bacterial_count_by_dipslide,
            'technicalServiceVisitPerYear': OPB13.toFixed(0),
            'technicalServiceOnSitePerVisit': OPB14.toFixed(0),
            'c1': '',
            'c1MN': '',
            'c1MX': '',
            'c1PH': '',
            'c1LMN': '',
            'c1LMX': '',
            'c1MS': '',
            'c1S': '',
            'c1C': '',
            'c1GS': '',
            'c2': '',
            'c2MN': '',
            'c2MX': '',
            'c2PH': '',
            'c2LMN': '',
            'c2LMX': '',
            'c2MS': '',
            'c2S': '',
            'c2C': '',
            'c2GS': '',
            'c3': '',
            'c3MN': '',
            'c3MX': '',
            'c3PH': '',
            'c3LMN': '',
            'c3LMX': '',
            'c3MS': '',
            'c3S': '',
            'c3C': '',
            'c3GS': '',
            'c4': '',
            'c4MN': '',
            'c4MX': '',
            'c4PH': '',
            'c4LMN': '',
            'c4LMX': '',
            'c4MS': '',
            'c4S': '',
            'c4C': '',
            'c4GS': '',
            'c5': '',
            'c5MN': '',
            'c5MX': '',
            'c5PH': '',
            'c5LMN': '',
            'c5LMX': '',
            'c5MS': '',
            'c5S': '',
            'c5C': '',
            'c5GS': '',
            'c6': '',
            'c6MN': '',
            'c6MX': '',
            'c6PH': '',
            'c6LMN': '',
            'c6LMX': '',
            'c6MS': '',
            'c6S': '',
            'c6C': '',
            'c6GS': '',
            'c7': '',
            'c7MN': '',
            'c7MX': '',
            'c7PH': '',
            'c7LMN': '',
            'c7LMX': '',
            'c7MS': '',
            'c7S': '',
            'c7C': '',
            'c7GS': '',
            'c8': '',
            'c8MN': '',
            'c8MX': '',
            'c8PH': '',
            'c8LMN': '',
            'c8LMX': '',
            'c8MS': '',
            'c8S': '',
            'c8C': '',
            'c8GS': '',
            'c9': '',
            'c9MN': '',
            'c9MX': '',
            'c9PH': '',
            'c9LMN': '',
            'c9LMX': '',
            'c9MS': '',
            'c9S': '',
            'c9C': '',
            'c9GS': '',
            'c10': '',
            'c10MN': '',
            'c10MX': '',
            'c10PH': '',
            'c10LMN': '',
            'c10LMX': '',
            'c10MS': '',
            'c10S': '',
            'c10C': '',
            'c10GS': '',
            'c11': '',
            'c11MN': '',
            'c11MX': '',
            'c11PH': '',
            'c11LMN': '',
            'c11LMX': '',
            'c11MS': '',
            'c11S': '',
            'c11C': '',
            'c11GS': '',
            'c12': '',
            'c12MN': '',
            'c12MX': '',
            'c12PH': '',
            'c12LMN': '',
            'c12LMX': '',
            'c12MS': '',
            'c12S': '',
            'c12C': '',
            'c12GS': '',
            'c13': '',
            'c13MN': '',
            'c13MX': '',
            'c13PH': '',
            'c13LMN': '',
            'c13LMX': '',
            'c13MS': '',
            'c13S': '',
            'c13C': '',
            'c13GS': '',
            'c14': '',
            'c14MN': '',
            'c14MX': '',
            'c14PH': '',
            'c14LMN': '',
            'c14LMX': '',
            'c14MS': '',
            'c14S': '',
            'c14C': '',
            'c14GS': '',
            'c15': '',
            'c15MN': '',
            'c15MX': '',
            'c15PH': '',
            'c15LMN': '',
            'c15LMX': '',
            'c15MS': '',
            'c15S': '',
            'c15C': '',
            'c15GS': '',
            'c16': '',
            'c16MN': '',
            'c16MX': '',
            'c16PH': '',
            'c16LMN': '',
            'c16LMX': '',
            'c16MS': '',
            'c16S': '',
            'c16C': '',
            'c16GS': '',
            'c17': '',
            'c17MN': '',
            'c17MX': '',
            'c17PH': '',
            'c17LMN': '',
            'c17LMX': '',
            'c17MS': '',
            'c17S': '',
            'c17C': '',
            'c17GS': '',
            'c18': '',
            'c18MN': '',
            'c18MX': '',
            'c18PH': '',
            'c18LMN': '',
            'c18LMX': '',
            'c18MS': '',
            'c18S': '',
            'c18C': '',
            'c18GS': ''

        };

        let i = 0;
        let templateMaxI = 18;

        for (let cyclingValue = this.props.offerValues.table_cycling_start; cyclingValue <= this.props.offerValues.table_cycling_stop && i <= templateMaxI; cyclingValue += this.props.offerValues.table_cycling_step) {

            i++;

            let phMinFormula = ((Math.log10(cyclingValue * this.props.offerValues.calculated_conductivity) - 1)) / 10 + 34.55 - 13.12 * Math.log10(this.props.offerValues.calculated_temperature_min) + 0.4 - Math.log10(this.props.offerValues.calcium_hardness * cyclingValue) - Math.log10(this.props.offerValues.m_alkalinity * cyclingValue) + 9.3;
            let phMaxFormula = ((Math.log10(cyclingValue * this.props.offerValues.calculated_conductivity) - 1)) / 10 + 34.55 - 13.12 * Math.log10(this.props.offerValues.calculated_temperature_max) + 0.4 - Math.log10(this.props.offerValues.calcium_hardness * cyclingValue) - Math.log10(this.props.offerValues.m_alkalinity * cyclingValue) + 9.3;
            let estimatedPH = 9.8;

            if (this.props.offerValues.m_alkalinity * cyclingValue < 100) {
                estimatedPH = 7.7;
            } else if (this.props.offerValues.m_alkalinity * cyclingValue < 300) {
                estimatedPH = 7.9 + (this.props.offerValues.m_alkalinity * cyclingValue - 150) * 0.003;
            } else if (this.props.offerValues.m_alkalinity * cyclingValue < 400) {
                estimatedPH = 8.4 + (this.props.offerValues.m_alkalinity * cyclingValue - 300) / 500;
            } else if (this.props.offerValues.m_alkalinity * cyclingValue < 1500) {
                estimatedPH = 8.6 + (this.props.offerValues.m_alkalinity * cyclingValue - 400) / 1000;
            } else if (this.props.offerValues.m_alkalinity * cyclingValue < 2000) {
                estimatedPH = 9.6 + (this.props.offerValues.m_alkalinity * cyclingValue - 1500) / 4700;
            } else if (this.props.offerValues.m_alkalinity * cyclingValue < 5000) {
                estimatedPH = 9.7;
            } else {
                estimatedPH = 9.8;
            }

            let lsiMin = estimatedPH - phMinFormula;
            let lsiMax = estimatedPH - phMaxFormula;

            let magnesiumSilicate = cyclingValue * this.props.offerValues.calculated_magnesium_hardness * cyclingValue * this.props.offerValues.silica;
            let silica = cyclingValue * this.props.offerValues.silica;
            let chlorides = cyclingValue * this.props.offerValues.chloride;
            let galvanizedSteel = estimatedPH;

            let sI = i.toString();
            renderParameters['c' + sI] = Number.isInteger(cyclingValue) ? cyclingValue : cyclingValue.toFixed(Number.parseInt(this.props.offerValues.table_cycling_precision));//Cycling
            renderParameters['c' + sI + 'MN'] = phMinFormula.toFixed(Number.parseInt(this.props.offerValues.table_cycling_precision));//Minimum temp
            renderParameters['c' + sI + 'MX'] = phMaxFormula.toFixed(Number.parseInt(this.props.offerValues.table_cycling_precision));//Maximum temp
            renderParameters['c' + sI + 'PH'] = estimatedPH.toFixed(Number.parseInt(this.props.offerValues.table_cycling_precision));//estimated pH
            renderParameters['c' + sI + 'LMN'] = Number.isInteger(lsiMin) ? lsiMin : lsiMin.toFixed(Number.parseInt(this.props.offerValues.table_cycling_precision));//Min LSI
            renderParameters['c' + sI + 'LMX'] = Number.isInteger(lsiMax) ? lsiMax : lsiMax.toFixed(Number.parseInt(this.props.offerValues.table_cycling_precision));//Max LSI
            renderParameters['c' + sI + 'MS'] = magnesiumSilicate.toFixed(0);//Magnesium silicate
            renderParameters['c' + sI + 'S'] = silica.toFixed(0);//Silica
            renderParameters['c' + sI + 'C'] = chlorides.toFixed(0);//Chlorides
            renderParameters['c' + sI + 'GS'] = galvanizedSteel.toFixed(Number.parseInt(this.props.offerValues.table_cycling_precision));//Galvanized steel
        }

        return renderParameters;
    }

    generateDocFile = (error, content) => {
        if (error) {
            throw error;
        }
        let zip = new PizZip(content);
        let doc = new Docxtemplater(zip, {paragraphLoop: true, linebreaks: true});

        doc.render(this.getRenderParameters());
        let out = doc.getZip().generate({
            type: "blob",
            mimeType: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
            compression: "DEFLATE"
        });
        saveAs(out, "output.docx");
    }

    renderDoc = () => {
        // Send the Offer parameters to the backend
        let data = Object.assign({}, this.props);

        data['application'] = Apps.WaterTreatment;
        data['id'] = moment().valueOf();
        data['lastModifDate'] = moment().valueOf();
        data['renderParameters'] = this.getRenderParameters();
        data['quote'] = this.context.currentQuote.saveContent(false, true);
        delete data.context;
        data = [data];

        const api = new EasycalcApi.QuoteApi(new EasycalcApiClient());
        api.setQuotes({quotes: JSON.stringify(data)}).then(() => {
            // No need to do anything for now...
            // console.log('Offer pushed');
        });

        // Generate the file
        const filePath = process.env.PUBLIC_URL + '/assets/template/WaterTreatmentTemplate.' + this.state.selectedDocxLocale + '.docx';
        loadFile(
            filePath,
            this.generateDocFile
        )
    }

    render() {
        return (
            <div className="row mt-3">
                <div className="col">
                    <div className="d-inline-flex align-items-center">
                        <button disabled={this.context.currentQuote.testMode} className={'btn btn-primary ml-3 mr-2 text-nowrap btn-sm'} onClick={this.renderDoc}>{getTranslation('wt.generate-docx', this.context.currentQuote.getUserContext().user.locale)}</button>
                        <select className="form-control" value={this.state.selectedDocxLocale} onChange={(e) => {this.onDocxLocaleChange(e.target.value)}}>
                            {config.supportedWaterTreatmentDocxLocales.map((locale) =>
                                <option key={locale} value={locale}>{locale.toUpperCase()}</option>
                            )}
                        </select>
                    </div>
                </div>
            </div>
        )
    }
}

TabOfferReport.contextType = QuoteContext;