import React from "react";
import {getTranslation} from "../tools/Translation";
import {UserContext} from "../../contexts/UserContext";

export default class Signature extends React.Component {
    render() {
        return (
            <div className="sheet-padding">
                <div className="print-signature">
                    <div className={"signature-background"}/>
                    <span className="title">{ getTranslation('print.approval', this.context.user.locale) }</span>
                    <div className="signature-box">
                        <span className="date">{ getTranslation('generaldata.date', this.context.user.locale) }</span>
                        <span className="signature">{ getTranslation('print.signature', this.context.user.locale) }</span>
                    </div>
                </div>
            </div>
        );
    }
}

Signature.contextType = UserContext;