import React from 'react';
import {Modal} from "react-bootstrap";
import {config} from "../config/Config";
import {flags, getStaticTranslation} from "./Translation";
import {deleteVersions} from "../data/Database";

export default class MissingDataOverlay extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            locale: localStorage.getItem(config.userLoginLocale) || config.defaultLocale
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.show !== prevProps.show) {
            deleteVersions();
        }
    }

    changeLocale = (e, locale) => {
        e.preventDefault();
        this.setState({
            locale: locale
        });
        localStorage.setItem(config.userLoginLocale, locale);
    }

    render() {
        return (
            <Modal
                show={this.props.show}
                backdrop="static"
                keyboard={false}
                size="md"
                centered>
                <Modal.Header>
                    <Modal.Title>
                        {getStaticTranslation('missingData', this.state.locale)}
                        <div className="independant-locale-selector">
                            <a href="." className={this.state.locale === 'fr' ? 'active' : ''} onClick={(e) => this.changeLocale(e, 'fr')}><img src={flags.fr} alt="Français"/></a>
                            <a href="." className={this.state.locale === 'en' ? 'active' : ''} onClick={(e) => this.changeLocale(e, 'en')}><img src={flags.en} alt="English"/></a>
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        {getStaticTranslation('missingDataText', this.state.locale)}
                    </p>
                </Modal.Body>
            </Modal>
        )
    }
}