import React, { createRef } from "react";
import { Row, Col } from "react-bootstrap";
import classNames from "classnames";
import { QuoteContext } from "../../contexts/QuoteContext";
import $ from "jquery";
import { config } from "../config/Config";
import { UserContext } from "../../contexts/UserContext";
import ProductFormats from "./ProductFormats";
import ProductCalculation from "./ProductCalculation";
import ProductPricing from "./ProductPricing";
import ProductEstimatedConsumption from "./ProductEstimatedConsumption";
import ProductDescription from "./ProductDescription";
import ProductImage from "./ProductImage";
import ProductSoftWater from "./ProductSoftWater";
import Biocide from "./Biocide";
import ProductInSpray from "./ProductInSpray";
import ProductDosage from "./ProductDosage";
import ProductDiscount from "./ProductDiscount";

export default class QuoteProduct extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            initialized: false,
            addedEffect: props.data.addedEffect || false
        }

        this.toggleScrollToElement = props.data.toggleScrollToElement || null;

        this.wrapper = createRef();
    }

    componentDidMount() {

        this.setState({
            initialized: true
        });

        let topA = 20;//$('#product-panel').offset().top;
        let topB = $(this.wrapper.current).offset().top;
        let offset = topA - topB;

        if (this.state.addedEffect && offset < 0) {
            this.setState({
                addedEffect: false
            });
        } else if (this.state.addedEffect) {
            setTimeout(() => {
                this.setState({
                    addedEffect: false
                });
            }, 800);
        }

        if (this.props.data.toggleScrollToElement && this.props.data.manuallyAdded) {
            setTimeout(() => {
                this.scrollToElement();
            }, 500);
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if (this.toggleScrollToElement !== this.props.data.toggleScrollToElement) {
            this.toggleScrollToElement = this.props.data.toggleScrollToElement;
            this.scrollToElement();
        }
    }
    
    scrollToElement = () => {
        let offset = $(window).height() - $('#product-panel').outerHeight() - ($(this.wrapper.current).outerHeight() + 1);
        const isLastElement = $(this.wrapper.current).parents('.container:first').children('.row:not(.total):last').is($(this.wrapper.current).parents('.row:first'));
        let boxPosition = $(this.wrapper.current).offset().top - offset + (isLastElement ? 46 : 0); // 46 = total price box height + 10px
        $('html, body').animate({
            scrollTop: boxPosition
        }, config.scrollToCategoryDuration, 'swing');
    }

    onMouseOverHandler = () => {
        this.wrapper.current.classList.add('hover');
    }

    onMouseOutHandler = () => {
        this.wrapper.current.classList.remove('hover');
    }

    render() {
        let category = this.props.category;
        let product = this.props.product;
        let data = this.props.data;

        return (
            <UserContext.Consumer>
                {({ user }) => (
                    <Row>
                        <Col ref={this.wrapper} className={classNames({
                            "product-box": true,
                            deleted: data.deleted || false,
                            initialize: !this.state.initialized,
                            "added-effect": this.state.addedEffect
                        })} onMouseEnter={this.onMouseOverHandler} onMouseLeave={this.onMouseOutHandler}>
                            <div className="close" onClick={() => this.context.currentQuote.removeProduct(category.id, product.id)} />
                            <Row>
                                <Col>
                                    <div className="product-title">{product.name}</div>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={2} lg={1} className="product-img">
                                    <ProductImage product={product} />
                                </Col>
                                <Col xs={10} lg={5} className="pr-lg-0">
                                    <Row className="no-gutters">
                                        <Col xs={12} className="product-description order-1">
                                            {product.inSpray === true &&
                                                <ProductInSpray category={category} product={product} productData={data} currentQuote={this.context.currentQuote} />
                                            }
                                            <ProductDescription product={product} user={user} />
                                        </Col>
                                        {!data.inSpray &&
                                            <Col xs={12} className="product-soft-water order-2">
                                                <ProductSoftWater product={product} category={category} currentQuote={this.context.currentQuote} />
                                            </Col>
                                        }
                                        <Col xs={12} lg={8} className="product-consumption order-3 order-lg-4" style={category.color ? { color: category.color } : null}>
                                            {data.inSpray &&
                                                <ProductDosage product={product} />
                                            }
                                            {!data.inSpray &&
                                                <ProductEstimatedConsumption category={category} product={product} />
                                            }
                                        </Col>
                                        <Col lg={4} className="product-packages-wrapper order-12 order-lg-3 text-right text-lg-left pr-lg-2 pt-2 pt-lg-0">
                                            <ProductFormats category={category} product={product} user={user} highlightFormats={this.props.data.highlightFormats} />
                                        </Col>
                                        {!data.inSpray &&
                                            <Col lg={12} className="order-11 order-lg-5">
                                                <ProductCalculation category={category} product={product} />
                                            </Col>
                                        }
                                    </Row>
                                </Col>
                                <Col xs={12} lg={6} className="mt-3 mt-lg-0">
                                    <ProductPricing category={category} product={product} user={user} productData={data} />
                                    <ProductDiscount category={category} product={product} productData={data} user={user} currentQuote={this.context.currentQuote} />
                                    <Biocide category={category} product={product} user={user} />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                )}
            </UserContext.Consumer>
        )
    }
}

QuoteProduct.contextType = QuoteContext;
