import React from "react";
import {UserContext} from "../../contexts/UserContext";
import {getTranslation} from "../tools/Translation";

export default class PaperSize extends React.Component {
    render() {
        return (
            <div className={"papersize"}>
                {getTranslation('papersize', this.context.user.locale)} :
                <select name="papersize" onChange={(event) => {this.props.onPaperSizeChange(event.target.value)}} value={this.props.currentQuote.settings['options.paperSizeA4'] === true || this.props.currentQuote.settings['options.paperSizeA4'] === 'true' ? 'A4' : 'Letter'}>
                    <option value="A4">A4</option>
                    <option value="Letter">{getTranslation('papersize.letter', this.context.user.locale)}</option>
                </select>
            </div>
        );
    }
}

PaperSize.contextType = UserContext;