import React from "react";
import {QuoteContext} from "../../contexts/QuoteContext";

export default class HideEmptyCategories extends React.Component {

    handleClick = () => {
        let hasProducts = this.context.currentQuote.hasProducts();
        if (hasProducts) {
            this.context.currentQuote.toggleDisplayEmptyCategories()
        }
    };

    render() {
        let hasProducts = this.context.currentQuote.hasProducts();
        let displayEmptyCategories = this.context.currentQuote.displayEmptyCategories;

        let classes = ['icon'];

        if (displayEmptyCategories) {
            classes.push('full');
        }
        if (!hasProducts) {
            classes.push('light');
        }
        return (
            <div className="change-view" onClick={this.handleClick}>
                <div className={classes.join(' ')}>&nbsp;</div>
            </div>
        );
    }
}

HideEmptyCategories.contextType = QuoteContext;