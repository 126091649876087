import React, {useContext, useEffect, useState} from "react";
import {Card, Col, Row} from "react-bootstrap";
import {getTranslation} from "../tools/Translation";
import {UserContext} from "../../contexts/UserContext";
import DatePicker from "react-datepicker";
import {QuoteContext} from "../../contexts/QuoteContext";

export default function ClientData(props) {
    const context = useContext(UserContext);
    const quoteContext = useContext(QuoteContext);
    const [datepickerLocalesLoaded, setDatepickerLocalesLoaded] = useState(false);
    const [datepickerLocales, setDatepickerLocales] = useState({});

    const loadDatepickerLocales = () => {
        if (!datepickerLocalesLoaded) {
            if (context.language && context.language.data) {
                if (Object.keys(context.language.data).length > 0) {
                    setDatepickerLocalesLoaded(true);
                    Object.keys(context.language.data).forEach((localeId) => {
                        if (context.language.data[localeId].localeValue) {
                            getDatepickerLocale(context.language.data[localeId].localeValue, context.language.data[localeId].fullLocale || context.language.data[localeId].localeValue);
                        }
                    });
                }
            }
        }
    }

    const getDatepickerLocale = (locale, fullLocale) => {
        datepickerLocales[locale] = require(`date-fns/locale/${fullLocale}/index.js`)
        setDatepickerLocales(datepickerLocales);
    }

    useEffect(() => {
        loadDatepickerLocales();
    });

    return (
        <Row className="mt-3">
            <Col>
                <Card>
                    <Card.Header className="text-center"><strong>{getTranslation('cs.client-data', context.user.locale)}</strong></Card.Header>
                    <Card.Body>
                        <Row className="mb-1">
                            <Col md={6} className="mb-3">
                                <input type="text" className="form-control" id="quote-name" onChange={(e) => {quoteContext.currentQuote.setName(e.target.value)}} value={quoteContext.currentQuote.name} placeholder={getTranslation('cs.quote-name', context.user.locale)} />
                            </Col>
                            <Col md={6} className="mb-3">
                                <input type="text" className="form-control" id="version" onChange={(e) => {props.setProperty('version', e.target.value)}} value={props.quote.version} placeholder={getTranslation('cs.version', context.user.locale)} />
                            </Col>
                        </Row>
                        <Row className="mb-1">
                            <Col md={6} className="mb-3">
                                <DatePicker as={"input"} id="date" className="gd-date form-control" placeholderText={getTranslation('cs.date', context.user.locale)}
                                                                                           onChange={(date) => {props.setProperty('date', date)}}
                                                                                           dateFormat="yyyy-MM-dd"
                                                                                           locale={datepickerLocales[quoteContext.currentQuote.locale] || null}
                                                                                           selected={props.quote.date ? new Date(props.quote.date) : null}/>
                            </Col>
                            <Col md={6} className="mb-3">
                                <input type="text" className="form-control" id="technician" onChange={(e) => {props.setProperty('technician', e.target.value)}} value={props.quote.technician} placeholder={getTranslation('cs.kersia-technician', context.user.locale)} />
                            </Col>
                            <Col md={6} className="mb-3">
                                <input type="text" className="form-control" id="society" onChange={(e) => {props.setProperty('society', e.target.value)}} value={props.quote.society} placeholder={getTranslation('cs.society', context.user.locale)} />
                            </Col>
                            <Col md={6} className="mb-3">
                                <input type="text" className="form-control" id="site" onChange={(e) => {props.setProperty('site', e.target.value)}} value={props.quote.site} placeholder={getTranslation('cs.site', context.user.locale)} />
                            </Col>
                            <Col md={6} className="mb-3">
                                <input type="text" className="form-control" id="town" onChange={(e) => {props.setProperty('town', e.target.value)}} value={props.quote.town} placeholder={getTranslation('cs.town', context.user.locale)} />
                            </Col>
                            <Col md={6} className="mb-3">
                                <input type="email" className="form-control" id="email" onChange={(e) => {props.setProperty('email', e.target.value)}} value={props.quote.email} placeholder={getTranslation('cs.contact-email', context.user.locale)} />
                            </Col>
                            <Col md={6} className="mb-3">
                                <input type="text" className="form-control" id="firstname" onChange={(e) => {props.setProperty('firstname', e.target.value)}} value={props.quote.firstname} placeholder={getTranslation('cs.contact-firstname', context.user.locale)} />
                            </Col>
                            <Col md={6} className="mb-3">
                                <input type="text" className="form-control" id="lastname" onChange={(e) => {props.setProperty('lastname', e.target.value)}} value={props.quote.lastname} placeholder={getTranslation('cs.contact-lastname', context.user.locale)} />
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    );
}