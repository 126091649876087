import React from "react";
import {UserContext} from "../../contexts/UserContext";
import {getTranslation} from "../tools/Translation";

export default class ProductDosage extends React.Component {
    render() {
        if (!this.props.product.concentration) {
            return null;
        }

        return (
            <>
                {getTranslation("dosage", this.context.user.locale)} : {this.props.product.concentration} %
            </>
        )
    }
}

ProductDosage.contextType = UserContext;