import { Component } from "react";
import Lactoduc from "../calculator/lactoduc/Lactoduc";
import ValorisationFourrages from "../calculator/valorisationfourrages/ValorisationFourrages";
import Categories from "../category/Categories";
import PrintPreview from "../export/PrintPreview";
import CustomerWidget from "../general-data/CustomerWidget";
import GeneralData from "../general-data/GeneralData";
import Mosaic from "../mosaic/Mosaic";
import PriceList from "../pricelist/PriceList";
import ProductDetailPopup from "../product/ProductDetailPopup";
import Protocol from "../calculator/protocol/Protocol";

var React = require('react');

export default class Farm extends Component {
    render() {
        return (
            <>
                {this.props.quoteContext.currentQuote.isProductDetailVisible &&
                    <ProductDetailPopup show={true} onHide={this.props.quoteContext.currentQuote.hideProductDetailsPopup} />
                }
                {this.props.quoteContext.currentQuote.isCalculatorLactoducVisible &&
                    <Lactoduc show={true} />
                }
                {this.props.quoteContext.currentQuote.isCalculatorValorisationVisible &&
                    <ValorisationFourrages show={true} />
                }
                {this.props.quoteContext.currentQuote.isCalculatorProtocolVisible &&
                    <Protocol show={true} />
                }
                {this.props.quoteContext.currentQuote.isPriceListVisible &&
                    <PriceList show={true} onHide={this.props.quoteContext.currentQuote.hidePriceList} />
                }
                {this.props.quoteContext.currentQuote.isPrintPreviewVisible &&
                    <PrintPreview show={true} userContext={this.props.userContext} onHide={() => this.props.quoteContext.currentQuote.setPrintPreviewVisible(false)} />
                }
                {this.props.quoteContext.currentQuote.isMosaicVisible &&
                    <Mosaic show={true} />
                }

                <GeneralData userContext={this.props.userContext} />

                <Categories displayEmptyCategories={this.props.quoteContext.currentQuote.displayEmptyCategories} quoteCategories={this.props.quoteContext.currentQuote.categories} />

                {this.props.quoteContext.currentQuote.settings['options.customerwidget'] !== false &&
                        <CustomerWidget userContext={this.props.userContext} />
                    }
            </>
        );
    }
}