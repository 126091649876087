import User from "../../model/User";
import {convertObjectsToEntity} from "../../model/Entity";
import {Quote} from "../../model/Quote";

let quotes = [];
quotes.push(new Quote());

export const config = {
    appId: '1fe5c583-ba56-46cc-af00-cf6bccd8264b',
    appAuthority: 'https://login.microsoftonline.com/bbe36403-f40c-48b7-a42b-b0ff2b8a03db',
    redirectUrl: 'https://login.microsoftonline.com/common/oauth2/nativeclient',
    resource: 'https://graph.microsoft.com',
    scopes: [
        'user.read'
    ],
    kersia: "Kersia, inventing a food safe world",
    appName: "Easycalc",
    appVersion: process.env.REACT_APP_VERSION,
    defaultLocale: "en",

    onScrollCallbackDelay: 100,
    scrollToCategoryDuration: 250,
    categoryProductAnimationDelay: 360,

    defaultUserContext: {
        user: new User(),
        category: {version: null, data: {}},
        country: {version: null, data: {}},
        ph: {version: null, data: {}},
        product: {version: null, data: {}},
        species: {version: null, data: []},
        unit: {version: null, data: {}},
        productgroup: {version: null, data: {}},
        language: {version: null, data: {}},
        currency: {version: null, data: {}},
        consumptiontype: {version: null, data: {}},
        formulavariable: {version: null, data: {}},
        globalvariable: {version: null, data: {}},
        translation: convertObjectsToEntity(JSON.parse(localStorage.getItem('translation')) || {version: null, data: {}}),
        surface: {version: null, data: {}},
        protocol: {version: null, data: {}},
        factoryobjecttype: {version: null, data: {}},
        inputproduct: {version: null, data: {}},
        recipe: {version: null, data: {}},
        washingtype: {version: null, data: {}},
        thermalcircuit: {version: null, data: {}},
        washingstep: {version: null, data: {}},
        recipematrix: {version: null, data: {}},
        producttype: {version: null, data: {}}
    },

    yesNoOptions: {
        '0': {
            id: 0,
            name: 'no'
        },
        '1': {
            id: 1,
            name: 'yes'
        }
    },

    defaultQuoteContext: {
        availableQuotes: quotes,
        currentQuote: quotes[0]
    },

    productPlaceholderImage: '/assets/images/product-placeholder.jpg',

    tokenParamName: 'token',
    userParamName: 'user',
    userLoginLocale: 'userLocale',

    phAcidicId: 1,
    phNeutralId: 2,
    phAlkalineId: 3,

    unitKg: 1,
    unitPercent: 14,
    unitUnit: 16,
    unitCubicMeters: 18,
    unitHours: 19,
    unitKgWashing: 20,
    unitTonPerYear: 21,

    applicationSprayKitId: 1,
    applicationManualId: 2,
    applicationAutoFoamId: 3,
    applicationManualFoamId: 4,

    washingStepPrelavageId: 1,
    washingStepSoudeId: 2,
    washingStepRincageSoudeId: 3,
    washingStepAcidId: 4,
    washingStepRincageAcidId: 5,
    washingStepDesinfectant: 6,
    washingStepRincageFinal: 7,

    supportedWaterTreatmentDocxLocales: [
        'en' //, 'fr'
    ],

    isElectron: () => {
        // Renderer process
        if (typeof window !== 'undefined' && typeof window.process === 'object' && window.process.type === 'renderer') {
            return true;
        }

        // Main process
        if (typeof process !== 'undefined' && typeof process.versions === 'object' && !!process.versions.electron) {
            return true;
        }

        // Detect the user agent when the `nodeIntegration` option is set to true
        if (typeof navigator === 'object' && typeof navigator.userAgent === 'string' && navigator.userAgent.indexOf('Electron') >= 0) {
            return true;
        }

        return false;
    }
};
