import React from 'react';
import {Button, Col, Container, FormControl, Modal, ModalBody, ModalFooter, ModalTitle, Row} from "react-bootstrap";
import ModalHeader from "react-bootstrap/ModalHeader";
import {getTranslation} from "../../tools/Translation";
import {sprintf} from "sprintf-js";
import {QuoteContext} from "../../../contexts/QuoteContext";

export default class Lactoduc extends React.Component {

    baseValues = {
        inletDiameter: '',
        inletLength: '',
        inletCircumference: 0,
        inletTotal: 0,
        outletDiameter: '',
        outletLength: '',
        outletCircumference: 0,
        outletTotal: 0,
        clustersLength: 0,
        clustersCircumference: 0.2, //TODO: configuration via backend
        clustersTotal: 0,
        measuringContainersQuantity: '',
        measuringContainersCircumference: 0.5, //TODO: configuration via backend
        measuringContainersTotal: 0,
        outletChamberTotal: 1, //TODO: configuration via backend
        total: 0
    }

    state = {
        visible: false,
        values: this.baseValues
    }

    reset = () => this.setState({values: this.baseValues}, this.recalculate);

    handleCloseButtonClick = () => {
        this.context.currentQuote.hideCalculatorLactoduc();
    }

    handleOnChange = (e) => {

        let values = this.state.values;
        values[e.target.getAttribute('data-key')] = e.target.value;

        this.setState({values: values}, this.recalculate)
    }

    handleOKButtonClick = () => {
        this.context.currentQuote.setProductEstimatedConsumption(this.context.currentQuote.selectedProductData.categoryId, this.context.currentQuote.selectedProductData.product.id, Math.round(this.state.values.total*7.5) || 0);
        this.handleCloseButtonClick();
    }

    recalculate = () => {

        //region inlet
        const iDiameter = this.state.values.inletDiameter;
        const iLength = this.state.values.inletLength;
        const iCircumference = iDiameter * Math.PI / 1000;
        const iTotal = iLength * iCircumference;
        //endregion

        //region outlet
        const oDiameter = this.state.values.outletDiameter;
        const oLength = this.state.values.outletLength;
        const oCircumference = oDiameter * Math.PI / 1000;
        const oTotal = oLength * oCircumference;
        //endregion

        //region clusters
        const cLength = this.context.currentQuote.generalData.clusters;
        const cCircumference = this.state.values.clustersCircumference;
        const cTotal = cLength * cCircumference;
        //endregion

        //region measuring containers
        const mcQuantity = this.state.values.measuringContainersQuantity;
        const mcCircumference = this.state.values.measuringContainersCircumference;
        const mcTotal = mcQuantity * mcCircumference;
        //endregion

        //region outlet chamber
        const ocTotal = this.state.values.outletChamberTotal || 0;
        //endregion

        const gTotal = parseFloat(iTotal.toString()) + parseFloat(oTotal.toString()) + parseFloat(cTotal.toString()) + parseFloat(mcTotal.toString()) + parseFloat(ocTotal.toString());

        let oldState = this.state.values;
        oldState.inletCircumference = iCircumference;
        oldState.inletTotal = iTotal;
        oldState.outletCircumference = oCircumference;
        oldState.outletTotal = oTotal;
        oldState.clustersTotal = cTotal;
        oldState.measuringContainersTotal = mcTotal;
        oldState.total = gTotal;

        this.setState({values: oldState});
    }

    render() {

        if (!this.context.currentQuote.generalData) {
            return false;
        }

        const UCLocale = this.context.currentQuote.getUserContext().user.locale;

        return (
            <Modal size="xs" show={this.props.show} onHide={this.handleCloseButtonClick} onEntered={this.reset}>
                <ModalHeader className={"text-center"}>
                    <ModalTitle className={"text-uppercase w-100"} style={{color: 'rgb(238, 75, 46)'}}>{getTranslation("Water requirements", UCLocale)}</ModalTitle>
                </ModalHeader>
                <ModalBody className={"p-2"} style={{background: 'rgb(238, 75, 46)', color: '#FFF'}}>
                    <Container>
                        <Row>
                            <Col xs={12} md={4} className={"p-0 pt-2 font-weight-bold"} style={{whiteSpace: 'nowrap'}}>{getTranslation("Lactoduc inlet", UCLocale)}</Col>
                            <Col xs={3} md={1} className={"p-0 text-center"}><FormControl className={"p-0 w-100 text-center"} onChange={this.handleOnChange} data-key="inletDiameter" placeholder="dia" aria-label={getTranslation("Diameter", UCLocale)} value={this.state.values.inletDiameter}></FormControl></Col>
                            <Col xs={1} md={1} className={"p-0 pt-2 text-center"}>Ø</Col>
                            <Col xs={3} md={1} className={"p-0 text-center"}><FormControl className={"p-0 w-100 text-center"} onChange={this.handleOnChange} data-key="inletLength" placeholder="len" aria-label={getTranslation("Length", UCLocale)} value={this.state.values.inletLength}></FormControl></Col>
                            <Col xs={1} md={1} className={"p-0 pt-2 text-center"} style={{whiteSpace: 'nowrap'}}>m x</Col>
                            <Col xs={3} md={1} className={"p-0 pt-2 text-center"}><span className={"p-0 w-100 text-center"}>{parseFloat((this.state.values.inletCircumference || 0).toFixed(3))}</span></Col>
                            <Col md={1} className={"p-0 pt-2 text-center d-sm-none d-md-block"}>=</Col>
                            <Col xs={11} md={1} className={"p-0 pt-2 text-center"}><span className={"p-0 w-100 text-center"}>{parseFloat((this.state.values.inletTotal || 0).toFixed(3))}</span></Col>
                            <Col xs={1} md={1} className={"p-0 pt-2 text-center"}>m²</Col>
                        </Row>
                        <Row className={"mt-sm-4"}>
                            <Col xs={12} md={4} className={"p-0 pt-2 font-weight-bold"} style={{whiteSpace: 'nowrap'}}>{getTranslation("Lactoduc outlet", UCLocale)}</Col>
                            <Col xs={3} md={1} className={"p-0 text-center"}><FormControl className={"p-0 w-100 text-center"} onChange={this.handleOnChange} data-key="outletDiameter" placeholder="dia" aria-label={getTranslation("Diameter", UCLocale)} value={this.state.values.outletDiameter}></FormControl></Col>
                            <Col xs={1} md={1} className={"p-0 pt-2 text-center"}>Ø</Col>
                            <Col xs={3} md={1} className={"p-0 text-center"}><FormControl className={"p-0 w-100 text-center"} onChange={this.handleOnChange} data-key="outletLength" placeholder="len" aria-label={getTranslation("Length", UCLocale)} value={this.state.values.outletLength}></FormControl></Col>
                            <Col xs={1} md={1} className={"p-0 pt-2 text-center"} style={{whiteSpace: 'nowrap'}}>m x</Col>
                            <Col xs={3} md={1} className={"p-0 pt-2 text-center"}><span className={"p-0 w-100 text-center"}>{parseFloat((this.state.values.outletCircumference || 0).toFixed(3))}</span></Col>
                            <Col md={1} className={"p-0 pt-2 text-center d-sm-none d-md-block"}>=</Col>
                            <Col xs={11} md={1} className={"p-0 pt-2 text-center"}><span className={"p-0 w-100 text-center"}>{parseFloat((this.state.values.outletTotal || 0).toFixed(3))}</span></Col>
                            <Col xs={1} md={1} className={"p-0 pt-2 text-center"}>m²</Col>
                        </Row>
                        <Row className={"mt-sm-4"}>
                            <Col xs={12} md={4} className={"p-0 pt-2 font-weight-bold"} style={{whiteSpace: 'nowrap'}}>{getTranslation("Clusters", UCLocale)}</Col>
                            <Col xs={3} md={1} className={"p-0 pt-2 text-center"}></Col>
                            <Col xs={1} md={1}></Col>
                            <Col xs={3} md={1} className={"p-0 pt-2 text-center"}><span className={"p-0 w-100 text-center"}>{this.context.currentQuote.generalData.clusters}</span></Col>
                            <Col xs={1} md={1} className={"p-0 pt-2 text-center"}>x</Col>
                            <Col xs={3} md={1} className={"p-0 pt-2 text-center"}><span className={"p-0 w-100 text-center"}>{parseFloat((this.state.values.clustersCircumference || 0).toFixed(3))}</span></Col>
                            <Col md={1} className={"p-0 pt-2 text-center d-sm-none d-md-block"}>=</Col>
                            <Col xs={11} md={1} className={"p-0 pt-2 text-center"}><span className={"p-0 w-100 text-center"}>{parseFloat((this.state.values.clustersTotal || 0).toFixed(3))}</span></Col>
                            <Col xs={1} md={1} className={"p-0 pt-2 text-center"}>m²</Col>
                        </Row>
                        <Row className={"mt-sm-4"}>
                            <Col xs={12} md={4} className={"p-0 pt-2 font-weight-bold"} style={{whiteSpace: 'nowrap'}}>{getTranslation("Measuring containers", UCLocale)}</Col>
                            <Col xs={3} md={1}></Col>
                            <Col xs={1} md={1}></Col>
                            <Col xs={3} md={1} className={"p-0 text-center"}><FormControl className={"p-0 w-100 text-center"} onChange={this.handleOnChange} data-key="measuringContainersQuantity" placeholder="qty" aria-label={getTranslation("Quantity", UCLocale)} value={this.state.values.measuringContainersQuantity}></FormControl></Col>
                            <Col xs={1} md={1} className={"p-0 pt-2 text-center"}>x</Col>
                            <Col xs={3} md={1} className={"p-0 pt-2 text-center"}><span className={"p-0 w-100 text-center"}>{parseFloat((this.state.values.measuringContainersCircumference || 0).toFixed(3))}</span></Col>
                            <Col md={1} className={"p-0 pt-2 text-center d-sm-none d-md-block"}>=</Col>
                            <Col xs={11} md={1} className={"p-0 pt-2 text-center"}><span className={"p-0 w-100 text-center"}>{parseFloat((this.state.values.measuringContainersTotal || 0).toFixed(3))}</span></Col>
                            <Col xs={1} md={1} className={"p-0 pt-2 text-center"}>m²</Col>
                        </Row>
                        <Row className={"mt-sm-4"}>
                            <Col xs={12} md={4} className={"p-0 pt-2 font-weight-bold"} style={{whiteSpace: 'nowrap'}}>{getTranslation("Outlet Chamber", UCLocale)}</Col>
                            <Col xs={3} md={1} className={"p-0 pt-2 text-center d-sm-none d-md-block"}></Col>
                            <Col xs={1} md={1}></Col>
                            <Col xs={3} md={1} className={"p-0 pt-2 text-center d-sm-none d-md-block"}></Col>
                            <Col xs={1} md={1}></Col>
                            <Col xs={3} md={1}></Col>
                            <Col md={1} className={"d-sm-none d-md-block"}></Col>
                            <Col xs={11} md={1} className={"p-0 pt-2 text-center"}><span className={"p-0 w-100 text-center"}>{parseFloat((this.state.values.outletChamberTotal || 0).toFixed(3))}</span></Col>
                            <Col xs={1} md={1} className={"p-0 pt-2 pt-2 text-center"}>m²</Col>
                        </Row>

                        <Row className={"mt-sm-4"}>
                            <Col className={"font-weight-bold text-right"}>{sprintf("%1$f m² (whole surface) x %2$f ℓ ☛ %3$f ℓ of water", parseFloat(this.state.values.total.toFixed(3)), 7.5, Math.round(this.state.values.total*7.5))}</Col>
                        </Row>
                    </Container>
                </ModalBody>
                <ModalFooter>
                    <Button variant="secondary" onClick={this.handleCloseButtonClick}>{getTranslation("Annuler", UCLocale)}</Button>
                    <Button variant="primary" onClick={this.handleOKButtonClick}>{getTranslation("Ok", UCLocale)}</Button>
                </ModalFooter>
            </Modal>
        );
    }
}

Lactoduc.contextType = QuoteContext;
