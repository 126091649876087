import React from "react";
import {UserContext} from "../../contexts/UserContext";
import {convertObjectToEntity} from "../../model/Entity";

export default class ProductFormatDescription extends React.Component {
    render() {
        let format = convertObjectToEntity(this.props.quoteProduct.getFormat());
        let formatString = format.getTranslation({fieldName: 'label', locale: this.context.user.locale});
        if (!formatString && format.unit) {
            const unit = this.context.unit.data[format.unit];
            if (unit) {
                formatString = unit.getTranslation({fieldName: 'name', locale: this.context.user.locale})
            }
        } else if (!formatString) {
            formatString = '';
        }

        if (!this.props.quoteProduct.showEstimateAndUnit()) {
            formatString = '';
        }

        return (
            <div className="cost-box">
                <div className="cost-text">
                    {formatString} x
                </div>
            </div>
        );
    }
}

ProductFormatDescription.contextType = UserContext;