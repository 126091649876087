import React from "react";
import infoImg from "../../assets/images/info.png";
import {getTranslation} from "../tools/Translation";
import {UserContext} from "../../contexts/UserContext";

export default class GlobalCostPer1000LMilk extends React.Component {
    render() {
        return (
            <div className="see-product-details milk">
                <img src={infoImg} alt="Info" className="info" />
                {getTranslation('print.cost1000lmilk', this.context.user.locale)}: { this.props.currentQuote.getCostPer1000LMilk(this.context.category.data, this.props.currentQuote.generalData['milkproductionperyear'] || 0, this.props.currentQuote.generalData['numberofanimals'] || 0).toFixed(2) } {this.props.currentQuote.getCurrencySign()}
            </div>
        )
    }
}

GlobalCostPer1000LMilk.contextType = UserContext;