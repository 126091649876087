import React from 'react';
import {QuoteContext} from "../../contexts/QuoteContext";
import {getTranslation} from "../tools/Translation";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSearch} from "@fortawesome/free-solid-svg-icons";
import {Form, InputGroup, ListGroup} from "react-bootstrap";
import SearchResult from "./SearchResult";

export default class SearchBar extends React.Component {

    minimumSearchLength = 1;

    constructor(props) {
        super(props);
        this.searchField = React.createRef();
    }

    state = {searchOpened: false, results: [], searchValue: ""}

    focusSearchField = () => this.searchField.current.focus();

    handleEscKey = (e) => {
        if (this.state.searchOpened && e.keyCode === 27) {
            this.handleToggleSearch();
        }
    }

    handleClick = () => {
        if (this.state.searchOpened) {
            this.handleToggleSearch();
        }
    }


    componentDidMount() {
        document.addEventListener('keyup', this.handleEscKey, false);
    }

    handleToggleSearch = () => {
        let nextState = !this.state.searchOpened;
        this.setState({searchOpened: nextState}, () => {
            if (nextState) {
                this.focusSearchField();
            } else {
                this.handleEmptyField();
            }
        });
    }

    handleEmptyField = () => this.setState({searchValue: "", results: []});

    handleKeyUp = (e) => this.setState({searchValue: e.target.value});

    getOrderedProductList = (list) => list.sort((a, b) => (a.name.trim().toLowerCase() > b.name.trim().toLowerCase()) ? 1 : -1);

    doSearch = (e) => {
        const searchValue = e.target.value.toLowerCase();
        const UC = this.context.currentQuote.getUserContext();
        const availableProducts = UC.user.categoryProducts[1];
        let productList = [];
        let searchResults = [];

        if (searchValue.length >= this.minimumSearchLength) {
            availableProducts.forEach((productId) => {
                const product = UC.product.data[productId];
                if (product.name.toLowerCase().includes(searchValue) || product.getTranslation({fieldName: 'description', locale: UC.user.locale}).toLowerCase().includes(searchValue)) {
                    productList.push(product);
                }
            })
        }
        let orderedProductList = this.getOrderedProductList(productList);
        orderedProductList.forEach((product) => {
            searchResults.push(<SearchResult key={product.id} product={product} toggleSearch={this.handleToggleSearch} emptyField={this.handleEmptyField}/>)
        })
        this.setState({results: searchResults})
    }

    render() {
        return (
            <>
                <FontAwesomeIcon size={"lg"} className={"search-icon " + (this.state.searchOpened ? "light" : "")} icon={faSearch} onClick={this.handleToggleSearch}/>
                <div className="search-wall" onClick={this.handleClick} style={{display: (this.state.searchOpened ? 'block' : 'none')}}></div>
                <div className={"search-box" + (this.state.searchOpened ? " opened" : "")}>
                    <Form.Group style={{marginBottom: 0}}>
                        <InputGroup>
                            <InputGroup.Prepend>
                                <InputGroup.Text>
                                    <FontAwesomeIcon size={"lg"} icon={faSearch}/>
                                </InputGroup.Text>
                            </InputGroup.Prepend>
                            <Form.Control ref={this.searchField} value={this.state.searchValue} onChange={this.handleKeyUp} type="text" placeholder={getTranslation("searchbar.search", this.context.currentQuote.getUserContext().user.locale)} onKeyUp={this.doSearch}/>
                        </InputGroup>
                    </Form.Group>
                    <ListGroup className="search-box-result">{this.state.results}</ListGroup>
                </div>
            </>
        );
    }
}

SearchBar.contextType = QuoteContext;
