import React from "react";
import addArrow from "../../assets/images/plus-white.svg";
import removeArrow from "../../assets/images/less-white.svg";
import arrow from "../../assets/images/packarrow.svg";
import {QuoteContext} from "../../contexts/QuoteContext";
import {getTranslation} from "../tools/Translation";
import ProductOffer from "./ProductOffer";
import ProductFormatDescription from "./ProductFormatDescription";
import ProductPrice from "./ProductPrice";
import ProductTotal from "./ProductTotal";
import NumberOfMonthsToggler from "./NumberOfMonthsToggler";

export default class ProductPricing extends React.Component {

    addRemoveQuantityHandler = (e, type) => {
        e.preventDefault();
        if (type === 'up' && this.props.productData) {
            this.props.productData.addQuantity();
        } else if (type === 'down' && this.props.productData) {
            this.props.productData.removeQuantity();
        }
    }

    loanClickHandler = () => {
        this.props.productData.toggleLoan();
        if (this.props.productData.loaned) {
            this.props.productData.removeDiscount();
        }
    }


    render() {
        let product = this.props.product;
        let user = this.props.user;

        let quoteProduct = this.props.productData;
        const unitId = product.getFormatUnit();

        let unitName = '';
        if (unitId) {
            unitName = this.context.currentQuote.app.state.userContext.unit.data[unitId].getTranslation({fieldName: 'name', locale: this.context.currentQuote.getUserContext().user.locale}) || ''
        }

        return (
            <div className="product-cost">

                <div className="flex-grow"/>

                {quoteProduct.product && quoteProduct.product.isEquipment &&
                    <>
                        <div className="loan">
                            <label>
                                <input type="checkbox" onChange={this.loanClickHandler} checked={quoteProduct.loaned} />
                                <span>{getTranslation("equipment.loan", user.locale)}</span>
                            </label>
                        </div>
                    </>
                }

                {!quoteProduct.inSpray && quoteProduct.showEstimateAndUnit() &&
                    <>
                        <div className="cost-box">
                            {!quoteProduct.hasNumberOfMonthsInCalculation() && !product.isEquipement &&
                                <NumberOfMonthsToggler locale={user.locale} />
                            }
                            <div className="cost-title">
                                {getTranslation("cost.estimated", user.locale)}
                            </div>
                            <div className="fake-input quantity">{quoteProduct.estimatedConsumption}</div>
                        </div>
                        {unitName &&
                            <div className="cost-box">
                                <div className="cost-text">
                                    {unitName}
                                </div>
                            </div>
                        }
                        <div className="cost-box arrow">
                            <div className="cost-text">
                                <a href="." onClick={(e) => {e.preventDefault()}}><img src={arrow} alt="" /></a>
                            </div>
                        </div>
                    </>
                }
                <div className="cost-box add-remove">
                    <div className="cost-text">
                        <a href="." className="add" onClick={(e) => {this.addRemoveQuantityHandler(e, 'up')}}><img src={addArrow} alt="" /></a>
                        <a href="." className="remove" onClick={(e) => {this.addRemoveQuantityHandler(e, 'down')}}><img src={removeArrow} alt="" /></a>
                    </div>
                </div>
                <div className="cost-box">
                    <div className="cost-title">
                        {getTranslation("cost.offer", user.locale)}
                    </div>
                    <ProductOffer quoteProduct={quoteProduct}/>
                </div>

                <div className="flex-break d-sm-none"/>

                <div className="flex-grow d-sm-none">&nbsp;</div>

                <ProductFormatDescription quoteProduct={quoteProduct}/>

                <ProductPrice quoteProduct={quoteProduct}/>

                <ProductTotal quoteProduct={quoteProduct} currency={this.context.currentQuote.getCurrencySign()} />
            </div>
        )
    }
}

ProductPricing.contextType = QuoteContext;