import React from "react";
import {QuoteContext} from "../../contexts/QuoteContext";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSave} from "@fortawesome/free-solid-svg-icons";
import QuoteSync from "../tools/QuoteSync";

export default class QuoteSave extends React.Component {

    handleQuoteSave = () => {
        if (this.context.currentQuote.isModified) {
            this.context.currentQuote.saveContent();

            // Push to the backend
            let quoteSync = new QuoteSync();
            quoteSync.uploadQuotes();
        }
    }

    render() {
        return (
            <FontAwesomeIcon onClick={this.handleQuoteSave} className={"file-save" + (!this.context.currentQuote.isModified ? " light" : "")} size={"lg"} icon={faSave}/>
        )
    }
}

QuoteSave.contextType = QuoteContext;
