import React from "react";
import {UserContext} from "../../contexts/UserContext";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCaretUp} from "@fortawesome/free-solid-svg-icons";
import {getTranslation} from "../tools/Translation";

export default class Calculation extends React.Component {
    componentDidMount() {
        // When opening a Quote, don't recalculate at load because
        // we already have the estimatedConsumption.
        if (!this.props.productData.estimatedConsumption) {
            this.props.productData.setEstimatedConsumption(this.props.calculation.getTotal());
        }
    }

    applicationTypeClickHandler = () => {
        // Get the next applicationType and set it as the current one
        this.props.productData.setEstimatedConsumptionObject(this.props.productData.getNextEstimatedConsumptionObject(this.props.category.id));
        this.props.calculation.updateEstimatedConsumption(this.props.currentQuote, this.props.category.id);
    }

    fodderCalculatorClickHandler = () => {
        this.props.currentQuote.setSelectedProductData(this.props.productData);
        this.props.currentQuote.showCalculatorValorisation();
    }

    lactoducCalculatorClickHandler = () => {
        this.props.currentQuote.setSelectedProductData(this.props.productData);
        this.props.currentQuote.showCalculatorLactoduc();
    }

    protocolCalculatorClickHandler = () => {
        this.props.currentQuote.setSelectedProductData(this.props.productData);
        this.props.currentQuote.showCalculatorProtocol();
    }

    render() {
        const applicationType = this.props.productData.applicationType;
        let applicationTypeLabel = '';
        if (applicationType) {
            if (this.context.applicationtype && this.context.applicationtype.data && this.context.applicationtype.data[applicationType]) {
                applicationTypeLabel = this.context.applicationtype.data[applicationType].getTranslation({fieldName: "name", locale: this.context.user.locale});
                if (applicationTypeLabel) {
                    applicationTypeLabel = applicationTypeLabel.replace(' ', '<br/>');
                }
            }
        }

        if (!this.context.formulavariable.data) {
            return null;
        }

        let useFodderCalculator = false;
        const consumption = this.props.productData.getEstimatedConsumptionObject();
        if (consumption && consumption.consumptionType === consumption.numberOfDoses) {
            useFodderCalculator = true;
        }

        let useLactoducCalculator = false;
        if (consumption && consumption.consumptionType === consumption.waterVolumeTrayor) {
            useLactoducCalculator = true;
        }

        let useProtocolCalculator = consumption && consumption.consumptionType === consumption.footbathVolumeCrossingMonths;

        const parts = this.props.calculation.getParts(this.props.currentQuote, this.context);

        if (parts && parts.length) {
            return (
                <div className="product-formula" style={this.props.category.color ? {backgroundColor: this.props.category.color} : null}>
                    {parts.map((part, i) => {
                        const Tag = part.type;
                        return (
                            <Tag calculationPart={part} productData={this.props.productData} product={this.props.product} key={i}/>
                        )
                    })}
                    {applicationType &&
                    <div className="spray-type pointer" onClick={this.applicationTypeClickHandler}>
                        <span dangerouslySetInnerHTML={{__html: applicationTypeLabel}}/>
                    </div>
                    }
                    {useFodderCalculator &&
                    <div className="spray-type pointer flex-column" onClick={this.fodderCalculatorClickHandler}>
                        <FontAwesomeIcon icon={faCaretUp}/>
                        {getTranslation('details', this.context.user.locale)}
                    </div>
                    }
                    {useLactoducCalculator &&
                    <div className="spray-type pointer flex-column" onClick={this.lactoducCalculatorClickHandler}>
                        <FontAwesomeIcon icon={faCaretUp}/>
                        {getTranslation('Lactoduc', this.context.user.locale)}
                    </div>
                    }
                    {useProtocolCalculator &&
                    <div className="spray-type pointer flex-column" onClick={this.protocolCalculatorClickHandler}>
                        <FontAwesomeIcon icon={faCaretUp}/>
                        {getTranslation('Protocol', this.context.user.locale)}
                    </div>
                    }
                </div>
            )
        }

        return null;
    }
}

Calculation.contextType = UserContext;
