import {ProductCalculation} from "./ProductCalculation";
import ProductCalculationPart from "./ProductCalculationPart";

export default class QuoteInitializer {
    init(quote, context) {
        const products = context.product.data || {};
        const formulaVariables = context.formulavariable.data || {};
        Object.keys(quote.categories).forEach((categoryId) => {
            Object.keys(quote.categories[categoryId]).forEach((productId) => {
                if (products[productId]) {
                    quote.categories[categoryId][productId].product = products[productId];
                    quote.categories[categoryId][productId].quote = quote;

                    if (quote.categories[categoryId][productId].calculation) {
                        quote.categories[categoryId][productId].calculation = new ProductCalculation(quote.categories[categoryId][productId].calculation);
                        quote.categories[categoryId][productId].calculation.product = products[productId];
                        quote.categories[categoryId][productId].calculation.parts.forEach((part, i) => {
                            part.dontParse = true;
                            part = new ProductCalculationPart(part);
                            part.dontParse = false;
                            part.product = products[productId];
                            part.currentQuote = quote;
                            part.formulaVariables = formulaVariables;
                            part.setFormulaVariable();

                            quote.categories[categoryId][productId].calculation.parts[i] = part;
                        });
                    }
                }
            });
        });
    }
}