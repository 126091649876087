import React, {useContext, useState} from "react";
import {Button, Form, Modal} from "react-bootstrap";
import {getTranslation} from "./Translation";
import {UserContext} from "../../contexts/UserContext";

export default function RenameModal(props) {
    const userContext = useContext(UserContext);
    const [name, setName] = useState(props.defaultValue ?? '');

    return (
        <Modal show={true}
               backdrop="static"
               keyboard={false}
               size="md"
               centered>
            <Modal.Header>
                <Modal.Title>{getTranslation('renameQuote.title', userContext.user.locale)}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form.Control as={"input"} value={name} onChange={(e) => setName(e.target.value)}/>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => props.closeModal()}>{getTranslation('renameQuote.actionCancel', userContext.user.locale)}</Button>
                <Button variant="primary" onClick={() => {props.setName(name); props.closeModal()}}>{getTranslation('renameQuote.actionRename', userContext.user.locale)}</Button>
            </Modal.Footer>
        </Modal>
    );
}