import React from "react";
import Accordion from 'react-bootstrap/Accordion';
import {Card} from "react-bootstrap";
import EquipmentForm from "./EquipmentForm";

export default function Equipments(props) {
    return (
        <Accordion className="mt-3">
            {props.quote.equipments.map((equipment, i) => {
                return (
                    <Card key={i}>
                        <Accordion.Toggle as={Card.Header} eventKey={i+1} className={"bg-primary text-white"}>
                            {equipment.name}
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey={i+1}>
                            <Card.Body>
                                <EquipmentForm {...props} equipment={equipment} />
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                )
            })}
        </Accordion>
    )
}