import React from "react";
import {UserContext} from "../../contexts/UserContext";
import {getTranslation} from "../tools/Translation";
import classNames from "classnames";

export default class Product extends React.Component {
    render() {
        const product = this.props.product;
        const productData = this.props.productData;
        const currentQuote = this.props.currentQuote;
        return (
            <div className={classNames({
                                "preview-box-wrapper": true,
                                "clearfix": true,
                                "no-discount": !currentQuote.quoteHasDiscount(),
                                "no-costperanimal": !currentQuote.quoteHasCostPerAnimalPerYear(this.context.category.data) || !currentQuote.settings['options.costpercowpermonth']
                            })}>
                <div className="preview-box product-title">
                    <div className="box-text">
                        { product.name }{product.isBiocide ? '*' : ''}
                    </div>
                </div>
                <div className="preview-box product-consumption">
                    <div className="box-text">
                        {currentQuote.categoryHasCalculation(this.props.category) && productData.calculation ? productData.calculation.getFormula(this.context) : <span>&nbsp;</span>}
                    </div>
                </div>
                {currentQuote.quoteHasCostPerAnimalPerYear(this.context.category.data) && currentQuote.settings['options.costpercowpermonth'] &&
                    <div className="preview-box product-consumption-per-animal">
                        <div className="box-text">
                            {currentQuote.categoryHasCostPerAnimalPerYear(this.context.category.data, this.props.category) ? productData.getCostPerAnimalPerMonth().toFixed(2) + ' ' + currentQuote.getCurrencySign() : <span>&nbsp;</span>}
                        </div>
                    </div>
                }
                <div className="preview-box product-offer">
                    <div className="box-text">
                        {!product.isEquipment && productData.format && productData.estimatedConsumption && productData.getTotalProductQuantity() ? productData.getTotalProductQuantity() + ' ' + productData.getUnitName(this.context) + ' (' + productData.quantity + ' x ' + productData.getFormat().name + ' ' + productData.getUnitName(this.context) + ')' : <span>&nbsp;</span>}
                    </div>
                </div>
                <div className="preview-box product-price">
                    <div className="box-text">
                        {parseFloat(productData.price).toFixed(2)} {currentQuote.getCurrencySign()}
                    </div>
                </div>
                <div className="preview-box product-price">
                    <div className="box-text">
                        {parseFloat(productData.totalWithoutDiscount).toFixed(2)} {currentQuote.getCurrencySign()}
                    </div>
                </div>
                {currentQuote.quoteHasDiscount() &&
                    <div className="preview-box product-price">
                        <div className={"box-text" + (productData.discount || productData.loaned ? " discount" : "")}>
                            {productData.discount ? parseFloat(productData.discount).toFixed(2) + ' %' : (productData.loaned ? getTranslation('print.loan', this.context.user.locale) : <span>&nbsp;</span>)}
                        </div>
                    </div>
                }
                <div className="preview-box product-price">
                    <div className="box-text">
                        {parseFloat(productData.total).toFixed(2)} {currentQuote.getCurrencySign()}
                    </div>
                </div>
            </div>
        );
    }
}

Product.contextType = UserContext;