import {config} from "../components/config/Config";
import _ from "lodash";
import {Apps} from "./Apps";

export default class User {

    constructor(data) {
        this.userProvider = null;
        this.species = 1;
        this.locale = "fr";
        this.country = 2;
        this.categories = [];
        this.categoryProducts = {};
        this.products = [];
        this.productFormats = [];
        this.productGroup = null;
        this.currency = 1;
        this.surfaces = [];
        this.apps = [];

        if (data) {
            Object.assign(this, data);
        }
    }

    setLocale(locale, currentQuote, dontPersistToState) {
        this.locale = locale;

        if (currentQuote) {
            currentQuote.setLocale(locale);
        }

        if (!dontPersistToState) {
            this.updateContextState();
        }
    }

    setCountry(country, currentQuote, dontPersistToState) {
        this.country = country;

        if (currentQuote) {
            currentQuote.setCountry(country);
        }

        if (!dontPersistToState) {
            this.updateContextState();
            this.userProvider.parseData(null, null, true);
        }
    }

    setSpecies(species, currentQuote, dontPersistToState) {
        this.species = species;

        if (currentQuote) {
            currentQuote.setSpecies(species);
        }

        if (!dontPersistToState) {
            this.updateContextState();
            this.userProvider.parseData(null, null, true);
        }
        this.setAppBackground(species);
    }

    setAppBackground(value) {
        document.body.classList.remove('bg-1', 'bg-2', 'bg-3', 'bg-app-2', 'bg-app-3');
        document.body.classList.add(`bg-${value}`);
    }

    setProductGroup(productGroup, dontPersistToState) {
        this.productGroup = productGroup;
        if (!dontPersistToState) {
            this.updateContextState();
            this.userProvider.parseData(null, null, true);
        }
    }

    getProductGroup() {
        return this.productGroup;
    }

    setCategories(categories, dontPersistToState) {
        this.categories = categories;
        if (!dontPersistToState) {
            this.updateContextState();
        }
    }

    setCategoryProducts(categoryProducts, dontPersistToState) {
        this.categoryProducts = categoryProducts;
        if (!dontPersistToState) {
            this.updateContextState();
        }
    }

    setProducts(products, dontPersistToState) {
        this.products = products;
        if (!dontPersistToState) {
            this.updateContextState();
        }
    }

    setProductFormats(productFormats, dontPersistToState) {
        this.productFormats = productFormats;
        if (!dontPersistToState) {
            this.updateContextState();
        }
    }

    setCurrency(currency, currentQuote, dontPersistToState) {
        this.currency = currency;

        if (currentQuote) {
            currentQuote.setCurrency(currency);
        }

        if (!dontPersistToState) {
            this.updateContextState();
        }
    }

    setSurfaces(surfaces, dontPersistToState) {
        this.surfaces = surfaces;
        if (!dontPersistToState) {
            this.updateContextState();
        }
    }

    setDataFromQuote(quote) {
        this.setCurrency(quote.currency, null, true);
        this.setLocale(quote.locale, null, true);
        this.setCountry(quote.country, null, true);

        if (quote.application === Apps.Farm) {
            this.setSpecies(quote.species, null, true);
        }

        this.updateContextState(() => {
            this.userProvider.parseData(null, null, true);
        });
    }

    updateContextState(callback) {
        this.userProvider.setState((state) => ({
            userContext: Object.assign(state.userContext, {user: this})
        }), callback);
        this.persistToLocalStorage();
    }

    persistToLocalStorage() {
        // To not stringify the this.userProvider instance
        let userData = _.cloneDeep(this)
        delete userData.userProvider;

        localStorage.setItem(config.userParamName, JSON.stringify(userData));
    }
}
