import React, {createRef} from "react";
import {QuoteContext} from "../../contexts/QuoteContext";
import $ from 'jquery';

export default class CustomerWidget extends React.Component {
    constructor(props) {
        super(props);
        this.closeButton = createRef();
    }

    onHover = () => {
        $(this.closeButton.current).css('opacity', 1);
    }

    onBlur = () => {
        $(this.closeButton.current).css('opacity', 0);
    }

    onClick = () => {
        this.context.currentQuote.setSetting('options.customerwidget', false);
    }

    render() {
        if (!this.props.userContext.species.data || !this.props.userContext.species.data[this.context.currentQuote.species]) {
            return null;
        }

        const species = this.props.userContext.species;
        const currentSpecies = this.context.currentQuote.species;

        return (
            <div id="customer-widget" onMouseEnter={this.onHover} onMouseLeave={this.onBlur}>
                <div id="customer-widget-close" ref={this.closeButton} className="tooltip" onClick={this.onClick}/>
                <div className="top-box-wrapper clearfix">
                    {species.data[currentSpecies].options.map((option) => {
                        if (option.displayOnTop === true && option.widgetImage && (option.name !== 'roboticsystems' || (option.name === 'roboticsystems' && parseInt(this.context.currentQuote.generalData[option.name]) > 0))) {
                            return (
                                <div className="top-box" key={option.name}>
                                    <div className="widget-img" style={{backgroundImage: "url(" + option.widgetImage + ")"}}/>
                                    <div className="widget-quantity">
                                        { this.context.currentQuote.generalData[option.name] }
                                    </div>
                                </div>
                            );
                        }
                        return null;
                    })}
                </div>
                {species.data[currentSpecies].options.map((option) => {
                    if (option.displayOnTop === false && this.context.currentQuote.generalData[option.name] && option.getTranslation({fieldName: 'widgetName', locale: this.context.currentQuote.locale})) {
                        return (
                            <div className="bottom-box" key={option.name}>
                                {option.getTranslation({fieldName: 'widgetName', locale: this.context.currentQuote.locale})} :&nbsp;
                                 { this.context.currentQuote.generalData[option.name] }
                            </div>
                        );
                    }
                    return null;
                })}
            </div>
        );
    }
}

CustomerWidget.contextType = QuoteContext;