import React from "react";
import {QuoteContext} from "../../contexts/QuoteContext";
import {Modal, ModalBody, ModalTitle} from "react-bootstrap";
import ModalHeader from "react-bootstrap/ModalHeader";
import {UserContext} from "../../contexts/UserContext";
import MosaicProduct from "./MosaicProduct";
import {Grid} from "@material-ui/core";
import {getTranslation} from "../tools/Translation";

export default class Mosaic extends React.Component {

    handleHide = () => this.context.currentQuote.hideMosaic();

    render() {
        return (
            <UserContext.Consumer>
                {({user, product}) => {
                    if (!user.categoryProducts || Object.keys(user.categoryProducts).length === 0) {
                        return null;
                    }
                    let productCount = Object.keys(user.categoryProducts[1]).length;
                    return (
                        <Modal className="modaic-modal-wrapper" show={true} backdrop='static' dialogClassName="mosaic-modal modal-90w" keyboard={false} onHide={this.handleHide}>
                            <ModalHeader closeButton>
                                <ModalTitle>
                                    <span className="mosaic-count">{productCount}</span>
                                    <span className="mosaic-count label"> {getTranslation(productCount <= 1 ? "product" : "products", user.locale)}</span>
                                </ModalTitle>
                            </ModalHeader>
                            <ModalBody>
                                <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={2}>
                                    {
                                        user.categoryProducts[1].map((productId, index) => {
                                            if (product && product.data && product.data[productId]) {
                                                return <MosaicProduct product={product.data[productId]} key={productId}/>
                                            }
                                            return null;
                                        })
                                    }
                                </Grid>
                            </ModalBody>
                        </Modal>
                    )
                }}
            </UserContext.Consumer>
        );
    }
}

Mosaic.contextType = QuoteContext;