import {Row, Col, Form} from "react-bootstrap";
import React, {Component} from "react";
import {UserContext} from "../../contexts/UserContext";
import {QuoteContext} from "../../contexts/QuoteContext";

export default class GeneralDataTopOption extends Component {

    constructor(props) {
        super(props);
        this.handleMouseHover = this.handleMouseHover.bind(this);
        this.handlePlusClick = this.handlePlusClick.bind(this);
        this.handleMinusClick = this.handleMinusClick.bind(this);
        this.handleResetClick = this.handleResetClick.bind(this);
        this.handleValueChange = this.handleValueChange.bind(this);

        this.state = {
            isHovering: false
        };
    }

    UNSAFE_componentWillMount() {
        let value = this.context.currentQuote.generalData[this.props.option.name];
        if (value === undefined) {
            value = this.props.option.defaultValue || '';
            this.setGeneralDataValue(this.props.option.name, value);
        }
    }

    handleMouseHover() {
        this.setState(this.toggleHoverState);
    }

    toggleHoverState(state) {
        return {
            isHovering: !state.isHovering
        };
    }

    setGeneralDataValue(key, value) {
        this.context.currentQuote.generalData[key] = value;

        if (this.props.option.name === 'roboticsystems') {
            this.context.currentQuote.updateRoboticSystem( value > 0 );
        }
        this.context.currentQuote.updateContextState();
        this.context.currentQuote.refreshCalculationGeneralData(key);
    }

    handlePlusClick() {
        const key = this.props.option.name;
        const og = this.context.currentQuote.generalData[key] || this.props.option.defaultValue;
        const ng = parseInt(og) + 1;
        this.setGeneralDataValue(key, ng);
    }

    handleMinusClick() {
        const key = this.props.option.name;
        let ov = this.context.currentQuote.generalData[key];
        if (ov === undefined) {
            ov = this.props.option.defaultValue;
        }
        let nv = parseInt(ov) - 1;
        if (nv <= 0) {
            nv = 0;
        }
        this.setGeneralDataValue(key, nv);
    }

    handleResetClick() {
        this.setGeneralDataValue(this.props.option.name, this.props.option.defaultValue);
    }

    handleValueChange(e) {
        let value = e.target.value;
        if (parseInt(value) <= 0 || isNaN(value)) {
            value = 0;
        }
        this.setGeneralDataValue(this.props.option.name, value);
    }

    render() {
        return (
            <UserContext.Consumer>
                {({user}) => {
                    const value = this.context.currentQuote.generalData[this.props.option.name] ?? '';
                    return (
                        <Col className={"gd-to"} onMouseOver={this.handleMouseHover} onMouseOut={this.handleMouseHover}>
                            <Row className={"no-gutters"}>
                                <Col><Form.Control className={(parseInt(value) <= 0) ? 'empty' : ''} as={"input"} value={value} onChange={this.handleValueChange}/></Col>
                            </Row>
                            <Row className={"no-gutters"}>
                                <Col>
                                    <div className={"thumbnail c" + this.props.topCount}>
                                        <div className="controls mediumtransition" data-html2canvas-ignore="" style={{'opacity': this.state.isHovering ? 1 : 0}}>
                                            <div className="pluscontrol" onClick={this.handlePlusClick}/>
                                            <div className="lesscontrol" onClick={this.handleMinusClick}/>
                                            <div className="reset" onClick={this.handleResetClick}/>
                                        </div>
                                        <img src={this.props.option.image} alt={this.props.option.getTranslation({fieldName: 'name', locale: user.locale})}/>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    )
                }}
            </UserContext.Consumer>
        )
    }
}

GeneralDataTopOption.contextType = QuoteContext;
