import {Container, Col, Form, Row, Button, Card} from "react-bootstrap";
import React, {Component} from "react";
import {UserContext} from "../../contexts/UserContext";
import {getTranslation} from "../tools/Translation";
import GeneralDataTopOption from "./GeneralDataTopOption";
import GeneralDataBottomOption from "./GeneralDataBottomOption";
import {QuoteContext} from "../../contexts/QuoteContext";
import QuoteList from "./QuoteList";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faClone} from "@fortawesome/free-solid-svg-icons";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

export default class GeneralData extends Component {

    datepickerLocales = {};
    datepickerLocalesLoaded = false;

    constructor(props, context) {
        super(props, context);
        this.handleOnChange = this.handleOnChange.bind(this);
        this.handleClone = this.handleClone.bind(this);
        this.loadDatepickerLocales();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.loadDatepickerLocales();
    }

    handleClone(u) {
        let clone = this.context.currentQuote.getClone(u);
        this.context.availableQuotes.push(clone);
        this.context.currentQuote = clone;
        this.context.currentQuote.setCurrent();
    }

    handleOnChange(e) {
        let key = e.target.id;
        let value = e.target.value;
        this.setGeneralDataValue(key, value);
    }

    setGeneralDataValue(key, value) {
        this.context.currentQuote.generalData[key] = value;
        this.context.currentQuote.updateContextState();
        // Not used in calculations
        // this.context.currentQuote.refreshCalculationGeneralData(key);
    }

    loadDatepickerLocales = () => {
        if (!this.datepickerLocalesLoaded) {
            if (this.props.userContext.language && this.props.userContext.language.data) {
                if (Object.keys(this.props.userContext.language.data).length > 0) {
                    this.datepickerLocalesLoaded = true;
                    Object.keys(this.props.userContext.language.data).forEach((localeId) => {
                        if (this.props.userContext.language.data[localeId].localeValue) {
                            this.getDatepickerLocale(this.props.userContext.language.data[localeId].localeValue, this.props.userContext.language.data[localeId].fullLocale || this.props.userContext.language.data[localeId].localeValue);
                        }
                    });
                }
            }
        }
    }

    getDatepickerLocale = (locale, fullLocale) => {
        this.datepickerLocales[locale] = require(`date-fns/locale/${fullLocale}/index.js`);
    }

    render() {
        return (
            <UserContext.Consumer>
                {({user, species}) => {
                    if (!species) {
                        return null;
                    }

                    //how many top options
                    let topOptionsCount = 0;
                    species.data[user.species] && species.data[user.species].options.map((o) => topOptionsCount += o.displayOnTop === true ? 1 : 0);

                    return (
                        <Container className={"gd-main"}>
                            <QuoteList/>

                            <Row>
                                <Col className={"gd-infotitle"} xs={9} sm={10}>{getTranslation("generaldata.infotitle", user.locale)}</Col>
                                <Col className={"gd-infotitle text-right"} xs={3} sm={2} style={this.context.availableQuotes.length >= 5 ? {display: 'none'} : {display: 'block'}}>
                                    <Button>
                                        <FontAwesomeIcon onClick={() => this.handleClone(user)} size={"lg"} icon={faClone}/>
                                    </Button>
                                </Col>
                            </Row>
                            <Row className={"gd-box"} style={{paddingTop: 13, paddingBottom: 13}}>
                                <Col xl={5}>
                                    <Row>
                                        <Col>
                                            <div className="custom-control custom-switch ml-1 text-primary">
                                                <input type="checkbox" className="custom-control-input" id="test-mode" checked={this.context.currentQuote.testMode} onChange={() => {this.context.currentQuote.setTestMode(!this.context.currentQuote.testMode);}} />
                                                <label className="custom-control-label" htmlFor="test-mode">{getTranslation('cs.test-mode', user.locale)}</label>
                                            </div>
                                        </Col>
                                        <Col>
                                            <div className="custom-control custom-switch ml-1 text-primary">
                                                <input type="checkbox" className="custom-control-input" id="quote-accepted" checked={this.context.currentQuote.quoteAccepted} onChange={() => {this.context.currentQuote.setQuoteAccepted(!this.context.currentQuote.quoteAccepted);}} />
                                                <label className="custom-control-label" htmlFor="quote-accepted">{getTranslation('quote-accepted', user.locale)}</label>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col><DatePicker as={"input"} id="date" className="gd-date form-control" placeholderText={getTranslation('generaldata.date', user.locale)}
                                                           onChange={(date) => this.handleOnChange({target: {id: 'date', value: date}})}
                                                           dateFormat="yyyy-MM-dd"
                                                           locale={this.datepickerLocales[this.context.currentQuote.locale] || null}
                                                           selected={this.context.currentQuote.generalData['date'] ? new Date(this.context.currentQuote.generalData['date']) : null}/></Col>
                                    </Row>
                                    <Row>
                                        <Col><Form.Control as={"input"} id="etablipar" placeholder={getTranslation('generaldata.etablipar', user.locale)}
                                                           onChange={this.handleOnChange}
                                                           value={this.context.currentQuote.generalData['etablipar'] || ""}/></Col>
                                        <Col><Form.Control as={"input"} id="nom" placeholder={getTranslation('generaldata.nom', user.locale)}
                                                           onChange={this.handleOnChange}
                                                           value={this.context.currentQuote.generalData['nom'] || ""}/></Col>
                                    </Row>
                                    <Row>
                                        <Col><Form.Control as={"input"} id="gaec" placeholder={getTranslation('generaldata.gaec', user.locale)}
                                                           onChange={this.handleOnChange}
                                                           value={this.context.currentQuote.generalData['gaec'] || ""}/></Col>
                                        <Col><Form.Control as={"input"} id="nomeleveur" placeholder={getTranslation('generaldata.nomeleveur', user.locale)}
                                                           onChange={this.handleOnChange}
                                                           value={this.context.currentQuote.generalData['nomeleveur'] || ""}/></Col>
                                    </Row>
                                    <Row>
                                        <Col><Form.Control as={"input"} id="email" placeholder={getTranslation('generaldata.email', user.locale)}
                                                           onChange={this.handleOnChange}
                                                           value={this.context.currentQuote.generalData['email'] || ""}/></Col>
                                        <Col><Form.Control as={"input"} id="telephone" placeholder={getTranslation('generaldata.telephone', user.locale)}
                                                           onChange={this.handleOnChange}
                                                           value={this.context.currentQuote.generalData['telephone'] || ""}/></Col>
                                    </Row>
                                    <Row>
                                        <Col><Form.Control as={"textarea"} id="adresse" placeholder={getTranslation('generaldata.adresse', user.locale)}
                                                           onChange={this.handleOnChange}
                                                           value={this.context.currentQuote.generalData['adresse'] || ""} rows={3}/></Col>
                                    </Row>
                                    <Row>
                                        <Col><Form.Control as={"textarea"} id="infosutiles" placeholder={getTranslation('generaldata.infosutiles', user.locale)}
                                                           onChange={this.handleOnChange}
                                                           value={this.context.currentQuote.generalData['infosutiles'] || ""} rows={6}/></Col>
                                    </Row>
                                </Col>
                                <Col className={"no-gutters"} xl={7}>
                                    <Row>
                                        {species.data[user.species] && species.data[user.species].options.map((option, index) => {
                                            if (option.displayOnTop === true && (!option.countries.length || option.countries.includes(user.country))) {
                                                return <GeneralDataTopOption option={option} key={index} topCount={topOptionsCount}/>
                                            } else {
                                                return null;
                                            }
                                        })}
                                    </Row>
                                    <Row className={"no-gutters"}>
                                        <Col className={"mb-2"}>
                                            {species.data[user.species] && species.data[user.species].options.map((option, index) => {
                                                if (option.displayOnTop !== true && (!option.countries.length || option.countries.includes(user.country))) {
                                                    return <GeneralDataBottomOption option={option} key={index}/>
                                                } else {
                                                    return null;
                                                }
                                            })}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col><Form.Control as={"textarea"} id="remarques" placeholder={getTranslation('generaldata.remarques', user.locale)}
                                                           onChange={this.handleOnChange}
                                                           value={this.context.currentQuote.generalData['remarques'] || ""}/></Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Container>
                    )
                }}
            </UserContext.Consumer>
        );
    }
}
GeneralData.contextType = QuoteContext;
