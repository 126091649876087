import moment from "moment";
import {config} from "../../components/config/Config";

export class Equipment {

    id = null;
    nom = '';
    ligne = '';
    typeObjet = '';
    entrantMatiere = '';
    circuitThermique = '';
    circuitRecetteSensible = 0;
    cycleLavage = '';
    frequenceRecettesMensuelles = '';
    debitCircuit = 25;
    maintientDesinfection = '';

    constructor(data) {
        if (!data || (data && !data.id)) {
            this.id = moment().valueOf();
        }

        if (data) {
            Object.assign(this, data);
        }
    }

    setProperty(key, value) {
        this[key] = value;
    }

    getTypeObject(context) {
        if (this.typeObjet) {
            return context.factoryobjecttype?.data[this.typeObjet];
        }

        return null;
    }

    getEntrantMatiere(context) {
        if (this.entrantMatiere) {
            return context.inputproduct?.data[this.entrantMatiere];
        }

        return null;
    }

    getCircuitThermique(context) {
        if (this.circuitThermique) {
            return context.thermalcircuit?.data[this.circuitThermique];
        }

        return null;
    }

    getCycleLavage(context) {
        if (this.cycleLavage) {
            return context.recipe?.data[this.cycleLavage];
        }

        return null;
    }

    getTypeCircuit(context) {
        if (this.getTypeObject(context) && this.getEntrantMatiere(context) && this.getCircuitThermique(context)) {
            const notation = parseFloat(this.getTypeObject(context).circuitValue) + parseFloat(this.getEntrantMatiere(context).circuitValue) + parseFloat(this.getCircuitThermique(context).circuitValue);

            if (context.washingtype?.data) {
                let washingTypes = [];
                Object.entries(context.washingtype.data).forEach((parts) => {
                    washingTypes.push(parts[1]);
                });

                washingTypes.sort((a, b) => (a.maxNotation > b.maxNotation) ? 1 : -1);

                let typeCircuit = null;

                washingTypes.forEach((washingType) => {
                    if (!typeCircuit && (notation < washingType.maxNotation)) {
                        typeCircuit = washingType;
                    }
                });

                return typeCircuit;
            }
        }

        return null;
    }

    getDureeTotaleLavage(context) {
        if (this.cycleLavage && this.getCycleLavage(context)) {
            let sum = 0;
            this.getCycleLavage(context).washingSteps.forEach((washingStepId) => {
                if (this['dureeWs' + washingStepId] !== undefined && this['dureeWs' + washingStepId]) {
                    sum += parseFloat(this['dureeWs' + washingStepId]);
                }
            });

            return sum;
        }

        return 0;
    }

    getDureeTotalLavagesMensuels(context) {
        return (this.getDureeTotaleLavage(context) * parseFloat(this.frequenceRecettesMensuelles ? this.frequenceRecettesMensuelles : 0)).toFixed(0);
    }

    getRecipeMatrix(context, washingStep, washingType, recipe, desinfection) {
        if (!recipe) {
            recipe = null;
        } else {
            recipe = parseInt(recipe);
        }

        if (washingStep) {
            washingStep = parseInt(washingStep);
        }

        if (washingType) {
            washingType = parseInt(washingType);
        }

        if (desinfection === "1" || desinfection === true || desinfection === 1) {
            desinfection = true;
        } else {
            desinfection = false;
        }

        let value = 0;
        Object.keys(context.recipematrix?.data).forEach((recipeMatrixId) => {
            const recipeMatrix = context.recipematrix.data[recipeMatrixId];
            if (recipeMatrix.washing_step === washingStep && recipeMatrix.washing_type === washingType && recipeMatrix.recipe === recipe && recipeMatrix.desinfection === desinfection) {
                value = parseFloat(recipeMatrix.value) / 7;
            }
        });

        return value;
    }

    getNbWashingStep(context, washingStep, washingType, recipe, desinfection) {
        return parseFloat(this.getRecipeMatrix(context, washingStep, washingType, recipe, desinfection)) * parseFloat(this.frequenceRecettesMensuelles ? this.frequenceRecettesMensuelles : 0);
    }

    getConsoEauAnnuelleWashingStep(context, washingStep, washingType = null, cycleLavage = null, desinfection = null) {
        if (cycleLavage === null) {
            cycleLavage = this.cycleLavage;
        }

        if (desinfection === null) {
            desinfection = true;
        }

        return this.getNbWashingStep(context, washingStep, washingType, cycleLavage, desinfection) * parseFloat(this['dureeWs' + washingStep] ? this['dureeWs' + washingStep] : 0) * this.debitCircuit * 12 / 3600;
    }

    getMonoAlterneeDureeTotalLavages(context, quote) {
        let sum = 0;
        Object.keys(context.washingstep.data).forEach((washingStepId) => {
            if (quote.advancedMethod || (!quote.advancedMethod && ![config.washingStepPrelavageId, config.washingStepDesinfectant].includes(parseInt(washingStepId)))) {
                if (this.getTypeCircuit(context) !== null) {
                    sum += this.getNbWashingStep(context, washingStepId, this.getTypeCircuit(context).id, this.cycleLavage, this.maintientDesinfection) * parseInt(this['dureeWs' + washingStepId] ? this['dureeWs' + washingStepId] : 0);
                }
            }
        });

        return sum;
    }

    getOptiMonoAlternateConsoEauAnnuelleWashingStep(context, washingStep, washingType = null, cycleLavage = null, desinfection = null) {
        if (cycleLavage === null) {
            cycleLavage = this.cycleLavage;
        }

        if (desinfection === null) {
            desinfection = true;
        }

        let factor = 1;

        if (parseInt(washingStep) === config.washingStepRincageAcidId) {
            factor = 0.9;
        }

        if (parseInt(washingStep) === config.washingStepRincageFinal) {
            if (this.getNbWashingStep(context, config.washingStepAcidId, washingType, cycleLavage, desinfection) > 0 && this.getNbWashingStep(context, config.washingStepDesinfectant, washingType, cycleLavage, desinfection) === 0) {
                factor = 0.9;
            }
        }

        return this.getConsoEauAnnuelleWashingStep(context, washingStep, washingType, cycleLavage, desinfection) * factor;
    }

    getOptiMonoAlternateDureeTotalAnnuelle(context) {
        let sum = 0;
        Object.keys(context.washingstep.data).forEach((washingStep) => {
            let factor = 1;

            if (this.getTypeCircuit(context)) {
                if (parseInt(washingStep) === config.washingStepRincageAcidId) {
                    factor = 0.9;
                }

                if (parseInt(washingStep) === config.washingStepRincageFinal) {
                    if (this.getNbWashingStep(context, config.washingStepAcidId, this.getTypeCircuit(context).id, this.cycleLavage, this.maintientDesinfection) > 0 && this.getNbWashingStep(context, config.washingStepDesinfectant, this.getTypeCircuit(context).id, this.cycleLavage, this.maintientDesinfection) === 0) {
                        factor = 0.9;
                    }
                }

                sum += this.getNbWashingStep(context, washingStep, this.getTypeCircuit(context).id, this.cycleLavage, this.maintientDesinfection) * parseFloat(this['dureeWs' + washingStep] ? this['dureeWs' + washingStep] : 0) * factor;
            }
        });

        return sum;
    }

    getOptiAdditiveConsoEauAnnuelleWashingStep(context, washingStep, washingType = null, cycleLavage = null, desinfection = null) {
        if (cycleLavage === null) {
            cycleLavage = this.cycleLavage;
        }

        if (desinfection === null) {
            desinfection = true;
        }

        let factor = 1;

        if (parseInt(washingStep) === config.washingStepRincageSoudeId) {
            factor = 0.9;
        }

        if (parseInt(washingStep) === config.washingStepRincageFinal) {
            if (this.getNbWashingStep(context, config.washingStepSoudeId, washingType, cycleLavage, desinfection) > 0 && this.getNbWashingStep(context, config.washingStepDesinfectant, washingType, cycleLavage, desinfection) === 0) {
                factor = 0.9;
            }
        }

        return this.getConsoEauAnnuelleWashingStep(context, washingStep, washingType, cycleLavage, desinfection) * factor;
    }

    getOptiAdditiveDureeTotalAnnuelle(context) {
        let sum = 0;
        Object.keys(context.washingstep.data).forEach((washingStep) => {
            let factor = 1;

            if (this.getTypeCircuit(context)) {
                if (parseInt(washingStep) === config.washingStepRincageSoudeId) {
                    factor = 0.9;
                }

                if (parseInt(washingStep) === config.washingStepRincageFinal) {
                    if (this.getNbWashingStep(context, washingStep, null, this.cycleLavage, true) > 0 && (this.getNbWashingStep(context, config.washingStepAcidId, null, this.cycleLavage, true) + this.getNbWashingStep(context, config.washingStepDesinfectant, null, this.cycleLavage, true) === 0)) {
                        factor = 0.9;
                    }
                }

                sum += this.getNbWashingStep(context, washingStep, null, this.cycleLavage, true) * parseFloat(this['dureeWs' + washingStep] ? this['dureeWs' + washingStep] : 0) * factor;
            }
        });

        return sum;
    }
}