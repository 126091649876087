import {Format} from "./Format";
import {config} from "../components/config/Config";

export class QuoteProduct {

    estimatedConsumptionId;
    calculation;
    calculationParts;

    constructor(data) {
        this.quote = null;
        this.id = null;
        this.product = null;
        this.categoryId = null;
        this.ordering = 1;
        this.addedEffect = false;
        this.deleted = false;
        this.toggleScrollToElement = false;
        this.highlightFormats = false;
        this.manuallyAdded = false;

        this.format = null;
        this.estimatedConsumption = 0;
        this.quantity = 0;
        this.price = 0;
        this.isPriceModified = false;
        this.total = 0;
        this.totalWithoutDiscount = 0;
        this.applicationType = null;
        this.estimatedConsumptionId = null;
        this.calculation = null;
        this.calculationParts = {};

        this.loaned = false;
        this.inSpray = false;

        this.hasDiscount = false;
        this.discount = 0;
        this.discountPrice = 0;

        this.formula = null;

        if (data) {
            Object.assign(this, data);
        }

        if (this.product) {
            this.id = this.product.id;

            // If only one format, select it
            if (this.product.formats && this.product.formats.length === 1) {
                this.setFormat(this.product.formats[0].id);
            }
        }
    }

    setQuote(value) {
        this.quote = value;
    }

    setId(value) {
        this.id = value;
        this.persistToContext();
    }

    setProduct(value) {
        this.product = value;
        this.id = this.product.id;
        this.persistToContext();
    }

    setOrdering(value) {
        this.ordering = value;
        this.persistToContext();
    }

    setAddedEffect(value) {
        this.addedEffect = value;
        this.persistToContext();
    }

    setDeleted(value) {
        this.deleted = value;
        this.persistToContext();
    }

    setToggleScrollToElement(value) {
        this.toggleScrollToElement = value;
        this.persistToContext();
    }

    setManuallyAdded(value) {
        this.manuallyAdded = value;
        this.persistToContext();
    }

    setFormat(value) {
        this.format = value;
        if (this.estimatedConsumption) {
            const format = this.getFormat();
            if (format) {
                if (format.value) {
                    const rawQuotient = parseFloat(this.estimatedConsumption) / parseFloat(format.value);
                    const remainder = rawQuotient % 1;
                    let quotient = rawQuotient - remainder;
                    if (remainder > 0) {
                        quotient = quotient + 1;
                    }

                    this.quantity = quotient;
                }
            }
        }
        this.computeTotal();
        this.persistToContext();
    }

    setQuantity(value) {
        this.quantity = value;

        this.computeTotal();
        this.persistToContext();
    }

    setEstimatedConsumptionObject(object) {
        this.estimatedConsumptionId = object.id;
        if (object.applicationType) {
            this.applicationType = object.applicationType;
        }
        this.persistToContext();
    }

    getNextEstimatedConsumptionObject(category) {
        let productConsumptions = this.product.getProductConsumption(this.quote, category);
        if (this.estimatedConsumptionId && productConsumptions.length > 1) {
            let productConsumption = null;
            productConsumptions.forEach((p, index) => {
                if (p.id === this.estimatedConsumptionId) {
                    if (index < (productConsumptions.length - 1)) {
                        productConsumption = productConsumptions[index + 1];
                    } else {
                        productConsumption = productConsumptions[0];
                    }
                }
            });

            return productConsumption;
        } else if (productConsumptions.length > 0) {
            return productConsumptions[0];
        }
    }

    getEstimatedConsumptionObject() {
        if (this.estimatedConsumptionId) {
            return this.product.getObjectById("productConsumptions", this.estimatedConsumptionId);
        }

        return null;
    }

    setApplicationType(value) {
        this.applicationType = value;
        this.persistToContext();
    }

    setCalculation(value) {
        this.calculation = value;
        this.persistToContext();
    }

    setCalculationPart(calculationPart, dontPersistToState, dontUpdateTotal) {
        this.calculationParts[calculationPart.parameter] = calculationPart.value;
        this.calculation.parts.forEach((part, i) => {
            if (part.parameter === calculationPart.parameter) {
                part.value = calculationPart.value;
            }
        });

        if (!dontUpdateTotal && this.calculation) {
            this.calculation.updateTotal(this);
            this.setEstimatedConsumption(this.calculation.getTotal());
        }

        if (!dontPersistToState) {
            this.persistToContext();
        }
    }

    addQuantity(value) {
        if (!value) {
            value = 1;
        }
        this.quantity = this.quantity + value;

        this.computeTotal();
        this.persistToContext();
    }

    removeQuantity(value) {
        if (!value) {
            value = 1;
        }

        this.quantity = this.quantity - value;
        if (this.quantity < 1) {
            this.quantity = 1;
        }

        this.computeTotal();
        this.persistToContext();
    }

    setEstimatedConsumption(value) {
        this.estimatedConsumption = parseInt(value);
        if (this.format) {
            // Calculate the quantity needed with the current format
            this.setFormat(this.format);
        }
        this.persistToContext();
    }

    setInSpray(isInSpray) {
        this.inSpray = isInSpray;
        this.persistToContext();
        if (isInSpray) {
            this.setEstimatedConsumption(1);
        }
    }

    toggleInSpray() {
        this.setInSpray(!this.inSpray);
    }

    toggleLoan() {
        this.loaned = !this.loaned;
        if (this.loaned) {
            // We must now show the price of the item, but has to be 0 in the total
            // this.setPrice(0);
        } else {
            if (this.quote) {
                this.quote.checkCartHasItemWithPrice();
            }
            this.computeTotal();
            this.persistToContext();
        }
    }

    setLoaned(value) {
        this.loaned = value === true;
        this.persistToContext();
    }

    /**
     * Return the Object instead of the ID
     * @returns {null|Format}
     */
    getFormat() {
        if (this.product && this.product.formats) {
            const data = this.product.getObjectById('formats', this.format);
            if (data) {
                return new Format(data);
            }
        }

        return null;
    }

    showEstimateAndUnit() {
        if (this.getFormat()) {
            if (this.getFormat().unit === config.unitUnit && !this.estimatedConsumptionId) {
                return false;
            }
        }

        if (this.product && this.product.isEquipment) {
            return false;
        }

        return true;
    }

    doHighlightFormats() {
        this.highlightFormats = !this.highlightFormats;
        this.persistToContext();
    }

    setPrice(value) {
        let price = (value + '').replace(',', '.');
        if (isNaN(price) || parseFloat(price) < 0) {
            price = 0;
        }

        this.price = price;
        this.computeTotal();
        this.persistToContext();
    }

    setIsPriceModified(value) {
        this.isPriceModified = value;
        this.persistToContext();
    }

    setHasDiscount() {
        this.hasDiscount = true;
        this.persistToContext();
    }

    setDiscount(value, dontPersistState) {
        let discount = (value + '').replace(',', '.');
        if (isNaN(discount)) {
            discount = 0;
        }
        this.discount = discount;
        this.computeTotal();
        if (!dontPersistState) {
            this.persistToContext();
        }
    }

    removeDiscount(dontPersistToState) {
        this.hasDiscount = false;
        this.discount = 0;
        this.discountPrice = 0;
        this.computeTotal();
        if (!dontPersistToState) {
            this.persistToContext();
        }
    }

    getSelectedFormat() {
        let retval = 0;
        this.product.formats.forEach((format) => {
            if (format.id === this.format) {
                retval = format;
            }
        })
        return retval;
    }

    computeTotal() {
        if (this.quantity && this.price && !this.loaned) {
            let selectedFormat = this.getSelectedFormat();
            let isKG = selectedFormat.unit === 1;
            this.total = this.totalWithoutDiscount = this.quantity * this.price * (isKG && selectedFormat.value ? selectedFormat.value : 1);

            if (this.hasDiscount && this.discount && parseFloat(this.discount) > 0) {
                if (this.discount > 100) {
                    this.discount = 0;
                }

                this.discountPrice = parseFloat(this.total) * parseFloat(this.discount) / 100;
                this.total -= this.discountPrice;
            } else if (this.hasDiscount) {
                this.discountPrice = 0;
            }
        } else {
            this.total = this.totalWithoutDiscount = 0;
        }

        this.quote.checkCartHasItemWithPrice();
    }

    getCostPerAnimalPerMonth() {
        if (this.quote && this.total && parseInt(this.quote.generalData['numberofanimals'])) {
            return this.total / this.quote.numberOfMonths / parseInt(this.quote.generalData['numberofanimals']);
        }

        return 0;
    }

    getTotalProductQuantity() {
        let format = this.getFormat();
        if (format) {
            return parseFloat(this.quantity) * parseFloat(format.value);
        }

        return 0;
    }

    getUnitName(userContext) {
        if (this.product) {
            let unitId = this.product.getFormatUnit();
            if (unitId) {
                return userContext.unit.data[unitId].getTranslation({fieldName: 'name', locale: userContext.user.locale}) || '';
            }
        }

        return null;
    }

    hasNumberOfMonthsInCalculation() {
        let has = false;
        if (this.product && this.calculation && this.calculation.parts.length) {
            this.calculation.parts.forEach((part, i) => {
                if (part.isNumberOfMonths()) {
                    has = true;
                }
            });
        }

        return has;
    }

    persistToContext() {
        if (this.quote) {
            this.quote.updateContextState();
        }
    }
}
