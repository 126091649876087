import React from "react";
import {QuoteContext} from "../../contexts/QuoteContext";
import nl2br from "react-nl2br";

export default class ContactDetails extends React.Component {
    render() {
        return (
            <div className="contact-details">
                {this.context.currentQuote.generalData['nomeleveur'] &&
                    <div className="contact-detail">
                        {this.context.currentQuote.generalData['nomeleveur']}
                    </div>
                }
                {this.context.currentQuote.generalData['gaec'] &&
                    <div className="contact-detail">
                        {this.context.currentQuote.generalData['gaec']}
                    </div>
                }
                {this.context.currentQuote.generalData['adresse'] &&
                    <div className="contact-detail">
                        {nl2br(this.context.currentQuote.generalData['adresse'])}
                    </div>
                }
                {(this.context.currentQuote.generalData['email'] || this.context.currentQuote.generalData['telephone']) &&
                    <div className="pt-1">
                        {this.context.currentQuote.generalData['email'] &&
                            <div className="contact-detail">
                                <strong>{this.context.currentQuote.generalData['email']}</strong>
                            </div>
                        }
                        {this.context.currentQuote.generalData['telephone'] &&
                            <div className="contact-detail">
                                {this.context.currentQuote.generalData['telephone']}
                            </div>
                        }
                    </div>
                }
            </div>
        )
    }
}

ContactDetails.contextType = QuoteContext;