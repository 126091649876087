export class Format {
    constructor(data) {
        this.id = null;
        this.name = '';
        this.translations = [];
        this.value = '';
        this.SAPCode = '';
        this.unit = null;
        this.countries = []
        this.applicationType = null;

        if (data) {
            Object.assign(this, data);
        }
    }
}