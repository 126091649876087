import React from "react";
import {getTranslation} from "../tools/Translation";
import {UserContext} from "../../contexts/UserContext";
import {QuoteContext} from "../../contexts/QuoteContext";
import essycalcLogo from "../../assets/images/easycalc-logo.png";
import {config} from "../config/Config";
import ContactDetails from "./ContactDetails";
import moment from 'moment';
import QuoteDetails from "./QuoteDetails";

export default class ContactHeader extends React.Component {
    render() {
        return (
            <UserContext.Consumer>
                {({user}) => {
                    return (
                        <div className={"sheet-padding sheet-padding-top clearfix"}>
                            <ContactDetails/>
                            <img src={essycalcLogo} className="easycalc-logo" alt="Easycalc" />
                            <div className="easycalc-version">{config.appVersion}</div>
                            <div className={"estimate-date"}>
                                {getTranslation('estimate', user.locale)} - { moment().format('DD/MM/YYYY') }
                            </div>
                            <QuoteDetails/>
                        </div>
                )}}
            </UserContext.Consumer>
        )
    }
}

ContactHeader.contextType = QuoteContext;