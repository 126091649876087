import {Entity} from "./Entity";
import LabelValueUnit from "../components/consumption-type/LabelValueUnit";
import LabelOnly from "../components/consumption-type/LabelOnly";
import LabelSpeciesValueUnit from "../components/consumption-type/LabelSpeciesValueUnit";

export class ProductConsumption extends Entity {
    components = {
        1: LabelSpeciesValueUnit,   //PerAnimalPerYear
        2: LabelValueUnit,          //WaterVolumeTrayor
        3: LabelValueUnit,          //WaterVolumeTank
        4: LabelOnly,               //FootbathVolumeCrossingMonths
        5: LabelValueUnit,          //QuantityPerWaterVolume
        6: LabelValueUnit,          //AverageConcentration
        7: LabelValueUnit,          //AveragePerCow
        8: LabelOnly,               //NumberOfDoses
        9: LabelValueUnit,          //AveragePerHeifer
        10: LabelValueUnit,         //AveragePerVeal
        11: LabelValueUnit          //PerRobotPerYear
    }

    waterVolumeTrayor = 2;
    footbathVolumeCrossingMonths = 4;
    numberOfDoses = 8;

    getComponent() {
        if (this.components[this.consumptionType]) {
            return this.components[this.consumptionType];
        }

        return null;
    }
}
