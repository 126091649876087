import React from "react";

export default class ProductTotal extends React.Component {
    render() {
        return (
            <div className="cost-box">
                <div className="cost-text">
                    <span className="mr-2">=</span> { this.props.quoteProduct.totalWithoutDiscount.toFixed(2) } {this.props.currency}
                </div>
            </div>
        )
    }
}