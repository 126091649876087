import {Entity} from "./Entity";
import InputType from "../components/calculation/InputType";
import LabelType from "../components/calculation/LabelType";
import {config} from "../components/config/Config";

export default class ProductCalculationPart extends Entity {
    product;
    category;
    currentQuote;
    formulaVariable;
    formulaVariables;
    parameter;
    value;
    additionalOperation;
    type;
    staticText;
    operator;
    addPadding;
    total;
    dontParse;

    operators = ['+', '÷', '-', 'x'];

    components = {
        input: InputType,
        label: LabelType
    }

    constructor(data) {
        super(data)
        if (!this.dontParse) {
            this.parse();
        }
    }

    isParameter() {
        const regexp = /[\w]+\.[\w]+/g;
        if (this.parameter.match(regexp)) {
            return true;
        }

        return false;
    }

    isUsingGeneralData() {
        if (this.isParameter() && this.formulaVariable && this.formulaVariable.property !== 'numberOfMonths') {
            return true;
        }

        return false;
    }

    isSoftWater() {
        if (this.isParameter() && this.formulaVariable && this.formulaVariable.source === "Category" && this.formulaVariable.property === 'frequencyPerWeek') {
            return true;
        }

        return false;
    }

    isEstimatedConsumption() {
        if (this.isParameter() && this.formulaVariable && this.formulaVariable.source === "ProductConsumption") {
            return true;
        }

        return false;
    }

    isNumberOfMonths() {
        if (this.isParameter() && this.formulaVariable && this.formulaVariable.source === "Quote" && this.formulaVariable.property === 'numberOfMonths') {
            return true;
        }

        return false;
    }

    isProtolMultiplier() {
        return this.isParameter() && this.formulaVariable && this.formulaVariable.source === 'Protocol' && this.formulaVariable.property === 'multiplier';
    }

    setFormulaVariable() {
        Object.keys(this.formulaVariables).forEach((formulaVariableId) => {
            if (this.formulaVariables[formulaVariableId].label === this.parameter) {
                this.formulaVariable = this.formulaVariables[formulaVariableId];
                if (this.formulaVariable && this.formulaVariable.additionalOperation) {
                    this.additionalOperation = this.formulaVariable.additionalOperation;
                }
            }
        });
    }

    parse() {
        if (this.isParameter()) {
            this.setFormulaVariable();

            if (this.formulaVariable) {
                switch (this.formulaVariable.source) {
                    case 'Category':
                        let userContext = this.currentQuote.getUserContext();
                        if (this.isSoftWater()) {
                            if (userContext && userContext.category.data && userContext.category.data[this.category]) {
                                let categoryData = userContext.category.data[this.category];
                                let frequency = categoryData.getFrequency(userContext.user.species);
                                if (frequency && frequency.frequencyPerWeek && frequency.frequencySoftWaterAlkalin && frequency.frequencySoftWaterAcid) {
                                    if (!this.currentQuote.isCategoryUsingSoftWater(this.category)) {
                                        this.value = frequency.frequencyPerWeek;
                                    } else {
                                        if (this.product.ph === config.phAcidicId) {
                                            this.value = frequency.frequencySoftWaterAcid;
                                        } else if (this.product.ph === config.phAlkalineId) {
                                            this.value = frequency.frequencySoftWaterAlkalin;
                                        }
                                    }

                                    if (this.value) {
                                        break;
                                    }
                                }
                            }
                        }

                        if (userContext && userContext.category.data && userContext.category.data[this.category]) {
                            this.value = userContext.category.data[this.category][this.formulaVariable.property];
                        }
                        break;

                    case 'Product':
                        this.value = this.product[this.formulaVariable.property];
                        break;

                    case 'Global':
                        switch (this.formulaVariable.property) {
                            case 'volume':
                                this.value = !isNaN(this.formulaVariable.defaultValue) && this.formulaVariable.defaultValue !== null ? this.formulaVariable.defaultValue.toFixed(1) + '' : 0;
                                break;
                            default:
                                break;
                        }
                        break;

                    case 'Quote':
                        if (this.isUsingGeneralData()) {
                            this.value = this.currentQuote.generalData[this.formulaVariable.property];
                        } else {
                            switch (this.formulaVariable.property) {
                                case 'numberOfMonths':
                                    this.value = this.currentQuote[this.formulaVariable.property];
                                    break;
                                default:
                            }
                        }
                        break;

                    case 'ProductConsumption':
                        const productData = this.currentQuote.getProductData(this.category, this.product.id);
                        if (productData) {
                            if (productData.estimatedConsumptionId) {
                                const consumption = this.product.getObjectById('productConsumptions', productData.estimatedConsumptionId);
                                if (consumption) {
                                    this.value = this.currentQuote.manualEstimatedConsumptionValue[this.category] || consumption[this.formulaVariable.property];
                                }
                            }
                        }
                        break;

                    case 'Protocol':
                        this.value = this.currentQuote.protocolMultiplier;
                        break;

                    default:
                        this.value = null;
                }

                if (this.value === undefined && this.formulaVariable.defaultValue !== null && !isNaN(this.formulaVariable.defaultValue)) {
                    this.value = parseFloat(this.formulaVariable.defaultValue);
                }

                if (this.formulaVariable.isEditable) {
                    this.type = this.components.input;
                } else {
                    this.type = this.components.label
                }
            }
        } else {
            this.staticText = true;
            this.type = this.components.label;

            if (this.parameter === 'total') {
                this.value = this.total;
            } else {
                this.value = this.parameter;
            }

            this.operator = this.operators.includes(this.value) !== false;
        }
    }
}
