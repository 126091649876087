import React, { Component, createRef } from "react";
import { config } from "../config/Config";
import { Row, Col, OverlayTrigger, Tooltip, Container } from "react-bootstrap";
import { UserContext } from "../../contexts/UserContext";
import { QuoteContext } from "../../contexts/QuoteContext";
import QuoteProduct from "../product/QuoteProduct";
import classNames from "classnames";
import $ from "jquery";
import ProductPanel from "../product/ProductPanel";
import { getTranslation } from "../tools/Translation";
import CategoryTotal from "./CategoryTotal";

class Category extends Component {
    constructor(props) {
        super(props);
        this.categoryElement = createRef();
    }

    render() {
        let cat = this.props.category;
        return (
            <UserContext.Consumer>
                {({ user, product }) => {
                    let pCount = this.context.currentQuote.categories[cat.id] ? this.context.currentQuote.categories[cat.id].length : 0;
                    if (cat.isRoboticMilking && !this.context.currentQuote.haveRoboticSystem && pCount === 0) {
                        return null;
                    }
                    let bottomTarget = cat.id === 1 ? <div id="bottom"></div> : null;
                    return (
                        <Row className="no-gutters">
                            <Col>
                                <div className="cat cat-border-bottom sortable"
                                    ref={this.categoryElement}
                                    data-id={cat.id}
                                    style={cat.image ? { background: "url(" + cat.image + ") center center" } : null}>
                                    {bottomTarget}
                                    <OverlayTrigger
                                        overlay={
                                            <Tooltip>
                                                {user.categoryProducts[cat.id].length} {getTranslation("product" + (user.categoryProducts[cat.id].length > 1 ? 's' : ''), user.locale)} {getTranslation("category.productinthiscategory", user.locale)}
                                            </Tooltip>
                                        }>
                                        <div className="titlecat" onClick={() => this.props.setCurrentCategory(cat.id, this.categoryElement.current)}
                                            style={cat.color ? { backgroundColor: cat.color } : null}>
                                            {cat.getTranslation({ fieldName: "name", locale: user.locale })}
                                        </div>
                                    </OverlayTrigger>
                                    <div className="selectprod-container" onClick={() => this.props.setCurrentCategory(cat.id, this.categoryElement.current)}>
                                        <div className={classNames({
                                            selectproduct: true,
                                            opaque: this.props.currentCategory === cat.id,
                                            hidden: this.context.currentQuote.hasCategory(cat.id)
                                        })}
                                            style={cat.color ? { backgroundColor: cat.color } : null}>{cat.isCatalog ? getTranslation("category.catalog.chooseproduct", user.locale) : getTranslation("category.chooseproduct", user.locale)}
                                        </div>
                                    </div>
                                    {this.context.currentQuote.hasCategory(cat.id) &&
                                        <Container>
                                            <Row>
                                                <Col>
                                                    <div className="wastebin-container" onClick={() => this.context.currentQuote.removeAllCategoryProducts(cat.id)}>
                                                        <div className="wastebin" style={{ display: this.context.currentQuote.getCategoryProducts(cat.id).length > 1 ? 'block' : 'none' }}>{getTranslation("category.removeall", user.locale)}</div>
                                                    </div>
                                                </Col>
                                            </Row>
                                            {this.context.currentQuote.getCategoryProducts(cat.id).map((productId) => {
                                                if (product && product.data && product.data[productId]) {
                                                    return (
                                                        <QuoteProduct category={cat} product={product.data[productId]} data={this.context.currentQuote.getProductData(cat.id, productId)} key={productId} />
                                                    )
                                                }
                                                return null;
                                            })}
                                            <CategoryTotal category={cat.id} user={user} />
                                        </Container>
                                    }
                                </div>
                            </Col>
                        </Row>
                    )
                }}
            </UserContext.Consumer>
        )
    }
}

Category.contextType = QuoteContext;

export default class Categories extends Component {
    constructor(props, userContext) {
        super(props, userContext);
        this.timeoutId = null;
        this.state = {
            currentButtonCategory: userContext && userContext.user && userContext.user.categories && userContext.user.categories.length > 0 ? userContext.user.categories.length[0] : null,
            currentPanelCategory: null
        };

        this.scrollAnimating = false;
    }

    componentDidMount() {
        // On scroll, highlight the current category
        $(window).on('scroll', (function () {
            clearTimeout(this.timeoutId);
            this.timeoutId = setTimeout(() => this.setCurrentCategory(), config.onScrollCallbackDelay);
        }).bind(this));
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let category = this.context.user.categories;
        // Mettre la première catégorie highlighted si pas de catégorie sélectionnée
        if (category.length > 0) {
            if (!this.state.currentButtonCategory || category.indexOf(this.state.currentButtonCategory) === -1) {
                this.setState({
                    currentButtonCategory: category[0]
                });
            }
        }
    }

    componentWillUnmount() {
        $(window).off('scroll');
    }

    setCurrentCategory = (category, element) => {
        // On scroll (and not onClick of a Category)
        if (!category && !this.scrollAnimating) {
            if ($(window).scrollTop() + $(window).height() === $(document).height()) {
                category = $('.cat:last', '#categories').data('id');
            } else if ($(window).scrollTop() === 0) {
                category = $('.cat:first', '#categories').data('id');
            } else {
                let productPanel = $('#product-panel');
                if (productPanel.length) {
                    let sliderHeight = productPanel.height();
                    let sliderTop = productPanel.offset().top - (sliderHeight === 0 ? 270 : 0);
                    let sliderBottom = parseInt(productPanel.css('bottom'));
                    $('#categories .cat').each(function () {
                        if (sliderTop - $(this).offset().top > 90 - sliderBottom) {
                            category = $(this).data('id');
                        }
                    });
                }
            }
        }

        // onClick of a Category or scrolling to a new visible Category
        if (category && category !== this.state.currentButtonCategory) {
            this.setState({
                currentButtonCategory: category
            });
            // Close the panel onClick of the current open category
        } else if (category === this.state.currentPanelCategory) {
            category = null;
        }

        // onClick of a Category, scroll to the select category
        if (element) {
            this.setState({
                currentPanelCategory: category
            });
            this.scrollToCategory(element);
        }
    }

    scrollToCategory = (element) => {
        let that = this;
        this.scrollAnimating = true;
        let catPosition = $(element).offset().top - $(window).height() + 394;
        $('html, body').animate({
            scrollTop: catPosition
        }, config.scrollToCategoryDuration, 'swing', function () {
            setTimeout(() => {
                that.scrollAnimating = false;
            }, config.onScrollCallbackDelay + 100);
        });
    }

    buttonClick = (closeButton) => {
        this.setState(function (state) {
            return {
                currentPanelCategory: closeButton === true ? null : state.currentButtonCategory
            }
        });
    }

    render() {
        if (!this.context.category || !this.context.category.data || Object.keys(this.context.category.data).length === 0 || !this.context.user.categories || this.context.user.categories.length === 0) {
            return null;
        }

        return (
            <>
                <Container fluid={true} className="p-0" id="categories">
                    {this.context.user.categories.map((categoryId, index) => {
                        let showEmptyCategories = this.props.displayEmptyCategories;
                        let hasProduct = this.props.quoteCategories[categoryId];
                        if (!showEmptyCategories && !hasProduct) {
                            return null
                        }
                        let c = this.context.category.data[categoryId];
                        return c ? <Category category={c} key={index} currentCategory={this.state.currentButtonCategory} setCurrentCategory={this.setCurrentCategory} /> : null;
                    }
                    )}
                </Container>
                <ProductPanel buttonCategory={this.state.currentButtonCategory} panelCategory={this.state.currentPanelCategory} onButtonClick={this.buttonClick} />
            </>
        )
    }
}

Categories.contextType = UserContext;
