import React from "react";
import {Modal} from "react-bootstrap";
import {getTranslation} from "./Translation";
import {UserContext} from "../../contexts/UserContext";
import {deleteVersions} from "../data/Database";

export default class NewVersionAvailable extends React.Component {
    onClickHandler = () => {
        deleteVersions().finally(() => {
            window.location.reload();
        });
    }

    render() {
        return (
            <Modal
                show={this.props.show}
                backdrop="static"
                keyboard={false}
                size="md"
                centered>
                <Modal.Header>
                    <Modal.Title>
                        {getTranslation('newVersionAvailable', this.context.user.locale)}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        {getTranslation('newVersionAvailableText', this.context.user.locale)}
                    </p>
                    <button className="btn btn-primary" onClick={this.onClickHandler}>{getTranslation('reload', this.context.user.locale)}</button>
                </Modal.Body>
            </Modal>
        );
    }
}

NewVersionAvailable.contextType = UserContext;