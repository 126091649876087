import moment from "moment";

export class CipProduct {

    id = null;
    type = '';
    product = '';
    concentrationNaohHno3 = '';
    concentration = '';
    volume = '';
    pricing = '';
    annualCa = '';

    ratio = '';
    ratioNote = '';

    ratios = {
        'A': 0,
        'B': 0,
        'C': 0,
        'D': 0,
        'E': 0,
        'F': 0
    };

    constructor(data) {
        if (!data || (data && !data.id)) {
            this.id = moment().valueOf();
        }

        if (data) {
            Object.assign(this, data);
        }
    }

}