import React from "react";
import {getTranslation} from "./Translation";
import {QuoteContext} from "../../contexts/QuoteContext";

export default class ClosePopup extends React.Component {
    render() {
        return (
            <div id="closePopup" className="closePopup">
                <div id="closePopupContainer" className="closePopupContainer">
                    <div id="closePopupTitle" className="closePopupTitle">{getTranslation("confirm.quit", this.context.currentQuote.locale)}</div>
                    <div id="closePopupContent" className="closePopupContent">
                        <div id="closePopupContentMessage" className="closePopupMessage">
                            <p>{getTranslation("data.loss", this.context.currentQuote.locale)}</p>
                        </div>
                    </div>
                    <div id="closePopupContentActions" className="closePopupActionBar">
                        <div id="closePopupContentActionsOk" className="closePopupAction Ok" onClick={() => {this.props.toggle('quit')}}>{getTranslation("ok", this.context.currentQuote.locale)}</div>
                        <div id="closePopupContentActionsCancel" className="closePopupAction Cancel" onClick={() => {this.props.toggle('close')}}>{getTranslation("renameQuote.actionCancel", this.context.currentQuote.locale)}</div>
                    </div>
                </div>
            </div>
        );
    }
}

ClosePopup.contextType = QuoteContext;