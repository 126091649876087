import React from "react";
import classNames from "classnames";

export default function ProductInput(props) {

    const onBlur = (e) => {
        props.onChange({
            target: {
                value: isNaN(e.target.value) ? '' : parseFloat(e.target.value).toFixed(props.digits || 2)
            }
        })
    }

    let classes = {'form-control': true, 'text-center': true, 'form-control-sm': true};
    if (props.classToAdd) {
        classes[props.classToAdd] = true;
    }

    return (
        <div className="input-group input-group-sm">
            <input type="number" className={classNames(classes)} onChange={props.onChange} value={isNaN(props.value) ? '' : props.value} onBlur={onBlur} disabled={props.disabled} />
                <div className="input-group-append">
                    <span className="input-group-text">{props.valueType}</span>
                </div>
        </div>
    );
}