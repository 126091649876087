import React, {Component} from "react";
import {UserContext} from "../../contexts/UserContext";
import {NavDropdown} from "react-bootstrap";
import {getTranslation} from "../tools/Translation";
import {QuoteContext} from "../../contexts/QuoteContext";

export default class LocaleSelector extends Component {
    onSelectHandler = (value, user) => {
        let selectedSplit = value.split('-');

        if (user.country !== parseInt(selectedSplit[1])) {
            // Create a new Quote if this one has products
            this.context.currentQuote.createNewQuote();
        }

        user.setCountry(parseInt(selectedSplit[1]), this.context.currentQuote);
        user.setLocale(selectedSplit[0], this.context.currentQuote);
    }

    render() {
        return (
            <UserContext.Consumer>
                {({user, language, country}) => {
                    // cacher le choix de langue si l'usager ne peut pas changer
                    if (!country.data || !language.data || !user.countries) {
                        return null;
                    }

                    return (
                        <NavDropdown className="locale-selector-wrapper" title={country.data && country.data[user.country] && country.data[user.country].image ?
                            <span className="selected-country-wrapper">
                                <img src={country.data[user.country].image} alt=""/>
                                <span className="locale-text">{user.locale}</span>
                            </span>
                            : getTranslation('locale.selector', user.locale)
                        } id={"locale-dropdown"} onSelect={(value) => this.onSelectHandler(value, user)}>
                            {
                                Object.keys(country.data).map(
                                    (key) => {
                                        let c = country.data[key];
                                        if (user.countries.indexOf(parseInt(key)) !== -1) {
                                            return c.languages.map((languageId) => {

                                                const l = language.data[languageId];
                                                if (l) {
                                                    const optionValue = l.localeValue + '-' + c.id
                                                    const userValue = user.locale + '-' + user.country;

                                                    if (optionValue !== userValue) {
                                                        return (
                                                            <NavDropdown.Item className="locale" eventKey={optionValue} key={optionValue}>
                                                                <img src={c.image} alt=""/> {l.name}
                                                            </NavDropdown.Item>
                                                        )
                                                    }
                                                }
                                                return null;
                                            });
                                        }

                                        return null;
                                    }
                                )
                            }
                        </NavDropdown>
                    )
                }}
            </UserContext.Consumer>
        )
    }
}

LocaleSelector.contextType = QuoteContext;
