import React from "react";
import {QuoteContext} from "../../contexts/QuoteContext";
import {Col, Container, Image, ListGroupItem, Row} from "react-bootstrap";

export default class SearchResult extends React.Component {

    handleClick = () => {
        this.context.currentQuote.showProductDetailsPopup(this.props.product);
        this.props.emptyField();
        this.props.toggleSearch();
    }

    render() {
        const name = this.props.product.name;
        const description = this.props.product.getTranslation({fieldName: 'description', locale: this.context.currentQuote.getUserContext().user.locale});
        const image = this.props.product.image;
        return (
            <>
                <ListGroupItem style={{padding: 2, background: 'none'}} onClick={this.handleClick}>
                    <Container className="search-box-result-item" style={{padding: 2}}>
                        <Row style={{margin: 0}}>
                            <Col style={{padding: 0, maxWidth: 100, overflow: 'hidden'}}><Image style={{height: 100, position: 'relative', left: '-30%'}} src={image} rounded/></Col>
                            <Col style={{padding: '0 0 0 5px'}}>
                                <Row style={{margin: 0}}><Col style={{padding: '5px 0'}}><span style={{color: '#111', fontWeight: 'bold'}}>{name}</span></Col></Row>
                                <Row style={{margin: 0}}><Col style={{padding: 0}}><span style={{color: '#555'}}>{description.substring(0, 100)}</span></Col></Row>
                            </Col>
                        </Row>

                    </Container>
                </ListGroupItem>
            </>
        );
    }

}

SearchResult.contextType = QuoteContext;
