import React from "react";
import {QuoteContext} from "../../contexts/QuoteContext";

export default class ProductEstimatedConsumption extends React.Component {
    render() {
        if (!this.props.product) {
            return null;
        }

        const productData = this.context.currentQuote.getProductData(this.props.category.id, this.props.product.id);
        if (!productData.estimatedConsumptionId) {
            return null;
        }

        const estimatedConsumptions = this.props.product.getProductConsumption(this.context.currentQuote, this.props.category.id);
        if (!estimatedConsumptions || estimatedConsumptions.length === 0) {
            return null;
        }

        const TagName = estimatedConsumptions[0].getComponent();
        if (!TagName) {
            return null;
        }

        return (
            <TagName product={this.props.product} productData={productData} estimatedConsumptions={estimatedConsumptions} currentQuote={this.context.currentQuote}/>
        )
    }
}

ProductEstimatedConsumption.contextType = QuoteContext;
