import moment from "moment";
import {Equipment} from "./Equipment";
import {Cip} from "./Cip";
import {CipTypes} from "./CipTypes";
import {ProductTypes} from "../../components/caustic-soda/ProductTypes";
import {config} from "../../components/config/Config";
import {getRatioNote, RatingRatios} from "./RatingRatios";
import {getTranslation} from "../../components/tools/Translation";

export class CausticSodaQuote {

    id = null;
    advancedMethod = false;
    equipments = [];
    cips = {
        [CipTypes.actual]: new Cip({type: CipTypes.actual}),
        [CipTypes.monoAlternate]: new Cip({type: CipTypes.monoAlternate}),
        [CipTypes.sodaAdditive]: new Cip({type: CipTypes.sodaAdditive}),
        [CipTypes.monoSodaAdditive]: new Cip({type: CipTypes.monoSodaAdditive})
    };

    version = '1.0';
    technician = '';
    society = '';
    site = '';
    town = '';
    firstname = '';
    lastname = '';
    email = '';
    date = '';

    constructor(data) {
        if (!data || (data && !data.id)) {
            this.id = moment().valueOf();
        }

        // Create objects from JSON data
        if (data && data.equipments) {
            data.equipments.forEach((equipment, i) => {
                data.equipments[i] = new Equipment(equipment);
            });
        }

        if (data && data.cips) {
            Object.keys(CipTypes).forEach((cipType) => {
                if (data.cips[cipType]) {
                    data.cips[cipType] = new Cip(data.cips[cipType]);
                }
            });
        }

        if (data) {
            Object.assign(this, data);
        }
    }

    setEquipmentProperty(context, id, key, value) {
        this.equipments.forEach((equipment, i) => {
            if (equipment.id === id) {
                equipment[key] = value;
            }
        });

        // Update automated values in CIPs
        this.setCipAutomaticProperties(context);
    }

    addEquipment(name) {
        if (name.trim().length > 0) {
            this.equipments.push(new Equipment({name: name.trim()}));
        }
    }

    setCipProperty(context, type, productType, key, value) {
        if (key !== 'product') {
            value = (value + '').replace(',', '.');
            if (isNaN(value)) {
                value = '';
            }
        }

        // Set the value
        this.cips[type].products[productType][key] = value;

        if (type === CipTypes.actual) {
            // Copy values to other CIPs
            if (productType === ProductTypes.CausticSoda) {
                if (key === 'product') {
                    this.setCipProperty(context, CipTypes.monoAlternate, productType, key, value);
                    this.setCipProperty(context, CipTypes.sodaAdditive, productType, key, value);
                    this.setCipProperty(context, CipTypes.monoSodaAdditive, productType, key, value);
                }

                if (key === 'concentrationNaohHno3') {
                    this.setCipProperty(context, CipTypes.monoAlternate, productType, key, value);
                    this.setCipProperty(context, CipTypes.sodaAdditive, productType, key, parseFloat(value * 0.8).toFixed(2));
                    this.setCipProperty(context, CipTypes.monoSodaAdditive, productType, key, parseFloat(value * 0.8).toFixed(2));
                }

                if (key === 'concentration') {
                    this.setCipProperty(context, CipTypes.monoAlternate, productType, key, value);
                    this.setCipProperty(context, CipTypes.sodaAdditive, productType, key, parseFloat(value * 0.8).toFixed(2));
                    this.setCipProperty(context, CipTypes.monoSodaAdditive, productType, key, parseFloat(value * 0.8).toFixed(2));
                }

                if (key === 'volume') {
                    this.setCipProperty(context, CipTypes.sodaAdditive, productType, key, parseFloat(value * 0.8).toFixed(2));
                }

                if (key === 'pricing') {
                    this.setCipProperty(context, CipTypes.monoAlternate, productType, key, value);
                    this.setCipProperty(context, CipTypes.sodaAdditive, productType, key, value);
                    this.setCipProperty(context, CipTypes.monoSodaAdditive, productType, key, value);
                }
            }

            if (productType === ProductTypes.Acid) {
                this.setCipProperty(context, CipTypes.sodaAdditive, productType, key, value);
            }

            if (productType === ProductTypes.Desinfectant) {
                this.setCipProperty(context, CipTypes.sodaAdditive, productType, key, value);
            }

            if (key === 'pricing' && productType === ProductTypes.Water) {
                this.setCipProperty(context, CipTypes.monoAlternate, productType, key, value);
                this.setCipProperty(context, CipTypes.sodaAdditive, productType, key, value);
                this.setCipProperty(context, CipTypes.monoSodaAdditive, productType, key, value);
            }
        }

        if (type === CipTypes.monoAlternate) {
            // Copy values to other CIPs

            if (productType === ProductTypes.Acid) {
                this.setCipProperty(context, CipTypes.monoSodaAdditive, productType, key, value);
            }

            if (productType === ProductTypes.Desinfectant) {
                this.setCipProperty(context, CipTypes.monoSodaAdditive, productType, key, value);
            }
        }

        if (type === CipTypes.sodaAdditive) {
            if (productType === ProductTypes.Additive) {
                if (key === 'concentration' || key === 'pricing') {
                    this.setCipProperty(context, CipTypes.monoSodaAdditive, productType, key, value);
                }
            }
        }

        // Set automatic calculated values
        this.setCipAutomaticProperties(context);

        // #### Product concentration ####
        if (type === CipTypes.monoSodaAdditive
            && ['product', 'concentrationNaohHno3'].includes(key)
            && [ProductTypes.CausticSoda, ProductTypes.Acid].includes(productType)
            && this.cips[type].products[productType]['product']
            && this.cips[type].products[productType]['concentrationNaohHno3']
        ) {
            if (context.product?.data[this.cips[type].products[productType]['product']]?.sodaConcentration) {
                this.cips[type].products[productType]['concentration'] = (parseFloat(this.cips[type].products[productType]['concentrationNaohHno3']) / parseFloat(context.product.data[this.cips[type].products[productType]['product']].sodaConcentration) * 100).toFixed(2);
                this.setCipAutomaticProperties(context);
            }
        }
        // #### Product concentration ####
    }

    setCipAutomaticProperties(context) {
        // #### Volume ####
        // Caustic Soda
        if (this.cips[CipTypes.actual].products[ProductTypes.CausticSoda]['volume']) {
            this.cips[CipTypes.monoAlternate].products[ProductTypes.CausticSoda]['volume'] = ((parseFloat(this.cips[CipTypes.actual].products[ProductTypes.CausticSoda]['volume']) / this.getSommeNbWashingStep(context, config.washingStepSoudeId, null, true)) * this.getSommeNbWashingStep(context, config.washingStepSoudeId, true, null)).toFixed(2);
            this.cips[CipTypes.monoSodaAdditive].products[ProductTypes.CausticSoda]['volume'] = (parseFloat(this.cips[CipTypes.monoAlternate].products[ProductTypes.CausticSoda]['volume']) * 0.8).toFixed(2);
        }
        // Acid
        if (this.cips[CipTypes.actual].products[ProductTypes.Acid]['volume'] && this.cips[CipTypes.actual].products[ProductTypes.Acid]['concentration']) {
            this.cips[CipTypes.monoAlternate].products[ProductTypes.Acid]['volume'] = (((parseFloat(this.cips[CipTypes.actual].products[ProductTypes.Acid]['volume']) / this.getSommeNbWashingStep(context, config.washingStepAcidId, null, true)) * this.getSommeNbWashingStep(context, config.washingStepAcidId, true, null) / parseFloat(this.cips[CipTypes.actual].products[ProductTypes.Acid]['concentration']).toFixed(2)) * parseFloat(this.cips[CipTypes.monoAlternate].products[ProductTypes.Acid]['concentration'])).toFixed(2);
            this.cips[CipTypes.monoSodaAdditive].products[ProductTypes.Acid]['volume'] = this.cips[CipTypes.monoAlternate].products[ProductTypes.Acid]['volume'];
        }
        // Desinfectant
        if (this.cips[CipTypes.actual].products[ProductTypes.Desinfectant]['volume'] && this.cips[CipTypes.actual].products[ProductTypes.Desinfectant]['concentration']) {
            this.cips[CipTypes.monoAlternate].products[ProductTypes.Desinfectant]['volume'] = (((parseFloat(this.cips[CipTypes.actual].products[ProductTypes.Desinfectant]['volume']) / this.getSommeNbWashingStep(context, config.washingStepDesinfectant, null, true)) * this.getSommeNbWashingStep(context, config.washingStepDesinfectant, true, null) / parseFloat(this.cips[CipTypes.actual].products[ProductTypes.Desinfectant]['concentration']).toFixed(2)) * parseFloat(this.cips[CipTypes.monoAlternate].products[ProductTypes.Desinfectant]['concentration'])).toFixed(2);
            this.cips[CipTypes.monoSodaAdditive].products[ProductTypes.Desinfectant]['volume'] = this.cips[CipTypes.monoAlternate].products[ProductTypes.Desinfectant]['volume'];
        }
        // Additive
        if (this.cips[CipTypes.sodaAdditive].products[ProductTypes.Additive]['concentration'] && this.cips[CipTypes.sodaAdditive].products[ProductTypes.CausticSoda]['volume']) {
            this.cips[CipTypes.sodaAdditive].products[ProductTypes.Additive]['volume'] = (parseFloat(this.cips[CipTypes.sodaAdditive].products[ProductTypes.Additive]['concentration']) * parseFloat(this.cips[CipTypes.sodaAdditive].products[ProductTypes.CausticSoda]['volume']) / 100).toFixed(2);
        }
        if (this.cips[CipTypes.monoSodaAdditive].products[ProductTypes.Additive]['concentration'] && this.cips[CipTypes.monoSodaAdditive].products[ProductTypes.CausticSoda]['volume']) {
            this.cips[CipTypes.monoSodaAdditive].products[ProductTypes.Additive]['volume'] = (parseFloat(this.cips[CipTypes.monoSodaAdditive].products[ProductTypes.Additive]['concentration']) * parseFloat(this.cips[CipTypes.monoSodaAdditive].products[ProductTypes.CausticSoda]['volume']) / 100).toFixed(2);
        }
        // #### Volume ####

        // #### Water and Occupation Time ####
        // Actual
        this.cips[CipTypes.actual].products[ProductTypes.Water]['volume'] = this.getSommeConsoEauAnnuelleWashingSteps(context).toFixed(0);
        this.cips[CipTypes.actual].products[ProductTypes.OccupationTime]['volume'] = parseFloat(this.getSommeDureeTotalLavagesMensuels(context).toFixed(0) * 12 / 3600).toFixed(0);

        this.cips[CipTypes.monoAlternate].products[ProductTypes.Water]['volume'] = this.getSommeConsoEauAnnuelleWashingSteps(context, CipTypes.monoAlternate).toFixed(0);
        this.cips[CipTypes.monoAlternate].products[ProductTypes.OccupationTime]['volume'] = parseFloat(this.getSommeMonoAlterneeDureeTotalLavages(context).toFixed(3) * 12 / 3600).toFixed(0);

        this.cips[CipTypes.sodaAdditive].products[ProductTypes.Water]['volume'] = this.getAdditiveSommeConsoEauAnnuelle(context).toFixed(0);
        this.cips[CipTypes.sodaAdditive].products[ProductTypes.OccupationTime]['volume'] = this.cips[CipTypes.actual].products[ProductTypes.OccupationTime]['volume'];

        this.cips[CipTypes.monoSodaAdditive].products[ProductTypes.Water]['volume'] = this.cips[CipTypes.monoAlternate].products[ProductTypes.Water]['volume'];
        this.cips[CipTypes.monoSodaAdditive].products[ProductTypes.OccupationTime]['volume'] = this.cips[CipTypes.monoAlternate].products[ProductTypes.OccupationTime]['volume']

        // #### Annual CA ####
        Object.keys(ProductTypes).forEach((productType) => {
            Object.keys(CipTypes).forEach((key) => {
                if (this.cips[CipTypes[key]].products[ProductTypes[productType]]['volume'] && this.cips[CipTypes[key]].products[ProductTypes[productType]]['pricing']) {
                    this.cips[CipTypes[key]].products[ProductTypes[productType]]['annualCa'] = (parseFloat(this.cips[CipTypes[key]].products[ProductTypes[productType]]['volume']) * parseFloat(this.cips[CipTypes[key]].products[ProductTypes[productType]]['pricing'])).toFixed(2);
                }
            });
        });
        // #### Annual CA ####

        // #### Global costs ####
        Object.keys(CipTypes).forEach((key) => {
            this.cips[CipTypes[key]].globalCost = this.getGlobalCost(CipTypes[key]);
            this.cips[CipTypes[key]].delta = this.getDelta(CipTypes[key]);
            this.cips[CipTypes[key]].optimizedWater = this.getOptimizedWater(context, CipTypes[key]);
            this.cips[CipTypes[key]].optimizedWaterCost = this.getOptimizedWaterCost(CipTypes[key]);
            this.cips[CipTypes[key]].optimizedTime = this.getOptimizedTime(context, CipTypes[key]);
            this.cips[CipTypes[key]].co2EmissionsDelta = this.getCo2EmissionsDelta(context, CipTypes[key]);
            this.cips[CipTypes[key]].co2EmissionsDeltaKg = this.getCo2EmissionsDeltaKg(context, CipTypes[key]);
        });
        // #### Global costs ####

        // #### Ratio Note ####
        [ProductTypes.CausticSoda, ProductTypes.Acid].forEach((productType) => {
            this.cips[CipTypes.actual].products[productType].ratio = parseFloat(parseFloat((parseFloat(this.cips[CipTypes.actual].products[productType]['volume']) / this.getSommeNbWashingStep(context, productType === ProductTypes.CausticSoda ? config.washingStepSoudeId : config.washingStepAcidId, null, true)) / parseFloat(this.cips[CipTypes.actual].products[productType]['concentration'])).toFixed(1));
            this.cips[CipTypes.actual].products[productType].ratioNote = getRatioNote(this.cips[CipTypes.actual].products[productType].ratio);
        });
        // #### Ratio Note ####

        // #### Potential Gains ####
        Object.keys(CipTypes).forEach((cipType) => {
            if (cipType !== CipTypes.actual) {
                [ProductTypes.CausticSoda, ProductTypes.Acid].forEach((productType) => {
                    Object.keys(RatingRatios).forEach((key) => {
                        let ratingRatio = RatingRatios[key];

                        if (this.cips[CipTypes.actual].products[productType].ratio && this.cips[CipTypes[cipType]].products[productType]['annualCa']) {
                            this.cips[CipTypes[cipType]].products[productType].ratios[ratingRatio.letter] = ((parseFloat(parseFloat(this.cips[CipTypes[cipType]].products[productType]['annualCa']).toFixed(0)) / parseFloat(this.cips[CipTypes.actual].products[productType].ratio)) * ratingRatio.averageValue) - parseFloat(parseFloat(this.cips[CipTypes[cipType]].products[productType]['annualCa']).toFixed(0));
                        } else {
                            this.cips[CipTypes[cipType]].products[productType].ratios[ratingRatio.letter] = 0;
                        }
                    });
                });
            }
        });
        // #### Potential Gains ####
    }

    removeEquipment(context, id) {
        this.equipments.forEach((equipment, i) => {
            if (equipment.id === id) {
                this.equipments.splice(i, 1);
            }
        });

        this.setCipAutomaticProperties(context);
    }

    getSommeDureeTotalLavagesMensuels(context) {
        let sum = 0;
        this.equipments.forEach((equipment) => {
            sum += parseFloat(equipment.getDureeTotalLavagesMensuels(context));
        });

        return sum;
    }

    getSommeNbWashingStep(context, washingStep, washingType, desinfection) {
        let sum = 0;
        let equipmentWashingType = null;
        let equipmentDesinfection = desinfection;
        let skip = false;

        this.equipments.forEach((equipment) => {
            skip = false;
            equipmentWashingType = null;

            if (washingType !== null) {
                if (equipment.getTypeCircuit(context) !== null) {
                    equipmentWashingType = equipment.getTypeCircuit(context).id;
                } else {
                    skip = true;
                }
            }

            if (desinfection === null) {
                equipmentDesinfection = equipment.maintientDesinfection;
            }

            if (!skip) {
                sum += parseFloat(equipment.getNbWashingStep(context, washingStep, equipmentWashingType, equipment.cycleLavage, equipmentDesinfection));
            }
        });

        return sum * 12;
    }

    getSommeConsoEauAnnuelleWashingStep(context, washingStep, initialCip = true) {
        let sum = 0;
        this.equipments.forEach((equipment) => {
            let washingType = null;
            let cycleLavage = null;
            let desinfection = null;

            if (!initialCip && equipment.getTypeCircuit(context) !== null) {
                washingType = equipment.getTypeCircuit(context).id;
                cycleLavage = equipment.cycleLavage;
                desinfection = equipment.maintientDesinfection;
            }

            sum += parseFloat(equipment.getConsoEauAnnuelleWashingStep(context, washingStep, washingType, cycleLavage, desinfection));
        });

        return sum;
    }

    getSommeConsoEauAnnuelleWashingSteps(context, cipType = CipTypes.actual) {
        let sum = 0;
        Object.keys(context.washingstep.data).forEach((washingStepId) => {
            if (!context.washingstep.data[washingStepId].rince && context.washingstep.data[washingStepId].productType) {
                sum += this.getSommeConsoEauAnnuelleProductType(context, cipType, context.washingstep.data[washingStepId].productType);
            }

            if (context.washingstep.data[washingStepId].rince) {
                sum += this.getSommeConsoEauAnnuelleWashingStep(context, washingStepId, cipType === CipTypes.actual);
            }
        });

        return sum;
    }

    getSommeConsoEauAnnuelleProductType(context, cipType, productType) {
        let total = 0;
        if (this.cips[cipType].products[productType]['volume'] && this.cips[cipType].products[productType]['concentration']) {
            total = (parseFloat(this.cips[cipType].products[productType]['volume']) / parseFloat(this.cips[cipType].products[productType]['concentration'])) * (100 - parseFloat(this.cips[cipType].products[productType]['concentration'])) / 1000;
        }

        return total;
    }

    getSommeMonoAlterneeDureeTotalLavages(context) {
        let sum = 0;
        this.equipments.forEach((equipment) => {
            sum += parseFloat(equipment.getMonoAlterneeDureeTotalLavages(context, this));
        });

        return sum;
    }

    getAdditiveSommeConsoEauAnnuelle(context) {
        let sum = 0;

        Object.keys(context.washingstep.data).forEach((washingStepId) => {
            if (context.washingstep.data[washingStepId].productType && !context.washingstep.data[washingStepId].rince) {
                sum += parseFloat(this.getSommeConsoEauAnnuelleProductType(context, CipTypes.sodaAdditive, context.washingstep.data[washingStepId].productType).toFixed(0));
            }
        });

        Object.keys(context.washingstep.data).forEach((washingStepId) => {
            if (context.washingstep.data[washingStepId].rince) {
                sum += parseFloat(this.getSommeConsoEauAnnuelleWashingStep(context, washingStepId).toFixed(0));
            }
        });

        return sum;
    }

    getMonoAdditiveSommeConsoEauAnnuelle(context) {
        let sum = 0;

        Object.keys(context.washingstep.data).forEach((washingStepId) => {
            if (context.washingstep.data[washingStepId].productType && !context.washingstep.data[washingStepId].rince) {
                sum += parseFloat(this.getSommeConsoEauAnnuelleProductType(context, CipTypes.monoSodaAdditive, context.washingstep.data[washingStepId].productType).toFixed(0));
            }
        });

        Object.keys(context.washingstep.data).forEach((washingStepId) => {
            if (context.washingstep.data[washingStepId].rince) {
                sum += parseFloat(this.getSommeConsoEauAnnuelleWashingStep(context, washingStepId, false).toFixed(0));
            }
        });

        return sum;
    }

    getSommeOptiConsoEauAnnuelleWashingStep(context, washingStepId, cipType) {
        let sum = 0;

        this.equipments.forEach((equipment) => {
            if (cipType === CipTypes.monoAlternate && equipment.getTypeCircuit(context) !== null) {
                sum += equipment.getOptiMonoAlternateConsoEauAnnuelleWashingStep(context, washingStepId, equipment.getTypeCircuit(context).id, equipment.cycleLavage, equipment.maintientDesinfection);
            }

            if (cipType === CipTypes.sodaAdditive) {
                sum += equipment.getOptiAdditiveConsoEauAnnuelleWashingStep(context, washingStepId);
            }
        });

        return sum;
    }

    getOptiMonoAlternateSommeConsoEauAnnuelle(context) {
        let sum = 0;

        Object.keys(context.washingstep.data).forEach((washingStepId) => {
            if (context.washingstep.data[washingStepId].productType && !context.washingstep.data[washingStepId].rince) {
                sum += parseFloat(this.getSommeConsoEauAnnuelleProductType(context, CipTypes.monoSodaAdditive, context.washingstep.data[washingStepId].productType).toFixed(0));
            }
        });

        Object.keys(context.washingstep.data).forEach((washingStepId) => {
            if (context.washingstep.data[washingStepId].rince) {
                sum += parseFloat(this.getSommeOptiConsoEauAnnuelleWashingStep(context, washingStepId, CipTypes.monoAlternate).toFixed(0));
            }
        });

        return sum;
    }

    getSommeOptiMonoAlternateDureeTotalAnnuelle(context) {
        let sum = 0;

        this.equipments.forEach((equipment) => {
            sum += parseFloat(equipment.getOptiMonoAlternateDureeTotalAnnuelle(context).toFixed(0));
        });

        return sum;
    }

    getSommeOptiAdditiveDureeTotalAnnuelle(context) {
        let sum = 0;

        this.equipments.forEach((equipment) => {
            sum += parseFloat(equipment.getOptiAdditiveDureeTotalAnnuelle(context).toFixed(0));
        });

        return sum;
    }

    getOptiAdditiveSommeConsoEauAnnuelle(context) {
        let sum = 0;

        Object.keys(context.washingstep.data).forEach((washingStepId) => {
            if (context.washingstep.data[washingStepId].productType && !context.washingstep.data[washingStepId].rince) {
                sum += parseFloat(this.getSommeConsoEauAnnuelleProductType(context, CipTypes.sodaAdditive, context.washingstep.data[washingStepId].productType).toFixed(0));
            }
        });

        Object.keys(context.washingstep.data).forEach((washingStepId) => {
            if (context.washingstep.data[washingStepId].rince) {
                sum += parseFloat(this.getSommeOptiConsoEauAnnuelleWashingStep(context, washingStepId, CipTypes.sodaAdditive).toFixed(0));
            }
        });

        return sum;
    }

    getGlobalCost(cipType) {
        let sum = 0;

        Object.keys(ProductTypes).forEach((productType) => {
            sum += parseFloat(parseFloat(this.cips[cipType].products[ProductTypes[productType]]['annualCa'] ? this.cips[cipType].products[ProductTypes[productType]]['annualCa'] : 0).toFixed(0));
        });

        return sum;
    }

    getDelta(cipType) {
        return this.cips[cipType].globalCost - this.cips[CipTypes.actual].globalCost;
    }

    getOptimizedWater(context, cipType) {
        if (cipType === CipTypes.monoAlternate) {
            return parseFloat(parseFloat(this.getOptiMonoAlternateSommeConsoEauAnnuelle(context)).toFixed(0));
        }

        if (cipType === CipTypes.sodaAdditive) {
            return parseFloat(parseFloat(this.getOptiAdditiveSommeConsoEauAnnuelle(context)).toFixed(0));
        }

        if (cipType === CipTypes.monoSodaAdditive) {
            return parseFloat(this.cips[CipTypes.monoSodaAdditive].products[ProductTypes.Water].volume ? this.cips[CipTypes.monoSodaAdditive].products[ProductTypes.Water].volume : 0) - (parseFloat(this.cips[CipTypes.sodaAdditive].products[ProductTypes.Water].volume ? this.cips[CipTypes.sodaAdditive].products[ProductTypes.Water].volume : 0) - this.cips[CipTypes.sodaAdditive].optimizedWater);
        }

        return 0;
    }

    getOptimizedWaterCost(cipType) {
        return parseFloat(parseFloat(this.cips[cipType].optimizedWater * parseFloat(this.cips[CipTypes.monoSodaAdditive].products[ProductTypes.Water].pricing ? this.cips[CipTypes.monoSodaAdditive].products[ProductTypes.Water].pricing : 0)).toFixed(0));
    }

    getOptimizedTime(context, cipType) {
        if (cipType === CipTypes.monoAlternate) {
            return parseFloat(parseFloat(parseFloat(this.getSommeOptiMonoAlternateDureeTotalAnnuelle(context)).toFixed(0)) * 12 / 3600).toFixed(0);
        }

        if (cipType === CipTypes.sodaAdditive) {
            return parseFloat(parseFloat(parseFloat(this.getSommeOptiAdditiveDureeTotalAnnuelle(context)).toFixed(0)) * 12 / 3600).toFixed(0);
        }

        if (cipType === CipTypes.monoSodaAdditive) {
            return parseFloat(this.cips[CipTypes.monoSodaAdditive].products[ProductTypes.OccupationTime].volume ? this.cips[CipTypes.monoSodaAdditive].products[ProductTypes.OccupationTime].volume : 0) - (parseFloat(this.cips[CipTypes.sodaAdditive].products[ProductTypes.OccupationTime].volume ? this.cips[CipTypes.sodaAdditive].products[ProductTypes.OccupationTime].volume : 0) - this.cips[CipTypes.sodaAdditive].optimizedTime);
        }

        return 0;
    }

    getCo2EmissionsDelta(context, cipType) {
        const cipEmissions = this.calculateCo2Emissions(context, cipType);
        const actualEmissions = this.calculateCo2Emissions(context, CipTypes.actual);

        console.log(cipType, cipEmissions, actualEmissions);
        if (cipEmissions === null || actualEmissions === null) {
            return NaN;
        }

        return (((cipEmissions - actualEmissions) / actualEmissions) * 100).toFixed(2);
    }

    getCo2EmissionsDeltaKg(context, cipType) {
        const cipEmissions = this.calculateCo2Emissions(context, cipType);
        const actualEmissions = this.calculateCo2Emissions(context, CipTypes.actual);

        if (cipEmissions === null || actualEmissions === null) {
            return NaN;
        }

        return (cipEmissions - actualEmissions).toFixed(2);
    }

    calculateCo2Emissions(context, cipType) {
        let sum = 0;

        for (const productType of Object.keys(ProductTypes)) {
            const productId = this.cips[cipType].products[ProductTypes[productType]].product;
            if (!productId) {
                break;
            }
            const product = context.product.data[productId];
            const co2Emissions = parseFloat(product.co2Emissions);
            const co2EmissionsCondition = parseFloat(product.co2EmissionsCondition);
            if (isNaN(co2Emissions) || co2EmissionsCondition < 95.0) {
                sum = null;
                break;
            }
            const volume = parseFloat(this.cips[cipType].products[ProductTypes[productType]].volume);

            sum += co2Emissions * volume;
        }
        return sum;
    }

    getPptxExportData(userContext, quote) {
        const data = {
            'cover-client': [this.society, this.site, this.town].join(' - '),
            'cover-date': this.date ? new Date(this.date).toLocaleDateString("fr") : '',
            'cover-version': this.version,
            'bilan-b3': this.cips[CipTypes.actual].products[ProductTypes.CausticSoda].product ? getTranslation(userContext.product?.data[this.cips[CipTypes.actual].products[ProductTypes.CausticSoda].product]?.name, userContext.user.locale) : '',
            'bilan-b6': Math.round((parseInt(this.cips[CipTypes.actual].products[ProductTypes.CausticSoda].volume ? this.cips[CipTypes.actual].products[ProductTypes.CausticSoda].volume : 0) / 1000) * 10) / 10,
            'bilan-w4': this.getSommeNbWashingStep(userContext, config.washingStepSoudeId, null, true).toFixed(0),
            'bilan-b4': Math.round(parseFloat(this.cips[CipTypes.actual].products[ProductTypes.CausticSoda].concentrationNaohHno3 ? this.cips[CipTypes.actual].products[ProductTypes.CausticSoda].concentrationNaohHno3 : 0) * 10) / 10,
            'bilan-b5': Math.round(parseFloat(this.cips[CipTypes.actual].products[ProductTypes.CausticSoda].concentration ? this.cips[CipTypes.actual].products[ProductTypes.CausticSoda].concentration : 0) * 10) / 10,
            'bilan-e6': parseInt(this.cips[CipTypes.actual].products[ProductTypes.Water].volume ? this.cips[CipTypes.actual].products[ProductTypes.Water].volume : 0),
            'bilan-c3': this.cips[CipTypes.actual].products[ProductTypes.Acid].product ? getTranslation(userContext.product?.data[this.cips[CipTypes.actual].products[ProductTypes.Acid].product]?.name, userContext.user.locale) : '',
            'bilan-c6': Math.round((parseInt(this.cips[CipTypes.actual].products[ProductTypes.Acid].volume ? this.cips[CipTypes.actual].products[ProductTypes.Acid].volume : 0) / 1000) * 10) / 10,
            'bilan-y4': this.getSommeNbWashingStep(userContext, config.washingStepAcidId, null, true).toFixed(0),
            'bilan-c4': Math.round(parseFloat(this.cips[CipTypes.actual].products[ProductTypes.Acid].concentrationNaohHno3 ? this.cips[CipTypes.actual].products[ProductTypes.Acid].concentrationNaohHno3 : 0) * 10) / 10,
            'bilan-c5': Math.round(parseFloat(this.cips[CipTypes.actual].products[ProductTypes.Acid].concentration ? this.cips[CipTypes.actual].products[ProductTypes.Acid].concentration : 0) * 10) / 10,
            'bilan-d3': this.cips[CipTypes.actual].products[ProductTypes.Desinfectant].product ? getTranslation(userContext.product?.data[this.cips[CipTypes.actual].products[ProductTypes.Desinfectant].product]?.name, userContext.user.locale) : '',
            'bilan-d6': Math.round((parseInt(this.cips[CipTypes.actual].products[ProductTypes.Desinfectant].volume ? this.cips[CipTypes.actual].products[ProductTypes.Desinfectant].volume : 0) / 1000) * 10) / 10,
            'bilan-b15': Math.round(parseFloat(this.cips[CipTypes.actual].products[ProductTypes.CausticSoda].ratio ? this.cips[CipTypes.actual].products[ProductTypes.CausticSoda].ratio : 0) * 10) / 10,
            'bilan-c15': Math.round(parseFloat(this.cips[CipTypes.actual].products[ProductTypes.Acid].ratio ? this.cips[CipTypes.actual].products[ProductTypes.Acid].ratio : 0) * 10) / 10,
            'synthese-date': this.date ? new Date(this.date).toLocaleDateString("fr") : '',
            'synth-alc-actu-prod': this.cips[CipTypes.actual].products[ProductTypes.CausticSoda].product ? getTranslation(userContext.product?.data[this.cips[CipTypes.actual].products[ProductTypes.CausticSoda].product]?.name, userContext.user.locale) : '',
            'synth-acd-actu-prod': this.cips[CipTypes.actual].products[ProductTypes.Acid].product ? getTranslation(userContext.product?.data[this.cips[CipTypes.actual].products[ProductTypes.Acid].product]?.name, userContext.user.locale) : '',
            'synth-des-actu-prod': this.cips[CipTypes.actual].products[ProductTypes.Desinfectant].product ? getTranslation(userContext.product?.data[this.cips[CipTypes.actual].products[ProductTypes.Desinfectant].product]?.name, userContext.user.locale) : '',
            'synth-actu-cout': (parseFloat(parseFloat(this.cips[CipTypes.actual].products[ProductTypes.CausticSoda].annualCa ? this.cips[CipTypes.actual].products[ProductTypes.CausticSoda].annualCa : 0).toFixed(0)) + parseFloat(parseFloat(this.cips[CipTypes.actual].products[ProductTypes.Acid].annualCa ? this.cips[CipTypes.actual].products[ProductTypes.Acid].annualCa : 0).toFixed(0)) + parseFloat(parseFloat(this.cips[CipTypes.actual].products[ProductTypes.Desinfectant].annualCa ? this.cips[CipTypes.actual].products[ProductTypes.Desinfectant].annualCa : 0).toFixed(0))) + ' ' + quote.getCurrencySign(),
            'synth-actu-eau': parseFloat(parseFloat(this.cips[CipTypes.actual].products[ProductTypes.Water].volume ? this.cips[CipTypes.actual].products[ProductTypes.Water].volume : 0).toFixed(0)) + ' ' + userContext.unit?.data[config.unitCubicMeters]?.getTranslation({fieldName: 'name', locale: userContext.user.locale}),
            'synth-actu-temps': parseFloat(parseFloat(this.cips[CipTypes.actual].products[ProductTypes.OccupationTime].volume ? this.cips[CipTypes.actual].products[ProductTypes.OccupationTime].volume : 0).toFixed(0)) + ' ' + userContext.unit?.data[config.unitHours]?.getTranslation({fieldName: 'name', locale: userContext.user.locale}),
            'synth-alc-actu-prix': parseFloat(this.cips[CipTypes.actual].products[ProductTypes.CausticSoda].pricing ? this.cips[CipTypes.actual].products[ProductTypes.CausticSoda].pricing : 0).toFixed(3) + quote.getCurrencySign() + '/' + userContext.unit?.data[config.unitKg]?.getTranslation({fieldName: 'name', locale: userContext.user.locale}),
            'synth-acd-actu-prix': parseFloat(this.cips[CipTypes.actual].products[ProductTypes.Acid].pricing ? this.cips[CipTypes.actual].products[ProductTypes.Acid].pricing : 0).toFixed(3) + quote.getCurrencySign() + '/' + userContext.unit?.data[config.unitKg]?.getTranslation({fieldName: 'name', locale: userContext.user.locale}),
            'synth-des-actu-prix': parseFloat(this.cips[CipTypes.actual].products[ProductTypes.Desinfectant].pricing ? this.cips[CipTypes.actual].products[ProductTypes.Desinfectant].pricing : 0).toFixed(3) + quote.getCurrencySign() + '/' + userContext.unit?.data[config.unitKg]?.getTranslation({fieldName: 'name', locale: userContext.user.locale}),
            'synth-actu-eau-cout': (parseFloat(parseFloat(this.cips[CipTypes.actual].products[ProductTypes.Water].annualCa ? this.cips[CipTypes.actual].products[ProductTypes.Water].annualCa : 0).toFixed(0))) + ' ' + quote.getCurrencySign(),
            'synth-alc-soude-prod': this.cips[CipTypes.sodaAdditive].products[ProductTypes.CausticSoda].product ? getTranslation(userContext.product?.data[this.cips[CipTypes.sodaAdditive].products[ProductTypes.CausticSoda].product]?.name, userContext.user.locale) : '',
            'synth-acd-soude-prod': this.cips[CipTypes.sodaAdditive].products[ProductTypes.Acid].product ? getTranslation(userContext.product?.data[this.cips[CipTypes.sodaAdditive].products[ProductTypes.Acid].product]?.name) : '',
            'synth-des-soude-prod': this.cips[CipTypes.sodaAdditive].products[ProductTypes.Desinfectant].product ? getTranslation(userContext.product?.data[this.cips[CipTypes.sodaAdditive].products[ProductTypes.Desinfectant].product]?.name) : '',
            'synth-soude-cout': (parseFloat(parseFloat(this.cips[CipTypes.sodaAdditive].products[ProductTypes.Additive].annualCa ? this.cips[CipTypes.sodaAdditive].products[ProductTypes.Additive].annualCa : 0).toFixed(0)) + parseFloat(parseFloat(this.cips[CipTypes.sodaAdditive].products[ProductTypes.CausticSoda].annualCa ? this.cips[CipTypes.sodaAdditive].products[ProductTypes.CausticSoda].annualCa : 0).toFixed(0)) + parseFloat(parseFloat(this.cips[CipTypes.sodaAdditive].products[ProductTypes.Acid].annualCa ? this.cips[CipTypes.sodaAdditive].products[ProductTypes.Acid].annualCa : 0).toFixed(0)) + parseFloat(parseFloat(this.cips[CipTypes.sodaAdditive].products[ProductTypes.Desinfectant].annualCa ? this.cips[CipTypes.sodaAdditive].products[ProductTypes.Desinfectant].annualCa : 0).toFixed(0))) + ' ' + quote.getCurrencySign(),
            'synth-soude-eau': parseFloat(parseFloat(this.cips[CipTypes.sodaAdditive].optimizedWater ? this.cips[CipTypes.sodaAdditive].optimizedWater : 0).toFixed(0)) + ' ' + userContext.unit?.data[config.unitCubicMeters]?.getTranslation({fieldName: 'name', locale: userContext.user.locale}),
            'synth-soude-temps': parseFloat(parseFloat(this.cips[CipTypes.sodaAdditive].optimizedTime ? this.cips[CipTypes.sodaAdditive].optimizedTime : 0).toFixed(0)) + ' ' + userContext.unit?.data[config.unitHours]?.getTranslation({fieldName: 'name', locale: userContext.user.locale}),
            'synth-alc-soude-%': parseFloat(this.cips[CipTypes.sodaAdditive].products[ProductTypes.CausticSoda].concentration ? this.cips[CipTypes.sodaAdditive].products[ProductTypes.CausticSoda].concentration : 0).toFixed(1) + userContext.unit?.data[config.unitPercent]?.getTranslation({fieldName: 'name', locale: userContext.user.locale}),
            'synth-alc-soude-prod-add': this.cips[CipTypes.sodaAdditive].products[ProductTypes.Additive].product ? getTranslation(userContext.product?.data[this.cips[CipTypes.sodaAdditive].products[ProductTypes.Additive].product]?.name, userContext.user.locale) : '',
            'synth-acd-soude-%': parseFloat(this.cips[CipTypes.sodaAdditive].products[ProductTypes.Acid].concentration ? this.cips[CipTypes.sodaAdditive].products[ProductTypes.Acid].concentration : 0).toFixed(1) + userContext.unit?.data[config.unitPercent]?.getTranslation({fieldName: 'name', locale: userContext.user.locale}),
            'synth-des-soude-%': parseFloat(this.cips[CipTypes.sodaAdditive].products[ProductTypes.Desinfectant].concentration ? this.cips[CipTypes.sodaAdditive].products[ProductTypes.Desinfectant].concentration : 0).toFixed(1) + userContext.unit?.data[config.unitPercent]?.getTranslation({fieldName: 'name', locale: userContext.user.locale}),
            'synth-soude-eau-cout': parseFloat(parseFloat(this.cips[CipTypes.sodaAdditive].optimizedWaterCost ? this.cips[CipTypes.sodaAdditive].optimizedWaterCost : 0).toFixed(0)) + ' ' + quote.getCurrencySign(),
            'synth-alc-soude-%-add': parseFloat(this.cips[CipTypes.sodaAdditive].products[ProductTypes.Additive].concentration ? this.cips[CipTypes.sodaAdditive].products[ProductTypes.Additive].concentration : 0).toFixed(1) + userContext.unit?.data[config.unitPercent]?.getTranslation({fieldName: 'name', locale: userContext.user.locale}),
            'synth-alc-mono-prod': this.cips[CipTypes.monoAlternate].products[ProductTypes.CausticSoda].product ? getTranslation(userContext.product?.data[this.cips[CipTypes.monoAlternate].products[ProductTypes.CausticSoda].product]?.name, userContext.user.locale) : '',
            'synth-acd-mono-prod': this.cips[CipTypes.monoAlternate].products[ProductTypes.Acid].product ? getTranslation(userContext.product?.data[this.cips[CipTypes.monoAlternate].products[ProductTypes.Acid].product]?.name, userContext.user.locale) : '',
            'synth-des-mono-prod': this.cips[CipTypes.monoAlternate].products[ProductTypes.Desinfectant].product ? getTranslation(userContext.product?.data[this.cips[CipTypes.monoAlternate].products[ProductTypes.Desinfectant].product]?.name, userContext.user.locale) : '',
            'synth-mono-cout': (parseFloat(parseFloat(this.cips[CipTypes.monoAlternate].products[ProductTypes.CausticSoda].annualCa ? this.cips[CipTypes.monoAlternate].products[ProductTypes.CausticSoda].annualCa : 0).toFixed(0)) + parseFloat(parseFloat(this.cips[CipTypes.monoAlternate].products[ProductTypes.Acid].annualCa ? this.cips[CipTypes.monoAlternate].products[ProductTypes.Acid].annualCa : 0).toFixed(0)) + parseFloat(parseFloat(this.cips[CipTypes.monoAlternate].products[ProductTypes.Desinfectant].annualCa ? this.cips[CipTypes.monoAlternate].products[ProductTypes.Desinfectant].annualCa : 0).toFixed(0))) + ' ' + quote.getCurrencySign(),
            'synth-mono-eau': parseFloat(parseFloat(this.cips[CipTypes.monoAlternate].optimizedWater ? this.cips[CipTypes.monoAlternate].optimizedWater : 0).toFixed(0)) + ' ' + userContext.unit?.data[config.unitCubicMeters]?.getTranslation({fieldName: 'name', locale: userContext.user.locale}),
            'synth-mono-temps': parseFloat(parseFloat(this.cips[CipTypes.monoAlternate].optimizedTime ? this.cips[CipTypes.monoAlternate].optimizedTime : 0).toFixed(0)) + ' ' + userContext.unit?.data[config.unitHours]?.getTranslation({fieldName: 'name', locale: userContext.user.locale}),
            'synth-alc-mono-%': parseFloat(this.cips[CipTypes.monoAlternate].products[ProductTypes.CausticSoda].concentration ? this.cips[CipTypes.monoAlternate].products[ProductTypes.CausticSoda].concentration : 0).toFixed(1) + userContext.unit?.data[config.unitPercent]?.getTranslation({fieldName: 'name', locale: userContext.user.locale}),
            'synth-acd-mono-%': parseFloat(this.cips[CipTypes.monoAlternate].products[ProductTypes.Acid].concentration ? this.cips[CipTypes.monoAlternate].products[ProductTypes.Acid].concentration : 0).toFixed(1) + userContext.unit?.data[config.unitPercent]?.getTranslation({fieldName: 'name', locale: userContext.user.locale}),
            'synth-des-mono-%': parseFloat(this.cips[CipTypes.monoAlternate].products[ProductTypes.Desinfectant].concentration ? this.cips[CipTypes.monoAlternate].products[ProductTypes.Desinfectant].concentration : 0).toFixed(1) + userContext.unit?.data[config.unitPercent]?.getTranslation({fieldName: 'name', locale: userContext.user.locale}),
            'synth-mono-eau-cout': parseFloat(parseFloat(this.cips[CipTypes.monoAlternate].optimizedWaterCost ? this.cips[CipTypes.monoAlternate].optimizedWaterCost : 0).toFixed(0)) + ' ' + quote.getCurrencySign(),
            'synth-alc-monoalt-prod': this.cips[CipTypes.monoSodaAdditive].products[ProductTypes.CausticSoda].product ? getTranslation(userContext.product?.data[this.cips[CipTypes.monoSodaAdditive].products[ProductTypes.CausticSoda].product]?.name, userContext.user.locale) : '',
            'synth-acd-monoalt-prod': this.cips[CipTypes.monoSodaAdditive].products[ProductTypes.Acid].product ? getTranslation(userContext.product?.data[this.cips[CipTypes.monoSodaAdditive].products[ProductTypes.Acid].product]?.name, userContext.user.locale) : '',
            'synth-des-monoalt-prod': this.cips[CipTypes.monoSodaAdditive].products[ProductTypes.Desinfectant].product ? getTranslation(userContext.product?.data[this.cips[CipTypes.monoSodaAdditive].products[ProductTypes.Desinfectant].product]?.name, userContext.user.locale) : '',
            'synth-monoalt-cout': (parseFloat(parseFloat(this.cips[CipTypes.monoSodaAdditive].products[ProductTypes.Additive].annualCa ? this.cips[CipTypes.monoSodaAdditive].products[ProductTypes.Additive].annualCa : 0).toFixed(0)) + parseFloat(parseFloat(this.cips[CipTypes.monoSodaAdditive].products[ProductTypes.CausticSoda].annualCa ? this.cips[CipTypes.monoSodaAdditive].products[ProductTypes.CausticSoda].annualCa : 0).toFixed(0)) + parseFloat(parseFloat(this.cips[CipTypes.monoSodaAdditive].products[ProductTypes.Acid].annualCa ? this.cips[CipTypes.monoSodaAdditive].products[ProductTypes.Acid].annualCa : 0).toFixed(0)) + parseFloat(parseFloat(this.cips[CipTypes.monoSodaAdditive].products[ProductTypes.Desinfectant].annualCa ? this.cips[CipTypes.monoSodaAdditive].products[ProductTypes.Desinfectant].annualCa : 0).toFixed(0))) + ' ' + quote.getCurrencySign(),
            'synth-monoalt-eau': parseFloat(parseFloat(this.cips[CipTypes.monoSodaAdditive].optimizedWater ? this.cips[CipTypes.monoSodaAdditive].optimizedWater : 0).toFixed(0)) + ' ' + userContext.unit?.data[config.unitCubicMeters]?.getTranslation({fieldName: 'name', locale: userContext.user.locale}),
            'synth-monoalt-temps': parseFloat(parseFloat(this.cips[CipTypes.monoSodaAdditive].optimizedTime ? this.cips[CipTypes.monoSodaAdditive].optimizedTime : 0).toFixed(0)) + ' ' + userContext.unit?.data[config.unitHours]?.getTranslation({fieldName: 'name', locale: userContext.user.locale}),
            'synth-alc-monoalt-%': parseFloat(this.cips[CipTypes.monoSodaAdditive].products[ProductTypes.CausticSoda].concentration ? this.cips[CipTypes.monoSodaAdditive].products[ProductTypes.CausticSoda].concentration : 0).toFixed(1) + userContext.unit?.data[config.unitPercent]?.getTranslation({fieldName: 'name', locale: userContext.user.locale}),
            'synth-alc-monoalt-prod-add': this.cips[CipTypes.monoSodaAdditive].products[ProductTypes.Additive].product ? getTranslation(userContext.product?.data[this.cips[CipTypes.monoSodaAdditive].products[ProductTypes.Additive].product]?.name, userContext.user.locale) : '',
            'synth-acd-monoalt-%': parseFloat(this.cips[CipTypes.monoSodaAdditive].products[ProductTypes.Acid].concentration ? this.cips[CipTypes.monoSodaAdditive].products[ProductTypes.Acid].concentration : 0).toFixed(1) + userContext.unit?.data[config.unitPercent]?.getTranslation({fieldName: 'name', locale: userContext.user.locale}),
            'synth-des-monoalt-%': parseFloat(this.cips[CipTypes.monoSodaAdditive].products[ProductTypes.Desinfectant].concentration ? this.cips[CipTypes.monoSodaAdditive].products[ProductTypes.Desinfectant].concentration : 0).toFixed(1) + userContext.unit?.data[config.unitPercent]?.getTranslation({fieldName: 'name', locale: userContext.user.locale}),
            'synth-monoalt-eau-cout': parseFloat(parseFloat(this.cips[CipTypes.monoSodaAdditive].optimizedWaterCost ? this.cips[CipTypes.monoSodaAdditive].optimizedWaterCost : 0).toFixed(0)) + ' ' + quote.getCurrencySign(),
            'synth-alc-monoalt-%-add': parseFloat(this.cips[CipTypes.monoSodaAdditive].products[ProductTypes.Additive].concentration ? this.cips[CipTypes.monoSodaAdditive].products[ProductTypes.Additive].concentration : 0).toFixed(1) + userContext.unit?.data[config.unitPercent]?.getTranslation({fieldName: 'name', locale: userContext.user.locale}),
            'editor': userContext.user.fullname,
            'synth-soude-co2-%': parseFloat(parseFloat(this.cips[CipTypes.sodaAdditive].co2EmissionsDelta ? this.cips[CipTypes.sodaAdditive].co2EmissionsDelta : 0).toFixed(0)) + ' ' + userContext.unit?.data[config.unitPercent]?.getTranslation({ fieldName: 'name', locale: userContext.user.locale }),
            'synth-soude-co2-kg': parseFloat(parseFloat(this.cips[CipTypes.sodaAdditive].co2EmissionsDeltaKg ? this.cips[CipTypes.sodaAdditive].co2EmissionsDeltaKg : 0).toFixed(0)) + ' ' + userContext.unit?.data[config.unitKg]?.getTranslation({ fieldName: 'name', locale: userContext.user.locale }),
            'synth-mono-co2-%': parseFloat(parseFloat(this.cips[CipTypes.monoAlternate].co2EmissionsDelta ? this.cips[CipTypes.monoAlternate].co2EmissionsDelta : 0).toFixed(0)) + ' ' + userContext.unit?.data[config.unitPercent]?.getTranslation({ fieldName: 'name', locale: userContext.user.locale }),
            'synth-mono-co2-kg': parseFloat(parseFloat(this.cips[CipTypes.monoAlternate].co2EmissionsDeltaKg ? this.cips[CipTypes.monoAlternate].co2EmissionsDeltaKg : 0).toFixed(0)) + ' ' + userContext.unit?.data[config.unitKg]?.getTranslation({ fieldName: 'name', locale: userContext.user.locale }),
            'synth-monoalt-co2-%': parseFloat(parseFloat(this.cips[CipTypes.monoSodaAdditive].co2EmissionsDelta ? this.cips[CipTypes.monoSodaAdditive].co2EmissionsDelta : 0).toFixed(0)) + ' ' + userContext.unit?.data[config.unitPercent]?.getTranslation({ fieldName: 'name', locale: userContext.user.locale }),
            'synth-monoalt-co2-kg': parseFloat(parseFloat(this.cips[CipTypes.monoSodaAdditive].co2EmissionsDeltaKg ? this.cips[CipTypes.monoSodaAdditive].co2EmissionsDeltaKg : 0).toFixed(0)) + ' ' + userContext.unit?.data[config.unitKg]?.getTranslation({ fieldName: 'name', locale: userContext.user.locale })
    };

        data['synth-add-gain'] = ((Math.round(((parseFloat(data['synth-actu-cout'].replace(' ' + quote.getCurrencySign(), '')) + parseFloat(data['synth-actu-eau-cout'].replace(' ' + quote.getCurrencySign(), ''))) - (parseFloat(data['synth-soude-cout'].replace(' ' + quote.getCurrencySign(), '')) + parseFloat(data['synth-soude-eau-cout'].replace(' ' + quote.getCurrencySign(), '')))) / 1000) * 10) / 10) + 'K' + quote.getCurrencySign();
        data['synth-addmono-gain'] = ((Math.round(((parseFloat(data['synth-actu-cout'].replace(' ' + quote.getCurrencySign(), '')) + parseFloat(data['synth-actu-eau-cout'].replace(' ' + quote.getCurrencySign(), ''))) - (parseFloat(data['synth-monoalt-cout'].replace(' ' + quote.getCurrencySign(), '')) + parseFloat(data['synth-monoalt-eau-cout'].replace(' ' + quote.getCurrencySign(), '')))) / 1000) * 10) / 10) + 'K' + quote.getCurrencySign();
        data['synth-opti-budget'] = Math.round(((1 - ((parseFloat(data['synth-actu-cout'].replace(' ' + quote.getCurrencySign(), '')) + parseFloat(data['synth-actu-eau-cout'].replace(' ' + quote.getCurrencySign(), ''))) / (parseFloat(data['synth-monoalt-cout'].replace(' ' + quote.getCurrencySign(), '')) + parseFloat(data['synth-monoalt-eau-cout'].replace(' ' + quote.getCurrencySign(), ''))))) * 100) * 10) / 10;
        data['synth-temps'] = parseInt(data['synth-actu-temps'].replace(' ' + userContext.unit?.data[config.unitHours]?.getTranslation({fieldName: 'name', locale: userContext.user.locale}), '')) - parseInt(data['synth-monoalt-temps'].replace(' ' + userContext.unit?.data[config.unitHours]?.getTranslation({fieldName: 'name', locale: userContext.user.locale}), ''));

        return data;
    }
}