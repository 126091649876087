import {config} from "../components/config/Config";
import {QuoteProduct} from "./QuoteProduct";
import moment from 'moment'
import {getTranslation} from "../components/tools/Translation";
import _ from "lodash";
import Debug from "../components/tools/Debug";
import Encryption from "../components/tools/Encryption";
import {Apps} from "./Apps";

export class Quote {

    app = null;
    categories = {};
    categoriesSoftWater = {};
    country = 2;
    currency = 1;
    displayEmptyCategories = true;
    emptyPricesCount = 0;
    generalData = {};
    haveRoboticSystem = false;
    id = moment().valueOf();
    isCalculatorLactoducVisible = false;
    isCalculatorProtocolVisible = false;
    isCalculatorValorisationVisible = false;
    isLoadedFromLocalStorage = false;
    isModified = false;
    isMosaicVisible = false;
    isPriceListVisible = false;
    isProductDetailVisible = false;
    locale = "fr";
    manualEstimatedConsumptionValue = {};
    name = 'Easycalc-' + moment().format("YYYY-MM-DD hh:mm");
    numberOfMonths = 12;
    priceListLoaded = false;
    priceListSortOrder = 'asc';
    prices = {};
    protocolMultiplier = 1;
    selectedProductData = null;
    showProductWithNoFormat = false;
    species = 1;
    lastModifDate = moment().valueOf();
    toBeDeleted = false;
    testMode = false;
    quoteAccepted = false;
    application = Apps.Farm;
    appRelatedData = {};

    constructor(data) {
        Debug.log('Quote::constructor');

        this.settings = {
            'options.globaldiscount': false,
            'options.udderhygienediscount': false,
            'options.costper1000lmilk': true,
            'options.costper1lmilk': true,
            'options.costpercowpermonth': true,
            'options.globalcostpercowpermonth': true,
            'options.customerwidget': true,
            'options.approval': true,
            'options.paperSizeA4': true
        };

        Object.keys(this.settings).forEach((setting) => {
            if (localStorage.getItem(setting) !== null) {
                this.settings[setting] = localStorage.getItem(setting) === "true" || localStorage.getItem(setting) === true;
            }
        });

        this.showPrintPreview = false;
        this.isPrintPreviewVisible = false;

        if (data) {
            Object.assign(this, data);
        }
    }

    setNumberOfMonths(value) {
        this.numberOfMonths = value;
        this.setProductNumberOfMonths();
        this.updateContextState();
    }

    resetPriceList = (basePrices) => {
        this.prices = Object.assign({}, basePrices);
        this.updateContextState(true);
    }

    setPriceListFromImport = (prices) => {
        Object.assign(this.prices, prices);
        this.updateContextState(true);
    }

    showPriceList = () => {
        Debug.log('Quote::showPriceList');
        this.isPriceListVisible = true;
        this.updateContextState(true);
    }

    hidePriceList = () => {
        this.isPriceListVisible = false;
        this.updateContextState(true);
    }

    showProductDetailsPopup = (product) => {
        this.selectedProductData = product;
        this.isProductDetailVisible = true;
        this.updateContextState(true);
    }

    hideProductDetailsPopup = () => {
        this.isProductDetailVisible = false;
        this.selectedProductData = null;
        this.updateContextState(true);
    }

    showCalculatorLactoduc = () => {
        this.isCalculatorLactoducVisible = true;
        this.updateContextState(true);
    }

    hideCalculatorLactoduc = () => {
        this.isCalculatorLactoducVisible = false;
        this.selectedProductData = null;
        this.updateContextState(true);
    }

    showCalculatorValorisation = () => {
        this.isCalculatorValorisationVisible = true;
        this.updateContextState(true);
    }

    hideCalculatorValorisation = () => {
        this.isCalculatorValorisationVisible = false;
        this.selectedProductData = null;
        this.updateContextState(true);
    }

    setSelectedProductData(data) {
        this.selectedProductData = data;
        this.updateContextState(true);
    }

    showCalculatorProtocol = () => {
        this.isCalculatorProtocolVisible = true;
        this.updateContextState(true);
    }

    hideCalculatorProtocol = () => {
        this.isCalculatorProtocolVisible = false;
        this.selectedProduct = null;
        this.updateContextState(true);
    }

    toggleNoFormat = () => {
        this.showProductWithNoFormat = !this.showProductWithNoFormat;
        this.updateContextState(true);
    }

    switchPriceListOrder() {
        this.priceListSortOrder = this.priceListSortOrder === 'asc' ? 'desc' : 'asc';
        this.updateContextState(true);
    }

    getCategoryProducts(category) {
        if (this.hasCategory(category)) {
            let sortedProductArray = Object.values(this.categories[category]);
            sortedProductArray.sort(this.compareOrdering);

            let sortedProductIds = [];
            sortedProductArray.forEach((data) => {
                sortedProductIds.push(data.id);
            });

            return sortedProductIds;
        }

        return [];
    }

    compareOrdering(a, b) {
        if (a.ordering < b.ordering) {
            return -1;
        }
        if (a.ordering > b.ordering) {
            return 1;
        }
        return 0;
    }

    reorderCategoryProducts(category) {
        let sortedProductArray = Object.values(this.categories[category]);
        sortedProductArray.sort(this.compareOrdering);
        sortedProductArray.forEach((data, index) => {
            if (this.categories[category] && this.categories[category][data.id]) {
                this.categories[category][data.id].ordering = index;
            }
        });
    }

    getProductData(category, product) {
        if (this.hasProduct(category, product)) {
            return this.categories[category][product];
        }

        return new QuoteProduct({quote: this});
    }

    addProduct(category, product, relatedProduct) {
        if (!this.hasCategory(category)) {
            this.categories[category] = {};
        }

        if (!this.hasProduct(category, product)) {

            const productInstance = this.getUserContext().product.data[product];

            // Set the first EstimatedComsumption if there is an Application Type
            const estimatedConsumptions = productInstance.getProductConsumption(this, category);
            let applicationType = null;
            let estimatedConsumptionId = null;
            if (estimatedConsumptions && estimatedConsumptions.length > 0) {
                if (estimatedConsumptions[0].applicationType) {
                    applicationType = estimatedConsumptions[0].applicationType;
                }
                estimatedConsumptionId = estimatedConsumptions[0].id;
            }

            // Set the default value for each parameter in this calculation
            const calculation = productInstance.getCalculation(category, this);

            // If no calculation & estimated consumption, set the estimated consumption to the smallest product format
            let estimatedConsumption = 0;
            let format = productInstance.getSmallestFormat();
            if (format && (!calculation || !estimatedConsumptionId)) {
                estimatedConsumption = format.value;
            }

            let price = 0;
            if (format && productInstance.formats.length === 1) {
                if (this.prices && this.prices[format.id]) {
                    price = this.prices[format.id];
                }
            }

            let discount = null;
            const categoryObject = this.getUserContext().category.data[category];
            if (this.settings['options.udderhygienediscount'] && categoryObject.isUdderHygiene) {
                discount = this.getFirstUdderHygieneDiscount(this.getUserContext().category.data);
            } else if (this.settings['options.globaldiscount']) {
                discount = this.getFirstDiscount();
            }

            this.categories[category][product] = new QuoteProduct({
                quote: this,
                product: productInstance,
                categoryId: category,
                applicationType: applicationType,
                estimatedConsumption: estimatedConsumption,
                estimatedConsumptionId: estimatedConsumptionId,
                calculation: calculation,
                ordering: Object.keys(this.categories[category]).length + 1,
                addedEffect: true,
                toggleScrollToElement: true,
                manuallyAdded: true,
                price: price,
                hasDiscount: discount !== null,
                discount: discount
            });

            if (calculation) {
                calculation.persistToContext(this, this.getUserContext());
            }

            this.checkCartHasItemWithPrice();

            // Prevent scrollToElement when switching to a different Quote
            setTimeout(() => {
                this.categories[category][product].setAddedEffect(false);
                this.categories[category][product].setManuallyAdded(false);
            }, 1000);
        } else {
            if (!relatedProduct) {
                this.categories[category][product].setToggleScrollToElement(!this.categories[category][product].toggleScrollToElement);
            }
            return false;
        }

        if (!relatedProduct && this.getUserContext().product && this.getUserContext().product.data) {
            let productEntity = this.getUserContext().product.data[product] || null;
            if (productEntity && productEntity.linkedProduct && !this.hasProduct(category, productEntity.linkedProduct)) {
                this.addProduct(category, productEntity.linkedProduct, true);
                return;
            }
        }

        this.hideMosaic();

        this.updateContextState();
    }

    hasCategory(category) {
        return this.categories && this.categories[category];
    }

    hasProduct(category, product) {
        return this.hasCategory(category) && this.categories[category][product];
    }

    hasProducts() {
        return Object.keys(this.categories).length > 0;
    }

    categoryHasProductWithPh(category, ph) {
        let hasProduct = false;
        if (ph && this.hasCategory(category)) {
            Object.keys(this.categories[category]).forEach((productId) => {
                let products = this.getUserContext().product.data;
                if (products[productId]) {
                    if (products[productId].ph && products[productId].ph === ph) {
                        hasProduct = true;
                    }
                }
            });
        }

        return hasProduct;
    }

    removeProduct(category, product) {
        if (this.hasProduct(category, product)) {
            this.categories[category][product].setDeleted(true);

            setTimeout(() => {
                delete this.categories[category][product];
                if (Object.keys(this.categories[category]).length === 0) {
                    delete this.categories[category];
                } else {
                    // Reorder the remaining products
                    this.reorderCategoryProducts(category);
                }
                this.checkCartHasItemWithPrice();
                if (!this.hasProducts()) {
                    this.displayEmptyCategories = true;
                }
                this.updateContextState();
            }, config.categoryProductAnimationDelay);
        }
    }

    removeAllCategoryProducts(category) {
        if (this.hasCategory(category)) {
            Object.keys(this.categories[category]).forEach((productId) => {
                this.categories[category][productId].setDeleted(true);
            });

            setTimeout(() => {
                delete this.categories[category];
                this.checkCartHasItemWithPrice();
                if (!this.hasProducts()) {
                    this.displayEmptyCategories = true;
                }
                this.updateContextState();
            }, config.categoryProductAnimationDelay);
        }
    }

    getCategoryTotal(category) {
        let total = 0;
        if (this.hasCategory(category)) {
            Object.keys(this.categories[category]).forEach((productId) => {
                let quoteProduct = this.categories[category][productId];
                if (quoteProduct) {
                    if (!quoteProduct.loaned) {
                        total = total + quoteProduct.total;
                    }
                }
            });
        }

        return total;
    }

    getTotal() {
        let total = 0;
        Object.keys(this.categories).forEach((categoryId) => {
            total = total + this.getCategoryTotal(categoryId);
        });

        return total;
    }

    setCurrency(currency) {
        this.currency = currency;
        this.updateContextState();
    }

    getCurrency() {
        if (this.app && this.getUserContext().currency && this.getUserContext().currency.data && this.getUserContext().currency.data[this.currency]) {
            return this.getUserContext().currency.data[this.currency];
        }

        return null;
    }

    setCountry(country) {
        this.country = country;
        this.updateContextState();
    }

    setLocale(locale) {
        this.locale = locale;
        this.updateContextState();
    }

    getCurrencySign() {
        const currency = this.getCurrency();
        if (currency) {
            return currency.sign;
        }

        return '€';
    }

    setIsLoadedFromLocalStorage(value) {
        this.isLoadedFromLocalStorage = value;
        this.updateContextState();
    }

    createNewQuote() {
        this.reset();
        // @TODO Create a new Quote in the context
    }

    reset() {
        this.categories = {};
        this.isLoadedFromLocalStorage = false;
        this.updateContextState();
    }

    clearQuotes() {
        const quote = new Quote();
        quote.app = this.app;
        quote.application = this.application;

        if (this.app) {
            this.app.setState(function (state) {
                return {
                    quoteContext: Object.assign(state.quoteContext, {
                        availableQuotes: [quote],
                        currentQuote: quote
                    })
                }
            });
        }
    }

    setSpecies(speciesId) {
        this.species = speciesId;
        this.updateContextState();
    }

    setDataFromUser(user) {
        this.setSpecies(user.species);
        this.setCurrency(user.currency);
        this.setCountry(user.country);
        this.setLocale(user.locale);
        this.updateContextState();
    }

    addToAvailableQuotes() {
        if (this.app) {
            let availableQuotes = this.app.state.quoteContext.availableQuotes;
            availableQuotes.push(this);
            this.app.setState((state) => ({
                quoteContext: Object.assign(state.quoteContext, {availableQuotes: availableQuotes})
            }));
        }
    }

    removeFromAvailableQuotes(callback) {
        if (this.app) {
            let availableQuotes = this.app.state.quoteContext.availableQuotes;
            let i = availableQuotes.length;
            while (i--) {
                if (availableQuotes[i].id === this.id) {
                    availableQuotes.splice(i, 1);
                }
            }

            this.app.setState((state) => ({
                quoteContext: Object.assign(state.quoteContext, {availableQuotes: availableQuotes})
            }), callback);
        }
    }

    setCurrent() {
        this.updateContextState(true);
    }

    updateContextState(fromSwitch) {
        if (this.app) {
            this.app.setState((state) => ({
                quoteContext: Object.assign(state.quoteContext, {currentQuote: this})
            }));
        }
        if (!fromSwitch) {
            this.setIsModified(true);
        }
    }

    setName(name) {
        this.name = name;
        this.updateContextState();
    }

    setIsModified(value) {
        this.isModified = value === true;
        this.lastModifDate = moment().valueOf();
    }

    saveContent(download = false, returnJson = false) {
        let data = _.cloneDeep(this);
        data.app = undefined;

        if (data.categories) {
            Object.keys(data.categories).forEach((categoryId) => {
                Object.keys(data.categories[categoryId]).forEach((productId) => {
                    data.categories[categoryId][productId].quote = undefined;
                    data.categories[categoryId][productId].product = undefined;

                    if (data.categories[categoryId][productId].calculation) {
                        data.categories[categoryId][productId].calculation.product = undefined;
                        data.categories[categoryId][productId].calculation.parts.forEach((part) => {
                            part.product = undefined;
                            part.currentQuote = undefined;
                            part.formulaVariable = undefined;
                            part.formulaVariables = undefined;
                        });
                    }
                })
            });
        }

        if (returnJson) {
            return data;
        }

        let originalData = JSON.parse(localStorage.getItem('quotes')) || [];
        let lsize = originalData.length || 0;
        let added = false;
        while (lsize--) {
            if (originalData[lsize].id === this.id) {
                originalData[lsize] = data;
                added = true;
            }
        }
        if (!added) {
            originalData.push(data);
        }
        let json = JSON.stringify(!download ? originalData : data);
        if (!download) {
            this.saveToLocalStorage(json);
        } else {
            this.downloadContent(json);
        }
    }

    saveToLocalStorage(json) {
        localStorage.setItem('quotes', json);
        this.setIsModified(false);
        this.updateContextState(true);
    }

    downloadContent(json) {
        let enc = new Encryption();
        const encrypted = enc.encrypt(json);
        const blob = new Blob([encrypted], {type: 'octet/stream'});
        const href = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = href;
        link.download = this.name + '.eaq';
        link.click();
    }

    removeFromLocalStorage(quoteId) {
        let currentLSQuotes = JSON.parse(localStorage.getItem('quotes'));
        let i = currentLSQuotes.length;
        if (i > 0) {
            while (i--) {
                if (currentLSQuotes[i].id === quoteId) {
                    currentLSQuotes[i].toBeDeleted = true;
                }
            }
        }
        let availableQuotes = this.app.state.quoteContext.availableQuotes;
        let iCQ = availableQuotes.length;
        while (iCQ--) {
            if (availableQuotes[iCQ].id === quoteId) {
                availableQuotes.splice(iCQ, 1);
            }
        }
        this.app.setState((state) => ({
            quoteContext: Object.assign(state.quoteContext, {availableQuotes: availableQuotes})
        }));
        localStorage.setItem('quotes', JSON.stringify(currentLSQuotes));
    }

    updateRoboticSystem(value) {
        if (value !== this.haveRoboticSystem) {
            this.haveRoboticSystem = value;
        }
    }

    isCategoryUsingSoftWater(categoryId) {
        return this.categoriesSoftWater[categoryId] === true;
    }

    setCategoryUsingSoftWater(categoryId, value) {
        this.categoriesSoftWater[categoryId] = value;
    }

    toggleSoftWater(categoryId) {
        if (this.hasCategory(categoryId)) {
            this.setCategoryUsingSoftWater(categoryId, !this.isCategoryUsingSoftWater(categoryId))

            Object.keys(this.categories[categoryId]).forEach((productId) => {
                if (this.categories[categoryId][productId].calculation) {
                    this.categories[categoryId][productId].calculation.updateSoftWater(this, categoryId);
                }
            });
        }
    }

    getClone(u) {
        //clone current
        let clone = _.cloneDeep(this);
        //reset app after clone
        clone.app = this.app;
        //set new id
        clone.id = moment().valueOf();
        //set clone name
        clone.name = getTranslation('clone of', u.locale) + ' ' + clone.name;

        return new Quote(clone);
    }

    refreshCalculationGeneralData(property) {
        Object.keys(this.categories).forEach((categoryId) => {
            Object.keys(this.categories[categoryId]).forEach((productId) => {
                if (this.categories[categoryId][productId].calculation) {
                    this.categories[categoryId][productId].calculation.updateGeneralDataPart(this, property, categoryId);
                }
            });
        });
    }

    setProductDosing(categoryId, productId, value) {
        if (this.hasProduct(categoryId, productId)) {
            if (this.categories[categoryId][productId].calculation) {
                this.categories[categoryId][productId].calculation.updateDosing(this, categoryId, value);
            }
        }
    }

    setProductEstimatedConsumption(categoryId, productId, value) {
        if (this.hasCategory(categoryId)) {
            this.manualEstimatedConsumptionValue[categoryId] = value;
            Object.keys(this.categories[categoryId]).forEach((p) => {
                if (this.categories[categoryId][p].calculation) {
                    this.categories[categoryId][p].calculation.updateEstimatedConsumption(this, categoryId, value);
                }
            });
            this.updateContextState(true);
        }
    }

    setProductNumberOfMonths() {
        Object.keys(this.categories).forEach((categoryId) => {
            Object.keys(this.categories[categoryId]).forEach((productId) => {
                if (this.categories[categoryId][productId].calculation) {
                    this.categories[categoryId][productId].calculation.updateNumberOfMonths(this, categoryId);
                }
            });
        });
    }

    getUserContext() {
        return this.app && this.app.state && this.app.state.userContext ? this.app.state.userContext : null;
    }

    setProtocolMultiplier(categoryId, value) {
        if (this.hasCategory(categoryId)) {
            Object.keys(this.categories[categoryId]).forEach((p) => {
                if (this.categories[categoryId][p].calculation) {
                    this.categories[categoryId][p].calculation.updateProtocolMultiplier(this, categoryId, value);
                }
            });
        }

        this.protocolMultiplier = value;
        this.updateContextState();
    }

    updateProductPrice(formatId, price) {
        Object.keys(this.categories).forEach((categoryId) => {
            Object.keys(this.categories[categoryId]).forEach((productId) => {
                if (this.categories[categoryId][productId].format && this.categories[categoryId][productId].format === formatId && !this.categories[categoryId][productId].isPriceModified) {
                    this.categories[categoryId][productId].setPrice(price);
                }
            });
        });
    }

    // Required to toggle the Print Preview icon, product must have a price or be loaned
    checkCartHasItemWithPrice() {
        this.showPrintPreview = true;
        if (Object.keys(this.categories).length === 0) {
            this.showPrintPreview = false;
            return;
        }

        Object.keys(this.categories).forEach((categoryId) => {
            Object.keys(this.categories[categoryId]).forEach((productId) => {
                const product = this.categories[categoryId][productId];
                if ((!parseFloat(product.price) && !product.loaned) || !product.format || !product.quantity) {
                    this.showPrintPreview = false;
                }
            });
        });
    }

    setPrintPreviewVisible(value) {
        this.isPrintPreviewVisible = value;
        this.updateContextState();
    }

    categoryHasCalculation(categoryId) {
        let has = false;
        if (this.hasCategory(categoryId)) {
            Object.keys(this.categories[categoryId]).forEach((productId) => {
                if (this.categories[categoryId][productId].calculation) {
                    has = true;
                }
            });
        }
        return has;
    }

    categoryHasDiscount(categoryId) {
        let has = false;
        if (this.hasCategory(categoryId)) {
            Object.keys(this.categories[categoryId]).forEach((productId) => {
                if (this.categories[categoryId][productId].hasDiscount || this.categories[categoryId][productId].loaned) {
                    has = true;
                }
            });
        }
        return has;
    }

    quoteHasDiscount() {
        let has = false;
        Object.keys(this.categories).forEach((categoryId) => {
            if (this.categoryHasDiscount(categoryId)) {
                has = true;
            }
        });

        return has;
    }

    categoryHasCostPerAnimalPerYear(categoryData, categoryId) {
        if (categoryData[categoryId] && categoryData[categoryId].costPerAnimalPerMonth) {
            return true;
        }

        return false;
    }

    quoteHasCostPerAnimalPerYear(categoryData) {
        let has = false;
        Object.keys(this.categories).forEach((categoryId) => {
            if (this.categoryHasCostPerAnimalPerYear(categoryData, categoryId)) {
                has = true;
            }
        });

        return has;
    }

    quoteHasCostPer1000LMilk(categoryData) {
        let has = false;
        Object.keys(this.categories).forEach((categoryId) => {
            if (categoryData && categoryData[categoryId] && categoryData[categoryId].costPer1000Liter) {
                has = true;
            }
        });

        return has;
    }

    getCostPerAnimalPerYear(categoryData) {
        let total = 0;
        Object.keys(this.categories).forEach((categoryId) => {
            if (this.categoryHasCostPerAnimalPerYear(categoryData, categoryId)) {
                Object.keys(this.categories[categoryId]).forEach((productId) => {
                    const product = this.categories[categoryId][productId];
                    total += parseFloat(product.getCostPerAnimalPerMonth().toFixed(2));
                });
            }
        });

        return total;
    }

    getCostPer1000LMilk(categoryData, milkProduction, numberOfAnimal) {
        let total = 0;
        Object.keys(this.categories).forEach((categoryId) => {
            if (categoryData && categoryData[categoryId] && categoryData[categoryId].costPer1000Liter) {
                total += this.getCategoryTotal(categoryId);
            }
        });

        if (total > 0 && parseFloat(milkProduction) > 0 && parseInt(numberOfAnimal) > 0) {
            return (total / (parseInt(numberOfAnimal) * parseFloat(milkProduction) / 1000));
        }

        return 0;
    }

    getCostPer1LMilk(categoryData, milkProduction, numberOfAnimal) {
        let total = this.getCostPer1000LMilk(categoryData, milkProduction, numberOfAnimal);
        if (total > 0) {
            return total / 1000;
        }

        return total;
    }

    getTotalDiscount() {
        let total = 0;
        Object.keys(this.categories).forEach((categoryId) => {
            Object.keys(this.categories[categoryId]).forEach((productId) => {
                const product = this.categories[categoryId][productId];
                if (product.hasDiscount) {
                    total += parseFloat(product.discountPrice.toFixed(2));
                }
            });
        });

        return total;
    }

    setSpeciesFromName(value) {
        let UC = this.getUserContext();
        let species = (UC && UC.species && UC.species.data ? UC.species.data : null);
        if (null !== species) {
            let found = false;
            let speciesId = null;
            species.forEach(function (s) {
                if (!found) {
                    let name = s.getTranslation({fieldName: 'name', locale: 'en'}).toLowerCase()
                    if (value.trim().toLowerCase() === name) {
                        speciesId = s.id;
                        found = true;
                    }
                }
            });
            if (null !== speciesId) {
                this.setSpecies(speciesId);
            }
        }
    }

    setCountryFromAlpha2(value) {
        let UC = this.getUserContext();
        let countries = (UC && UC.country && UC.country.data ? UC.country.data : null);
        if (null !== countries) {
            let found = false;
            let countryId = null;
            countries.forEach(function (c) {
                if (!found && value.trim().toLowerCase() === c.shortName) {
                    countryId = c.id;
                    found = true;
                }
            });
            if (null !== countryId) {
                this.setCountry(countryId);
            }
        }
    }

    setCurrencyFromSign(value) {
        let UC = this.getUserContext();
        let currencies = (UC && UC.currency && UC.currency.data ? UC.currency.data : null);
        if (null !== currencies) {
            let found = false;
            let currencyId = null;
            currencies.forEach(function (c) {
                if (!found && value.trim() === c.sign) {
                    currencyId = c.id;
                    found = true;
                }
            });
            if (null !== currencyId) {
                this.setCurrency(currencyId);
            }
        }
    }

    setProductGroupFromOldId(value) {
        let UC = this.getUserContext();
        let productGroups = (UC && UC.productgroup && UC.productgroup.data ? UC.productgroup.data : null);
        let toFind = value === 30 ? 'sanicoopa' : 'france products';
        if (null !== productGroups) {
            let found = false;
            productGroups.forEach(function (g) {
                if (!found && g.name.toLowerCase() === toFind) {
                    UC.user.setProductGroup(g.id);
                    found = true;
                }
            });
        }
    }

    hasBiocide() {
        let has = false;
        Object.keys(this.categories).forEach((categoryId) => {
            Object.keys(this.categories[categoryId]).forEach((productId) => {
                const productInstance = this.getUserContext().product.data[productId];
                if (productInstance.isBiocide) {
                    has = true;
                }
            });
        });

        return has;
    }

    setSetting(key, value, dontUpdateState) {
        this.settings[key] = value;
        localStorage.setItem(key, value);
        if (!dontUpdateState) {
            this.updateContextState();
        }
    }

    setUdderHygieneProductsDiscount(discount, categories) {
        let hasProducts = false;
        Object.keys(this.categories).forEach((categoryId) => {
            if (categories && categories[categoryId] && categories[categoryId].isUdderHygiene) {
                Object.keys(this.categories[categoryId]).forEach((productId) => {
                    hasProducts = true;

                    const quoteProduct = this.categories[categoryId][productId];
                    if (!quoteProduct.loaned) {
                        quoteProduct.hasDiscount = true;
                        quoteProduct.setDiscount(discount, true);
                    }
                });
            }
        });

        if (hasProducts) {
            this.updateContextState();
        }
    }

    removeUdderHygieneProductsDiscount(categories) {
        Object.keys(this.categories).forEach((categoryId) => {
            if (categories && categories[categoryId] && categories[categoryId].isUdderHygiene) {
                Object.keys(this.categories[categoryId]).forEach((productId) => {
                    this.categories[categoryId][productId].removeDiscount(true);
                });
            }
        });

        this.updateContextState();
    }

    setProductsDiscount(discount) {
        let hasProducts = false;
        Object.keys(this.categories).forEach((categoryId) => {
            Object.keys(this.categories[categoryId]).forEach((productId) => {
                hasProducts = true;

                const quoteProduct = this.categories[categoryId][productId];
                if (!quoteProduct.loaned) {
                    quoteProduct.hasDiscount = true;
                    quoteProduct.setDiscount(discount, true);
                }
            });
        });

        if (hasProducts) {
            this.updateContextState();
        }
    }

    removeProductsDiscount() {
        Object.keys(this.categories).forEach((categoryId) => {
            Object.keys(this.categories[categoryId]).forEach((productId) => {
                this.categories[categoryId][productId].removeDiscount(true);
            });
        });

        this.updateContextState();
    }

    getFirstUdderHygieneDiscount(categories) {
        let discount = null;
        Object.keys(this.categories).forEach((categoryId) => {
            if (categories && categories[categoryId] && categories[categoryId].isUdderHygiene) {
                Object.keys(this.categories[categoryId]).forEach((productId) => {
                    if (!discount && this.categories[categoryId][productId].hasDiscount) {
                        discount = this.categories[categoryId][productId].discount;
                    }
                });
            }
        });
        return discount;
    }

    getFirstDiscount() {
        let discount = null;
        Object.keys(this.categories).forEach((categoryId) => {
            Object.keys(this.categories[categoryId]).forEach((productId) => {
                if (!discount && this.categories[categoryId][productId].hasDiscount) {
                    discount = this.categories[categoryId][productId].discount;
                }
            });
        });
        return discount;
    }

    toggleDisplayEmptyCategories() {
        this.displayEmptyCategories = !this.displayEmptyCategories;
        this.updateContextState();
    }

    showMosaic() {
        this.isMosaicVisible = true;
        this.updateContextState();
    }

    hideMosaic() {
        this.isMosaicVisible = false;
        this.updateContextState();
    }

    setTestMode(value) {
        this.testMode = value;
        this.updateContextState();
    }

    setQuoteAccepted(value) {
        this.quoteAccepted = value;
        this.updateContextState();
    }

    setAppRelatedData(data) {
        this.appRelatedData = data;
        this.updateContextState();
    }

    setApplication(appId) {
        this.application = appId;
        this.reset();
    }
}
