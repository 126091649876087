import {ApiClient} from 'easycalc-api-client';
import {config} from "../config/Config";

export default class EasycalcApiClient extends ApiClient {
    constructor() {
        super();
        this.basePath = process.env.REACT_APP_API_PATH;
        var secure_token = this.authentications['secure_token'];
        secure_token.apiKey = '7de7ec55-a72d-4f97-af9e-36e636bd1e22';
        var user_token = this.authentications['user_token'];
        user_token.apiKey = localStorage.getItem(config.tokenParamName);
        this.timeout = 300000;
        this.defaultHeaders = {};
    }

    hostSettings() {
        return [
            {
                'url': this.basePath,
                'description': 'No description provided'
            }
        ];
    }
}
