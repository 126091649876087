import {Col, Row} from "react-bootstrap";
import {getTranslation} from "../tools/Translation";
import React, {useContext, useRef, useState} from "react";
import {UserContext} from "../../contexts/UserContext";
import {CausticSodaQuote} from "../../model/CausticSoda/CausticSodaQuote";
import DeleteConfirmModal from "../tools/DeleteConfirmModal";
import RenameModal from "../tools/RenameModal";
import {Equipment} from "../../model/CausticSoda/Equipment";
import moment from "moment";

export default function EquipmentList(props) {
    const context = useContext(UserContext);
    const nameRef = useRef(null);
    const [showDelete, setShowDelete] = useState(false);
    const [renameEquipmentId, setRenameEquipmentId] = useState(null);
    const [renameEquipmentName, setRenameEquipmentName] = useState('');
    const [showNameChangeModal, setShowNameChangeModal] = useState(false);
    const [deleteId, setDeleteId] = useState(null);

    const addEquipment = () => {
        props.quote.addEquipment(nameRef.current.value);
        props.setQuote(new CausticSodaQuote(props.quote));
        nameRef.current.value = '';
    }

    const deleteEquipement = (id) => {
        setDeleteId(id);
        setShowDelete(true);
    }

    const doDeleteEquipment = () => {
        props.quote.removeEquipment(context, deleteId);
        props.setQuote(new CausticSodaQuote(props.quote));

        setDeleteId(null);
        setShowDelete(false);
    }

    const resetToggledFieldValues = (context) => {
        if (!props.quote.advancedMethod) {
            props.quote.equipments.forEach((equipment) => {
                Object.keys(context.washingstep.data).forEach((washingStepId) => {
                    if (context.washingstep.data[washingStepId].advancedMethod) {
                        props.setEquipmentProperty(equipment.id, 'dureeWs' + washingStepId, '');
                    }
                });
            });
        }
    }

    const cloneEquipment = (equipment) => {
        const newEquipment = new Equipment(equipment);
        newEquipment.name = 'Copy of ' + newEquipment.name;
        newEquipment.id = moment().valueOf();

        const index = props.quote.equipments.indexOf(equipment);
        props.quote.equipments.splice(index + 1, 0, newEquipment);
        props.setQuote(new CausticSodaQuote(props.quote));
    }

    return (
        <>
            {showNameChangeModal &&
                <RenameModal closeModal={() => {setShowNameChangeModal(false)}} setName={(name) => {props.setEquipmentProperty(renameEquipmentId, 'name', name)}} defaultValue={renameEquipmentName} />
            }
            <DeleteConfirmModal show={showDelete} okCallback={doDeleteEquipment} cancelCallback={() => { setDeleteId(null); setShowDelete(false);}} title={getTranslation('cs.warning', context.user.locale)} text={getTranslation('cs.confirm-delete', context.user.locale)} />
            <Row className="mb-3">
                <Col>
                    <div className="custom-control custom-switch">
                        <input type="checkbox" className="custom-control-input" id="method" checked={props.quote.advancedMethod} onChange={() => {props.setProperty('advancedMethod', !props.quote.advancedMethod); resetToggledFieldValues(context)}} />
                        <label className="custom-control-label" htmlFor="method">{getTranslation('cs.advanced-method', context.user.locale)}</label>
                    </div>
                    <small className="advanced-method-warning" style={{display: 'block'}}>* {getTranslation('cs.advanced-method-text', context.user.locale)}</small>
                </Col>
            </Row>
            <Row className="mb-1">
                <Col><strong>{getTranslation('cs.equipments', context.user.locale)}</strong></Col>
            </Row>
            <Row className="mt-2 mb-2">
                <Col className="col-md-6">
                    <div className="input-group mb-3">
                        <input ref={nameRef} type="text" onKeyDown={(e) => {if (e.key === 'Enter') addEquipment()}} className="form-control" placeholder={getTranslation('cs.enter-equipment-name', context.user.locale)} />
                        <div className="input-group-append">
                            <button className="btn btn-outline-primary" type="button" onClick={() => addEquipment()}>{getTranslation('cs.add-equipment', context.user.locale)}</button>
                        </div>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    {props.quote.equipments.length > 0 &&
                    <table className="table table-striped table-bordered small">
                        <thead className="thead-light">
                            <tr>
                                <th>
                                    {getTranslation('cs.equipment-name', context.user.locale)}
                                </th>
                                <th className="text-center">
                                    {getTranslation('cs.actions', context.user.locale)}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {props.quote.equipments.map((equipment) => {
                                return (
                                    <tr key={equipment.id}>
                                        <td className="pt-2">
                                            {equipment.name}
                                        </td>
                                        <td className="text-center">
                                            <button className="btn btn-sm btn-primary mr-2" onClick={() => {setRenameEquipmentId(equipment.id); setRenameEquipmentName(equipment.name); setShowNameChangeModal(true);}}>{getTranslation('renameQuote.title', context.user.locale)}</button>
                                            <button className="btn btn-sm btn-info mr-2" onClick={() => {cloneEquipment(equipment)}}>{getTranslation('cs.clone', context.user.locale)}</button>
                                            <button className="btn btn-sm btn-danger" onClick={() => deleteEquipement(equipment.id)}>{getTranslation('cs.delete', context.user.locale)}</button>
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                    }
                </Col>
            </Row>
        </>
    )
}