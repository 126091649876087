import React from "react";
import {QuoteContext} from "../../contexts/QuoteContext";

export default class Months extends React.Component {
    render() {
        return (
            <div className="quote-months" onClick={() => this.props.toggleToolbar()}>
                <div id="calendar">
                    { this.context.currentQuote.numberOfMonths }
                </div>
            </div>
        )
    }
}

Months.contextType = QuoteContext;