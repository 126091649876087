export function getProducts(context, type, sort) {
    const products = [];
    if (context.user?.products.length > 0 && context.product?.data) {
        context.user.products.forEach((productId) => {
            if (context.product.data[productId] && context.product.data[productId].productType === type) {
                products.push(context.product.data[productId]);
            }
        });
    }

    if (sort) {
        products.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
    }

    return products;
}
