import React from "react";
import moment from "moment";
import {QuoteContext} from "../../contexts/QuoteContext";
import {UserContext} from "../../contexts/UserContext";
import {getTranslation} from "../tools/Translation";
import attached from "../../assets/images/attached.png";
let vsprintf = require('sprintf-js').vsprintf;

export default class QuoteDetails extends React.Component {
    render() {
        const userContext = this.context.currentQuote.getUserContext();
        let breeding = [];
        let breedingStr = '';
        breeding.push((this.context.currentQuote.generalData['numberofanimals'] || 0) + ' ' + userContext.species.data[this.context.currentQuote.species].getTranslation({fieldName: 'name', locale: this.context.currentQuote.locale}).toLowerCase() + ((this.context.currentQuote.generalData['numberofanimals'] || 0) > 1 ? 's' : ''));
        if ((this.context.currentQuote.generalData['clusters'] || 0) > 0) {
            breeding.push(this.context.currentQuote.generalData['clusters'] + ' ' + getTranslation('cluster' + (this.context.currentQuote.generalData['clusters'] > 1 ? 's' : ''), userContext.user.locale));
        }
        if ((this.context.currentQuote.generalData['roboticsystems'] || 0) > 0) {
            breeding.push(this.context.currentQuote.generalData['roboticsystems'] + ' ' + getTranslation('robot' + (this.context.currentQuote.generalData['roboticsystems'] > 1 ? 's' : ''), userContext.user.locale));
        }
        if ((this.context.currentQuote.generalData['milktankcapacity'] || 0) > 0) {
            breeding.push(vsprintf(getTranslation('print.milktank', userContext.user.locale), [this.context.currentQuote.generalData['milktankcapacity']]));
        }

        if (breeding.length > 1) {
            const last = breeding.pop();
            if (breeding.length > 1) {
                breedingStr = breeding.join(", ");
            } else {
                breedingStr = breeding[0];
            }

            breedingStr += ' ' + getTranslation('and', userContext.user.locale) + ' ' + last;
        } else {
            breedingStr = breeding[0];
        }

        return (
            <UserContext.Consumer>
                {({user}) => {
                    return (
                        <>
                            {(this.context.currentQuote.generalData['date'] || this.context.currentQuote.generalData['etablipar'] || this.context.currentQuote.generalData['nom']) &&
                            <div className="quote-author">
                                {this.context.currentQuote.generalData['date'] &&
                                    <div className="contact">
                                        {getTranslation('visit.contact', user.locale)}: <strong>{ moment(this.context.currentQuote.generalData['date']).format('DD/MM/YYYY') }</strong>
                                    </div>
                                }
                                {this.context.currentQuote.generalData['etablipar'] &&
                                    <>
                                        {getTranslation('preparedby', user.locale)}:
                                        {this.context.currentQuote.generalData['nom'] &&
                                            <> {this.context.currentQuote.generalData['nom']}</>
                                        }
                                        {this.context.currentQuote.generalData['etablipar'] &&
                                            <> ({this.context.currentQuote.generalData['etablipar']})</>
                                        }
                                    </>
                                }
                            </div>
                            }
                            <div className="quote-notes">
                                {getTranslation('selected.period', user.locale)}: { this.context.currentQuote.numberOfMonths } {getTranslation('months', user.locale)}

                                {this.context.currentQuote.generalData['infosutiles'] &&
                                    <div className="notes">{getTranslation('preview.note', user.locale)}: {this.context.currentQuote.generalData['infosutiles']}</div>
                                }
                            </div>
                            <div className="see-product-details mt-2">
                                <img src={attached} alt="info" />
                                {getTranslation('see.product.detail', user.locale)} 2
                            </div>
                            <div className="installation">
                                {getTranslation('breeding', user.locale)}: {breedingStr}.
                            </div>
                        </>
                )}}
            </UserContext.Consumer>
        )
    }
}

QuoteDetails.contextType = QuoteContext;