import React, { Component } from 'react';

export default class ErrorMessage extends Component {
    render() {
        if (!this.props.error) {
            return null;
        }

        if (this.props.error) {
            return (
              <div className="alert alert-danger">
                <p className="mb-0">{this.props.error}</p>
              </div>
            );
        }

        return null;
    }
}