import React from 'react';
import ReactDOM from 'react-dom';
import './assets/css/App.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(
    <>
        <App/>
    </>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register({
    onUpdate: registration => {
        const waitingServiceWorker = registration.waiting

        if (waitingServiceWorker) {
            waitingServiceWorker.addEventListener("statechange", event => {
                if (event.target.state === "activated") {
                    const channelBroadcast = new BroadcastChannel('easycalc');
                    channelBroadcast.postMessage({key: 'NEW_VERSION_AVAILABLE'});
                }
            });
            waitingServiceWorker.postMessage({type: "SKIP_WAITING"});
        }
    }
});
