import React from 'react';
import {Col, Container, Image, Row} from "react-bootstrap";
import {QuoteContext} from "../../contexts/QuoteContext";

export default class PriceListProduct extends React.Component {

    showDetails = (product) => this.context.currentQuote.showProductDetailsPopup(product);

    render() {
        return (
            <Container onClick={() => this.showDetails(this.props.data)}>
                <Row>
                    <Col style={{maxWidth: 81, padding: 0, textAlign: 'center'}}>
                        <Image style={{borderRadius: 3, border: 'solid 1px #af1a3f', cursor: 'pointer'}} src={this.props.data.image}/>
                    </Col>
                    <Col>
                        <div className="description">{this.props.data.name}</div>
                    </Col>
                </Row>
            </Container>
        );
    }
}

PriceListProduct.contextType = QuoteContext;
