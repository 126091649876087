import React from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { getTranslation } from "../tools/Translation";
import { QuoteContext } from "../../contexts/QuoteContext";
import { resetDB } from "../data/Database";
import {Apps} from "../../model/Apps";

export default class OptionsForm extends React.Component {

    handleDbReset = () => {
        if (this.state.resetClickCount > 0) {
            resetDB();
        } else {
            let state = this.state;
            state.resetClickCount = state.resetClickCount + 1;
            this.setState(state);
        }
    }

    onChangeHandler = (option, e) => {
        if (['options.udderhygienediscount', 'options.globaldiscount'].includes(option) && e.target.checked === true) {
            if (option === 'options.udderhygienediscount') {
                this.context.currentQuote.setSetting('options.globaldiscount', false, true);
            } else {
                this.context.currentQuote.setSetting('options.udderhygienediscount', false, true);
            }
        }

        this.context.currentQuote.setSetting(option, e.target.checked);
    }

    constructor(props) {
        super(props);
        this.state = { resetClickCount: 0 }
    }

    render() {

        let resetMessage = null;
        if (this.state.resetClickCount > 0) {
            resetMessage = <span className="mr-2">Reset ?</span>;
        }

        let options = [];
        if (this.props.currentModule === Apps.Farm) {
            options.push(
                <Row key="options">
                    <Col md={6} lg={4}>
                        <div className="option-block">
                            <div className="option-header clearfix">
                                <div className="option-icon prices" />
                                <div className="option-title">{getTranslation('Prices', this.context.currentQuote.locale)}</div>
                            </div>
                            <div style={{ clear: "both" }} />
                            <div className="option clearfix">
                                <input type="checkbox" name="udderhygienediscount" id="udderhygienediscount" value={1} checked={this.context.currentQuote.settings['options.udderhygienediscount'] ?? false} onChange={(e) => { this.onChangeHandler('options.udderhygienediscount', e) }} />
                                <label htmlFor="udderhygienediscount">{getTranslation('options.udderhygienediscount', this.context.currentQuote.locale)}</label>
                            </div>
                            <div style={{ clear: "both" }} />
                            <div className="option clearfix">
                                <input type="checkbox" name="globaldiscount" id="globaldiscount" value={1} checked={this.context.currentQuote.settings['options.globaldiscount'] ?? false} onChange={(e) => { this.onChangeHandler('options.globaldiscount', e) }} />
                                <label htmlFor="globaldiscount">{getTranslation('options.globaldiscount', this.context.currentQuote.locale)}</label>
                            </div>
                            <div style={{ clear: "both" }} />
                        </div>
                    </Col>
                    <Col md={6} lg={4}>
                        <div className="option-block">
                            <div className="option-header clearfix">
                                <div className="option-icon sheets" />
                                <div className="option-title">{getTranslation('Estimateinfo', this.context.currentQuote.locale)}</div>
                            </div>
                            <div style={{ clear: "both" }} />
                            <div className="option clearfix">
                                <input type="checkbox" name="costper1000lmilk" id="costper1000lmilk" value={1} checked={this.context.currentQuote.settings['options.costper1000lmilk'] ?? false} onChange={(e) => { this.onChangeHandler('options.costper1000lmilk', e) }} />
                                <label htmlFor="costper1000lmilk">{getTranslation('options.costper1000lmilk', this.context.currentQuote.locale)}</label>
                            </div>
                            <div style={{ clear: "both" }} />
                            <div className="option clearfix">
                                <input type="checkbox" name="costper1lmilk" id="costper1lmilk" value={1} checked={this.context.currentQuote.settings['options.costper1lmilk'] ?? false} onChange={(e) => { this.onChangeHandler('options.costper1lmilk', e) }} />
                                <label htmlFor="costper1lmilk">{getTranslation('options.costper1lmilk', this.context.currentQuote.locale)}</label>
                            </div>
                            <div style={{ clear: "both" }} />
                            <div className="option clearfix">
                                <input type="checkbox" name="costpercowpermonth" id="costpercowpermonth" value={1} checked={this.context.currentQuote.settings['options.costpercowpermonth'] ?? false} onChange={(e) => { this.onChangeHandler('options.costpercowpermonth', e) }} />
                                <label htmlFor="costpercowpermonth">{getTranslation('options.costpercowpermonth', this.context.currentQuote.locale)}</label>
                            </div>
                            <div style={{ clear: "both" }} />
                            <div className="option clearfix">
                                <input type="checkbox" name="globalcostpercowpermonth" id="globalcostpercowpermonth" value={1} checked={this.context.currentQuote.settings['options.globalcostpercowpermonth'] ?? false} onChange={(e) => { this.onChangeHandler('options.globalcostpercowpermonth', e) }} />
                                <label htmlFor="globalcostpercowpermonth">{getTranslation('options.globalcostpercowpermonth', this.context.currentQuote.locale)}</label>
                            </div>
                            <div style={{ clear: "both" }} />
                            <div className="option clearfix">
                                <input type="checkbox" name="approval" id="approval" value={1} checked={this.context.currentQuote.settings['options.approval'] ?? false} onChange={(e) => { this.onChangeHandler('options.approval', e) }} />
                                <label htmlFor="approval">{getTranslation('options.approval', this.context.currentQuote.locale)}</label>
                            </div>
                            <div style={{ clear: "both" }} />
                        </div>
                    </Col>
                    <Col md={6} lg={4}>
                        <div className="option-block">
                            <div className="option-header clearfix">
                                <div className="option-icon settings" />
                                <div className="option-title">{getTranslation('options.othersettings', this.context.currentQuote.locale)}</div>
                            </div>
                            <div style={{ clear: "both" }} />
                            <div className="option clearfix">
                                <input type="checkbox" name="customerwidget" id="customerwidget" value={1} checked={this.context.currentQuote.settings['options.customerwidget'] ?? false} onChange={(e) => { this.onChangeHandler('options.customerwidget', e) }} />
                                <label htmlFor="customerwidget">{getTranslation('options.customerwidget', this.context.currentQuote.locale)}</label>
                            </div>
                            <div style={{ clear: "both" }} />
                            <div style={{ clear: "both" }} />
                        </div>
                    </Col>
                </Row>);
        }

        return (
            <Modal
                show={this.props.show}
                onHide={() => {
                    this.props.showMethod(false);
                }}
                size="lg"
                className="options-form"
                centered>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {getTranslation('Settings', this.context.currentQuote.locale)}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {options}
                    <Row>
                        <Col className="option-version">Version: {process.env.REACT_APP_VERSION}</Col>
                    </Row>
                    <Row>
                        <Col className="option-dbreset">
                            {resetMessage}
                            <Button variant="danger" onClick={this.handleDbReset}>Reset database</Button>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
        );
    }
}

OptionsForm.contextType = QuoteContext;