import {Entity} from "./Entity";

export class Category extends Entity {
    getFrequency(species) {
        let frequency = null;
        if (this.categorySpeciesFrequencies && this.categorySpeciesFrequencies.length > 0) {
            this.categorySpeciesFrequencies.forEach((f) => {
                if (f.species === species) {
                    frequency = f;
                }
            });

            if (!frequency) {
                frequency = this.categorySpeciesFrequencies[0];
            }
        }

        return frequency;
    }
}