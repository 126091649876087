import React from 'react';
import {Button, ButtonGroup, ButtonToolbar, Container} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faTimesCircle,
    faSave,
    faSortAlphaDown,
    faSortAlphaUp,
    faUndo,
    faEye,
    faEyeSlash,
    faFileImport
} from "@fortawesome/free-solid-svg-icons";
import {QuoteContext} from "../../contexts/QuoteContext";
import Encryption from "../tools/Encryption";
import Debug from "../tools/Debug";
import PriceListExport from "./PriceListExport";
import {read, utils} from "xlsx";

export default class PriceListToolbox extends React.Component {

    handleCloseClick = () => this.context.currentQuote.hidePriceList();

    handleToggleNoFormat = () => this.context.currentQuote.toggleNoFormat();

    handleSaveClick = () => {
        //console.log('PriceListToolbox::handleSaveClick');
        let prices = this.context.currentQuote.prices;
        this.props.overwritePriceList(prices);
    }

    handleSortClick = () => this.context.currentQuote.switchPriceListOrder();

    handleResetClick = () => {
        this.context.currentQuote.resetPriceList(this.props.getBasePriceList());
    }

    handleImportClick = () => {
        let el = document.createElement('input');
        el.setAttribute('type', 'file');
        el.setAttribute('multiple', 'multiple');
        el.setAttribute('accept', '.ecp, .xlsx')
        el.onchange = this.handleFileSelect;
        el.click();
    }

    handleFileSelect = async (e) => {
        if (e.target.files[0].name.toLowerCase().includes('xlsx')) {
            await this.processXlsx(e);
        } else {
            const reader = new FileReader();
            reader.onload = this.processEps;
            reader.readAsText(e.target.files[0]);
        }
    }

    processXlsx = async (e) =>  {
        /* data is an ArrayBuffer */
        const file = e.target.files[0];
        const data = await file.arrayBuffer();

        const workbook = read(data);
        const jsonData = utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]]);

        if (jsonData && jsonData.length) {
            if (jsonData[0]['id'] && jsonData[0]['name'] && jsonData[0]['package']) {
                const prices = {};
                jsonData.forEach((row) => {
                    prices[row.id] = row.price ? parseFloat((row.price + '').replace(',', '.')).toFixed(2) + "" : "0.00";
                });
                this.context.currentQuote.setPriceListFromImport(prices);
            }
        }
    }

    processEps = async (e) => {
        let enc = new Encryption();
        const decrypted = enc.decrypt(e.target.result).split('@');
        if (decrypted && decrypted[2]) {
            let fileContent = decrypted[2];
            let pattern = /(.*?)=([1-99])/g;
            let matches = fileContent.match(pattern);
            let count = matches.length;
            for (let i = 0; i < count; i++) {
                let results = this.getFirstProduct(fileContent);
                this.setPriceList(results.product);
                fileContent = results.fileContent;
            }
            this.context.currentQuote.updateContextState(false);
        }
    }

    setPriceList(product) {
        Debug.log('PriceListToolbox::setPriceList');
        const UC = this.context.currentQuote.getUserContext();
        let productId = null;
        //try to find the product id based on the name
        if (UC && UC.product && UC.product.data) {
            UC.product.data.forEach( p => {
                if (p.name === product.code) {
                    //try to find the package id based on the weight?
                    if (p.formats && p.formats.length > 0) {
                        product.packaging.forEach((pack) => {
                            p.formats.forEach(f => {
                                if (pack.price !== 'x' && f.name === pack.size) {
                                    let packageId = f.id;
                                    //set price list
                                    let price = parseFloat(pack.price).toFixed(2);
                                    console.log('SET PRICE LIST PRICE: ', packageId, price);
                                    this.context.currentQuote.prices[packageId] = price;
                                }
                            })
                        })
                    }
                    return productId = p.id;
                }
            });
        }
        if (null == productId) {
            console.error("CAN'T FIND PRODUCT: " + product.code);
        }
    }

    getFirstProduct(fileContent) {
        let pattern = /(.*?)=([1-99])/g;
        let result = pattern.exec(fileContent);
        let productCode = result[1];
        let packagingCount = result[2];
        let newPattern = productCode.replace(/[.*+?^${}()|[\]\\]/g, '\\$&') + '=' + packagingCount + '-';
        let toRemove = '';
        let product = {
            code: productCode, packaging: []
        };
        for (let i = 0; i < packagingCount; i++) {
            newPattern += '(.*?)-';
        }
        let regex = new RegExp(newPattern);
        let wholeProduct = regex.exec(fileContent);
        if (wholeProduct) {
            toRemove = wholeProduct[0];
            for (let packagingIndex = 1; packagingIndex <= packagingCount; packagingIndex++) {
                let packaging = wholeProduct[packagingIndex].split(':');
                product.packaging.push({size: packaging[0], price: packaging[1]});
            }
        }
        fileContent = fileContent.substr(toRemove.length);
        return {product: product, fileContent: fileContent};
    }

    isAdmin = () => {
        let UC = this.context.currentQuote.getUserContext();
        let group = UC.productgroup.data[UC.user.productGroup];
        if(!group){
            return false;
        }
        let admins = group.admins;
        let userId = UC.user.id;
        for (let i = 0; i < admins.length; i++) {
            if (admins[i] === userId) {
                return true;
            }
        }
        return false;
    }

    render() {
        const currentQuote = this.context.currentQuote;
        this.props.updateEmptyPackagingCount(currentQuote);

        return (
            <Container className="pl-toolbox p-0 position-fixed">
                <ButtonToolbar className="float-right btn-group">

                    <ButtonGroup className="mr-2 btn-group">
                        <Button className={!this.isAdmin() ? 'd-none' : ''} onClick={this.handleSaveClick}>
                            <FontAwesomeIcon size={"lg"} icon={faSave}/>
                        </Button>
                        <Button onClick={this.handleImportClick}>
                            <FontAwesomeIcon size={"lg"} icon={faFileImport}/>
                        </Button>
                    </ButtonGroup>

                    <PriceListExport currentQuote={this.context.currentQuote} />

                    <ButtonGroup className="mr-2 btn-group">
                        <Button onClick={this.handleToggleNoFormat}>
                            <FontAwesomeIcon icon={currentQuote.showProductWithNoFormat ? faEye : faEyeSlash}/>
                        </Button>
                    </ButtonGroup>

                    <span className="mr-2">{currentQuote.emptyPricesCount}</span>

                    <ButtonGroup className="mr-2 btn-group">
                        <Button onClick={this.handleSortClick}>
                            <FontAwesomeIcon size={"lg"} icon={currentQuote.priceListSortOrder === 'asc' ? faSortAlphaDown : faSortAlphaUp}/>
                        </Button>
                        <Button onClick={this.handleResetClick}>
                            <FontAwesomeIcon size={"lg"} icon={faUndo}/>
                        </Button>
                    </ButtonGroup>

                    <ButtonGroup className="btn-group">
                        <Button onClick={this.handleCloseClick}>
                            <FontAwesomeIcon size={"lg"} icon={faTimesCircle}/>
                        </Button>
                    </ButtonGroup>
                </ButtonToolbar>
            </Container>
        )
    }
}
PriceListToolbox.contextType = QuoteContext;
