import React from "react";
import {Col, Container, Form, Row, Table, Tab, Tabs, Card} from "react-bootstrap";
import '../../assets/css/modules/WaterTreatment.scss';
import {QuoteContext} from "../../contexts/QuoteContext";
import {PencilSquare} from "react-bootstrap-icons";
import ModalInput from "../tools/ModalInput";
import TabOfferParameters from "../water-treatment/tabOfferParameters";
import TabOfferReport from "../water-treatment/tabOfferReport";
import PriceList from "../pricelist/PriceList";
import {getTranslation} from "../tools/Translation";
import QuoteList from "../general-data/QuoteList";
let vsprintf = require('sprintf-js').vsprintf;

export default class WaterTreatment extends React.Component {

    defaultValues = {
        temp_min: 20,
        temp_max: 60,
        conductivity: 350,
        total_hardness: 70,
        calcium_hardness: 50,
        m_alkalinity: 100,
        silica: 21,
        chloride: 30,
        chloride_limitation: 250,
        galvanized_steel_limitation: 9.2,
        circuit_volume: 15,
        make_up_water_consumption: 35000,
        current_conductivity_in_circuit: 1500,
        working_hours_per_year: 8760,
        current_chemical_costs_per_year: 17500,
        calculated_magnesium_hardness: null,
        calculated_temperature_min: null,
        calculated_temperature_max: null,
        calculated_conductivity: null,
        calculated_cycling_in_circuit: null,

        maximum_megnesium_silicate: 15000,
        maximum_silica: 150,

        table_cycling_start: 1.5,
        table_cycling_stop: 10,
        table_cycling_step: 0.5,
        table_cycling_precision: 2,

        simulation_regulated_ph: 8.3,

        maximum_lsi: 2.5,

        offer_parameter_main_price: '',
        offer_parameter_main_dosing_rate: 100,
        offer_parameter_main_make_up_water_cost: 0.55,
        offer_parameter_main_effluent_costs: 0.6,
        offer_parameter_biocide_price: '',
        offer_parameter_biocide_dosing_rate: 200,
        offer_parameter_biocide_dosing_week: 7,
        offer_parameter_biocide_service_frequency: 2,
        offer_parameter_biocide_service_frequency_year: 6,
        offer_parameter_biocide_service_needed: 3,
        offer_parameter_biocide_travel_time: 2,
        offer_parameter_service_cost_per_hour: 25,
        offer_parameter_visit_fee: 100,
        offer_parameter_technical_comments_water_profile: '',
        offer_parameter_main_package: '',
        offer_parameter_biocide_package: '',

        offer_selected_biocide: null,

        offer_parameter_customer_name: '',

        bacterial_count_by_dipslide: 10000

    }

    defaultState = {
        values: this.defaultValues,
        inputPopup: {isVisible: false, fieldName: 'input', dataKey: '', value: 0},
        products: {min: null, max: null, cyclingValue: 0, minCyclingValue: 0, maxCyclingValue: 0},
        customProducts: {product: null, biocide: null, cyclingValue: null},
        simulationProducts: {min: null, max: null},
        mounted: false
    }

    state = this.defaultState;

    recalculate = () => {
        let values = this.state.values;
        values.calculated_magnesium_hardness = Number.parseFloat(this.state.values.total_hardness) - Number.parseFloat(this.state.values.calcium_hardness);
        values.calculated_temperature_min = 273 + Number.parseFloat(this.state.values.temp_min);
        values.calculated_temperature_max = 273 + Number.parseFloat(this.state.values.temp_max);
        values.calculated_conductivity = 0.54 * Number.parseFloat(this.state.values.conductivity);
        values.calculated_cycling_in_circuit = Number.parseFloat(this.state.values.current_conductivity_in_circuit) / Number.parseFloat(this.state.values.conductivity);
        this.setState({values: values});
    }

    handleChange = (e) => {
        this.handleChangeByFieldName(e.target.getAttribute('data-key'), e.target.value);
    }

    updateCycling = (value) => {
        let customProducts = this.state.customProducts;
        customProducts.cyclingValue = parseFloat(value);
        this.setState({customProducts: customProducts});
    }

    handleChangeByFieldName = (fieldName, value) => {
        let values = this.state.values;
        // filtrer le type de valeur.
        // un float stored as string brise la majorité des formules
        if (!Number.isNaN(parseFloat(value)) && !Number.isInteger(value)) {
            value = Number.parseFloat(value);//float
        } else if (Number.isInteger(value)) {
            value = Number.parseInt(value);
        }//else string
        values[fieldName] = value;
        this.setState({values: values}, this.recalculate);
    }

    componentDidMount = () => {
        if (!this.state.mounted) {
            this.setState({
                mounted: true
            });
        }

        this.recalculate();
    }

    isActualCycling = (cyclingValue) => {
        if (this.state.values.calculated_cycling_in_circuit && (Math.round(this.state.values.calculated_cycling_in_circuit / parseFloat(this.state.values.table_cycling_step)) * parseFloat(this.state.values.table_cycling_step)) === cyclingValue) {
            return true;
        }

        return false;
    }

    generateCalculations = (returnBody = false, idToHighlight = null) => {
        let body = [];
        let lastGoodId = null;
        let selectedProductMin = null;
        let selectedProductMax = null;

        for (var cyclingValue = this.state.values.table_cycling_start; cyclingValue <= this.state.values.table_cycling_stop; cyclingValue += this.state.values.table_cycling_step) {

            let phMinFormula = ((Math.log10(cyclingValue * this.state.values.calculated_conductivity) - 1)) / 10 + 34.55 - 13.12 * Math.log10(this.state.values.calculated_temperature_min) + 0.4 - Math.log10(this.state.values.calcium_hardness * cyclingValue) - Math.log10(this.state.values.m_alkalinity * cyclingValue) + 9.3;
            let phMaxFormula = ((Math.log10(cyclingValue * this.state.values.calculated_conductivity) - 1)) / 10 + 34.55 - 13.12 * Math.log10(this.state.values.calculated_temperature_max) + 0.4 - Math.log10(this.state.values.calcium_hardness * cyclingValue) - Math.log10(this.state.values.m_alkalinity * cyclingValue) + 9.3;
            let estimatedPH = 9.8;

            if (this.state.values.m_alkalinity * cyclingValue < 100) {
                estimatedPH = 7.7;
            } else if (this.state.values.m_alkalinity * cyclingValue < 300) {
                estimatedPH = 7.9 + (this.state.values.m_alkalinity * cyclingValue - 150) * 0.003;
            } else if (this.state.values.m_alkalinity * cyclingValue < 400) {
                estimatedPH = 8.4 + (this.state.values.m_alkalinity * cyclingValue - 300) / 500;
            } else if (this.state.values.m_alkalinity * cyclingValue < 1500) {
                estimatedPH = 8.6 + (this.state.values.m_alkalinity * cyclingValue - 400) / 1000;
            } else if (this.state.values.m_alkalinity * cyclingValue < 2000) {
                estimatedPH = 9.6 + (this.state.values.m_alkalinity * cyclingValue - 1500) / 4700;
            } else if (this.state.values.m_alkalinity * cyclingValue < 5000) {
                estimatedPH = 9.7;
            } else {
                estimatedPH = 9.8;
            }

            let lsiMin = estimatedPH - phMinFormula;
            let lsiMax = estimatedPH - phMaxFormula;

            let magnesiumSilicate = cyclingValue * this.state.values.calculated_magnesium_hardness * cyclingValue * this.state.values.silica;
            let silica = cyclingValue * this.state.values.silica;
            let chlorides = cyclingValue * this.state.values.chloride;
            let galvanizedSteel = estimatedPH;

            let productMin = this.getProduct(lsiMin);//'PROA';
            let productMax = this.getProduct(lsiMax);//'PROB';

            let conductivity = cyclingValue * this.state.values.conductivity;
            let totalHardness = cyclingValue * this.state.values.total_hardness;
            let calciumHardness = cyclingValue * this.state.values.calcium_hardness;
            let magnesiumHardness = cyclingValue * this.state.values.calculated_magnesium_hardness;

            let className = idToHighlight && cyclingValue === idToHighlight ? 'lastGoodCalculation' : null;

            if (this.isActualCycling(cyclingValue)) {
                className += ' actualValue';
            }

            if (returnBody) {

                body.push(
                    <tr key={cyclingValue} className={className}>
                        <td className="text-center">{Number.isInteger(cyclingValue) ? cyclingValue : parseFloat(cyclingValue).toFixed(Number.parseInt(this.state.values.table_cycling_precision))}</td>
                        <td className="text-center">{phMinFormula.toFixed(Number.parseInt(this.state.values.table_cycling_precision))}</td>
                        <td className="text-center">{phMaxFormula.toFixed(Number.parseInt(this.state.values.table_cycling_precision))}</td>
                        <td className="text-center">{Number.isInteger(estimatedPH) ? estimatedPH : estimatedPH.toFixed(Number.parseInt(this.state.values.table_cycling_precision))}</td>
                        <td style={lsiMin >= this.state.values.maximum_lsi ? {backgroundColor: '#ffe0b5'} : null}
                            className="text-center">
                            <strong>{Number.isInteger(lsiMin) ? lsiMin : lsiMin.toFixed(Number.parseInt(this.state.values.table_cycling_precision))}</strong>
                        </td>
                        <td style={lsiMax >= this.state.values.maximum_lsi ? {backgroundColor: '#ffe0b5'} : null}
                            className="text-center">
                            <strong>{Number.isInteger(lsiMax) ? lsiMax : lsiMax.toFixed(Number.parseInt(this.state.values.table_cycling_precision))}</strong>
                        </td>
                        <td style={magnesiumSilicate >= this.state.values.maximum_megnesium_silicate ? {backgroundColor: '#ffe0b5'} : null}
                            className="text-center">{magnesiumSilicate.toFixed(0)}</td>
                        <td style={silica >= this.state.values.maximum_silica ? {backgroundColor: '#ffe0b5'} : null}
                            className="text-center">{silica.toFixed(0)}</td>
                        <td style={chlorides >= this.state.values.chloride_limitation ? {backgroundColor: '#ffe0b5'} : null}
                            className="text-center">{chlorides.toFixed(0)}</td>
                        <td style={galvanizedSteel >= this.state.values.galvanized_steel_limitation ? {backgroundColor: '#ffe0b5'} : null}
                            className="text-center">{Number.isInteger(galvanizedSteel) ? galvanizedSteel : galvanizedSteel.toFixed(Number.parseInt(this.state.values.table_cycling_precision))}</td>
                        <td className="text-center" title={productMin.maxLSI}>{productMin.label}</td>
                        <td className="text-center" title={productMax.maxLSI}>{productMax.label}</td>
                        <td style={{backgroundColor: '#d3ffe5'}}
                            className="d-none d-xxl-table-cell text-center">{conductivity.toFixed(0)}</td>
                        <td style={{backgroundColor: '#d3ffe5'}}
                            className="d-none d-xxl-table-cell text-center">{totalHardness.toFixed(0)}</td>
                        <td style={{backgroundColor: '#d3ffe5'}}
                            className="d-none d-xxl-table-cell text-center">{calciumHardness.toFixed(0)}</td>
                        <td style={{backgroundColor: '#d3ffe5'}}
                            className="d-none d-xxl-table-cell text-center">{magnesiumHardness.toFixed(0)}</td>
                        <td style={{backgroundColor: '#d3ffe5'}}
                            className="d-none d-xxl-table-cell text-center">{Number.isInteger(silica) ? silica : silica.toFixed(this.state.values.table_cycling_precision)}</td>
                        <td style={{backgroundColor: '#d3ffe5'}}
                            className="d-none d-xxl-table-cell text-center">{Number.isInteger(chlorides) ? chlorides : chlorides.toFixed(this.state.values.table_cycling_precision)}</td>
                    </tr>
                )
            } else {
                if (
                    (this.state.customProducts.cyclingValue && this.state.customProducts.cyclingValue === cyclingValue)
                    ||
                    (
                        this.state.customProducts.cyclingValue === null
                        && lsiMin < this.state.values.maximum_lsi
                        && lsiMax < this.state.values.maximum_lsi
                        && magnesiumSilicate < this.state.values.maximum_megnesium_silicate
                        && silica < this.state.values.maximum_silica
                        && chlorides < this.state.values.chloride_limitation
                        && galvanizedSteel < this.state.values.galvanized_steel_limitation
                    )
                ) {
                    lastGoodId = cyclingValue;
                    selectedProductMin = productMin.product;
                    selectedProductMax = productMax.product;
                    this.state.products.cyclingValue = lastGoodId;

                    if (this.state.products.min == null || (selectedProductMin && selectedProductMin.id !== this.state.products.min.id)) {
                        if (selectedProductMin !== null) {
                            this.state.products.min = selectedProductMin;
                            this.state.products.minCyclingValue = lastGoodId;
                        }
                    }
                    if (this.state.products.max == null || (selectedProductMax && selectedProductMax.id !== this.state.products.max.id)) {
                        if (selectedProductMax !== null) {
                            this.state.products.max = selectedProductMax;
                            this.state.products.maxCyclingValue = lastGoodId;
                        }
                    }
                }
            }
        }

        return returnBody ? body : lastGoodId;
    }

    generateSimulations = (returnBody = false, idToHighlight = null) => {

        let body = [];
        let lastGoodId = null;
        let selectedProductMin = null;
        let selectedProductMax = null;

        for (var cyclingValue = this.state.values.table_cycling_start; cyclingValue <= this.state.values.table_cycling_stop; cyclingValue += this.state.values.table_cycling_step) {
            let phMinFormula = ((Math.log10(cyclingValue * this.state.values.calculated_conductivity) - 1)) / 10 + 34.55 - 13.12 * Math.log10(this.state.values.calculated_temperature_min) + 0.4 - Math.log10(this.state.values.calcium_hardness * cyclingValue) - Math.log10(this.state.values.m_alkalinity * cyclingValue) + 9.3;
            let phMaxFormula = ((Math.log10(cyclingValue * this.state.values.calculated_conductivity) - 1)) / 10 + 34.55 - 13.12 * Math.log10(this.state.values.calculated_temperature_max) + 0.4 - Math.log10(this.state.values.calcium_hardness * cyclingValue) - Math.log10(this.state.values.m_alkalinity * cyclingValue) + 9.3;
            let regulatedPH = this.state.values.simulation_regulated_ph;

            let lsiMin = regulatedPH - phMinFormula;
            let lsiMax = regulatedPH - phMaxFormula;

            let magnesiumSilicate = cyclingValue * this.state.values.calculated_magnesium_hardness * cyclingValue * this.state.values.silica;
            let silica = cyclingValue * this.state.values.silica;
            let chlorides = cyclingValue * this.state.values.chloride;
            let galvanizedSteel = regulatedPH;

            let productMin = this.getProduct(lsiMin);//'PROA';
            let productMax = this.getProduct(lsiMax);//'PROB';

            let conductivity = cyclingValue * this.state.values.conductivity;
            let totalHardness = cyclingValue * this.state.values.total_hardness;
            let calciumHardness = cyclingValue * this.state.values.calcium_hardness;
            let magnesiumHardness = cyclingValue * this.state.values.calculated_magnesium_hardness;

            let className = idToHighlight && cyclingValue === idToHighlight ? 'lastGoodSimulation' : null;

            if (this.isActualCycling(cyclingValue)) {
                className += ' actualValue';
            }

            if (returnBody) {
                body.push(
                    <tr key={cyclingValue} className={className}>
                        <td className="text-center">{Number.isInteger(cyclingValue) ? cyclingValue : parseFloat(cyclingValue).toFixed(Number.parseInt(this.state.values.table_cycling_precision))}</td>
                        <td className="text-center">{phMinFormula.toFixed(Number.parseInt(this.state.values.table_cycling_precision))}</td>
                        <td className="text-center">{phMaxFormula.toFixed(Number.parseInt(this.state.values.table_cycling_precision))}</td>
                        <td className="text-center">{Number.isInteger(regulatedPH) ? regulatedPH : parseFloat(regulatedPH).toFixed(Number.parseInt(this.state.values.table_cycling_precision))}</td>
                        <td style={lsiMin >= this.state.values.maximum_lsi ? {backgroundColor: '#ffe0b5'} : null}
                            className="text-center">
                            <strong>{Number.isInteger(lsiMin) ? lsiMin : parseFloat(lsiMin).toFixed(Number.parseInt(this.state.values.table_cycling_precision))}</strong>
                        </td>
                        <td style={lsiMax >= this.state.values.maximum_lsi ? {backgroundColor: '#ffe0b5'} : null}
                            className="text-center">
                            <strong>{Number.isInteger(lsiMax) ? lsiMax : parseFloat(lsiMax).toFixed(Number.parseInt(this.state.values.table_cycling_precision))}</strong>
                        </td>
                        <td style={magnesiumSilicate > this.state.values.maximum_megnesium_silicate ? {backgroundColor: '#ffe0b5'} : null}
                            className="text-center">{parseFloat(magnesiumSilicate).toFixed(0)}</td>
                        <td style={silica >= this.state.values.maximum_silica ? {backgroundColor: '#ffe0b5'} : null}
                            className="text-center">{parseFloat(silica).toFixed(0)}</td>
                        <td style={chlorides >= this.state.values.chloride_limitation ? {backgroundColor: '#ffe0b5'} : null}
                            className="text-center">{parseFloat(chlorides).toFixed(0)}</td>
                        <td style={galvanizedSteel >= this.state.values.galvanized_steel_limitation ? {backgroundColor: '#ffe0b5'} : null}
                            className="text-center">{Number.isInteger(galvanizedSteel) ? galvanizedSteel : parseFloat(galvanizedSteel).toFixed(Number.parseInt(this.state.values.table_cycling_precision))}</td>
                        <td className="text-center" title={productMin.maxLSI}>{productMin.label}</td>
                        <td className="text-center" title={productMax.maxLSI}>{productMax.label}</td>
                        <td style={{backgroundColor: '#d3ffe5'}}
                            className="d-none d-xxl-table-cell text-center">{parseFloat(conductivity).toFixed(0)}</td>
                        <td style={{backgroundColor: '#d3ffe5'}}
                            className="d-none d-xxl-table-cell text-center">{parseFloat(totalHardness).toFixed(0)}</td>
                        <td style={{backgroundColor: '#d3ffe5'}}
                            className="d-none d-xxl-table-cell text-center">{parseFloat(calciumHardness).toFixed(0)}</td>
                        <td style={{backgroundColor: '#d3ffe5'}}
                            className="d-none d-xxl-table-cell text-center">{parseFloat(magnesiumHardness).toFixed(0)}</td>
                        <td style={{backgroundColor: '#d3ffe5'}}
                            className="d-none d-xxl-table-cell text-center">{Number.isInteger(silica) ? silica : parseFloat(silica).toFixed(this.state.values.table_cycling_precision)}</td>
                        <td style={{backgroundColor: '#d3ffe5'}}
                            className="d-none d-xxl-table-cell text-center">{Number.isInteger(chlorides) ? chlorides : parseFloat(chlorides).toFixed(this.state.values.table_cycling_precision)}</td>
                    </tr>
                );
            } else {
                if (lsiMin < this.state.values.maximum_lsi && lsiMax < this.state.values.maximum_lsi && magnesiumSilicate < this.state.values.maximum_megnesium_silicate && silica < this.state.values.maximum_silica && chlorides < this.state.values.chloride_limitation && galvanizedSteel < this.state.values.galvanized_steel_limitation) {
                    lastGoodId = cyclingValue;
                    selectedProductMin = productMin.product;
                    selectedProductMax = productMax.product;

                    if (this.state.simulationProducts.min == null || selectedProductMin.id !== this.state.simulationProducts.min.id) {
                        if (selectedProductMin !== null) {
                            this.state.simulationProducts.min = selectedProductMin;
                        }
                    }
                    if (this.state.simulationProducts.max == null || selectedProductMax.id !== this.state.simulationProducts.max.id) {
                        if (selectedProductMax !== null) {
                            this.state.simulationProducts.max = selectedProductMax;
                        }
                    }
                }
            }
        }

        return returnBody ? body : lastGoodId;
    }

    getProducts = () => {
        let products = this.context.currentQuote.getUserContext().product.data;
        let userProducts = this.context.currentQuote.getUserContext().user.products;
        let wtProducts = [];

        if (products && products.length && userProducts && userProducts.length) {
            userProducts.forEach((productId) => {
                if (products[productId] && products[productId].isWaterTreatment()) {
                    wtProducts.push(products[productId]);
                }
            });
        }

        return wtProducts;
    }

    getProduct = (lsi) => {
        let selectedLabel = '---';
        let selectedLSI = parseFloat(999999);
        let products = this.getProducts();
        let selectedProduct = null;

        if (lsi > this.state.values.maximum_lsi) {
            selectedLabel = getTranslation('wt.scale', this.context.currentQuote.getUserContext().user.locale);
        } else {
            if (products && products.length) {
                products.forEach((p) => {
                    if (p.maxLSI !== null) {
                        let productMaximumLSI = parseFloat(p.maxLSI);
                        let lineLSI = parseFloat(lsi);
                        let switchA = lineLSI < productMaximumLSI;
                        let switchB = productMaximumLSI < selectedLSI;
                        if (switchA && switchB) {
                            selectedProduct = p;
                            selectedLabel = p.name;
                            selectedLSI = productMaximumLSI;
                        }
                    }
                });
            }
        }

        return {product: selectedProduct, label: selectedLabel, maxLSI: selectedLSI};
    }

    getBiocide = (hourRate) => {
        let selectedLabel = '---';
        let selectedHR = parseFloat(999999);
        let products = this.getProducts();
        let selectedProduct = null;

        if (products && products.length) {
            products.forEach((p) => {
                if (p.maxAverageHourRate !== null) {
                    let productMAHR = parseFloat(p.maxAverageHourRate);
                    let lineMAHR = parseFloat(hourRate);
                    let switchA = lineMAHR < productMAHR;
                    let switchB = productMAHR < selectedHR;
                    if (switchA && switchB) {
                        selectedProduct = p;
                        selectedLabel = p.name;
                        selectedHR = productMAHR;
                    }
                }
            });
        }

        return {product: selectedProduct, label: selectedLabel, maxHR: selectedHR};
    }

    setCustomProduct = (type, product) => {
        this.setState({
            customProducts: Object.assign(this.state.customProducts, {[type]: product})
        });
    }

    showInputPopup = (fieldName, dataKey, initialValue) => this.setState({
        inputPopup: {
            isVisible: true,
            fieldName: fieldName,
            dataKey: dataKey,
            initialValue: initialValue
        }
    });

    closeInputPopup = () => this.setState({inputPopup: {isVisible: false}}, this.recalculate);

    getCalculationsBody = () => this.generateCalculations(true, this.generateCalculations(false, null));

    getSimulationBody = () => this.generateSimulations(true, this.generateSimulations(false, null));

    getOfferParametersBody = () => {
        let body = [];

        const cycling = this.state.values.calculated_cycling_in_circuit ? Math.round(this.state.values.calculated_cycling_in_circuit / parseFloat(this.state.values.table_cycling_step)) * parseFloat(this.state.values.table_cycling_step) : 0;

        let evaporation = this.state.values.make_up_water_consumption * (1 - 1 / cycling);
        let roundedEvaporation = evaporation.toFixed(0);

        for (var cyclingValue = cycling - 2.5; cyclingValue <= cycling + 6; cyclingValue += 0.5) {

            let roundedCyclingValue = cyclingValue.toFixed(1);
            let style;

            if (this.isActualCycling(cyclingValue)) {
                style = 'actualValue';
            }

            let isSelectedCycling = this.state.customProducts.cyclingValue ? cyclingValue === this.state.customProducts.cyclingValue : (this.state.products.cyclingValue ? this.state.products.cyclingValue === cyclingValue : false);
            if (isSelectedCycling) {
                style = 'lastGoodCalculation';
            }

            let makeUpWater = evaporation / (1 - 1 / cyclingValue);
            let roundedMakeUpWater = makeUpWater.toFixed(0);

            let blowdown = makeUpWater - evaporation;
            let roundedBlowdown = blowdown.toFixed(0);

            let hydricPerformance = 100 * evaporation / makeUpWater;
            let roundedHydricPerformance = hydricPerformance.toFixed(0);

            let averageHourRate = 0.7 * this.state.values.circuit_volume / (blowdown / this.state.values.working_hours_per_year);
            let roundedAverageHourRate = averageHourRate.toFixed(0);

            let b = this.getBiocide(averageHourRate);
            let biocide = b && b.label ? b.label : null; // averageHourRate < 8 ? '422' : 'ATC';

            if (this.isActualCycling(cyclingValue)) {
                b.yearlyMakeUpWater = makeUpWater;
                b.evaporation = roundedEvaporation;
                b.blowdown = roundedBlowdown;
                b.hydricPerformance = roundedHydricPerformance;

                b.cyclingValue = cyclingValue;
                this.state.offer_selected_biocide = b;
            }

            body.push(
                <tr key={roundedCyclingValue} className={style}>
                    <td>{roundedCyclingValue}</td>
                    <td title={evaporation + "/(1-1/" + cyclingValue + ")"}>{roundedMakeUpWater}</td>
                    <td>{this.isActualCycling(cyclingValue) ? roundedEvaporation : null}</td>
                    <td>{roundedBlowdown}</td>
                    <td>{roundedHydricPerformance}</td>
                    <td>{roundedAverageHourRate}</td>
                    <td>{biocide}</td>
                </tr>
            )
        }

        return body;
    }

    render() {
        let calculationsBody = this.getCalculationsBody();
        let simulationBody = this.getSimulationBody();
        let offerParametersBody = this.getOfferParametersBody();

        return (
            <>
                <div style={{display: 'none'}}><QuoteList/></div>
                {this.props.quoteContext.currentQuote.isPriceListVisible &&
                    <PriceList show={true} onHide={this.props.quoteContext.currentQuote.hidePriceList} />
                }
                <ModalInput display={this.state.inputPopup.isVisible}
                            title={this.state.inputPopup.title}
                            fieldName={this.state.inputPopup.fieldName}
                            dataKey={this.state.inputPopup.dataKey}
                            initialValue={this.state.inputPopup.initialValue}
                            onInputChange={this.handleChange}
                            onClose={this.closeInputPopup}/>

                <Container className="water-treatment" fluid style={{marginTop: 75}}>

                    <Tabs justify defaultActiveKey="calculations" variant="pills">

                        <Tab eventKey="calculations" title={getTranslation('wt.calculations', this.context.currentQuote.getUserContext().user.locale)} className="pt-3">

                            <Container fluid>

                                <Row className="mt-3">
                                    <Col>
                                        <Card>
                                            <Card.Header className="text-center">{getTranslation('wt.water-chemistry', this.context.currentQuote.getUserContext().user.locale)}</Card.Header>
                                            <Card.Body>
                                                <Row className="mb-1">
                                                    <Col>{getTranslation('wt.temp-min', this.context.currentQuote.getUserContext().user.locale)}:</Col>
                                                    <Col><Form.Control onBlur={this.handleChange}
                                                                       data-key="temp_min"
                                                                       defaultValue={this.state.values.temp_min}
                                                                       key={this.state.values.temp_min}
                                                    /></Col>
                                                </Row>
                                                <Row className="mb-1">
                                                    <Col>{getTranslation('wt.temp-max', this.context.currentQuote.getUserContext().user.locale)}:</Col>
                                                    <Col><Form.Control onBlur={this.handleChange}
                                                                       data-key="temp_max"
                                                                       defaultValue={this.state.values.temp_max}
                                                                       key={this.state.values.temp_max}
                                                    /></Col>
                                                </Row>
                                                <Row className="mb-1">
                                                    <Col>{getTranslation('wt.condictivity-cm', this.context.currentQuote.getUserContext().user.locale)}:</Col>
                                                    <Col><Form.Control onBlur={this.handleChange}
                                                                       data-key="conductivity"
                                                                       defaultValue={this.state.values.conductivity}
                                                    /></Col>
                                                </Row>
                                                <Row className="mb-1">
                                                    <Col>{getTranslation('wt.total-hardness', this.context.currentQuote.getUserContext().user.locale)}</Col>
                                                    <Col><Form.Control onBlur={this.handleChange}
                                                                       data-key="total_hardness"
                                                                       defaultValue={this.state.values.total_hardness}
                                                    /></Col>
                                                </Row>
                                                <Row className="mb-1">
                                                    <Col>{getTranslation('wt.calcium-hardness', this.context.currentQuote.getUserContext().user.locale)}:</Col>
                                                    <Col><Form.Control onBlur={this.handleChange}
                                                                       data-key="calcium_hardness"
                                                                       defaultValue={this.state.values.calcium_hardness}
                                                    /></Col>
                                                </Row>
                                                <Row className="mb-1">
                                                    <Col>{getTranslation('wt.m-alkalanity', this.context.currentQuote.getUserContext().user.locale)}:</Col>
                                                    <Col><Form.Control onBlur={this.handleChange}
                                                                       data-key="m_alkalinity"
                                                                       defaultValue={this.state.values.m_alkalinity}
                                                    /></Col>
                                                </Row>
                                                <Row className="mb-1">
                                                    <Col>{getTranslation('wt.silika-ppm', this.context.currentQuote.getUserContext().user.locale)}:</Col>
                                                    <Col><Form.Control onBlur={this.handleChange}
                                                                       data-key="silica"
                                                                       defaultValue={this.state.values.silica}
                                                    /></Col>
                                                </Row>
                                                <Row className="mb-1">
                                                    <Col>{getTranslation('wt.chloride-ppm', this.context.currentQuote.getUserContext().user.locale)}:</Col>
                                                    <Col><Form.Control onBlur={this.handleChange}
                                                                       data-key="chloride"
                                                                       defaultValue={this.state.values.chloride}
                                                    /></Col>
                                                </Row>
                                                <Row className="mb-1">
                                                    <Col>{getTranslation('wt.chloride-limitation-ppm', this.context.currentQuote.getUserContext().user.locale)}:</Col>
                                                    <Col><Form.Control onBlur={this.handleChange}
                                                                       data-key="chloride_limitation"
                                                                       defaultValue={this.state.values.chloride_limitation}
                                                                       key={this.state.values.chloride_limitation}
                                                    /></Col>
                                                </Row>
                                                <Row className="mb-1">
                                                    <Col>{getTranslation('wt.galvanized-steel', this.context.currentQuote.getUserContext().user.locale)}:</Col>
                                                    <Col><Form.Control onBlur={this.handleChange}
                                                                       data-key="galvanized_steel_limitation"
                                                                       defaultValue={this.state.values.galvanized_steel_limitation}
                                                                       key={this.state.values.galvanized_steel_limitation}
                                                    /></Col>
                                                </Row>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                    <Col>
                                        <Card>
                                            <Card.Header className="text-center">{getTranslation('wt.operational-conditions', this.context.currentQuote.getUserContext().user.locale)}</Card.Header>
                                            <Card.Body>
                                                <Row className="mb-1">
                                                    <Col>{getTranslation('wt.circuit-volume', this.context.currentQuote.getUserContext().user.locale)}:</Col>
                                                    <Col><Form.Control onBlur={this.handleChange}
                                                                       data-key="circuit_volume"
                                                                       defaultValue={this.state.values.circuit_volume}
                                                    /></Col>
                                                </Row>
                                                <Row className="mb-1">
                                                    <Col>{getTranslation('wt.make-up-water-consumption', this.context.currentQuote.getUserContext().user.locale)}:</Col>
                                                    <Col><Form.Control onBlur={this.handleChange}
                                                                       data-key="make_up_water_consumption"
                                                                       defaultValue={this.state.values.make_up_water_consumption}
                                                    /></Col>
                                                </Row>
                                                <Row className="mb-1">
                                                    <Col>{getTranslation('wt.current-conductivity', this.context.currentQuote.getUserContext().user.locale)}:</Col>
                                                    <Col><Form.Control onBlur={this.handleChange}
                                                                       data-key="current_conductivity_in_circuit"
                                                                       defaultValue={this.state.values.current_conductivity_in_circuit}
                                                    /></Col>
                                                </Row>
                                                <Row className="mb-1">
                                                    <Col>{getTranslation('wt.working-hours', this.context.currentQuote.getUserContext().user.locale)}:</Col>
                                                    <Col><Form.Control onBlur={this.handleChange}
                                                                       data-key="working_hours_per_year"
                                                                       defaultValue={this.state.values.working_hours_per_year}
                                                    /></Col>
                                                </Row>
                                                <Row className="mb-1">
                                                    <Col>{vsprintf(getTranslation('wt.current-chemical-costs', this.context.currentQuote.getUserContext().user.locale), [this.context.currentQuote.getCurrencySign()])}:</Col>
                                                    <Col><Form.Control onBlur={this.handleChange}
                                                                       data-key="current_chemical_costs_per_year"
                                                                       defaultValue={this.state.values.current_chemical_costs_per_year}
                                                    /></Col>
                                                </Row>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                </Row>
                            </Container>

                            <Container fluid className="mt-5">
                                <Card>
                                    <Card.Header className="text-center">{getTranslation('wt.calculated-parameters', this.context.currentQuote.getUserContext().user.locale)}</Card.Header>
                                    <Card.Body>
                                        <Table striped bordered hover className="text-center mb-0">
                                            <thead>
                                            <tr>
                                                <th>{getTranslation('wt.magnesium-hardness', this.context.currentQuote.getUserContext().user.locale)}</th>
                                                <th>{getTranslation('wt.temp-min-k', this.context.currentQuote.getUserContext().user.locale)}</th>
                                                <th>{getTranslation('wt.temp-max-k', this.context.currentQuote.getUserContext().user.locale)}</th>
                                                <th>{getTranslation('wt.conductivity-tds', this.context.currentQuote.getUserContext().user.locale)}</th>
                                                <th>{getTranslation('wt.cycling-in-circuit', this.context.currentQuote.getUserContext().user.locale)}</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr>
                                                <td>{Number.parseFloat(this.state.values.calculated_magnesium_hardness).toFixed(Number.isInteger(this.state.values.calculated_magnesium_hardness) ? 0 : 2)}</td>
                                                <td>{Number.parseFloat(this.state.values.calculated_temperature_min).toFixed(Number.isInteger(this.state.values.calculated_temperature_min) ? 0 : 2)}</td>
                                                <td>{Number.parseFloat(this.state.values.calculated_temperature_max).toFixed(Number.isInteger(this.state.values.calculated_temperature_max) ? 0 : 2)}</td>
                                                <td>{Number.parseFloat(this.state.values.calculated_conductivity).toFixed(Number.isInteger(this.state.values.calculated_conductivity) ? 0 : 2)}</td>
                                                <td>{Number.parseFloat(this.state.values.calculated_cycling_in_circuit).toFixed(Number.isInteger(this.state.values.calculated_cycling_in_circuit) ? 0 : 2)}</td>
                                            </tr>
                                            </tbody>
                                        </Table>
                                    </Card.Body>
                                </Card>

                            </Container>

                            <Container fluid className="mt-5">

                                <Card>
                                    <Card.Body>

                                        <Tabs justify defaultActiveKey="calculations" variant="pills">

                                            <Tab eventKey="calculations" title={getTranslation('wt.calculations', this.context.currentQuote.getUserContext().user.locale)} className="pt-3">

                                                <Table striped bordered hover className="mb-0">
                                                    <thead className="text-center">
                                                    <tr>
                                                        <th colSpan={10}></th>
                                                        <th colSpan={2}>{getTranslation('wt.temperatures', this.context.currentQuote.getUserContext().user.locale)}</th>
                                                        <th className="d-none d-xxl-table-cell"
                                                            style={{backgroundColor: '#d3ffe5'}}
                                                            colSpan={6}>{getTranslation('wt.operational-targets', this.context.currentQuote.getUserContext().user.locale)}
                                                        </th>
                                                    </tr>
                                                    <tr>
                                                        <th></th>
                                                        <th colSpan={2}>{getTranslation('wt.ph-equilibrium', this.context.currentQuote.getUserContext().user.locale)}</th>
                                                        <th></th>
                                                        <th colSpan={2}>{getTranslation('wt.lsi', this.context.currentQuote.getUserContext().user.locale)}</th>
                                                        <th>&lt;&nbsp;{this.state.values.maximum_megnesium_silicate}</th>
                                                        <th>&lt;&nbsp;{this.state.values.maximum_silica}</th>
                                                        <th>&lt;&nbsp;{this.state.values.chloride_limitation}
                                                            <button className="ml-2 bg-transparent border-0"
                                                                    onClick={() => {
                                                                        this.showInputPopup(getTranslation('wt.chloride-limitation', this.context.currentQuote.getUserContext().user.locale), 'chloride_limitation', this.state.values.chloride_limitation)
                                                                    }}><PencilSquare/></button>
                                                        </th>
                                                        <th>&lt;&nbsp;{this.state.values.galvanized_steel_limitation}
                                                            <button className="ml-2 bg-transparent border-0"
                                                                    onClick={() => {
                                                                        this.showInputPopup(getTranslation('wt.galvanized-steel-limitation', this.context.currentQuote.getUserContext().user.locale), 'galvanized_steel_limitation', this.state.values.galvanized_steel_limitation)
                                                                    }}><PencilSquare/></button>
                                                        </th>
                                                        <th>{this.state.values.temp_min}
                                                            <button className="ml-2 bg-transparent border-0"
                                                                    onClick={() => {
                                                                        this.showInputPopup(getTranslation('wt.temp-min', this.context.currentQuote.getUserContext().user.locale), 'temp_min', this.state.values.temp_min)
                                                                    }}><PencilSquare/></button>
                                                        </th>
                                                        <th>{this.state.values.temp_max}
                                                            <button className="ml-2 bg-transparent border-0"
                                                                    onClick={() => {
                                                                        this.showInputPopup(getTranslation('wt.temp-max', this.context.currentQuote.getUserContext().user.locale), 'temp_max', this.state.values.temp_max)
                                                                    }}><PencilSquare/></button>
                                                        </th>
                                                        <th className="d-none d-xxl-table-cell"
                                                            style={{backgroundColor: '#d3ffe5'}}>{getTranslation('wt.us-cm', this.context.currentQuote.getUserContext().user.locale)}
                                                        </th>
                                                        <th className="d-none d-xxl-table-cell"
                                                            style={{backgroundColor: '#d3ffe5'}} colSpan={5}>{getTranslation('wt.ppm', this.context.currentQuote.getUserContext().user.locale)}
                                                        </th>
                                                    </tr>
                                                    <tr>
                                                        <th>{getTranslation('wt.cycling', this.context.currentQuote.getUserContext().user.locale)}</th>
                                                        <th>{this.state.values.temp_min}</th>
                                                        <th>{this.state.values.temp_max}</th>
                                                        <th>{getTranslation('wt.estimated-ph', this.context.currentQuote.getUserContext().user.locale)}</th>
                                                        <th>{this.state.values.temp_min}</th>
                                                        <th>{this.state.values.temp_max}</th>
                                                        <th>{getTranslation('wt.magnesium-silicate', this.context.currentQuote.getUserContext().user.locale)}</th>
                                                        <th>{getTranslation('wt.silica', this.context.currentQuote.getUserContext().user.locale)}</th>
                                                        <th>{getTranslation('wt.chlorides', this.context.currentQuote.getUserContext().user.locale)}</th>
                                                        <th>{getTranslation('wt.galvanized-steel', this.context.currentQuote.getUserContext().user.locale)}</th>
                                                        <th colSpan={2}>{getTranslation('wt.product', this.context.currentQuote.getUserContext().user.locale)}</th>
                                                        <th className="d-none d-xxl-table-cell"
                                                            style={{backgroundColor: '#d3ffe5'}}>{getTranslation('wt.conductivity', this.context.currentQuote.getUserContext().user.locale)}
                                                        </th>
                                                        <th className="d-none d-xxl-table-cell"
                                                            style={{backgroundColor: '#d3ffe5'}}>{getTranslation('wt.total-hardness', this.context.currentQuote.getUserContext().user.locale)}
                                                        </th>
                                                        <th className="d-none d-xxl-table-cell"
                                                            style={{backgroundColor: '#d3ffe5'}}>{getTranslation('wt.calcium-hardness', this.context.currentQuote.getUserContext().user.locale)}
                                                        </th>
                                                        <th className="d-none d-xxl-table-cell"
                                                            style={{backgroundColor: '#d3ffe5'}}>{getTranslation('wt.magnesium-hardness', this.context.currentQuote.getUserContext().user.locale)}
                                                        </th>
                                                        <th className="d-none d-xxl-table-cell"
                                                            style={{backgroundColor: '#d3ffe5'}}>{getTranslation('wt.silica', this.context.currentQuote.getUserContext().user.locale)}
                                                        </th>
                                                        <th className="d-none d-xxl-table-cell"
                                                            style={{backgroundColor: '#d3ffe5'}}>{getTranslation('wt.chlorides', this.context.currentQuote.getUserContext().user.locale)}
                                                        </th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {calculationsBody}
                                                    </tbody>
                                                </Table>

                                            </Tab>

                                            <Tab eventKey="simulation" title={getTranslation('wt.simulation', this.context.currentQuote.getUserContext().user.locale)} className="pt-3">

                                                <Row className="mb-1">
                                                    <Col>{getTranslation('wt.simulation-with-ph', this.context.currentQuote.getUserContext().user.locale)}:</Col>
                                                    <Col><Form.Control onBlur={this.handleChange}
                                                                       data-key="simulation_regulated_ph"
                                                                       defaultValue={this.state.values.simulation_regulated_ph}/></Col>
                                                    <Col></Col>
                                                </Row>

                                                <Table striped bordered hover>
                                                    <thead className="text-center">
                                                    <tr>
                                                        <th colSpan={10}></th>
                                                        <th colSpan={2}>{getTranslation('wt.temperatures', this.context.currentQuote.getUserContext().user.locale)}</th>
                                                        <th className="d-none d-xxl-table-cell"
                                                            style={{backgroundColor: '#d3ffe5'}}
                                                            colSpan={6}>{getTranslation('wt.operational-targets', this.context.currentQuote.getUserContext().user.locale)}
                                                        </th>
                                                    </tr>
                                                    <tr>
                                                        <th></th>
                                                        <th colSpan={2}>{getTranslation('wt.ph-equilibrium', this.context.currentQuote.getUserContext().user.locale)}</th>
                                                        <th></th>
                                                        <th colSpan={2}>{getTranslation('wt.lsi', this.context.currentQuote.getUserContext().user.locale)}</th>
                                                        <th>&lt;&nbsp;{this.state.values.maximum_megnesium_silicate}</th>
                                                        <th>&lt;&nbsp;{this.state.values.maximum_silica}</th>
                                                        <th>&lt;&nbsp;{this.state.values.chloride_limitation}
                                                            <button className="ml-2 bg-transparent border-0"
                                                                    onClick={() => {
                                                                        this.showInputPopup(getTranslation('wt.chloride-limitation', this.context.currentQuote.getUserContext().user.locale), 'chloride_limitation', this.state.values.chloride_limitation)
                                                                    }}><PencilSquare/></button>
                                                        </th>
                                                        <th>&lt;&nbsp;{this.state.values.galvanized_steel_limitation}
                                                            <button className="ml-2 bg-transparent border-0"
                                                                    onClick={() => {
                                                                        this.showInputPopup(getTranslation('wt.galvanized-steel', this.context.currentQuote.getUserContext().user.locale), 'galvanized_steel_limitation', this.state.values.galvanized_steel_limitation)
                                                                    }}><PencilSquare/></button>
                                                        </th>
                                                        <th>{this.state.values.temp_min}
                                                            <button className="ml-2 bg-transparent border-0"
                                                                    onClick={() => {
                                                                        this.showInputPopup(getTranslation('wt.temp-min', this.context.currentQuote.getUserContext().user.locale), 'temp_min', this.state.values.temp_min)
                                                                    }}><PencilSquare/></button>
                                                        </th>
                                                        <th>{this.state.values.temp_max}
                                                            <button className="ml-2 bg-transparent border-0"
                                                                    onClick={() => {
                                                                        this.showInputPopup(getTranslation('wt.temp-max', this.context.currentQuote.getUserContext().user.locale), 'temp_max', this.state.values.temp_max)
                                                                    }}><PencilSquare/></button>
                                                        </th>
                                                        <th className="d-none d-xxl-table-cell"
                                                            style={{backgroundColor: '#d3ffe5'}}>{getTranslation('wt.us-cm', this.context.currentQuote.getUserContext().user.locale)}
                                                        </th>
                                                        <th className="d-none d-xxl-table-cell"
                                                            style={{backgroundColor: '#d3ffe5'}} colSpan={5}>{getTranslation('wt.ppm', this.context.currentQuote.getUserContext().user.locale)}
                                                        </th>
                                                    </tr>
                                                    <tr>
                                                        <th>{getTranslation('wt.cycling', this.context.currentQuote.getUserContext().user.locale)}</th>
                                                        <th>{this.state.values.temp_min}</th>
                                                        <th>{this.state.values.temp_max}</th>
                                                        <th>{getTranslation('wt.regulated-ph', this.context.currentQuote.getUserContext().user.locale)}</th>
                                                        <th>{this.state.values.temp_min}</th>
                                                        <th>{this.state.values.temp_max}</th>
                                                        <th>{getTranslation('wt.magnesium-silicate', this.context.currentQuote.getUserContext().user.locale)}</th>
                                                        <th>{getTranslation('wt.silica', this.context.currentQuote.getUserContext().user.locale)}</th>
                                                        <th>{getTranslation('wt.chlorides', this.context.currentQuote.getUserContext().user.locale)}</th>
                                                        <th>{getTranslation('wt.galvanized-steel', this.context.currentQuote.getUserContext().user.locale)}</th>
                                                        <th colSpan={2}>{getTranslation('wt.product', this.context.currentQuote.getUserContext().user.locale)}</th>
                                                        <th className="d-none d-xxl-table-cell"
                                                            style={{backgroundColor: '#d3ffe5'}}>{getTranslation('wt.conductivity', this.context.currentQuote.getUserContext().user.locale)}
                                                        </th>
                                                        <th className="d-none d-xxl-table-cell"
                                                            style={{backgroundColor: '#d3ffe5'}}>{getTranslation('wt.total-hardness', this.context.currentQuote.getUserContext().user.locale)}
                                                        </th>
                                                        <th className="d-none d-xxl-table-cell"
                                                            style={{backgroundColor: '#d3ffe5'}}>{getTranslation('wt.calcium-hardness', this.context.currentQuote.getUserContext().user.locale)}
                                                        </th>
                                                        <th className="d-none d-xxl-table-cell"
                                                            style={{backgroundColor: '#d3ffe5'}}>{getTranslation('wt.magnesium-hardness', this.context.currentQuote.getUserContext().user.locale)}
                                                        </th>
                                                        <th className="d-none d-xxl-table-cell"
                                                            style={{backgroundColor: '#d3ffe5'}}>{getTranslation('wt.silica', this.context.currentQuote.getUserContext().user.locale)}
                                                        </th>
                                                        <th className="d-none d-xxl-table-cell"
                                                            style={{backgroundColor: '#d3ffe5'}}>{getTranslation('wt.chlorides', this.context.currentQuote.getUserContext().user.locale)}
                                                        </th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {simulationBody}
                                                    </tbody>
                                                </Table>
                                            </Tab>

                                            <Tab eventKey="settings" title={getTranslation('wt.settings', this.context.currentQuote.getUserContext().user.locale)} className="pt-3 right">

                                                <Row className="mb-1">
                                                    <Col>{getTranslation('wt.cycling-start', this.context.currentQuote.getUserContext().user.locale)}:</Col>
                                                    <Col><Form.Control onBlur={this.handleChange}
                                                                       data-key="table_cycling_start"
                                                                       defaultValue={this.state.values.table_cycling_start}/></Col>
                                                    <Col>{getTranslation('wt.cycling-stop', this.context.currentQuote.getUserContext().user.locale)}:</Col>
                                                    <Col><Form.Control onBlur={this.handleChange}
                                                                       data-key="table_cycling_stop"
                                                                       defaultValue={this.state.values.table_cycling_stop}/></Col>
                                                    <Col>{getTranslation('wt.cycling-step', this.context.currentQuote.getUserContext().user.locale)}:</Col>
                                                    <Col><Form.Control onBlur={this.handleChange}
                                                                       data-key="table_cycling_step"
                                                                       defaultValue={this.state.values.table_cycling_step}/></Col>
                                                    <Col>{getTranslation('wt.output-precision', this.context.currentQuote.getUserContext().user.locale)}:</Col>
                                                    <Col><Form.Control onBlur={this.handleChange}
                                                                       data-key="table_cycling_precision"
                                                                       defaultValue={this.state.values.table_cycling_precision}/></Col>
                                                </Row>
                                                <Row className="mb-1">
                                                    <Col>{getTranslation('wt.max-magnesium-silicate', this.context.currentQuote.getUserContext().user.locale)}:</Col>
                                                    <Col><Form.Control onBlur={this.handleChange}
                                                                       data-key="maximum_magnesium_silicate"
                                                                       defaultValue={this.state.values.maximum_megnesium_silicate}/></Col>
                                                    <Col>{getTranslation('wt.max-silica', this.context.currentQuote.getUserContext().user.locale)}:</Col>
                                                    <Col><Form.Control onBlur={this.handleChange}
                                                                       data-key="maximum_silica"
                                                                       defaultValue={this.state.values.maximum_silica}/></Col>
                                                    <Col>{getTranslation('wt.max-bacterial', this.context.currentQuote.getUserContext().user.locale)}:</Col>
                                                    <Col><Form.Control onBlur={this.handleChange}
                                                                       data-key="bacterial_count_by_dipslide"
                                                                       defaultValue={this.state.values.bacterial_count_by_dipslide}/></Col>
                                                </Row>
                                            </Tab>

                                        </Tabs>

                                    </Card.Body>
                                </Card>

                            </Container>

                        </Tab>

                        <Tab eventKey="offer" title={getTranslation('wt.offer', this.context.currentQuote.getUserContext().user.locale)} className="pt-3">
                            <Container fluid>
                                <Card>
                                    <Card.Body>
                                        {this.state.offer_selected_biocide &&
                                            <>
                                                <TabOfferParameters body={offerParametersBody}
                                                                    selectedbiocide={this.state.offer_selected_biocide}
                                                                    products={this.state.products}
                                                                    values={this.state.values}
                                                                    handlechange={this.handleChange}
                                                                    handleChangeByFieldName={this.handleChangeByFieldName}
                                                                    setCustomProduct={this.setCustomProduct}
                                                                    customProducts={this.state.customProducts}
                                                                    updateCycling={this.updateCycling}
                                                                    getProducts={this.getProducts} />
                                                <TabOfferReport offerValues={this.state.values} products={this.state.products}
                                                                context={this.context}
                                                                selectedBiocide={this.state.offer_selected_biocide}
                                                                customProducts={this.state.customProducts} />
                                            </>
                                        }
                                    </Card.Body>

                                </Card>

                            </Container>

                        </Tab>

                    </Tabs>
                    <div id="bottom"></div>
                </Container>
            </>
        );
    }
}

WaterTreatment.contextType = QuoteContext;