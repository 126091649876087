import React, {useContext} from "react";
import {Card, Col, Row} from "react-bootstrap";
import {getTranslation} from "../tools/Translation";
import {UserContext} from "../../contexts/UserContext";
import Cip from "./Cip";
import {CausticSodaQuote} from "../../model/CausticSoda/CausticSodaQuote";
import {CipTypes} from "../../model/CausticSoda/CipTypes";

export default function Cips(props) {
    const context = useContext(UserContext);


    const setCipProperty = (type, productType, key, value) => {
        props.quote.setCipProperty(context, type, productType, key, value);
        props.setQuote(new CausticSodaQuote(props.quote));
    }

    return (
        <>
            {!props.showAlternative &&
                <Row>
                    <Col>
                        <Cip accordionKey={1} activeKey={1} title={getTranslation('cs.actual-cip', context.user.locale)}
                             quote={props.quote} setCipProperty={setCipProperty} type={CipTypes.actual} displayAdditive={false} editSoda={true}
                             editAcid={true} editDesinfectant={true} editWater={true} editAdditive={false}
                             showDelta={false} displayDelta={false} displayWaterOptimisation={false} displayTimeOptimization={false}
                             showCurrentRatio={true} showPotentialGains={false} displayCo2EmissionsDelta={false} />
                    </Col>
                </Row>
            }
            {props.showAlternative &&
                <Row className="mt-3">
                    <Col>
                        <Card>
                            <Card.Header className="text-center"><strong>{getTranslation('cs.cips', context.user.locale)}</strong></Card.Header>
                            <Card.Body>
                                <Row>
                                    <Col>
                                        <Cip accordionKey={2} activeKey={2} title={getTranslation('cs.mono-alternate', context.user.locale)}
                                             quote={props.quote} setCipProperty={setCipProperty} type={CipTypes.monoAlternate} displayAdditive={false} editSoda={true}
                                             editAcid={true} editDesinfectant={true} editWater={false} editAdditive={false}
                                             showDelta={true} displayDelta={true} displayWaterOptimisation={true} displayTimeOptimization={true}
                                             showCurrentRatio={false} showPotentialGains={true} displayCo2EmissionsDelta={true} />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Cip accordionKey={3} activeKey={3} title={getTranslation('cs.soda-additive', context.user.locale)}
                                             quote={props.quote} setCipProperty={setCipProperty} type={CipTypes.sodaAdditive} displayAdditive={true} editSoda={true}
                                             editAcid={false} editDesinfectant={true} editWater={false} editAdditive={true}
                                             showDelta={true} displayDelta={true} displayWaterOptimisation={true} displayTimeOptimization={true}
                                             showCurrentRatio={false} showPotentialGains={true} displayCo2EmissionsDelta={true} />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Cip accordionKey={4} activeKey={4} title={getTranslation('cs.mono-additive-soda', context.user.locale)}
                                             quote={props.quote} setCipProperty={setCipProperty} type={CipTypes.monoSodaAdditive} displayAdditive={true} editSoda={false}
                                             editAcid={false} editDesinfectant={false} editWater={false} editAdditive={false}
                                             showDelta={true} displayDelta={true} displayWaterOptimisation={true} displayTimeOptimization={true}
                                             showCurrentRatio={false} showPotentialGains={true} displayCo2EmissionsDelta={true} />
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            }
        </>
    );
}