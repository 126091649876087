import React from "react";
import ProductDetail from "./ProductDetail";
import {UserContext} from "../../contexts/UserContext";

export default class ProductDetails extends React.Component {
    render() {
        let i = 0;
        return (
            <div className={"sheet-padding" + (this.props.page > 1 ? ' sheet-padding-top' : '')}>
                { this.props.products.map((productId) => {
                    i++;
                    const product = this.context.product.data[productId];
                    return (
                        <ProductDetail even={i%2}
                                 product={product} key={'product-detail' + productId} />
                    )
                })}
            </div>
        );
    }
}

ProductDetails.contextType = UserContext;