import React from "react";
import {UserContext} from "../../contexts/UserContext";
import CalculationAdditionalText from "./CalculationAdditionalText";

export default class LabelType extends React.Component {
    render() {
        if (!this.props.productData.calculationParts[this.props.calculationPart.parameter]) {
            return null;
        }

        return (
            <div className="formula-text">
                {this.props.calculationPart.addPadding &&
                    <em>&nbsp;</em>
                }
                {this.props.productData.calculationParts[this.props.calculationPart.parameter]}
                {this.props.calculationPart.formulaVariable && this.props.calculationPart.formulaVariable.additionalText &&
                    <CalculationAdditionalText additionalText={this.props.calculationPart.formulaVariable.additionalText} product={this.props.product} productData={this.props.productData} />
                }
                {this.props.calculationPart.addPadding &&
                    <em>&nbsp;</em>
                }
            </div>
        )
    }
}

LabelType.contextType = UserContext;