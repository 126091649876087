import React, {useContext} from "react";
import classNames from "classnames";
import {getProducts} from "./ProductRepo";
import {UserContext} from "../../contexts/UserContext";
import {getTranslation} from "../tools/Translation";

export default function ProductSelect(props) {
    const context = useContext(UserContext);

    return (
        <select className={classNames({'form-control': true, 'form-control-sm': true, 'text-center': true})} onChange={(e) => props.onChange(e)} value={props.value}>
            <option key={0} value='' />
            {getProducts(context, props.productType, true).map((object) => {
                return (
                    <option key={object.id} value={object.id}>{getTranslation(object.name, context.user.locale)}</option>
                )
            })}
        </select>
    );
}