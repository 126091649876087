import React from 'react';
import {Col, Container, Modal, ModalBody, ModalTitle, Row} from "react-bootstrap";
import ModalHeader from "react-bootstrap/ModalHeader";
import {QuoteContext} from "../../contexts/QuoteContext";
import {UserContext} from "../../contexts/UserContext";
import {getTranslation} from "../tools/Translation";
import AddToCategorySelector from "../category/AddToCategorySelector";
import {QuoteProduct} from "../../model/QuoteProduct";

export default class ProductDetailPopup extends React.Component {

    hideHandler = () => this.context.currentQuote.hideProductDetailsPopup();

    render() {
        let product = this.context.currentQuote.selectedProductData ? this.context.currentQuote.selectedProductData : null;

        if (!product) {
            return null;
        }

        // Not sure why product is an instance of QuoteProduct, but it should be an instance of Product...
        if (product instanceof QuoteProduct) {
            product = product.product;
        }

        return (
            <UserContext.Consumer>
                {({user}) => {
                    return (
                        <Modal show={this.props.show} onHide={this.hideHandler} className="product-details-popup">
                            <ModalHeader>
                                <ModalTitle>{product.name}</ModalTitle>
                            </ModalHeader>
                            <ModalBody>
                                <Container>
                                    <Row><Col style={{textAlign: 'center'}}><img alt={product.name} src={product.image}/></Col></Row>
                                    {product.categories ? <AddToCategorySelector product={product} hideHandler={this.hideHandler}/> : false}
                                    <Row>
                                        <Col/>
                                        {product.document && product.document.length > 0 ? <Col md="auto"><a className="FT" href={product.document} target="_blank" rel="noopener noreferrer">{getTranslation('Fiche produit', user.local)}</a></Col> : null}
                                        <Col md="auto"><a className="DOCFDS" href="https://kite.kersia-group.com/4dcgi/SEARCHDOC?start" target="_blank" rel="noopener noreferrer">{getTranslation('FT/FDS', user.locale)}</a></Col>
                                        <Col/>
                                    </Row>
                                    <Row><Col><p className="description">{product.getTranslation({fieldName: 'description', locale: user.locale})}</p></Col></Row>
                                </Container>
                            </ModalBody>
                        </Modal>
                    );
                }}
            </UserContext.Consumer>
        )
    }
}
ProductDetailPopup.contextType = QuoteContext;
