import React from "react";
import {getTranslation} from "../tools/Translation";
import {UserContext} from "../../contexts/UserContext";

export default class ProductSoftWater extends React.Component {
    onChangeHandler = () => {
        this.props.currentQuote.toggleSoftWater(this.props.category.id);
    }

    render() {
        let frequency = this.props.category.getFrequency(this.context.user.species);
        if (!frequency || !frequency.frequencyPerWeek || !frequency.frequencySoftWaterAlkalin || !frequency.frequencySoftWaterAcid) {
            return null;
        }

        const label = getTranslation("softwater", this.context.user.locale);

        return (
            <div className="product-soft-water-wrapper" style={this.props.category.color ? {color: this.props.category.color} : null}>
                <label>
                    <input type="checkbox" onChange={this.onChangeHandler} checked={this.props.currentQuote.isCategoryUsingSoftWater(this.props.category.id)} />
                    <span>{label}</span>
                </label>
            </div>
        )
    }
}

ProductSoftWater.contextType = UserContext;