import React from "react";
import {QuoteContext} from "../../contexts/QuoteContext";
import {getTranslation} from "../tools/Translation";
import {UserContext} from "../../contexts/UserContext";

export default class QuoteTotal extends React.Component {
    render() {
        return (
            <UserContext.Consumer>
                {({user}) => (
                    <div className="quote-total">
                        <span>{getTranslation("category.total", user.locale)}:</span>
                        {this.context.currentQuote.getTotal().toFixed(2)} {this.context.currentQuote.getCurrencySign()}
                    </div>
                )}
            </UserContext.Consumer>
        )
    }
}

QuoteTotal.contextType = QuoteContext;