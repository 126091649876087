import React, {useContext} from "react";
import {Card, Col, Container, Row} from "react-bootstrap";
import TableInitialRecipe from "./TableInitialRecipe";
import {getTranslation} from "../tools/Translation";
import {UserContext} from "../../contexts/UserContext";
import TableMonoAlternate from "./TableMonoAlternate";
import TableAdditiveOnly from "./TableAdditiveOnly";
import TableMonoAdditive from "./TableMonoAdditive";
import TableOptiMonoAlternate from "./TableOptiMonoAlternate";
import TableOptiAdditiveOnly from "./TableOptiAdditiveOnly";

export default function EquipmentsTables(props) {
    const context = useContext(UserContext);

    if (props.quote.equipments.length < 1) {
        return null;
    }

    return (
        <Container fluid>
            <Row className="mt-3">
                <Col>
                    <Card>
                        <Card.Header className="text-center"><strong>{getTranslation('cs.equipments-table', context.user.locale)}</strong></Card.Header>
                        <Card.Body>
                            <TableInitialRecipe {...props} />
                            <TableMonoAlternate {...props} />
                            {props.quote.advancedMethod &&
                                <>
                                    <TableAdditiveOnly {...props} />
                                    <TableMonoAdditive {...props} />
                                    <TableOptiMonoAlternate {...props} />
                                    <TableOptiAdditiveOnly {...props} />
                                </>
                            }
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
}