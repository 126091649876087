import moment from "moment";
import {ProductTypes} from "../../components/caustic-soda/ProductTypes";
import {CipProduct} from "./CipProduct";
import _, { cloneDeep } from "lodash";

export class Cip {

    id = null;
    type = '';
    products = {
        [ProductTypes.Additive] : new CipProduct({type: ProductTypes.Additive}),
        [ProductTypes.CausticSoda] : new CipProduct({type: ProductTypes.CausticSoda}),
        [ProductTypes.Acid] : new CipProduct({type: ProductTypes.Acid}),
        [ProductTypes.Desinfectant] : new CipProduct({type: ProductTypes.Desinfectant}),
        [ProductTypes.Water]: new CipProduct({type: ProductTypes.Water}),
        [ProductTypes.OccupationTime] : new CipProduct({type: ProductTypes.OccupationTime}),
        [ProductTypes.Co2Emissions] : new CipProduct({type: ProductTypes.Co2Emissions}),
    }

    globalCost = 0;
    delta = 0;
    optimizedWater = 0;
    optimizedWaterCost = 0;
    optimizedTime = 0;
    co2EmissionsDelta = 0;

    constructor(data) {
        if (!data || (data && !data.id)) {
            this.id = moment().valueOf();
        }

        // Create objects from JSON data
        if (data && data.products) {
            Object.keys(ProductTypes).forEach((productType) => {
                if (data.products[ProductTypes[productType]]) {
                    data.products[ProductTypes[productType]] = new CipProduct(data.products[ProductTypes[productType]]);
                }
            });
        }

        if (data) {
            const mergedObjects = _.merge(this, data);
            Object.assign(this, mergedObjects);
        }
    }
}