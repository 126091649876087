import React from 'react';
import {Col, Container, Row} from "react-bootstrap";
import {QuoteContext} from "../../contexts/QuoteContext";
import $ from "jquery";

export default class PriceListPackaging extends React.Component {

    constructor(props) {
        super(props);
        this.inputElement = React.createRef();
    }

    handleChange = (e) => {
        let price = (e.target.value + '').replace(',', '.');
        if (isNaN(price)) {
            price = 0;
        }

        this.context.currentQuote.prices[this.props.id] = price;
        this.props.updateEmptyPackagingCount(this.context.currentQuote);
        this.context.currentQuote.updateProductPrice(this.props.id, parseFloat(price))
        this.context.currentQuote.updateContextState(true);
    }

    onFocusHandler = (e) => {
        if (parseFloat(e.target.value) === 0) {
            e.target.value = '';
        }

        $(this.inputElement.current).select();
    }

    onBlurHandler = (e) => {
        let price = (parseFloat(this.context.currentQuote.prices[this.props.id] || 0) + '').replace(',', '.');
        if (isNaN(price)) {
            price = 0;
        }

        this.context.currentQuote.prices[this.props.id] = parseFloat(price).toFixed(2);
        this.context.currentQuote.updateContextState(true);
    }

    render() {
        return (
            <div className={'pl-packaging-wrapper'} style={this.props.sizeLabel.length > 8 ? {width: 'auto'} : {}}>
                <Container className={'pl-packaging-container'}>
                    <Row>
                        <Col>{this.props.sizeLabel}</Col>
                    </Row>
                    <Row>
                        <Col>
                            <input
                                onFocus={this.onFocusHandler}
                                onBlur={this.onBlurHandler}
                                ref={this.inputElement}
                                type="text"
                                onChange={this.handleChange}
                                value={this.inputElement.current === document.activeElement ? this.context.currentQuote.prices[this.props.id] : (parseFloat(this.context.currentQuote.prices[this.props.id] || 0).toFixed(2) > 0 ? parseFloat(this.context.currentQuote.prices[this.props.id] || 0).toFixed(2) : '')}/>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}
PriceListPackaging.contextType = QuoteContext;
