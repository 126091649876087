import React from "react";
import {getTranslation} from "../tools/Translation";
import {UserContext} from "../../contexts/UserContext";

export default class CategoryTotal extends React.Component {
    render() {
        return (
            <div className="print-category-total clearfix">
                <span className="title">
                    {getTranslation('category.total', this.context.user.locale)}:
                </span>
                {this.props.currentQuote.getCategoryTotal(this.props.category).toFixed(2)} {this.props.currentQuote.getCurrencySign()}
            </div>
        )
    }
}

CategoryTotal.contextType = UserContext;