import React from 'react';
import {Modal} from "react-bootstrap";
import {getTranslation} from "./Translation";
import {UserContext} from "../../contexts/UserContext";

export default class CGU extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            accepted1: false,
            accepted2: false
        };
    }

    onClickHandler = () => {
        if (this.state.accepted1 && this.state.accepted2) {
            localStorage.setItem('cgu', 'true');
            this.props.closeCguPopup();
        }
    }

    onCheckHandler = (checkbox) => {
        this.setState({
            [checkbox]: !this.state[checkbox]
        });
    }

    render() {
        return (
            <Modal
                show={this.props.show}
                backdrop="static"
                keyboard={false}
                size="md"
                centered>
                <Modal.Body>
                    <h5>{getTranslation('cgu1', this.context.user.locale)}</h5>
                    <div className="d-flex flex-row align-items-start mb-1">
                        <input type="checkbox" id="accepted1" value="1" checked={this.state.accepted1} onClick={() => {this.onCheckHandler('accepted1')}} style={{margin: '6px 10px 0 0'}} />
                        <label for='accepted1'>{getTranslation('cgu2', this.context.user.locale)}</label>
                    </div>
                    <div className="d-flex flex-row align-items-start">
                        <input type="checkbox" id="accepted2" value="1" checked={this.state.accepted2} onClick={() => {this.onCheckHandler('accepted2')}} style={{margin: '6px 10px 0 0'}} />
                        <label for='accepted2'>{getTranslation('cgu3', this.context.user.locale)}</label>
                    </div>
                    <button className="btn btn-primary float-right" onClick={this.onClickHandler} disabled={!this.state.accepted1 || !this.state.accepted2}>{getTranslation('ok', this.context.user.locale)}</button>
                </Modal.Body>
            </Modal>
        )
    }
}

CGU.contextType = UserContext;