import React from "react";
import {Grid} from "@material-ui/core";
import {QuoteContext} from "../../contexts/QuoteContext";

export default class MosaicProduct extends React.Component {

    handleClick = () => this.context.currentQuote.showProductDetailsPopup(this.props.product);

    render() {
        return (
            <Grid item xs={6} sm={4} md={3} lg={2} xl={1}>
                <div className="mosaicProdBox" onClick={this.handleClick}>
                    <img alt={this.props.product.name} className="mosaicProdBox-img" src={this.props.product.image}/>
                    <div className="mosaicProdBox-name">{this.props.product.name}</div>
                    <div className="mosaic-packaging-container">
                        {this.props.product.formats.map((format) => <div className="mosaic-packaging" key={format.id}>{format.name}</div>)}
                    </div>
                </div>
            </Grid>
        );
    }
}
MosaicProduct.contextType = QuoteContext;