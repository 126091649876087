import {getDataFromStorage} from "./LocalStorage";

function compareOrdering(a, b) {
    if (a.ordering < b.ordering) {
        return -1;
    }
    if (a.ordering > b.ordering) {
        return 1;
    }
    return 0;
}

export async function parseData(stateUserContext) {
    try {
        return getDataFromStorage().then((data) => {
            let userContext = Object.assign(stateUserContext, data);

            let userCategories = [];
            let userCategoryProducts = {};
            let userProducts = [];
            let categories = {};
            let products = {};
            let userProductFormats = {};

            if (userContext.product && userContext.product.data) {
                products = userContext.product.data;
            }

            if (userContext.category && userContext.category.data) {
                categories = userContext.category.data;
            }

            let categoryCatalog = null;

            let _categories = Object.values(categories);
            _categories.forEach((category) => {
                if (category.isCatalog) {
                    categoryCatalog = category.id;
                    return false; // Break
                }
            });

            for (const [productId, product] of Object.entries(products)) {
                // If product is in a Country
                if (product.countries.length > 0) {
                    // If the product is in the user selected country
                    if (product.countries.indexOf(userContext.user.country) !== -1) {
                        // If the product is available for this species, or no species specified
                        if (product.species.length === 0 || product.species.indexOf(userContext.user.species) !== -1) {
                            // If the product is in a user's product group
                            if (product.productGroups.indexOf(userContext.user.productGroup) !== -1) {
                                userProducts.push(parseInt(productId));

                                // Add all the product in the Catalog Category
                                if (categoryCatalog) {
                                    product.categories.push(categoryCatalog);
                                }

                                // Build category array for this user
                                product.categories.forEach((categoryId) => {
                                    let category = categories[parseInt(categoryId)] || null;
                                    if (category && ((category.countries.indexOf(userContext.user.country) !== -1) || category.isCatalog)) {
                                        if (userCategories.indexOf(categoryId) === -1) {
                                            userCategories.push(parseInt(categoryId));
                                        }

                                        if (!Array.isArray(userCategoryProducts[parseInt(categoryId)])) {
                                            userCategoryProducts[parseInt(categoryId)] = [];
                                        }

                                        if (userCategoryProducts[parseInt(categoryId)].indexOf(parseInt(productId)) === -1) {
                                            userCategoryProducts[parseInt(categoryId)].push(parseInt(productId));
                                        }
                                    }
                                });
                            }
                        }
                    }
                }
            }

            userProducts.forEach((productId) => {
                userProductFormats[productId] = [];
                if (products[productId] && products[productId].formats && products[productId].formats.length > 0) {
                    products[productId].formats.forEach((format) => {
                        if (!format.countries.length || format.countries.includes(userContext.user.country)) {
                            userProductFormats[productId].push(format.id);
                        }
                    })
                }
            });

            // Sort user categories based on Category.ordering
            let sortedCategoryArray = Object.values(categories);
            sortedCategoryArray.sort(compareOrdering);

            let userSortedCategories = [];
            sortedCategoryArray.forEach((category) => {
                if (userCategories.indexOf(parseInt(category.id)) !== -1) {
                    userSortedCategories.push(parseInt(category.id));
                }
            });

            userContext.user.setCategories(userSortedCategories, true);
            userContext.user.setCategoryProducts(userCategoryProducts, true);
            userContext.user.setProducts(userProducts, true);
            userContext.user.setProductFormats(userProductFormats, true);

            // Return the next userContext that will be merged into the current userContext in the state
            return Object.assign(stateUserContext, userContext);
        }).catch(function(e) {
            console.error(e);
        });
    } catch (e) {
        console.error(e);
    }
}
