import React, {useContext} from "react";
import {Card, Col, Container, Row} from "react-bootstrap";
import {getTranslation} from "../tools/Translation";
import {UserContext} from "../../contexts/UserContext";
import {ProductTypes} from "./ProductTypes";
import {CipTypes} from "../../model/CausticSoda/CipTypes";
import {QuoteContext} from "../../contexts/QuoteContext";
import {config} from "../config/Config";

export default function Conclusion(props) {
    const context = useContext(UserContext);
    const quoteContext = useContext(QuoteContext);

    if (props.quote.equipments.length < 1) {
        return null;
    }

    const productTypes = context.producttype?.data;

    return (
        <Container fluid>
            <Row className="mt-3">
                <Col>
                    <Card>
                        <Card.Header className="text-center"><strong>{getTranslation('cs.conclusion-etude', context.user.locale)}</strong></Card.Header>
                        <Card.Body>
                            <table className="table table-bordered table-conclusion table-fixed-width small text-center table-valign-middle">
                                <thead>
                                    <tr>
                                        <th>
                                            {getTranslation('cs.methods', context.user.locale)}
                                        </th>
                                        <th className="bg-warning">
                                            {getTranslation('cs.alkaline', context.user.locale)}
                                        </th>
                                        <th className="bg-danger">
                                            {productTypes[ProductTypes.Acid].getTranslation({fieldName: 'name', locale: context.user.locale})}
                                        </th>
                                        <th className="bg-success">
                                            {productTypes[ProductTypes.Desinfectant].getTranslation({fieldName: 'name', locale: context.user.locale})}
                                        </th>
                                        <th>
                                            {getTranslation('cs.product-cost', context.user.locale)}
                                        </th>
                                        <th>
                                            {getTranslation('cs.water', context.user.locale)}
                                        </th>
                                        <th>
                                            {getTranslation('cs.occupation-time', context.user.locale)}
                                        </th>
                                        <th>
                                            {getTranslation('cs.co2-emissions', context.user.locale)}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td rowSpan={2} className="method">
                                            <strong>{getTranslation('cs.soude-acide', context.user.locale)}</strong>
                                            <br/>
                                            <small><strong className="font-italic">({getTranslation('cs.actual-method', context.user.locale)})</strong></small>
                                        </td>
                                        <td className="alkaline">
                                            {props.quote.cips[CipTypes.actual].products[ProductTypes.CausticSoda].product ? getTranslation(context.product?.data[props.quote.cips[CipTypes.actual].products[ProductTypes.CausticSoda].product]?.name, context.user.locale) : ''}
                                        </td>
                                        <td className="acid">
                                            {props.quote.cips[CipTypes.actual].products[ProductTypes.Acid].product ? getTranslation(context.product?.data[props.quote.cips[CipTypes.actual].products[ProductTypes.Acid].product]?.name, context.user.locale) : ''}
                                        </td>
                                        <td className="desinfectant">
                                            {props.quote.cips[CipTypes.actual].products[ProductTypes.Desinfectant].product ? getTranslation(context.product?.data[props.quote.cips[CipTypes.actual].products[ProductTypes.Desinfectant].product]?.name, context.user.locale) : ''}
                                        </td>
                                        <td rowSpan={2} className="method">
                                            {parseFloat(parseFloat(props.quote.cips[CipTypes.actual].products[ProductTypes.CausticSoda].annualCa ? props.quote.cips[CipTypes.actual].products[ProductTypes.CausticSoda].annualCa : 0).toFixed(0)) + parseFloat(parseFloat(props.quote.cips[CipTypes.actual].products[ProductTypes.Acid].annualCa ? props.quote.cips[CipTypes.actual].products[ProductTypes.Acid].annualCa : 0).toFixed(0)) + parseFloat(parseFloat(props.quote.cips[CipTypes.actual].products[ProductTypes.Desinfectant].annualCa ? props.quote.cips[CipTypes.actual].products[ProductTypes.Desinfectant].annualCa : 0).toFixed(0))} {quoteContext.currentQuote.getCurrencySign()}
                                        </td>
                                        <td>
                                            {parseFloat(parseFloat(props.quote.cips[CipTypes.actual].products[ProductTypes.Water].volume ? props.quote.cips[CipTypes.actual].products[ProductTypes.Water].volume : 0).toFixed(0))} {context.unit?.data[config.unitCubicMeters]?.getTranslation({fieldName: 'name', locale: context.user.locale})}
                                        </td>
                                        <td rowSpan={2} className="method">
                                            {parseFloat(parseFloat(props.quote.cips[CipTypes.actual].products[ProductTypes.OccupationTime].volume ? props.quote.cips[CipTypes.actual].products[ProductTypes.OccupationTime].volume : 0).toFixed(0))} {context.unit?.data[config.unitHours]?.getTranslation({fieldName: 'name', locale: context.user.locale})}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="alkaline">
                                            {parseFloat(props.quote.cips[CipTypes.actual].products[ProductTypes.CausticSoda].pricing ? props.quote.cips[CipTypes.actual].products[ProductTypes.CausticSoda].pricing : 0).toFixed(3)}{quoteContext.currentQuote.getCurrencySign() + '/' + context.unit?.data[config.unitKg]?.getTranslation({fieldName: 'name', locale: context.user.locale})}
                                        </td>
                                        <td className="acid">
                                            {parseFloat(props.quote.cips[CipTypes.actual].products[ProductTypes.Acid].pricing ? props.quote.cips[CipTypes.actual].products[ProductTypes.Acid].pricing : 0).toFixed(3)}{quoteContext.currentQuote.getCurrencySign() + '/' + context.unit?.data[config.unitKg]?.getTranslation({fieldName: 'name', locale: context.user.locale})}
                                        </td>
                                        <td className="desinfectant">
                                            {parseFloat(props.quote.cips[CipTypes.actual].products[ProductTypes.Desinfectant].pricing ? props.quote.cips[CipTypes.actual].products[ProductTypes.Desinfectant].pricing : 0).toFixed(3)}{quoteContext.currentQuote.getCurrencySign() + '/' + context.unit?.data[config.unitKg]?.getTranslation({fieldName: 'name', locale: context.user.locale})}
                                        </td>
                                        <td>
                                            {parseFloat(parseFloat(props.quote.cips[CipTypes.actual].products[ProductTypes.Water].annualCa ? props.quote.cips[CipTypes.actual].products[ProductTypes.Water].annualCa : 0).toFixed(0))} {quoteContext.currentQuote.getCurrencySign()}
                                        </td>
                                    </tr>

                                    <tr>
                                        <td rowSpan={4} className="method">
                                            <strong>{getTranslation('cs.soude-additivee', context.user.locale)}</strong>
                                        </td>
                                        <td className="alkaline">
                                            {props.quote.cips[CipTypes.sodaAdditive].products[ProductTypes.CausticSoda].product ? getTranslation(context.product?.data[props.quote.cips[CipTypes.sodaAdditive].products[ProductTypes.CausticSoda].product]?.name, context.user.locale) : ''}
                                        </td>
                                        <td className="acid" rowSpan={2}>
                                            {props.quote.cips[CipTypes.sodaAdditive].products[ProductTypes.Acid].product ? getTranslation(context.product?.data[props.quote.cips[CipTypes.sodaAdditive].products[ProductTypes.Acid].product]?.name, context.user.locale) : ''}
                                        </td>
                                        <td className="desinfectant" rowSpan={2}>
                                            {props.quote.cips[CipTypes.sodaAdditive].products[ProductTypes.Desinfectant].product ? getTranslation(context.product?.data[props.quote.cips[CipTypes.sodaAdditive].products[ProductTypes.Desinfectant].product]?.name, context.user.locale) : ''}
                                        </td>
                                        <td rowSpan={4} className="method">
                                            {parseFloat(parseFloat(props.quote.cips[CipTypes.sodaAdditive].products[ProductTypes.Additive].annualCa ? props.quote.cips[CipTypes.sodaAdditive].products[ProductTypes.Additive].annualCa : 0).toFixed(0)) + parseFloat(parseFloat(props.quote.cips[CipTypes.sodaAdditive].products[ProductTypes.CausticSoda].annualCa ? props.quote.cips[CipTypes.sodaAdditive].products[ProductTypes.CausticSoda].annualCa : 0).toFixed(0)) + parseFloat(parseFloat(props.quote.cips[CipTypes.sodaAdditive].products[ProductTypes.Acid].annualCa ? props.quote.cips[CipTypes.sodaAdditive].products[ProductTypes.Acid].annualCa : 0).toFixed(0)) + parseFloat(parseFloat(props.quote.cips[CipTypes.sodaAdditive].products[ProductTypes.Desinfectant].annualCa ? props.quote.cips[CipTypes.sodaAdditive].products[ProductTypes.Desinfectant].annualCa : 0).toFixed(0))} {quoteContext.currentQuote.getCurrencySign()}
                                        </td>
                                        <td rowSpan={2}>
                                            {parseFloat(parseFloat(props.quote.cips[CipTypes.sodaAdditive].optimizedWater ? props.quote.cips[CipTypes.sodaAdditive].optimizedWater : 0).toFixed(0))} {context.unit?.data[config.unitCubicMeters]?.getTranslation({fieldName: 'name', locale: context.user.locale})}
                                        </td>
                                        <td rowSpan={4} className="method">
                                            {parseFloat(parseFloat(props.quote.cips[CipTypes.sodaAdditive].optimizedTime ? props.quote.cips[CipTypes.sodaAdditive].optimizedTime : 0).toFixed(0))} {context.unit?.data[config.unitHours]?.getTranslation({fieldName: 'name', locale: context.user.locale})}
                                        </td>
                                        <td rowSpan={2} className="method">
                                            {parseFloat(parseFloat(props.quote.cips[CipTypes.sodaAdditive].co2EmissionsDelta ? props.quote.cips[CipTypes.sodaAdditive].co2EmissionsDelta : 0).toFixed(0))} {context.unit?.data[config.unitPercent]?.getTranslation({
                                                fieldName: 'name',
                                                locale: context.user.locale
                                            })}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="alkaline">
                                            {parseFloat(props.quote.cips[CipTypes.sodaAdditive].products[ProductTypes.CausticSoda].concentration ? props.quote.cips[CipTypes.sodaAdditive].products[ProductTypes.CausticSoda].concentration : 0).toFixed(1)} {context.unit?.data[config.unitPercent]?.getTranslation({fieldName: 'name', locale: context.user.locale})}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="alkaline">
                                            {props.quote.cips[CipTypes.sodaAdditive].products[ProductTypes.Additive].product ? getTranslation(context.product?.data[props.quote.cips[CipTypes.sodaAdditive].products[ProductTypes.Additive].product]?.name, context.user.locale) : ''}
                                        </td>
                                        <td className="acid" rowSpan={2}>
                                            {parseFloat(props.quote.cips[CipTypes.sodaAdditive].products[ProductTypes.Acid].concentration ? props.quote.cips[CipTypes.sodaAdditive].products[ProductTypes.Acid].concentration : 0).toFixed(1)} {context.unit?.data[config.unitPercent]?.getTranslation({fieldName: 'name', locale: context.user.locale})}
                                        </td>
                                        <td className="desinfectant" rowSpan={2}>
                                            {parseFloat(props.quote.cips[CipTypes.sodaAdditive].products[ProductTypes.Desinfectant].concentration ? props.quote.cips[CipTypes.sodaAdditive].products[ProductTypes.Desinfectant].concentration : 0).toFixed(1)} {context.unit?.data[config.unitPercent]?.getTranslation({fieldName: 'name', locale: context.user.locale})}
                                        </td>
                                        <td rowSpan={2}>
                                            {parseFloat(parseFloat(props.quote.cips[CipTypes.sodaAdditive].optimizedWaterCost ? props.quote.cips[CipTypes.sodaAdditive].optimizedWaterCost : 0).toFixed(0))} {quoteContext.currentQuote.getCurrencySign()}
                                        </td>
                                        <td rowSpan={2} className="method">
                                            {parseFloat(parseFloat(props.quote.cips[CipTypes.sodaAdditive].co2EmissionsDeltaKg ? props.quote.cips[CipTypes.sodaAdditive].co2EmissionsDeltaKg : 0).toFixed(0))} {context.unit?.data[config.unitKg]?.getTranslation({ fieldName: 'name', locale: context.user.locale })}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="alkaline">
                                            {parseFloat(props.quote.cips[CipTypes.sodaAdditive].products[ProductTypes.Additive].concentration ? props.quote.cips[CipTypes.sodaAdditive].products[ProductTypes.Additive].concentration : 0).toFixed(1)} {context.unit?.data[config.unitPercent]?.getTranslation({fieldName: 'name', locale: context.user.locale})}
                                        </td>
                                    </tr>

                                    <tr>
                                        <td rowSpan={2} className="method">
                                            <strong>{getTranslation('cs.monophase-alternee', context.user.locale)}</strong>
                                        </td>
                                        <td className="alkaline">
                                            {props.quote.cips[CipTypes.monoAlternate].products[ProductTypes.CausticSoda].product ? getTranslation(context.product?.data[props.quote.cips[CipTypes.monoAlternate].products[ProductTypes.CausticSoda].product]?.name, context.user.locale) : ''}
                                        </td>
                                        <td className="acid">
                                            {props.quote.cips[CipTypes.monoAlternate].products[ProductTypes.Acid].product ? getTranslation(context.product?.data[props.quote.cips[CipTypes.monoAlternate].products[ProductTypes.Acid].product]?.name, context.user.locale) : ''}
                                        </td>
                                        <td className="desinfectant">
                                            {props.quote.cips[CipTypes.monoAlternate].products[ProductTypes.Desinfectant].product ? getTranslation(context.product?.data[props.quote.cips[CipTypes.monoAlternate].products[ProductTypes.Desinfectant].product]?.name, context.user.locale) : ''}
                                        </td>
                                        <td rowSpan={2} className="method">
                                            {parseFloat(parseFloat(props.quote.cips[CipTypes.monoAlternate].products[ProductTypes.CausticSoda].annualCa ? props.quote.cips[CipTypes.monoAlternate].products[ProductTypes.CausticSoda].annualCa : 0).toFixed(0)) + parseFloat(parseFloat(props.quote.cips[CipTypes.monoAlternate].products[ProductTypes.Acid].annualCa ? props.quote.cips[CipTypes.monoAlternate].products[ProductTypes.Acid].annualCa : 0).toFixed(0)) + parseFloat(parseFloat(props.quote.cips[CipTypes.monoAlternate].products[ProductTypes.Desinfectant].annualCa ? props.quote.cips[CipTypes.monoAlternate].products[ProductTypes.Desinfectant].annualCa : 0).toFixed(0))} {quoteContext.currentQuote.getCurrencySign()}
                                        </td>
                                        <td>
                                            {parseFloat(parseFloat(props.quote.cips[CipTypes.monoAlternate].optimizedWater ? props.quote.cips[CipTypes.monoAlternate].optimizedWater : 0).toFixed(0))} {context.unit?.data[config.unitCubicMeters]?.getTranslation({fieldName: 'name', locale: context.user.locale})}
                                        </td>
                                        <td rowSpan={2} className="method">
                                            {parseFloat(parseFloat(props.quote.cips[CipTypes.monoAlternate].optimizedTime ? props.quote.cips[CipTypes.monoAlternate].optimizedTime : 0).toFixed(0))} {context.unit?.data[config.unitHours]?.getTranslation({fieldName: 'name', locale: context.user.locale})}
                                        </td>
                                        <td className="method">
                                            {parseFloat(parseFloat(props.quote.cips[CipTypes.monoAlternate].co2EmissionsDelta ? props.quote.cips[CipTypes.monoAlternate].co2EmissionsDelta : 0).toFixed(0))} {context.unit?.data[config.unitPercent]?.getTranslation({
                                                fieldName: 'name',
                                                locale: context.user.locale
                                            })}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="alkaline">
                                            {parseFloat(props.quote.cips[CipTypes.monoAlternate].products[ProductTypes.CausticSoda].concentration ? props.quote.cips[CipTypes.monoAlternate].products[ProductTypes.CausticSoda].concentration : 0).toFixed(1)} {context.unit?.data[config.unitPercent]?.getTranslation({fieldName: 'name', locale: context.user.locale})}
                                        </td>
                                        <td className="acid">
                                            {parseFloat(props.quote.cips[CipTypes.monoAlternate].products[ProductTypes.Acid].concentration ? props.quote.cips[CipTypes.monoAlternate].products[ProductTypes.Acid].concentration : 0).toFixed(1)} {context.unit?.data[config.unitPercent]?.getTranslation({fieldName: 'name', locale: context.user.locale})}
                                        </td>
                                        <td className="desinfectant">
                                            {parseFloat(props.quote.cips[CipTypes.monoAlternate].products[ProductTypes.Desinfectant].concentration ? props.quote.cips[CipTypes.monoAlternate].products[ProductTypes.Desinfectant].concentration : 0).toFixed(1)} {context.unit?.data[config.unitPercent]?.getTranslation({fieldName: 'name', locale: context.user.locale})}
                                        </td>
                                        <td>
                                            {parseFloat(parseFloat(props.quote.cips[CipTypes.monoAlternate].optimizedWaterCost ? props.quote.cips[CipTypes.monoAlternate].optimizedWaterCost : 0).toFixed(0))} {quoteContext.currentQuote.getCurrencySign()}
                                        </td>
                                        <td className="method">
                                            {parseFloat(parseFloat(props.quote.cips[CipTypes.monoAlternate].co2EmissionsDeltaKg ? props.quote.cips[CipTypes.monoAlternate].co2EmissionsDeltaKg : 0).toFixed(0))} {context.unit?.data[config.unitKg]?.getTranslation({ fieldName: 'name', locale: context.user.locale })}
                                        </td>
                                    </tr>

                                    <tr>
                                        <td rowSpan={4} className="method">
                                            <strong>{getTranslation('cs.monophase-alternee-additivage', context.user.locale)}</strong>
                                        </td>
                                        <td className="alkaline">
                                            {props.quote.cips[CipTypes.monoSodaAdditive].products[ProductTypes.CausticSoda].product ? getTranslation(context.product?.data[props.quote.cips[CipTypes.monoSodaAdditive].products[ProductTypes.CausticSoda].product]?.name, context.user.locale) : ''}
                                        </td>
                                        <td className="acid" rowSpan={2}>
                                            {props.quote.cips[CipTypes.monoSodaAdditive].products[ProductTypes.Acid].product ? getTranslation(context.product?.data[props.quote.cips[CipTypes.monoSodaAdditive].products[ProductTypes.Acid].product]?.name, context.user.locale) : ''}
                                        </td>
                                        <td className="desinfectant" rowSpan={2}>
                                            {props.quote.cips[CipTypes.monoSodaAdditive].products[ProductTypes.Desinfectant].product ? getTranslation(context.product?.data[props.quote.cips[CipTypes.monoSodaAdditive].products[ProductTypes.Desinfectant].product]?.name, context.user.locale) : ''}
                                        </td>
                                        <td rowSpan={4} className="method">
                                            {parseFloat(parseFloat(props.quote.cips[CipTypes.monoSodaAdditive].products[ProductTypes.Additive].annualCa ? props.quote.cips[CipTypes.monoSodaAdditive].products[ProductTypes.Additive].annualCa : 0).toFixed(0)) + parseFloat(parseFloat(props.quote.cips[CipTypes.monoSodaAdditive].products[ProductTypes.CausticSoda].annualCa ? props.quote.cips[CipTypes.monoSodaAdditive].products[ProductTypes.CausticSoda].annualCa : 0).toFixed(0)) + parseFloat(parseFloat(props.quote.cips[CipTypes.monoSodaAdditive].products[ProductTypes.Acid].annualCa ? props.quote.cips[CipTypes.monoSodaAdditive].products[ProductTypes.Acid].annualCa : 0).toFixed(0)) + parseFloat(parseFloat(props.quote.cips[CipTypes.monoSodaAdditive].products[ProductTypes.Desinfectant].annualCa ? props.quote.cips[CipTypes.monoSodaAdditive].products[ProductTypes.Desinfectant].annualCa : 0).toFixed(0))} {quoteContext.currentQuote.getCurrencySign()}
                                        </td>
                                        <td rowSpan={2}>
                                            {parseFloat(parseFloat(props.quote.cips[CipTypes.monoSodaAdditive].optimizedWater ? props.quote.cips[CipTypes.monoSodaAdditive].optimizedWater : 0).toFixed(0))} {context.unit?.data[config.unitCubicMeters]?.getTranslation({fieldName: 'name', locale: context.user.locale})}
                                        </td>
                                        <td rowSpan={4} className="method">
                                            {parseFloat(parseFloat(props.quote.cips[CipTypes.monoSodaAdditive].optimizedTime ? props.quote.cips[CipTypes.monoSodaAdditive].optimizedTime : 0).toFixed(0))} {context.unit?.data[config.unitHours]?.getTranslation({fieldName: 'name', locale: context.user.locale})}
                                        </td>
                                        <td rowSpan={2} className="method">
                                            {parseFloat(parseFloat(props.quote.cips[CipTypes.monoSodaAdditive].co2EmissionsDelta ? props.quote.cips[CipTypes.monoSodaAdditive].co2EmissionsDelta : 0).toFixed(0))} {context.unit?.data[config.unitPercent]?.getTranslation({
                                                fieldName: 'name',
                                                locale: context.user.locale
                                            })}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="alkaline">
                                            {parseFloat(props.quote.cips[CipTypes.monoSodaAdditive].products[ProductTypes.CausticSoda].concentration ? props.quote.cips[CipTypes.monoSodaAdditive].products[ProductTypes.CausticSoda].concentration : 0).toFixed(1)} {context.unit?.data[config.unitPercent]?.getTranslation({fieldName: 'name', locale: context.user.locale})}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="alkaline">
                                            {props.quote.cips[CipTypes.monoSodaAdditive].products[ProductTypes.Additive].product ? getTranslation(context.product?.data[props.quote.cips[CipTypes.monoSodaAdditive].products[ProductTypes.Additive].product]?.name, context.user.locale) : ''}
                                        </td>
                                        <td className="acid" rowSpan={2}>
                                            {parseFloat(props.quote.cips[CipTypes.monoSodaAdditive].products[ProductTypes.Acid].concentration ? props.quote.cips[CipTypes.monoSodaAdditive].products[ProductTypes.Acid].concentration : 0).toFixed(1)} {context.unit?.data[config.unitPercent]?.getTranslation({fieldName: 'name', locale: context.user.locale})}
                                        </td>
                                        <td className="desinfectant" rowSpan={2}>
                                            {parseFloat(props.quote.cips[CipTypes.monoSodaAdditive].products[ProductTypes.Desinfectant].concentration ? props.quote.cips[CipTypes.monoSodaAdditive].products[ProductTypes.Desinfectant].concentration : 0).toFixed(1)} {context.unit?.data[config.unitPercent]?.getTranslation({fieldName: 'name', locale: context.user.locale})}
                                        </td>
                                        <td rowSpan={2}>
                                            {parseFloat(parseFloat(props.quote.cips[CipTypes.monoSodaAdditive].optimizedWaterCost ? props.quote.cips[CipTypes.monoSodaAdditive].optimizedWaterCost : 0).toFixed(0))} {quoteContext.currentQuote.getCurrencySign()}
                                        </td>
                                        <td rowSpan={2} className="method">
                                            {parseFloat(parseFloat(props.quote.cips[CipTypes.monoSodaAdditive].co2EmissionsDeltaKg ? props.quote.cips[CipTypes.monoSodaAdditive].co2EmissionsDeltaKg : 0).toFixed(0))} {context.unit?.data[config.unitKg]?.getTranslation({ fieldName: 'name', locale: context.user.locale })}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="alkaline">
                                            {parseFloat(props.quote.cips[CipTypes.monoSodaAdditive].products[ProductTypes.Additive].concentration ? props.quote.cips[CipTypes.monoSodaAdditive].products[ProductTypes.Additive].concentration : 0).toFixed(1)} {context.unit?.data[config.unitPercent]?.getTranslation({fieldName: 'name', locale: context.user.locale})}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
}