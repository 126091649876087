import React from "react";
import {Col, Modal, ModalBody, Row} from "react-bootstrap";
import PrintPreviewToolbar from "./PrintPreviewToolbar";
import ContactHeader from "./ContactHeader";
import {QuoteContext} from "../../contexts/QuoteContext";
import Products from "./Products";
import TotalDiscount from "./TotalDiscount";
import Total from "./Total";
import GlobalCostPerAnimal from "./GlobalCostPerAnimal";
import GlobalCostPer1000LMilk from "./GlobalCostPer1000LMilk";
import Signature from "./Signature";
import Footer from "./Footer";
import ProductListHeader from "./ProductListHeader";
import ProductDetails from "./ProductDetails";
import $ from "jquery";
import GlobalCostPer1LMilk from "./GlobalCostPer1LMilk";

export default class PrintPreview extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            checkLayout: true,
            breakAtCategory: [],
            breakAtProduct: [],
            pages: 2
        };

        this.salesTermsAndConditions = null;
    }

    componentDidMount() {
        this.checkLayout();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state.checkLayout) {
            this.checkLayout();
        }
    }

    onPaperSizeChange = (value) => {
        this.context.currentQuote.setSetting('options.paperSizeA4', value === 'A4');
        this.setState({
            checkLayout: true,
            breakAtCategory: [],
            breakAtProduct: [],
            pages: 2
        });
    }

    checkLayout = () => {
        let breakAtCategory = null;
        const categoryBreakOffset = this.context.currentQuote.settings['options.paperSizeA4'] === true ? 1000 : 890;
        let productListBreakOffset = this.context.currentQuote.settings['options.paperSizeA4'] === true ? 1200 : 1090;

        let productGroups = this.props.userContext.productgroup.data;

        // If there are Sales Terms and Conditions on this Product Group, let some space for this text on the last page
        if (productGroups[this.props.userContext.user.productGroup] && productGroups[this.props.userContext.user.productGroup].getTranslation({fieldName: 'salesTermsAndConditions', locale: this.props.userContext.user.locale})) {
            this.salesTermsAndConditions = productGroups[this.props.userContext.user.productGroup].getTranslation({fieldName: 'salesTermsAndConditions', locale: this.props.userContext.user.locale});
            productListBreakOffset = productListBreakOffset - 50;
        }

        $('.sheet:eq(' + this.state.breakAtCategory.length + ') .preview-box-wrapper').each((i, el) => {
            if ($(el).offset().top - $(el).parents('.sheet:first').offset().top > categoryBreakOffset) {
                breakAtCategory = parseInt($(el).parents('.category-box:first').data('id'));
                // Check if this category would fit without the page total components
                // if we have 1 more category
                const wrapper = $(el).parents('.category-box:first');
                if (wrapper.next().length) {
                    if ($(el).parents('.category-box:first').find('.preview-box-wrapper:last').offset().top - $(el).parents('.sheet:first').offset().top <= productListBreakOffset) {
                        breakAtCategory = wrapper.next().data('id');
                    }
                }

                return false;
            }
        });

        let breakAtProduct = null;
        $('.sheet:eq(' + (this.state.breakAtCategory.length + this.state.breakAtProduct.length + 1) + ') .print-product-detail').each((i, el) => {
            if ($(el).offset().top - $(el).parents('.sheet:first').offset().top > productListBreakOffset) {
                breakAtProduct = parseInt($(el).data('id'));
                return false;
            }
        });

        if (!breakAtCategory && !breakAtProduct) {
            this.setState({
                checkLayout: false
            });
        } else {
            this.setState((state) => {
                if (breakAtCategory) {
                    state.breakAtCategory.push(breakAtCategory);
                }

                if (breakAtProduct) {
                    state.breakAtProduct.push(breakAtProduct);
                }

                return {
                    breakAtCategory: state.breakAtCategory,
                    breakAtProduct: state.breakAtProduct,
                    pages: state.pages + (breakAtCategory ? 1 : 0) + (breakAtProduct ? 1 : 0)
                }
            });
        }
    }

    render() {
        // Create product listing pages
        let pageCategories = [];
        const pagesCategories = [];

        let categories = Object.keys(this.context.currentQuote.categories);
        categories.sort((a, b) => {
            return this.props.userContext.category.data[a].ordering - this.props.userContext.category.data[b].ordering;
        });

        categories.forEach((categoryId) => {
            if (this.state.breakAtCategory.indexOf(parseInt(categoryId)) > -1) {
                pagesCategories.push(pageCategories);
                pageCategories = [];
            }
            pageCategories.push(categoryId);
        });
        if (pageCategories.length > 0) {
            pagesCategories.push(pageCategories);
        }

        // Create product details pages
        let pageProducts = [];
        const pagesProducts = [];
        let products = [];
        categories.forEach((categoryId) => {
            this.context.currentQuote.getCategoryProducts(categoryId).map((productId) => {
            if (this.props.userContext.product && this.props.userContext.product.data && this.props.userContext.product.data[productId]) {
                if (products.indexOf(parseInt(productId)) === -1) {
                    products.push(parseInt(productId));
                    if (this.state.breakAtProduct.indexOf(parseInt(productId)) > -1) {
                        pagesProducts.push(pageProducts);
                        pageProducts = [];
                    }
                    pageProducts.push(productId);
                }
            }
            return true;
        })});

        if (pageProducts.length > 0) {
            pagesProducts.push(pageProducts);
        }

        return (
            <Modal className={"print-preview-modal " + (this.context.currentQuote.settings['options.paperSizeA4'] === true || this.context.currentQuote.settings['options.paperSizeA4'] === 'true' ? 'a4' : 'letter')} size={"xl"} show={this.props.show} onHide={this.props.onHide} backdrop={'static'} animation={false}>
                <PrintPreviewToolbar closeModal={this.props.onHide} currentQuote={this.context.currentQuote} onPaperSizeChange={this.onPaperSizeChange} />
                <ModalBody>

                    {pagesCategories.map((categories, i) => {
                        return (
                            <Row noGutters={true} key={'page-category' + i}>
                                <Col>
                                    <div className={"sheet"}>
                                        {i === 0 &&
                                            <ContactHeader/>
                                        }
                                        <Products currentQuote={this.context.currentQuote} categories={categories} page={i} />
                                        {(i + 1) === pagesCategories.length && this.context.currentQuote.quoteHasDiscount() &&
                                            <TotalDiscount currentQuote={this.context.currentQuote}/>
                                        }
                                        {(i + 1) === pagesCategories.length &&
                                            <div className="sheet-padding clearfix">
                                                <Total currentQuote={this.context.currentQuote}/>
                                                {((this.context.currentQuote.quoteHasCostPerAnimalPerYear(this.props.userContext.category.data) && this.context.currentQuote.settings['options.globalcostpercowpermonth']) || (this.context.currentQuote.quoteHasCostPer1000LMilk(this.props.userContext.category.data) && this.context.currentQuote.settings['options.costper1000lmilk']) || (this.context.currentQuote.quoteHasCostPer1000LMilk(this.props.userContext.category.data) && this.context.currentQuote.settings['options.costper1lmilk'])) &&
                                                <div className="cost-per">
                                                    {this.context.currentQuote.quoteHasCostPerAnimalPerYear(this.props.userContext.category.data) && this.context.currentQuote.settings['options.globalcostpercowpermonth'] &&
                                                    <GlobalCostPerAnimal currentQuote={this.context.currentQuote}/>
                                                    }
                                                    {this.context.currentQuote.quoteHasCostPer1000LMilk(this.props.userContext.category.data) && this.context.currentQuote.settings['options.costper1000lmilk'] &&
                                                    <GlobalCostPer1000LMilk currentQuote={this.context.currentQuote}/>
                                                    }
                                                    {this.context.currentQuote.quoteHasCostPer1000LMilk(this.props.userContext.category.data) && this.context.currentQuote.settings['options.costper1lmilk'] &&
                                                    <GlobalCostPer1LMilk currentQuote={this.context.currentQuote}/>
                                                    }
                                                </div>
                                                }
                                            </div>
                                        }
                                        {(i + 1) === pagesCategories.length && this.context.currentQuote.settings['options.approval'] &&
                                            <Signature/>
                                        }
                                        <Footer page={i + 1} pages={this.state.pages} currentQuote={this.context.currentQuote} />
                                    </div>
                                </Col>
                            </Row>
                        )
                    })}

                    {pagesProducts.map((products, i) => {
                        return (
                            <Row noGutters={true} key={'page-product' + i}>
                                <Col>
                                    <div className={"sheet"}>
                                        {i === 0 &&
                                            <ProductListHeader/>
                                        }
                                        <ProductDetails currentQuote={this.context.currentQuote} products={products} page={i + 1} />
                                        <Footer page={pagesCategories.length + i + 1} pages={this.state.pages}
                                                currentQuote={this.context.currentQuote} salesTermsAndConditions={this.salesTermsAndConditions} />
                                    </div>
                                </Col>
                            </Row>
                        )
                    })}
                </ModalBody>
            </Modal>
        )
    }
}

PrintPreview.contextType = QuoteContext;