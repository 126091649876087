import React from "react";
import { Card, Col, Container, Form, Row, Table } from "react-bootstrap";
import { QuoteContext } from "../../contexts/QuoteContext";
import {Select2} from "select2-react-component";
import 'select2-component/dist/select2.min.css';
import ProductFormats from "./ProductFormats";
import {getTranslation} from "../tools/Translation";
let vsprintf = require('sprintf-js').vsprintf;

export default class TabOfferParameters extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            mounted: false
        };

        this.product = props.products.max;
        this.biocide = props.selectedbiocide.product;
        this.customProduct = props.customProducts.product;
        this.customBiocide = props.customProducts.biocide;
    }

    getSelect2Products = () => {
        return this.convertToValueLabel(this.props.getProducts());
    }

    convertToValueLabel(products) {
        const newProducts = [];
        products.forEach((product) => {
            newProducts.push({
                value: product.id,
                label: product.name
            });
        });
        return newProducts;
    }

    componentDidMount() {
        this.setState({
            mounted: true
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        // Set the right Dosing rate according to the selected product
        let productId = null;

        if (this.customProduct !== this.props.customProducts.product && this.props.customProducts.product) {
            this.customProduct = this.props.customProducts.product;
            productId = this.props.customProducts.product;
        }

        if (this.product !== this.props.products.max && this.props.products.max && this.props.products.max.id) {
            this.product = this.props.products.max;
            productId = prevProps.products.max.id;
        }

        if (productId) {
            this.props.getProducts().forEach((product) => {
                if (product.id === productId) {
                    this.props.handleChangeByFieldName('offer_parameter_main_dosing_rate', parseInt(product.wtDosing));
                }
            });
        }

        productId = null;

        if (this.customBiocide !== this.props.customProducts.biocide && this.props.customProducts.biocide) {
            this.customBiocide = this.props.customProducts.biocide;
            productId = this.props.customProducts.biocide;
        }

        if (this.biocide !== this.props.selectedbiocide.product && this.props.selectedbiocide.product && this.props.selectedbiocide.product.id) {
            this.biocide = this.props.selectedbiocide.product;
            productId = this.props.selectedbiocide.product.id;
        }

        if (productId) {
            this.props.getProducts().forEach((product) => {
                if (product.id === productId) {
                    this.props.handleChangeByFieldName('offer_parameter_biocide_dosing_rate', parseInt(product.wtDosing));
                }
            });
        }
    }

    render() {
        const selectOptions = [];
        for (let cyclingValue = this.props.values.table_cycling_start; cyclingValue <= this.props.values.table_cycling_stop; cyclingValue += this.props.values.table_cycling_step) {
            selectOptions.push(
                <option key={cyclingValue} value={cyclingValue}>{cyclingValue}</option>
            );
        }

        return (
            <Container fluid>
                <Row className="mt-3">
                    <Col>
                        <Card>
                            <Card.Header className="text-center">{getTranslation('wt.parameters', this.context.currentQuote.getUserContext().user.locale)}</Card.Header>
                            <Card.Body>
                                <Table striped bordered hover className="text-center mb-0">
                                    <tbody>
                                        <tr><td>
                                            <Row>
                                                <Col>
                                                    <div className="custom-control custom-switch ml-1 text-primary">
                                                        <input type="checkbox" className="custom-control-input" id="test-mode" checked={this.context.currentQuote.testMode} onChange={() => {this.context.currentQuote.setTestMode(!this.context.currentQuote.testMode);}} />
                                                        <label className="custom-control-label pt-1" htmlFor="test-mode">{getTranslation('cs.test-mode', this.context.currentQuote.getUserContext().user.locale)}</label>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </td></tr>
                                        <tr><td>{getTranslation('wt.customer-name', this.context.currentQuote.getUserContext().user.locale)}</td><td><Form.Control onBlur={this.props.handlechange} data-key="offer_parameter_customer_name" defaultValue={this.props.values.offer_parameter_customer_name} /></td><td></td></tr>
                                        <tr>
                                            <td>{getTranslation('wt.selected-product', this.context.currentQuote.getUserContext().user.locale)}</td>
                                            <td>
                                                {this.state.mounted &&
                                                    <Select2 multiple={false} data={this.getSelect2Products()} update={value => this.props.setCustomProduct('product', value)} value={this.props.customProducts.product ? this.props.customProducts.product : (this.props.products && this.props.products.max ? this.props.products.max.id : null)}/>
                                                }
                                            </td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td>
                                                {getTranslation('wt.product-price', this.context.currentQuote.getUserContext().user.locale)}
                                                <ProductFormats productId={this.props.customProducts.product ? this.props.customProducts.product : (this.props.products && this.props.products.max ? this.props.products.max.id : null)} priceProperty={'offer_parameter_main_price'} packageNameProperty={'offer_parameter_main_package'} onChange={this.props.handleChangeByFieldName} />
                                            </td>
                                            <td>
                                                <Form.Control onBlur={this.props.handlechange} onChange={(e) => this.props.handleChangeByFieldName('offer_parameter_main_price', e.target.value)} data-key="offer_parameter_main_price" value={this.props.values.offer_parameter_main_price} />
                                            </td>
                                            <td>{vsprintf(getTranslation('wt.currency-per-liter', this.context.currentQuote.getUserContext().user.locale), [this.context.currentQuote.getCurrencySign()])}</td>
                                        </tr>
                                        <tr><td>{getTranslation('wt.dosing-rate', this.context.currentQuote.getUserContext().user.locale)}</td><td><Form.Control onChange={(e) => this.props.handleChangeByFieldName('offer_parameter_main_dosing_rate', e.target.value)} data-key="offer_parameter_main_dosing_rate" value={this.props.values.offer_parameter_main_dosing_rate} /></td><td>ml/m3</td></tr>
                                        <tr>
                                            <td>{getTranslation('wt.selected-cycling', this.context.currentQuote.getUserContext().user.locale)}</td>
                                            <td>
                                                <select className="form-control" name="cycling" onChange={(e) => this.props.updateCycling(e.target.value)} value={this.props.products.cyclingValue}>
                                                    {selectOptions}
                                                </select>
                                            </td>
                                            <td></td>
                                        </tr>
                                        <tr><td>{getTranslation('wt.make-up-water-costs', this.context.currentQuote.getUserContext().user.locale)}</td><td><Form.Control onBlur={this.props.handlechange} data-key="offer_parameter_main_make_up_water_cost" defaultValue={this.props.values.offer_parameter_main_make_up_water_cost} /></td><td>{this.context.currentQuote.getCurrencySign()}</td></tr>
                                        <tr><td>{getTranslation('wt.effluent-costs', this.context.currentQuote.getUserContext().user.locale)}</td><td><Form.Control onBlur={this.props.handlechange} data-key="offer_parameter_main_effluent_costs" defaultValue={this.props.values.offer_parameter_main_effluent_costs} /></td><td>{this.context.currentQuote.getCurrencySign()}</td></tr>

                                        <tr>
                                            <td>{getTranslation('wt.selected-biocide', this.context.currentQuote.getUserContext().user.locale)}</td><td>
                                                {this.state.mounted &&
                                                    <Select2 multiple={false} data={this.getSelect2Products()} update={value => this.props.setCustomProduct('biocide', value)} value={this.props.customProducts.biocide ? this.props.customProducts.biocide : (this.props.selectedbiocide && this.props.selectedbiocide.product ? this.props.selectedbiocide.product.id : null)}/>
                                                }
                                            </td>
                                            <td></td>
                                        </tr>
                                        <tr><td>
                                            {getTranslation('wt.biocide-price', this.context.currentQuote.getUserContext().user.locale)}
                                                <ProductFormats productId={this.props.customProducts.biocide ? this.props.customProducts.biocide : (this.props.selectedbiocide && this.props.selectedbiocide.product ? this.props.selectedbiocide.product.id : null)} priceProperty={'offer_parameter_biocide_price'} packageNameProperty={'offer_parameter_biocide_package'} onChange={this.props.handleChangeByFieldName} />
                                            </td>
                                            <td>
                                                <Form.Control onBlur={this.props.handlechange} onChange={(e) => this.props.handleChangeByFieldName('offer_parameter_biocide_price', e.target.value)} data-key="offer_parameter_biocide_price" value={this.props.values.offer_parameter_biocide_price} />
                                            </td>
                                            <td>{vsprintf(getTranslation('wt.currency-per-liter', this.context.currentQuote.getUserContext().user.locale), [this.context.currentQuote.getCurrencySign()])}</td>
                                        </tr>
                                        <tr><td>{getTranslation('wt.dosing-rate', this.context.currentQuote.getUserContext().user.locale)}</td><td><Form.Control onChange={(e) => this.props.handleChangeByFieldName('offer_parameter_biocide_dosing_rate', e.target.value)} data-key="offer_parameter_biocide_dosing_rate" value={this.props.values.offer_parameter_biocide_dosing_rate} /></td><td>ml/m3</td></tr>
                                        <tr><td>{getTranslation('wt.peak-dosing-per-week', this.context.currentQuote.getUserContext().user.locale)}</td><td><Form.Control onBlur={this.props.handlechange} data-key="offer_parameter_biocide_dosing_week" defaultValue={this.props.values.offer_parameter_biocide_dosing_week} /></td><td></td></tr>
                                        <tr><td>{getTranslation('wt.service-frequency', this.context.currentQuote.getUserContext().user.locale)}</td><td><Form.Control onBlur={this.props.handlechange} data-key="offer_parameter_biocide_service_frequency" defaultValue={this.props.values.offer_parameter_biocide_service_frequency} /></td><td></td></tr>
                                        <tr><td>{getTranslation('wt.service-frequency-per-year', this.context.currentQuote.getUserContext().user.locale)}</td><td><Form.Control onBlur={this.props.handlechange} data-key="offer_parameter_biocide_service_frequency_year" defaultValue={this.props.values.offer_parameter_biocide_service_frequency_year} /></td><td></td></tr>
                                        <tr><td>{getTranslation('wt.service-needed', this.context.currentQuote.getUserContext().user.locale)}</td><td><Form.Control onBlur={this.props.handlechange} data-key="offer_parameter_biocide_service_needed" defaultValue={this.props.values.offer_parameter_biocide_service_needed} /></td><td></td></tr>
                                        <tr><td>{getTranslation('wt.travel-time', this.context.currentQuote.getUserContext().user.locale)}</td><td><Form.Control onBlur={this.props.handlechange} data-key="offer_parameter_biocide_travel_time" defaultValue={this.props.values.offer_parameter_biocide_travel_time} /></td><td></td></tr>
                                        <tr><td>{vsprintf(getTranslation('wt.service-costs-per-hour', this.context.currentQuote.getUserContext().user.locale), [this.context.currentQuote.getCurrencySign()])}</td><td><Form.Control onBlur={this.props.handlechange} data-key="offer_parameter_service_cost_per_hour" defaultValue={this.props.values.offer_parameter_service_cost_per_hour} /></td><td></td></tr>
                                        <tr><td>{vsprintf(getTranslation('wt.visit-fee', this.context.currentQuote.getUserContext().user.locale), [this.context.currentQuote.getCurrencySign()])}</td><td><Form.Control onBlur={this.props.handlechange} data-key="offer_parameter_visit_fee" defaultValue={this.props.values.offer_parameter_visit_fee} /></td><td></td></tr>
                                        <tr><td>{getTranslation('wt.technical-comments', this.context.currentQuote.getUserContext().user.locale)}</td><td><Form.Control as="textarea" rows={3} onBlur={this.props.handlechange} data-key="offer_parameter_technical_comments_water_profile" defaultValue={this.props.values.offer_parameter_technical_comments_water_profile} /></td><td></td></tr>
                                    </tbody>
                                </Table>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>

                <Row className="mt-3">
                    <Col>
                        <Card>
                            <Card.Body>
                                <Table striped bordered hover className="text-center mb-0">
                                    <thead>
                                        <tr>
                                            <th>{getTranslation('wt.cycling', this.context.currentQuote.getUserContext().user.locale)}</th>
                                            <th>{getTranslation('wt.make-up-water', this.context.currentQuote.getUserContext().user.locale)} (m3)</th>
                                            <th>{getTranslation('wt.evaporation', this.context.currentQuote.getUserContext().user.locale)} (m3)</th>
                                            <th>{getTranslation('wt.blowdown', this.context.currentQuote.getUserContext().user.locale)} (m3)</th>
                                            <th>{getTranslation('wt.hydric-performance', this.context.currentQuote.getUserContext().user.locale)}</th>
                                            <th>{getTranslation('wt.avg-hrt', this.context.currentQuote.getUserContext().user.locale)}</th>
                                            <th>{getTranslation('wt.biocide', this.context.currentQuote.getUserContext().user.locale)}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.props.body}
                                    </tbody>
                                </Table>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>

            </Container>
        )
    }
}
TabOfferParameters.contextType = QuoteContext;