import React from "react";
import {getTranslation} from "../tools/Translation";
import {UserContext} from "../../contexts/UserContext";
import {QuoteContext} from "../../contexts/QuoteContext";
import classNames from "classnames";
import $ from "jquery";

export default class ProductPrice extends React.Component {
    constructor(props) {
        super(props);

        this.textWidthElement = React.createRef();
        this.inputElement = React.createRef();
        this.discountElement = React.createRef();

        this.timeout = null;
    }

    componentDidMount() {
        this.resizeInput();
    }

    componentWillUnmount() {
        clearTimeout(this.timeout);
    }

    resizeInput = () => {
        this.textWidthElement.current.innerHTML = this.props.quoteProduct.price;
        $(this.inputElement.current).width($(this.textWidthElement.current).outerWidth());
    }

    onChangeHandler = (e) => {
        if (this.props.quoteProduct.loaned) {
            this.props.quoteProduct.setPrice(0);
        } else {
            this.props.quoteProduct.setPrice(e.target.value);
        }

        if (e.target !== document.activeElement) {
            this.onBlurHandler(e);
        }

        this.resizeInput();
    }

    onKeyUpHandler = () => {
        if (!this.props.quoteProduct.price) {
            this.props.quoteProduct.setIsPriceModified(false);
        } else if (!this.props.quoteProduct.isPriceModified && this.props.quoteProduct.price) {
            this.props.quoteProduct.setIsPriceModified(true);
        }
    }

    onFocusHandler = (e) => {
        if (parseFloat(e.target.value) === 0) {
            e.target.value = '';
        }

        $(this.inputElement.current).select();
    }

    onBlurHandler = (e) => {
        this.props.quoteProduct.setPrice(parseFloat(this.props.quoteProduct.price || 0).toFixed(2));
    }

    wrapperOnMouseEnter = () => {
        clearTimeout(this.timeout);
        $(this.discountElement.current).show();
    }

    wrapperOnMouseLeave = () => {
        clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
            $(this.discountElement.current).hide();
        }, 1000);
    }

    discountOnMouseEnter = () => {
        clearTimeout(this.timeout);
    }

    discountOnMouseLeave = () => {
        clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
            $(this.discountElement.current).hide();
        }, 1000);
    }

    render() {
        return (
            <UserContext.Consumer>
                {({user}) =>
                    <div className="cost-box" onMouseEnter={this.wrapperOnMouseEnter} onMouseLeave={this.wrapperOnMouseLeave}>
                        <div className="cost-title">
                            <div className="cost-title-wrapper">
                                {getTranslation("cost.unitprice", user.locale)}
                                <div className="discount" onMouseEnter={this.discountOnMouseEnter} onMouseLeave={this.discountOnMouseLeave} onClick={() => {this.props.quoteProduct.setHasDiscount()}} ref={this.discountElement}>%</div>
                            </div>
                        </div>
                        <input ref={this.inputElement} className={classNames({
                            "product-input": true,
                            "money": true,
                            "border-danger": !parseFloat(this.props.quoteProduct.price || 0) && !this.props.quoteProduct.loaned
                        })} value={this.inputElement.current === document.activeElement ? this.props.quoteProduct.price : parseFloat(this.props.quoteProduct.price || 0).toFixed(2)} onChange={this.onChangeHandler} onFocus={this.onFocusHandler} onBlur={this.onBlurHandler} onKeyUp={this.onKeyUpHandler} />
                        <span ref={this.textWidthElement} className="hidden-element"/>
                    </div>
                }
            </UserContext.Consumer>
        )
    }
}

ProductPrice.contextType = QuoteContext;