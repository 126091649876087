import React from 'react';
import {Col, Container, Image, Modal, ModalBody, ModalTitle, Row} from "react-bootstrap";
import {QuoteContext} from "../../../contexts/QuoteContext";
import ModalHeader from "react-bootstrap/ModalHeader";
import {getTranslation} from "../../tools/Translation";
let vsprintf = require('sprintf-js').vsprintf;

export default class Protocol extends React.Component {

    handleOnHide = () => this.context.currentQuote.hideCalculatorProtocol();

    handleOnClick = (multiplier) => {
        this.context.currentQuote.setProtocolMultiplier(this.context.currentQuote.selectedProductData.categoryId, multiplier);
        this.handleOnHide();
    };

    render() {

        const UC = this.context.currentQuote.getUserContext();
        const UCLocale = UC.user.locale;
        const protocols = UC.protocol;

        if (!protocols || !protocols.data || !protocols.data.length) {
            return false;
        }

        //TODO: permettre la sélection du bon protocol selon le pays? espèce?, etc...
        const protocolId = 1;
        const protocol = protocols.data[protocolId];

        let modalBody = [];

        //region header
        let modalLevelHeaderContent = [];
        for (let i = 0; i <= protocol.length; i++) {
            let c = null;
            if (i > 0) {
                c = vsprintf(getTranslation('protocol.weeknumber', UCLocale), [i]);
            }
            modalLevelHeaderContent.push(<Col key={i} lg={i === 0 ? 3 : null} className={i > 0 ? "week-title" : ''}>{c}</Col>);
        }
        modalBody.push(<Row key="title">{getTranslation(modalLevelHeaderContent, UCLocale)}</Row>);
        //endregion

        //region content
        protocol.levels.forEach((level) => {

            let modalLevelWeeks = [];

            for (let i = 0; i <= protocol.length; i++) {

                let weekContent = [];

                if (i === 0) {
                    let image = level.image ? <Row><Col><Image src={level.image}/></Col></Row> : null;
                    weekContent.push(
                        <Col key={level.id + '-' + i} className="text-center pt-2 pb-2" style={{color: level.color ? level.color : '#242424'}}>
                            <Row>
                                <Col className="name">{level.getTranslation({fieldName: 'name', locale: UCLocale})}</Col>
                            </Row>
                            <Row>
                                <Col className="description">{level.getTranslation({fieldName: 'description', locale: UCLocale})}</Col>
                            </Row>
                            {image}
                        </Col>
                    );
                } else {
                    let weekProductContent = [];
                    level.levelValues.forEach((week) => {
                        if (week.weekNumber === i) {
                            week.products.forEach((product) => {
                                if (UC.product && UC.product.data && UC.product.data[product.id]) {
                                    weekProductContent.push(<Row key={level.id + '-' + i + '-' + week.id + '-' + product.id}><Col>
                                        <div className={UC.product.data[product.id].name.replace(/\s/g, '').trim().toLowerCase()}>{UC.product.data[product.id].name + ' - ' + UC.product.data[product.id].concentration + '%'}</div>
                                    </Col></Row>);
                                }
                            })
                        }
                    })
                    weekContent.push(weekProductContent);
                }
                modalLevelWeeks.push(
                    <Col
                        key={i}
                        className={i > 0 ? "border-left" : null}
                        ref={el => {
                            if (el && i > 0) el.style.setProperty('border-left-color', (level.color ? level.color : '#242424'), 'important')
                        }}
                        lg={i === 0 ? 3 : null}>{weekContent}</Col>
                );
            }
            modalBody.push(<Row key={level.id} className="situationRow" style={{borderColor: level.color ? level.color : '#716f71'}} onClick={() => this.handleOnClick(level.multiplier)}>{modalLevelWeeks}</Row>);
        });
        //endregion


        return (
            <Modal className={"calculatorProtocol"} size={"lg"} show={this.props.show} onHide={this.handleOnHide}>
                <ModalHeader className="border-bottom-0">
                    <ModalTitle className="ml-auto mr-auto w-100 text-center">{protocol.getTranslation({fieldName: 'description', locale: UCLocale})}</ModalTitle>
                </ModalHeader>
                <ModalBody className={"p-0 m-0"}>
                    <Container fluid style={{paddingBottom: 15}}>{modalBody}</Container>
                </ModalBody>
            </Modal>

        );
    }
}

Protocol.contextType = QuoteContext;
