import React, {useContext} from "react";
import {getTranslation} from "../tools/Translation";
import {UserContext} from "../../contexts/UserContext";

export default function TableCommonHeader(props) {
    const context = useContext(UserContext);

    return (
        <>
            <th rowSpan={2}>
                {getTranslation('cs.equipment-name', context.user.locale)}
            </th>
            <th rowSpan={2}>
                {getTranslation('cs.ligne', context.user.locale)}
            </th>
            <th rowSpan={2}>
                {getTranslation('cs.typeObjet', context.user.locale)}
            </th>
            <th rowSpan={2}>
                {getTranslation('cs.entrantMatiere', context.user.locale)}
            </th>
            <th rowSpan={2}>
                {getTranslation('cs.circuitThermique', context.user.locale)}
            </th>
            <th rowSpan={2}>
                {getTranslation('cs.circuitRecetteSensible', context.user.locale)}
            </th>
            {props.quote.advancedMethod &&
                <th rowSpan={2}>
                    {getTranslation('cs.typeCircuit', context.user.locale)}
                </th>
            }
            <th rowSpan={2}>
                {getTranslation('cs.cycleLavage', context.user.locale)}
            </th>
        </>
    );
}