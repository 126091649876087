import React from "react";
import {UserContext} from "../../contexts/UserContext";
import {vsprintf} from "sprintf-js"

export default class LabelValueUnit extends React.Component {
    render() {

        if (!this.props.estimatedConsumptions) {
            return null;
        }

        let estimatedConsumption = null;

        if (this.props.productData.estimatedConsumptionId && this.props.estimatedConsumptions.length > 0) {
            this.props.estimatedConsumptions.forEach((e) => {
                if (e.id === this.props.productData.estimatedConsumptionId) {
                    estimatedConsumption = e;
                }
            });
        } else {
            return null;
        }

        if (!this.context.consumptiontype.data || !this.context.consumptiontype.data[estimatedConsumption.consumptionType]) {
            return null;
        }

        // Get unit from consumption
        let unitId = estimatedConsumption.unit;
        if (!unitId) {
            // Get unit from porduct
            unitId = this.props.product.getFormatUnit();
        }

        let unitName = '';
        if (unitId) {
            unitName = this.context.unit.data[unitId].getTranslation({fieldName: 'name', locale: this.context.user.locale}) || ''
        }

        let label = this.context.consumptiontype.data[estimatedConsumption.consumptionType].getTranslation({fieldName: 'label', locale: this.context.user.locale});
        let value = this.props.currentQuote.manualEstimatedConsumptionValue[this.props.productData.categoryId] || estimatedConsumption.value;
        if (estimatedConsumption.maximumValue) {
            value += '-' + estimatedConsumption.maximumValue;
        }
        label = vsprintf(label, [value, unitName]);
        return <>{label}</>
    }
}

LabelValueUnit.contextType = UserContext;
