import React from "react";
import { Button, Form, Modal } from "react-bootstrap";

export default class ModalInput extends React.Component {
    render() {
        return (
            <Modal show={this.props.display} keyboard={true} onHide={this.props.onClose} size="sm" centered>
                <Modal.Header>
                    <Modal.Title>Change value</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group controlId="modalInput">
                        <Form.Label>{this.props.fieldName}</Form.Label>
                        <Form.Control type="text" defaultValue={this.props.initialValue} data-key={this.props.dataKey} onChange={this.props.onInputChange} />
                    </Form.Group>
                    <Button className="float-right" variant="primary" onClick={this.props.onClose}>ok</Button>
                </Modal.Body>
            </Modal>

        )
    }
}