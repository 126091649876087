import React, {useContext} from "react";
import {Col, Row} from "react-bootstrap";
import {getTranslation} from "../tools/Translation";
import {UserContext} from "../../contexts/UserContext";
import {slugify} from "../tools/String";
import {CipTypes} from "../../model/CausticSoda/CipTypes";
import TableCommonHeader from "./TableCommonHeader";
import TableCommonBody from "./TableCommonBody";

export default function TableInitialRecipe(props) {
    const context = useContext(UserContext);

    return (
        <Row className="mb-3">
            <Col>
                <h3>{getTranslation('cs.initial-recipe', context.user.locale)}</h3>
                <table className="table table-bordered table-responsive small data-table table-hover table-valign-middle text-center">
                    <thead className="table-initial-recipe">
                        <tr>
                            <TableCommonHeader {...props} />
                            {Object.keys(context.washingstep.data).map((washingStepId) => {
                                if (props.quote.advancedMethod || (!props.quote.advancedMethod && !context.washingstep.data[washingStepId].advancedMethod)) {
                                    return (
                                        <th rowSpan={2} key={washingStepId}>
                                            {getTranslation(slugify('cs.duration-washing-step-' + context.washingstep.data[washingStepId].getTranslation({fieldName: 'name', locale: 'fr'})), context.user.locale)}
                                        </th>
                                    )
                                }

                                return null;
                            })}
                            {props.quote.advancedMethod &&
                            <th rowSpan={2}>
                                {getTranslation('cs.dureeTotaleParLavage', context.user.locale)}
                            </th>
                            }
                            <th rowSpan={2}>
                                {getTranslation('cs.frequenceRecettesMensuelles', context.user.locale)}
                            </th>
                            {props.quote.advancedMethod &&
                            <th>
                                {getTranslation('cs.dureeTotaleLavagesMensuels', context.user.locale)}
                            </th>
                            }
                            {Object.keys(context.washingstep.data).map((washingStepId) => {
                                if (props.quote.advancedMethod || (!props.quote.advancedMethod && !context.washingstep.data[washingStepId].advancedMethod)) {
                                    return (
                                        <th key={washingStepId}>
                                            {getTranslation(slugify('cs.nb-washing-step-' + context.washingstep.data[washingStepId].getTranslation({fieldName: 'name', locale: 'fr'})), context.user.locale)}
                                        </th>
                                    )
                                }

                                return null;
                            })}
                            <th rowSpan={2}>
                                {getTranslation('cs.debitCircuit', context.user.locale)}
                            </th>
                            {Object.keys(context.washingstep.data).map((washingStepId) => {
                                if (props.quote.advancedMethod && !context.washingstep.data[washingStepId].rince && context.washingstep.data[washingStepId].productType) {
                                    return (
                                        <th key={washingStepId}>
                                            {getTranslation(slugify('cs.conso-annuelle-washing-step-' + context.washingstep.data[washingStepId].getTranslation({fieldName: 'name', locale: 'fr'})), context.user.locale)}
                                        </th>
                                    )
                                }

                                return null;
                            })}
                            {Object.keys(context.washingstep.data).map((washingStepId) => {
                                if (context.washingstep.data[washingStepId].rince) {
                                    return (
                                        <th key={washingStepId}>
                                            {getTranslation(slugify('cs.conso-annuelle-rince-washing-step-' + context.washingstep.data[washingStepId].getTranslation({fieldName: 'name', locale: 'fr'})), context.user.locale)}
                                        </th>
                                    )
                                }

                                return null;
                            })}
                            <th>
                                {getTranslation('cs.consoEauAnnuelleGlobale', context.user.locale)}
                            </th>
                        </tr>
                        <tr>
                            {props.quote.advancedMethod &&
                            <th className="text-center">
                                {props.quote.getSommeDureeTotalLavagesMensuels(context).toFixed(0)}
                            </th>
                            }
                            {Object.keys(context.washingstep.data).map((washingStepId) => {
                                if (props.quote.advancedMethod || (!props.quote.advancedMethod && !context.washingstep.data[washingStepId].advancedMethod)) {
                                    return (
                                        <th key={washingStepId} className="text-center">
                                            {props.quote.getSommeNbWashingStep(context, washingStepId, null, true).toFixed(0)}
                                        </th>
                                    )
                                }

                                return null;
                            })}
                            {Object.keys(context.washingstep.data).map((washingStepId) => {
                                if (props.quote.advancedMethod && !context.washingstep.data[washingStepId].rince && context.washingstep.data[washingStepId].productType) {
                                    return (
                                        <th key={washingStepId} className="text-center">
                                            {props.quote.getSommeConsoEauAnnuelleProductType(context, CipTypes.actual, context.washingstep.data[washingStepId].productType).toFixed(0)}
                                        </th>
                                    )
                                }

                                return null;
                            })}
                            {Object.keys(context.washingstep.data).map((washingStepId) => {
                                if (context.washingstep.data[washingStepId].rince) {
                                    return (
                                        <th key={washingStepId} className="text-center">
                                            {props.quote.getSommeConsoEauAnnuelleWashingStep(context, washingStepId).toFixed(0)}
                                        </th>
                                    )
                                }

                                return null;
                            })}
                            <th className="text-center">
                                {props.quote.getSommeConsoEauAnnuelleWashingSteps(context).toFixed(0)}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {props.quote.equipments.map((equipment) => {
                            return (
                                <tr key={equipment.id}>
                                    <TableCommonBody quote={props.quote} equipment={equipment} />
                                    {Object.keys(context.washingstep.data).map((washingStepId) => {
                                        if (props.quote.advancedMethod || (!props.quote.advancedMethod && !context.washingstep.data[washingStepId].advancedMethod)) {
                                            return (
                                                <td key={washingStepId}>
                                                    {parseInt(equipment['dureeWs' + washingStepId] ? equipment['dureeWs' + washingStepId] : 0)}
                                                </td>
                                            )
                                        }

                                        return null;
                                    })}
                                    {props.quote.advancedMethod &&
                                    <td>
                                        {equipment.getDureeTotaleLavage(context).toFixed(0)}
                                    </td>
                                    }
                                    <td>
                                        {parseInt(equipment.frequenceRecettesMensuelles ? equipment.frequenceRecettesMensuelles : 0)}
                                    </td>
                                    {props.quote.advancedMethod &&
                                    <td>
                                        {equipment.getDureeTotalLavagesMensuels(context)}
                                    </td>
                                    }
                                    {Object.keys(context.washingstep.data).map((washingStepId) => {
                                        if (props.quote.advancedMethod || (!props.quote.advancedMethod && !context.washingstep.data[washingStepId].advancedMethod)) {
                                            return (
                                                <td key={washingStepId}>
                                                    {equipment.getNbWashingStep(context, washingStepId, null, equipment.cycleLavage, true).toFixed(0)}
                                                </td>
                                            )
                                        }

                                        return null;
                                    })}
                                    <td>
                                        {parseFloat(equipment.debitCircuit ? equipment.debitCircuit : 0).toFixed(0)}
                                    </td>
                                    {Object.keys(context.washingstep.data).map((washingStepId) => {
                                        if (props.quote.advancedMethod && !context.washingstep.data[washingStepId].rince && context.washingstep.data[washingStepId].productType) {
                                            return (
                                                <td key={washingStepId}>

                                                </td>
                                            )
                                        }

                                        return null;
                                    })}
                                    {Object.keys(context.washingstep.data).map((washingStepId) => {
                                        if (context.washingstep.data[washingStepId].rince) {
                                            return (
                                                <td key={washingStepId}>
                                                    {equipment.getConsoEauAnnuelleWashingStep(context, washingStepId)}
                                                </td>
                                            )
                                        }

                                        return null;
                                    })}
                                    <td>

                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </Col>
        </Row>
    );
}