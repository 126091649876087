import React from "react";
import {QuoteContext} from "../../contexts/QuoteContext";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDownload} from "@fortawesome/free-solid-svg-icons";

export default class QuoteDownload extends React.Component {

    handleQuoteDownload = () => {
        if (this.context.currentQuote.isModified) {
            this.context.currentQuote.saveContent(true);
        }
    }

    render() {
        return (
            <FontAwesomeIcon onClick={this.handleQuoteDownload} className={"file-download" + (!this.context.currentQuote.isModified ? " light" : "")} size={"lg"} icon={faDownload}/>
        )
    }
}

QuoteDownload.contextType = QuoteContext;
