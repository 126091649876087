import React, {Component} from "react";
import {UserContext} from "../../contexts/UserContext";
import {Container, Row, Col} from "react-bootstrap";
import classNames from "classnames";
import ProductPanelToggle from "./ProductPanelToggle";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import CarouselItem from "./CarouselItem";
import $ from 'jquery';

export default class ProductPanel extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            isOpen: false,
            panelCategory: null,
            isAnimating: false
        };

        this.responsive = {
            superLargeDesktop: {
                // the naming can be any, depends on you.
                breakpoint: {max: 4000, min: 1024},
                items: 4,
                slidesToSlide: 4
            },
            desktop: {
                breakpoint: {max: 1024, min: 768},
                items: 3,
                slidesToSlide: 3
            },
            tablet: {
                breakpoint: {max: 768, min: 300},
                items: 2,
                slidesToSlide: 2
            },
            mobile: {
                breakpoint: {max: 300, min: 0},
                items: 1,
                slidesToSlide: 1
            }
        };

        this.prevPanelCategory = null;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        // If never open, open it on the selected Category
        if (!prevProps.panelCategory && this.props.panelCategory) {
            // Before opening, set the current slide to 1 if the category is different from the last time it's been closed
            if (this.carousel && this.props.panelCategory !== this.prevPanelCategory) {
                // Remove transition on carousel sliding, we don't want to see the animation
                // when returning on slide 0 after switching category
                $(this.carousel.containerRef.current).children().addClass('no-transition');
                this.carousel.goToSlide(0);
                // Put back the transition
                $(this.carousel.containerRef.current).children().removeClass('no-transition');
            }

            // Set the new category, so the carousel re-renders
            this.setState({
                panelCategory: this.props.panelCategory
            }, () => {
                // After a 150ms delay, open the slider
                setTimeout(() => {
                    this.setState({
                        isOpen: true
                    });
                }, 150);
            });

        // Close the panel by setting the panelCategory to null from the parent component
        } else if (prevProps.panelCategory && !this.props.panelCategory && this.state.isOpen) {
            this.togglePanel();

        // If open and the category is different than the previous one, close the panel, switch Category, open the panel
        } else if (prevProps.panelCategory && this.props.panelCategory && prevProps.panelCategory !== this.props.panelCategory && this.state.isOpen) {
            // Close the panel before switching category
            this.togglePanel(true);

            // once the panel is closed (360ms is the CSS transition duration)...
            setTimeout(() => {
                // Set the current slide to 1
                if (this.carousel) {
                    // Remove transition on carousel sliding, we don't want to see the animation
                    // when returning on slide 0 after switching category
                    $(this.carousel.containerRef.current).children().addClass('no-transition');
                    this.carousel.goToSlide(0);
                    // Put back the transition
                    $(this.carousel.containerRef.current).children().removeClass('no-transition');
                }

                // Set the new category, so the carousel re-renders
                this.setState({
                    panelCategory: this.props.panelCategory
                }, () => {
                    // After a 150ms delay, open the slider
                    setTimeout(() => {
                        this.setState({
                            isOpen: true,
                            isAnimating: false
                        });
                    }, 150);
                });
            }, 360);
        }

        // Save the last category that was set
        if (this.props.panelCategory) {
            this.prevPanelCategory = this.props.panelCategory;
        }
    }

    /**
     * @param isAnimating           To keep hidden the Power button (at the bottom) while switching between 2 categories (close + open)
     * @param panelCategoryButton   Power button (at the bottom) was clicked
     * @param closeButton           Close button was clicked
     */
    togglePanel = (isAnimating, panelCategoryButton, closeButton) => {
        this.setState(function (state) {
            return {
                isOpen: !state.isOpen,
                isAnimating: isAnimating ? true : false,
            }
        });

        if (panelCategoryButton || closeButton) {
            // Close button will set the panelCategory to null, so the panel gets closed
            this.props.onButtonClick(closeButton);
        }
    }

    render() {
        if (!this.props.buttonCategory || !this.context.category || !this.context.category.data || Object.keys(this.context.category.data).length === 0) {
            return null;
        }

        let mayOpen = this.state.panelCategory && this.context.category.data && this.context.category.data[this.state.panelCategory] && this.context.user.categoryProducts[this.state.panelCategory]

        return (
            <>
                <div id="product-panel" className={classNames({
                    open: this.state.isOpen && mayOpen
                })}>
                    {mayOpen &&
                        <>
                            <div onClick={() => this.togglePanel(false, false, true)} className="hide-panel"
                                style={mayOpen && this.context.category.data[this.state.panelCategory].color ? {backgroundColor: this.context.category.data[this.state.panelCategory].color} : null}/>
                            <Container>
                                <Row>
                                    <Col>
                                        <Carousel
                                            ref={el => (this.carousel = el)}
                                            responsive={this.responsive}
                                            showDots={true}
                                            swipeable={true}
                                            dotListClass="pager-item"
                                        >
                                            {this.context.user.categoryProducts[this.state.panelCategory].map((productId, index) =>
                                                <CarouselItem product={this.context.product.data[productId]}
                                                              category={this.context.category.data[this.state.panelCategory]}
                                                              categories={this.context.category.data}
                                                              user={this.context.user}
                                                              key={index}/>
                                            )}
                                        </Carousel>
                                    </Col>
                                </Row>
                            </Container>
                        </>
                    }
                </div>
                <ProductPanelToggle category={this.props.buttonCategory} isVisible={!this.state.isOpen && !this.state.isAnimating} togglePanel={this.togglePanel}/>
            </>
        )
    }
}

ProductPanel.contextType = UserContext
