import React from "react";
import {UserContext} from "../../contexts/UserContext";
import {config} from "../config/Config";

export default class ProductDetail extends React.Component {
    render() {
        return (
            <div className={"print-product-detail" + (this.props.even ? ' even' : '')} data-id={this.props.product.id}>
                <img src={this.props.product.image ? this.props.product.image : config.productPlaceholderImage} alt={this.props.product.name}/>
                <div className="title">
                    {this.props.product.name}{this.props.product.isBiocide ? '*' : ''}
                </div>
                <div className="text">
                    {this.props.product.getTranslation({fieldName: 'description', locale: this.context.user.locale})}
                </div>
            </div>
        );
    }
}

ProductDetail.contextType = UserContext;