import React, {Component} from "react";
import {Button, Col, Row} from "react-bootstrap";
import {QuoteContext} from "../../contexts/QuoteContext";
import {Quote} from "../../model/Quote";
import {UserContext} from "../../contexts/UserContext";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus, faTimesCircle} from "@fortawesome/free-solid-svg-icons";
import RenameModal from "../tools/RenameModal";

export default class QuoteList extends Component {

    state = {showNameChangeModal: false, quoteName: ''}

    setSelectedQuote(quote) {
        if (!quote.app) {
            quote.app = this.context.currentQuote.app;
        }
        quote.setCurrent();
    }

    switchQuoteHandler(user, e) {
        user.setDataFromQuote(e);
        this.setSelectedQuote(e);
        this.setState({quoteName: this.context.currentQuote.name});
    }

    removeQuoteHandler(user, quote) {
        if (!quote.app) {
            quote.app = this.context.currentQuote.app;
        }
        quote.removeFromAvailableQuotes(() => {
            this.switchQuoteHandler(user, this.context.availableQuotes[0]);
        });
    }

    render() {

        return (
            <UserContext.Consumer>
                {({user, species, country, currency, productgroup}) => {
                    if (user && species.data.length && this.context.availableQuotes.length > 0) {
                        return (
                            <>
                                {this.state.showNameChangeModal &&
                                    <RenameModal closeModal={() => {this.setState({showNameChangeModal: false});}} setName={(name) => {this.context.currentQuote.setName(name)}} />
                                }

                                <Row className={"quoteList"}>
                                    {
                                        this.context.availableQuotes.map((e) => {

                                            let additionnalClass = '';
                                            if (this.context.currentQuote.id === e.id) {
                                                additionnalClass = ' current';
                                                user.species = e.species;

                                                //pricelist
                                                if (!e.priceListLoaded) {
                                                    let p = productgroup.data[user.productGroup];
                                                    if (p) {
                                                        e.prices = Object.assign({}, JSON.parse(p.prices));
                                                        e.priceListLoaded = true;
                                                    }
                                                }
                                            }

                                            return (
                                                <Col key={e.id}>
                                                    <div className={"quoteTab" + additionnalClass} onDoubleClick={() => this.setState({showNameChangeModal: true})} onClick={() => {
                                                        this.switchQuoteHandler(user, e);
                                                    }}>
                                                        { species.data[e.species] &&
                                                            <img className={"icon"} src={species.data[e.species].thumbnail} alt={species.data[e.species].getTranslation({fieldName: 'name', locale: user.locale})}/>
                                                        }
                                                        {currency.data[e.currency] &&
                                                            <span className={"currency"}>{currency.data[e.currency].sign}</span>
                                                        }
                                                        { country.data[e.country] &&
                                                            <img className={"country"} src={country.data[e.country].image} alt={country.data[e.country].getTranslation({fieldName: 'name', locale: user.locale})}/>
                                                        }
                                                        <span>{e.name}</span>
                                                        <div style={this.context.availableQuotes.length === 1 ? {display: 'none'} : {display: 'block'}} className={"remove"} onClick={(ev) => {
                                                            ev.stopPropagation();
                                                            this.removeQuoteHandler(user, e)
                                                        }}>
                                                            <FontAwesomeIcon icon={faTimesCircle}/>
                                                        </div>

                                                    </div>

                                                </Col>
                                            );
                                        })
                                    }
                                    <Col style={this.context.availableQuotes.length >= 5 ? {display: 'none'} : {display: 'block'}} xs={1} className={"newQuote"}>
                                        <Button size={"xs"} onClick={() => {
                                            let q = new Quote();
                                            this.context.availableQuotes.push(q);
                                            q.app = this.context.currentQuote.app;
                                            q.currency = this.context.currentQuote.currency;
                                            q.country = this.context.currentQuote.country;
                                            q.locale = this.context.currentQuote.locale;
                                            this.setSelectedQuote(q);
                                            this.switchQuoteHandler(user, q);
                                        }}>
                                            <FontAwesomeIcon icon={faPlus} size={"xs"}/>
                                        </Button>
                                    </Col>
                                </Row>
                            </>
                        );
                    } else {
                        return null;
                    }
                }}
            </UserContext.Consumer>
        )
    }
}
QuoteList.contextType = QuoteContext;
