import {Row, Col, Form} from "react-bootstrap";
import React, {Component} from "react";
import {UserContext} from "../../contexts/UserContext";
import {QuoteContext} from "../../contexts/QuoteContext";

export default class GeneralDataBottomOption extends Component {

    constructor(props) {
        super(props);
        this.handleValueChange = this.handleValueChange.bind(this);
    }

    UNSAFE_componentWillMount() {
        let value = this.context.currentQuote.generalData[this.props.option.name];
        if (value === undefined) {
            value = this.props.option.defaultValue || '';
            this.setGeneralDataValue(this.props.option.name, value);
        }
    }

    setGeneralDataValue(key, value) {
        this.context.currentQuote.generalData[key] = value;
        this.context.currentQuote.updateContextState();
        this.context.currentQuote.refreshCalculationGeneralData(key);
    }

    handleValueChange(e) {
        let value = e.target.value;
        if (parseInt(value) <= 0 || isNaN(value)) {
            value = 0;
        }
        this.setGeneralDataValue(this.props.option.name, value);
    }

    render() {
        return (
            <UserContext.Consumer>
                {({user}) => {
                    return (
                        <Row className={"gd-bo no-gutters"}>
                            <Col><span>{this.props.option.getTranslation({fieldName: 'name', locale: user.locale})}</span></Col>
                            <Col xs={2}>
                                <Form.Control as={"input"}
                                              onChange={this.handleValueChange}
                                              value={this.context.currentQuote.generalData[this.props.option.name] ?? ''}/>
                            </Col>
                        </Row>
                    )
                }}
            </UserContext.Consumer>
        )
    }
}

GeneralDataBottomOption.contextType = QuoteContext;
