import React from "react";
import {getTranslation} from "../tools/Translation";
import classNames from "classnames";
import $ from "jquery";
import {UserContext} from "../../contexts/UserContext";

export default class ProductDiscount extends React.Component {
    constructor(props) {
        super(props);

        this.textWidthElement = React.createRef();
        this.inputElement = React.createRef();
    }

    componentDidMount() {
        this.resizeInput();
    }

    resizeInput = () => {
        if (this.textWidthElement.current) {
            this.textWidthElement.current.innerHTML = this.props.productData.discount;
            $(this.inputElement.current).width($(this.textWidthElement.current).outerWidth());
        }
    }

    onChangeHandler = (e) => {
        this.props.productData.setDiscount(e.target.value);

        if (e.target !== document.activeElement) {
            this.onBlurHandler(e);
        }

        this.resizeInput();

        if (this.context.category && this.context.category.data && this.context.category.data[this.props.productData.categoryId]) {
            if (!this.props.productData.loaned) {
                const category = this.context.category.data[this.props.productData.categoryId];
                if (this.props.currentQuote.settings['options.udderhygienediscount'] && category.isUdderHygiene) {
                    this.props.currentQuote.setUdderHygieneProductsDiscount(e.target.value, this.context.category.data);
                } else if (this.props.currentQuote.settings['options.globaldiscount']) {
                    this.props.currentQuote.setProductsDiscount(e.target.value);
                }
            }
        }
    }

    onFocusHandler = (e) => {
        if (parseFloat(e.target.value) === 0) {
            e.target.value = '';
        }

        $(this.inputElement.current).select();
    }

    onBlurHandler = (e) => {
        this.props.productData.setDiscount(parseFloat(this.props.productData.discount || 0).toFixed(2));
    }

    deleteDiscountHandler = () => {
        this.props.productData.removeDiscount();

        if (this.context.category && this.context.category.data && this.context.category.data[this.props.productData.categoryId]) {
            const category = this.context.category.data[this.props.productData.categoryId];
            if (this.props.currentQuote.settings['options.udderhygienediscount'] && category.isUdderHygiene) {
                this.props.currentQuote.removeUdderHygieneProductsDiscount(this.context.category.data);
            } else if (this.props.currentQuote.settings['options.globaldiscount']) {
                this.props.currentQuote.removeProductsDiscount();
            }
        }
    }
    
    render() {
        if (!this.props.productData.hasDiscount) {
            return null;
        }

        return (
            <div className="product-cost product-cost-padding">
                <div className="flex-grow" />

                <div className="cost-box">
                    <div className="delete-discount" onClick={this.deleteDiscountHandler} />
                </div>

                <div className="cost-box discount">
                    {getTranslation("cost.discount", this.props.user.locale)}
                </div>

                <div className="cost-box">
                    <input ref={this.inputElement} className={classNames({
                        "product-input": true,
                        "money": true,
                        "border-danger": !parseFloat(this.props.productData.discountPrice || 0)
                    })} value={this.inputElement.current === document.activeElement ? this.props.productData.discount : parseFloat(this.props.productData.discount || 0).toFixed(2)} onChange={this.onChangeHandler} onFocus={this.onFocusHandler} onBlur={this.onBlurHandler} />
                    <span ref={this.textWidthElement} className="hidden-element"/>
                    &nbsp;%
                </div>

                <div className="cost-box">
                    <div className="cost-text">
                        <span className="mr-2">=</span>
                        {this.props.productData.discountPrice > 0 &&
                            <>
                                -
                            </>
                        }
                        { this.props.productData.discountPrice.toFixed(2) } {this.props.currentQuote.getCurrencySign()}
                    </div>
                </div>
            </div>
        )
    }
}

ProductDiscount.contextType = UserContext;