export default class Encryption {

    key = [/\r\n/g, "=/+9654328710zyxwmlkjihgfedcvutsrqponbaONMLKJIHGFEDCBAZYXWVUTSRQP", /[^A-Za-z0-9+/=]/g];
    getKey = r => "string" == typeof this.key[r] ? this.key[r].split("").reverse().join("") : this.key[r];
    _keyStr = this.getKey(1);

    /**
     * NOT TESTED
     * @param r
     * @returns {string}
     */
    encrypt = r => {
        let t, o, e, a, C, n, i, h = "", c = 0;
        for (r = this._utf8_encrypt(r); c < r.length;) {
            t = r.charCodeAt(c++);
            o = r.charCodeAt(c++);
            e = r.charCodeAt(c++);
            a = t >> 2;
            C = ((3 & t) << 4) | (o >> 4);
            n = ((15 & o) << 2) | (e >> 6);
            i = 63 & e;
            if (isNaN(o)) {
                n = i = 64;
            } else {
                if (isNaN(e)) {
                    i = 64;
                }
            }
            h = h + this._keyStr.charAt(a) + this._keyStr.charAt(C) + this._keyStr.charAt(n) + this._keyStr.charAt(i);
        }
        return h
    }

    decrypt = r => {
        let t = "";
        let o = "";
        let e = "";
        let a = "";
        let C = "";
        let n = "";
        let i = "";
        let h = "";
        let c = 0;
        for (r = r.replace(this.getKey(2), ""); c < r.length;) {
            a = this._keyStr.indexOf(r.charAt(c++));
            C = this._keyStr.indexOf(r.charAt(c++));
            n = this._keyStr.indexOf(r.charAt(c++));
            i = this._keyStr.indexOf(r.charAt(c++));
            t = (a << 2) | (C >> 4);
            o = ((15 & C) << 4) | (n >> 2);
            e = ((3 & n) << 6) | i;
            h += String.fromCharCode(t);
            if (64 !== n) {
                h += String.fromCharCode(o);
            }
            if (64 !== i) {
                h += String.fromCharCode(e);
            }
        }
        return this._utf8_decrypt(h)
    }

    /**
     * NOT TESTED
     * @param r
     * @returns {*}
     * @private
     */
    _utf8_encrypt = r => {
        let t = "";
        r = r.replace(this.getKey(0), "\n");
        for (let o = 0; o < r.length; o++) {
            let e = r.charCodeAt(o);
            if (128 > e) {
                t += String.fromCharCode(e);
            } else {
                if (e > 127 && 2048 > e) {
                    t += String.fromCharCode((e >> 6) | 192);
                    t += String.fromCharCode((63 & e) | 128);
                } else {
                    t += String.fromCharCode((e >> 12) | 224);
                    t += String.fromCharCode((e >> (6 & 63) )| 128);
                    t += String.fromCharCode((63 & e) | 128);
                }
            }
        }
        return t
    }

    _utf8_decrypt = r => {
        let c2 = 0;
        let c3 = 0;
        let t = "";
        for (let o = 0; o < r.length;) {
            let e = r.charCodeAt(o);
            if (e < 128) {
                t += String.fromCharCode(e);
                o++;
            } else if (e > 191 && e < 224) {
                c2 = r.charCodeAt(o + 1);
                t += String.fromCharCode(((31 & e) << 6) | (63 & c2));
                o += 2;
            } else {
                c2 = r.charCodeAt(o + 1);
                c3 = r.charCodeAt(o + 2);
                t += String.fromCharCode(((15 & e) << 12) | ((63 & c2) << 6) | (63 & c3));
                o += 3;
            }

        }
        return t;
    }
}