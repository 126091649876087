import React from "react";
import {getTranslation} from "../tools/Translation";
import {UserContext} from "../../contexts/UserContext";

export default class CalculationAdditionalText extends React.Component {
    render() {
        const regexp = /[\w]+\.[\w]+/g;
        if (this.props.additionalText.match(regexp)) {
            const parts = this.props.additionalText.split('.');
            let text = '';
            switch (parts[0]) {
                // Unit.name
                case 'Unit':
                    switch(parts[1]) {
                        case 'name':
                            const unitId = this.props.product.getFormatUnit();
                            if (unitId) {
                                text = ' ' + this.context.unit.data[unitId].getTranslation({fieldName: 'name', locale: this.context.user.locale}) || '';
                            }
                            break;
                        default: break;
                    }
                    break;

                // ProductConsumption.unit
                case 'ProductConsumption':
                    switch(parts[1]) {
                        case 'unit':
                            const consumption = this.props.productData.getEstimatedConsumptionObject();
                            if (consumption) {
                                if (consumption.unit) {
                                    text = ' ' + this.context.unit.data[consumption.unit].getTranslation({fieldName: 'name', locale: this.context.user.locale}) || '';
                                }
                            }
                            break;
                        default: break;
                    }
                    break;
                default: break;
            }

            return text;
        } else {
            return getTranslation(this.props.additionalText, this.context.user.locale);
        }
    }
}

CalculationAdditionalText.contextType = UserContext;