import React from "react";
import {QuoteContext} from "../../contexts/QuoteContext";
import {UserContext} from "../../contexts/UserContext";
import classNames from "classnames";
import {QuoteProduct} from "../../model/QuoteProduct";
import $ from "jquery";

export default class ProductFormats extends React.Component {
    constructor(props) {
        super(props);
        this.highlightFormats = this.props.highlightFormats || false;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.highlightFormats !== this.props.highlightFormats) {
            this.highlightFormats = this.props.highlightFormats;
            this.doHighlightFormats();
        }
    }

    onClickHandler = (productFormatId) => {
        this.context.currentQuote.categories[this.props.category.id][this.props.product.id].setFormat(productFormatId);

        // Set price
        const quoteProduct = this.context.currentQuote.categories[this.props.category.id][this.props.product.id];
        if (!quoteProduct.isPriceModified && this.context.currentQuote.prices[productFormatId]) {
            quoteProduct.setPrice(this.context.currentQuote.prices[productFormatId]);
        }
    }

    doHighlightFormats = () => {
        $('.formats-' + this.props.product.id + ' span').addClass('blink');
        setTimeout(() => {
            $('.formats-' + this.props.product.id + ' span').removeClass('blink');
        }, 1000)
    }

    render() {
        let category = this.props.category;
        if (!this.props.user.productFormats[this.props.product.id] || this.props.user.productFormats[this.props.product.id].length === 0) {
            return null;
        }

        let quoteProduct = this.context.currentQuote.categories[category.id][this.props.product.id] || new QuoteProduct();

        return (
            <div className={"formats-" + this.props.product.id}>
                <UserContext.Consumer>
                    {({product}) => {
                        return this.props.user.productFormats[this.props.product.id].map((productFormatId) =>
                            <span key={productFormatId} id={'format-' + productFormatId} onClick={(e) => this.onClickHandler(productFormatId)} style={category.color ? {backgroundColor: category.color} : null}
                                  className={classNames({
                                      "product-package": true,
                                      "pointer": true,
                                      "selected": quoteProduct.format === productFormatId
                                  })}>
                                {product.data[this.props.product.id].getObjectById('formats', productFormatId).name}
                            </span>
                        )
                    }}
                </UserContext.Consumer>
            </div>
        )
    }
}

ProductFormats.contextType = QuoteContext;